import { H2 } from 'components/common/H2'
import { P } from 'components/common/P'
import config from 'config'
import { useABExperiment, usePrice } from 'hooks'
import React, { useMemo } from 'react'
import {
  CrossedOutPrice,
  DiscountedPrice,
  DiscountedPriceWrapper,
  PriceDetailsWithUnderwriterWrapper,
  PriceDetailsWrapper,
  PriceInfoKlarnaWrapper,
  PriceWrapper,
  UnderwriterImageWrapper,
} from './PriceDetails.styles'
import policyDuration from '../../../@forms/PolicyDuration'

const PriceDetails = () => {
  const { price, coverTypeWording, totalPriceFixed } = usePrice()

  const affiliateDiscount = price.campaign.Discount

  const positiveDiscountValue = useMemo(
    () => Math.abs(affiliateDiscount),
    [affiliateDiscount],
  )

  const hasAffiliateDiscount = useMemo(
    () => positiveDiscountValue > 0,
    [positiveDiscountValue],
  )

  const discountedTotalPrice = useMemo(
    () => `£${(+totalPriceFixed + positiveDiscountValue).toFixed(2)}`,
    [totalPriceFixed, positiveDiscountValue],
  )

  const coverTypeFullWording = useMemo(() => {
    if (hasAffiliateDiscount) {
      return `${coverTypeWording} - includes £${positiveDiscountValue.toFixed(
        2,
      )} discount`
    }
    return coverTypeWording
  }, [coverTypeWording, hasAffiliateDiscount, positiveDiscountValue])

  let underwriterName = price.Underwriter
  if (underwriterName === 'Kgm') {
    underwriterName = underwriterName.toUpperCase()
  }

  if (price.TotalPrice > 0 && price.Underwriter) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <PriceDetailsWithUnderwriterWrapper>
          <UnderwriterImageWrapper>
            <img
              // @ts-ignore
              src={require(`../../../../images/Underwriters/${underwriterName}LogoForUnderwriterSelection.png`)}
              alt={price.Underwriter}
              className='price-comparison-underwriter-logo'
            />
          </UnderwriterImageWrapper>
          <PriceInfoKlarnaWrapper>
            <PriceDetailsWrapper>
              <P>Total price</P>
              {hasAffiliateDiscount && (
                <>
                  <P style={{ color: 'red', fontWeight: 'bold' }}>
                    WAS <del>{discountedTotalPrice}</del>
                  </P>
                  <P style={{ fontWeight: 'bold', fontSize: 24 }}>
                    NOW £{totalPriceFixed}
                  </P>
                </>
              )}
              {!hasAffiliateDiscount && (
                <P style={{ fontWeight: 'bold', fontSize: 36 }}>
                  £{totalPriceFixed}
                </P>
              )}
              <P>{coverTypeFullWording}</P>
            </PriceDetailsWrapper>
          </PriceInfoKlarnaWrapper>
        </PriceDetailsWithUnderwriterWrapper>
      </div>
    )
  }
  return null
}

export default PriceDetails
