import FormField from 'components/@common/FormField'
import DatePicker from 'components/@common/DatePicker'
import React from 'react'
import { useField } from 'formik'
import { VALID_DATE_OF_BIRTH_WORDING } from '../../DriverDetailsForm.constants'

interface Props {
  minimumAge?: {
    years: number
    months: number
  }
  maximumAge?: {
    years: number
    months: number
  }
}

const DateOfBirth: React.FC<Props> = ({ minimumAge, maximumAge }) => {
  const fieldName = 'dateOfBirth'
  const [field, meta, helpers] = useField(fieldName)

  return (
    <FormField name={fieldName} label="Date of birth">
      <DatePicker
        name={fieldName}
        value={field.value}
        setValue={helpers.setValue}
        setError={helpers.setError}
        setTouched={helpers.setTouched}
        errorMessage={VALID_DATE_OF_BIRTH_WORDING}
      />
    </FormField>
  )
}

export default DateOfBirth
