import { UPDATE_VALIDATION_RISK_DATA_COLLECTION } from '../constants'
import { put, takeLatest, select } from 'redux-saga/effects'
import { toggleFormIsValid, updateValidation } from '../actions'
import { getValidation } from '../selector'
import { getSelectedScheme } from '../../App/selector'
import {
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
  PRIVATE_CAR_SCHEME,
  COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_PRIVATE_CAR_SCHEME,
  VEHICLE_LEARNER_SCHEME,
  MOTORCYCLE_VEHICLE_SCHEME,
} from '../../Vehicle/constants'

export function* formValidation() {
  const validation = yield select(getValidation)
  const fields = validation.fields
  const validForm = Object.keys(fields).every((key) => {
    return fields[key] === ''
  })
  yield put(
    toggleFormIsValid({
      valid: validForm,
    }),
  )
}

export function* formValidationSaga() {
  yield takeLatest(UPDATE_VALIDATION_RISK_DATA_COLLECTION, formValidation)
}

export function* updateRequiredFieldsSaga() {
  const scheme = yield select(getSelectedScheme)
  let fields = {}

  switch (scheme) {
    case MOTORCYCLE_VEHICLE_SCHEME:
      fields = {
        LicenceHeldDurationError: '',
        UseOfVehicleError: '',
        OwnerOfVehicleError: '',
        OccupationError: '',
        MotorcycleEntitlementError: '',
        MotorcycleRidingExperienceError: '',
        MotorcycleOvernightParkingError: '',
        MotorcycleReasonForTempcoverPurchaseError: '',
        PreviouslyRiddenMotorcycleVehicleError: '',
      }
      break
    case PRIVATE_CAR_SCHEME:
    case COMMERCIAL_VEHICLE_SCHEME:
    case IMPOUNDED_PRIVATE_CAR_SCHEME:
    case IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME:
      fields = {
        LicenceHeldDurationError: null,
        OwnerOfVehicleError: '',
        HasVehicleGotAnnualInsuranceEnumerationError: '',
        OccupationError: '',
        DrivingLicenceNumberError: '',
      }
      break
    case VEHICLE_LEARNER_SCHEME:
      fields = {
        LicenceHeldDurationError: '',
        OwnerOfVehicleError: '',
        HasVehicleGotAnnualInsuranceEnumerationError: null,
        OccupationError: null,
        DrivingLicenceNumberError: '',
      }
      break
    default:
      break
  }
  yield put(updateValidation(fields))
}
