import * as yup from 'yup'
import { ReasonForPurchaseOtherTextValidation } from '../ReasonForPurchaseOtherText/ReasonForPurchaseOtherText.validation'
import { ReasonForPurchaseEnum } from './ReasonForPurchaseEnumeration'

export const ReasonForPurchaseValidation = yup.string()

export const ReasonForPurchaseSchema = yup.object().shape({
  reasonForPurchase: ReasonForPurchaseValidation.required(
    'Please enter a reason for cover',
  ),
  reasonForPurchaseOtherText: yup
    .string()
    .when('reasonForPurchase', {
      is: ReasonForPurchaseEnum.other,
      then: ReasonForPurchaseOtherTextValidation.required(
        'Please enter a reason for other',
      ),
    })
    .optional()
    .nullable(),
})
