import styled from 'styled-components'
import { Button } from '../common/Button'

export const DurationButton = styled(Button)`
  display: inline-block;
  margin: 5px;
  background-color: ${(props) =>
    props.theme.durationOptions.durationButtonForeColour};
  border: 1px solid ${(props) => props.theme.primaryTextColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  color: ${(props) => props.theme.primaryTextColour};
  width: 38px;
  height: 38px;
  text-align: center;
  font-size: 18px;
  font-family: BrandonText;
  padding: 0;

  :disabled {
    background-color: ${(props) => props.theme.buttonBackgroundColour};
    color: #fff;
  }
`

export const DefaultDurationsWrapper = styled.div`
  text-align: center;

  button {
    width: 85px;
  }

  p {
    text-align: center;
  }
`

export const DurationTypesWrapper = styled.div`
  text-align: center;

  button {
    width: 80px;
    height: 40px;
    text-align: center;
  }
`

export const AllDurationsWrapper = styled.div`
  text-align: center;
`

export const SubmitDurationWrapper = styled.div`
  text-align: center;
  button {
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const SecondaryDurationButton = styled(DurationButton)`
  border: 1px solid ${(props) => props.theme.durationOptions.secondaryColour};
  color: ${(props) => props.theme.durationOptions.secondaryColour};
  :disabled {
    background-color: ${(props) => props.theme.durationOptions.secondaryColour};
    color: #fff;
  }
`
