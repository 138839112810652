import React, { useEffect } from 'react'
import Spinner from '../../../../components/Spinner'
import config from '../../../../config'
import {
  LoadingBanner,
  BannerH2Secondary,
  ConfusedLogo,
  StaticBannerTextTertiary,
  SpinnerContainer,
  BannerTitle,
} from '../../styles'

const ConfusedLoadingBanner = ({
  requestConfusedQuickQuoteResponse,
  confusedQuickQuoteDateTimeRequested,
  confusedQuickQuoteLoadCount,
  registrationNumber,
}) => {
  useEffect(() => {
    let timeOut = 0
    if (confusedQuickQuoteDateTimeRequested) {
      const now = new Date().getTime()
      timeOut =
        parseInt(confusedQuickQuoteDateTimeRequested) +
        parseInt(config.CONFUSED_QUICK_QUOTE_MAX_GENERATION_DURATION) -
        now +
        500
    }

    if (timeOut > 0) {
      setTimeout(() => requestConfusedQuickQuoteResponse(), timeOut)
    } else if (confusedQuickQuoteLoadCount === 0) {
      requestConfusedQuickQuoteResponse()
    } else {
      setTimeout(
        () => requestConfusedQuickQuoteResponse(),
        config.CONFUSED_QUICK_QUOTE_RETRY_DELAY,
      )
    }
  }, [
    requestConfusedQuickQuoteResponse,
    confusedQuickQuoteDateTimeRequested,
    confusedQuickQuoteLoadCount,
  ])

  return (
    <LoadingBanner id='ConfusedUpsellLoadingBanner'>
      <BannerTitle>Annual car insurance</BannerTitle>
      <BannerH2Secondary>
        Cheapest 3 annual car insurance prices for {registrationNumber} from{' '}
        <ConfusedLogo alt='Confused.com' />
      </BannerH2Secondary>
      <StaticBannerTextTertiary>
        Finding your prices... This won't take long!
      </StaticBannerTextTertiary>
      <SpinnerContainer>
        <Spinner size='smaller' color={'#264694'} />
      </SpinnerContainer>
      <StaticBannerTextTertiary>
        <strong>Not ready yet?</strong> Don't worry, your quote will be saved to
        your Confused.com account so you can come back to it later.
      </StaticBannerTextTertiary>
    </LoadingBanner>
  )
}
export default ConfusedLoadingBanner
