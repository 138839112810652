import React from 'react'
import PropTypes from 'prop-types'
import { Select } from '../common/Select'

export const VehicleBuilder = ({
  searchedVehicle,
  vehicleOptions,
  handleSelect,
}) => {
  return (
    <>
      <Select
        id='VehicleBuilderMake'
        name='makes'
        placeholder='Make'
        value={searchedVehicle.Make || ''}
        onChange={handleSelect}
        options={vehicleOptions.makes}
      />
      {searchedVehicle.Make &&
        vehicleOptions.models &&
        vehicleOptions.models.length > 0 && (
          <Select
            id='VehicleBuilderModel'
            name='models'
            placeholder='Model'
            value={searchedVehicle.Model || ''}
            onChange={handleSelect}
            options={vehicleOptions.models}
          />
        )}
      {searchedVehicle.Model &&
        vehicleOptions.variants &&
        vehicleOptions.variants.length > 0 && (
          <Select
            id='VehicleBuilderVariant'
            name='variants'
            placeholder='Variant'
            value={searchedVehicle.Abi || ''}
            onChange={handleSelect}
            options={vehicleOptions.variants}
          />
        )}
    </>
  )
}

VehicleBuilder.propTypes = {
  searchedVehicle: PropTypes.shape({
    Make: PropTypes.string,
    Models: PropTypes.string,
    Abi: PropTypes.string,
  }),
  vehicleOptions: PropTypes.shape({
    makes: PropTypes.array,
    models: PropTypes.array,
    variants: PropTypes.array,
  }),
  handleSelect: PropTypes.func,
}
