import LoadingScreen from 'components/LoadingScreen'
import config from 'config'
import { UserManager, UserManagerSettings } from 'oidc-client-ts'
import React, { useEffect } from 'react'

const Login = () => {
  useEffect(() => {
    const queryParameters = window.location.search
    localStorage.setItem('queryParameters', queryParameters)
    const authConfig: UserManagerSettings = {
      authority: config.CUSTOMER_ACCOUNT_ID_SERVER_URL,
      client_id: 'teslaClient',
      redirect_uri: `${config.HOST_PATH}/auth/callback`,
      response_type: 'code',
      filterProtocolClaims: true,
      loadUserInfo: true,
      scope: 'openid profile api',
      post_logout_redirect_uri: config.SITE_URL,
    }
    const mgr = new UserManager(authConfig)
    mgr.getUser().then(() => {
      // always sign in from bug of switching users and both visit tesla on same device
      // the user access token always matches the store token of UserManager
      mgr.signinRedirect()
    })
  }, [])

  return <LoadingScreen message='Loading' />
}

export default Login
