import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
`

export const PreSelectedDurationWrapper = styled(Wrapper)`
  button {
    width: 85px;
  }
`

export const ButtonWrapper = styled(Wrapper)`
  margin-top: 15px;
  button {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const ParagraphWrapper = styled(Wrapper)``
