import defaultTheme from './default'

let uSwitchTheme = { ...defaultTheme }

const uswitchBlack = '#141424'
const uswitchWhite = '#fff'
const uswitchblue = '#EAF4FF'

const fontFamily = 'Founders Grotesk, sans-serif'

const iconColour = uswitchWhite

const primaryColour = uswitchBlack
const secondaryColour = uswitchBlack
const gradientEnd = uswitchWhite
const headerBackgroundColour = uswitchWhite
const labelColour = uswitchBlack
uSwitchTheme.fontFamily = fontFamily
uSwitchTheme.secondaryFontFamily = 'BrandonText'
uSwitchTheme.linkColour = uswitchBlack
uSwitchTheme.inputFontFamily = 'BrandonText'
uSwitchTheme.LegalDecsfontFamily = 'Lato'
uSwitchTheme.radioFieldbackground = uswitchWhite
uSwitchTheme.labelTextColour = labelColour
uSwitchTheme.backgroundColour = uswitchWhite
uSwitchTheme.infoIconColour = uswitchBlack
uSwitchTheme.primaryColour = primaryColour
uSwitchTheme.primaryTextColour = '#333'
uSwitchTheme.headerFontColour = primaryColour
uSwitchTheme.buttonAccentColour = uswitchBlack
uSwitchTheme.buttonBackgroundColour = uswitchBlack
uSwitchTheme.formControlValidIcon = {
  ...defaultTheme.formControlValidIcon,
  colour: primaryColour,
}
uSwitchTheme.infoBorderProperties = '2px solid #141424'
uSwitchTheme.formControlBorderRadius = '0px'
uSwitchTheme.breadcrumb = {
  ...defaultTheme.breadcrumb,
  activeColor: uswitchBlack,
  activeBorderColor: uswitchBlack,
  defaultColor: uswitchWhite,
  defaultBorderColor: uswitchBlack,
  defaultBarColor: uswitchBlack,
  completeColor: uswitchBlack,
  completeBorderColor: uswitchBlack,
  completeBarColor: uswitchBlack,
}
uSwitchTheme.spinner = {
  ...defaultTheme.spinner,
  primaryColour: primaryColour,
  textColour: primaryColour,
}
uSwitchTheme.durationOptions = {
  ...defaultTheme.durationOptions,
  primaryColour: primaryColour,
  secondaryColour: secondaryColour,
  durationButtonForeColour: uswitchWhite,
}
uSwitchTheme.button = {
  ...defaultTheme.button,
  backgroundColour: secondaryColour,
  addon: {
    backgroundColour: secondaryColour,
    disabledBackground: secondaryColour,
    disabledFont: uswitchWhite,
  },
}
uSwitchTheme.errorBackgroundColour = uswitchWhite
uSwitchTheme.header = {
  ...defaultTheme.header,
  background: headerBackgroundColour,
  borderBottom: 'none',
}
uSwitchTheme.headerLinkStyled = {
  ...defaultTheme.headerLinkStyled,
  textColour: uswitchBlack,
  padding: '0px',
  hover: {
    textDecoration: 'underline',
    color: uswitchWhite,
    backgroundColour: primaryColour,
    padding: '0px',
  },
}

uSwitchTheme.gradient = {
  ...defaultTheme.gradient,
  background: uswitchblue,
  startColour: gradientEnd,
}
uSwitchTheme.icon = {
  ...defaultTheme.icon,
  color: iconColour,
  backgroundColor: uswitchBlack,
}
uSwitchTheme.GbIcon = iconColour
uSwitchTheme.formControlValidIcon = {
  ...defaultTheme.formControlValidIcon,
  colour: uswitchWhite,
  backgroundColour: primaryColour,
}
uSwitchTheme.headerFontColour = primaryColour
uSwitchTheme.inputBorderColour = uswitchBlack
uSwitchTheme.successColour = primaryColour
uSwitchTheme.marketingPreferencesColour = secondaryColour
uSwitchTheme.svg = {
  ...defaultTheme.svg,
  primaryColour: primaryColour,
  accentColour: '#141414',
}
uSwitchTheme.icons = {
  border: '',
  height: '33px',
  width: '43px',
}

export default uSwitchTheme
