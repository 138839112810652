import FormField from 'components/@common/FormField'
import Occupation from 'components/Occupation'
import { VEHICLE_HAS_ANNUAL_INSURANCE } from 'components/VehicleHasInsurance/constants'
import { VEHICLE_OWNERS } from 'components/VehicleOwner/constants'
import {
  clearIdentityCheck,
  updateRiskData,
} from 'containers/RiskDataCollectionPage/actions'
import { updateVehicleValue } from 'containers/Vehicle/actions'
import { useFormikContext } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import Select from '../../../@common/Select'
import Address from '../../../Address'
import {
  DateOfBirth,
  FirstName,
  LicenceHeldDuration,
  Surname,
  Title,
  VehicleValue,
} from '../@CommonFields'
import { LEARNER_LICENCE_HELD_DURATIONS } from '../@CommonFields/LicenceHeldDuration/LIcenceHeldDuration.constants'
import { LearnerFormData } from './LearnerForm.types'

const LearnerForm: React.FC = () => {
  const dispatch = useDispatch()

  const { values, setFieldValue, errors } = useFormikContext<LearnerFormData>()
  const { dateOfBirth: dateOfBirthValue } = values

  // TODO: Revisit this, at the minute we are just
  // reusing logic from before to store descriptions in redux.
  const handleSelect = (name: string, value: string, label: string) => {
    // We need to capitalise first letter, as in state they are using capitals at the start
    const capitalisedName = name.charAt(0).toUpperCase() + name.slice(1)
    switch (capitalisedName) {
      case 'LicenceType':
        return dispatch(clearIdentityCheck())
      case 'ResidencyType':
        if (value === 'PermanentUkResident') {
          setFieldValue('ukResidencyDuration', '')
          return dispatch(
            updateRiskData({
              Expat: false,
              UkResidencyDuration: {
                value: '',
                description: '',
              },
            }),
          )
        }
        return dispatch(
          updateRiskData({
            Expat: true,
          }),
        )

      default:
        return dispatch(
          updateRiskData({
            [capitalisedName]: {
              value,
              description: label,
            },
          }),
        )
    }
  }

  return (
    <>
      <Title onSelect={handleSelect} />
      <FirstName />
      <Surname />

      <FormField label="Driver's occupation" name='occupation'>
        <Occupation />
      </FormField>
      <DateOfBirth />
      <FormField
        name='ownerOfVehicle'
        label='Vehicle owner'
        isValidIcon={false}
      >
        <Select
          id='OwnerOfVehicle'
          placeholder='Select vehicle owner'
          onChange={handleSelect}
          name='ownerOfVehicle'
          options={VEHICLE_OWNERS}
        />
      </FormField>
      <FormField
        name='hasVehicleGotAnnualInsuranceEnumeration'
        label='Does the vehicle have annual insurance'
        isValidIcon={false}
      >
        <Select
          id='HasVehicleGotAnnualInsuranceEnumeration'
          placeholder='Please select'
          onChange={handleSelect}
          name='hasVehicleGotAnnualInsuranceEnumeration'
          options={VEHICLE_HAS_ANNUAL_INSURANCE}
        />
      </FormField>

      <Address />
      {dateOfBirthValue && !errors.dateOfBirth && (
        <LicenceHeldDuration
          label='Provisional licence held duration'
          placeholder="Please select how long the driver has held their provisional licence"
          licenceDurations={LEARNER_LICENCE_HELD_DURATIONS}
          onSelect={handleSelect}
        />
      )}
    </>
  )
}

export default LearnerForm
