import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: -8px;
  font-family: ${(props) => props.theme.labelFontFamily};
  img[src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAMCAYAAABvEu28AAAABHNCSVQICAgIfAhkiAAAAMFJREFUKJGd0TFLQlEYBmBFr5u0Co0uLhaEIAgOgdSPEPw9QlO/oTlwdguXJqcIXJwaWgoJ4i7K43IvHA838/qu33ue73BOpXJGUMUDUsxRPwdJMHOYflmkiZcIWaJWBmnhLUJecZEXhnjC7RGkjY8IeUYjLKXZYIu7AqSHrwiZFm17DwopBsFsFCyCHSZ/XbuD76D8g2uMs1vm+cX9fw/ZjbA4n7g6ikTYpgBZ4fIkJMBuImwh/96yybA1HpGcem4PRMNhWxJbs4cAAAAASUVORK5CYII='] {
    padding-top: 5px;
  }

  div div div div:nth-of-type(2),
  div div div div:nth-of-type(3),
  div div div div:nth-of-type(4) {
    margin-top: 3px;
  }

  /* All incomplete steps */
  div {
    font-weight: 400 !important;
  }

  /* All active / completed steps */
  a {
    font-weight: 400 !important;
    text-decoration: none;
  }
`
