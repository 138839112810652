import styled from 'styled-components'

interface GridContainerWrapperProps {
  pageBottomPadding?: boolean
  overflowX?: string
}

export const StyledGridContainerWrapper = styled.div<GridContainerWrapperProps>`
  padding-bottom: ${({ pageBottomPadding }) =>
    pageBottomPadding ? '20px' : ''};
  overflow-x: ${({ overflowX }) => (overflowX ? overflowX : 'visible')};
`
