import * as yup from 'yup'

export const FirstNameValidation = yup
  .string()
  .typeError('Please enter a valid first name')
  .matches(
    /^[a-zA-Z]+(([’'\-\s][a-zA-Z])?[a-zA-Z]+)$/,
    'Please enter a valid first name',
  )
  .min(2, 'Please enter a valid first name')
  .max(30, 'Please enter a valid first name')
