import { useMutation } from 'react-query'
import customerAccountApi, { SaveNewVehicleRequest } from 'api/customerAccount'

export const useSaveNewVehicleToCustomer = () => {
  const saveNewVehicleToCustomerMutation = useMutation(
    ['customerAccountApi.saveNewVehicleToCustomer'],
    async (data: SaveNewVehicleRequest) =>
      customerAccountApi.saveNewVehicleToCustomer(data),
    {
      retry: false,
    },
  )
  return saveNewVehicleToCustomerMutation
}
