import Button from 'components/@common/Button'
import Form from 'components/@templates/Form'
import { setQuoteJourneyPosition } from 'containers/App/actions'
import { useDuration } from 'hooks'
import useCustomerAccount from 'hooks/useCustomerAccount'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { DurationType, QuoteJourneyPosition } from 'types/global'
import { DurationFormData } from '../DriverDetails/DriverDetails.types'
import DurationSelector from './components/DurationSelector'

import { ButtonWrapper } from './PolicyDuration.styles'
import policyDurationSchema from './PolicyDuration.validation'

interface Props {
  onSubmit: (duration: number | null, durationType: DurationType) => void
}

const PolicyDuration: React.FC<Props> = ({ onSubmit }) => {
  const { duration, durationType: riskDataDurationType } = useDuration()
  const dispatch = useDispatch()
  const { isCustomerAccountQuote } = useCustomerAccount()

  const handleSubmit = (data: DurationFormData) => {
    if (isCustomerAccountQuote) {
      dispatch(
        setQuoteJourneyPosition(
          QuoteJourneyPosition.CustomerAccountPolicyDurationChanged,
        ),
      )
    } else {
      dispatch(
        setQuoteJourneyPosition(
          QuoteJourneyPosition.CustomerPolicyDurationChanged,
        ),
      )
    }
    onSubmit(data.duration, data.durationType)
  }

  const formattedDurationType: DurationType = useMemo(() => {
    const durationTypeLowerCase: DurationType =
      (riskDataDurationType?.toLowerCase() as DurationType) || 'days'
    return durationTypeLowerCase.slice(-1) === 's'
      ? durationTypeLowerCase
      : (`${durationTypeLowerCase}s` as DurationType)
  }, [riskDataDurationType])

  const initialValues: DurationFormData = {
    duration,
    durationType: formattedDurationType,
  }

  return (
    <Form
      initialValues={initialValues}
      validationSchema={policyDurationSchema}
      // @ts-ignore
      onSubmit={(data: PolicyDurationFormData) => handleSubmit(data)}
    >
      <div>
        <DurationSelector />
      </div>
      <ButtonWrapper>
        <Button type='submit'>Update</Button>
      </ButtonWrapper>
    </Form>
  )
}

export default PolicyDuration
