import React from 'react'
import { usePrice } from 'hooks'
import {
  PolicyDetailsHeading,
  PolicyDetailsContainer,
  PolicyDetailsP,
} from './styles'

interface Props {
  policyId: string
}

export const PolicyDetails = ({ policyId }: Props) => {
  const { totalPrice } = usePrice()
  return (
    <PolicyDetailsContainer>
      <PolicyDetailsHeading>Your policy details</PolicyDetailsHeading>
      <PolicyDetailsP>
        Policy cost: <b>£{totalPrice.toFixed(2)}</b>
      </PolicyDetailsP>
      <PolicyDetailsP>
        Policy reference number: <b>{policyId}</b>
      </PolicyDetailsP>
      <PolicyDetailsP>
        A confirmation email with your policy documents has been sent to the
        email address provided.
      </PolicyDetailsP>
    </PolicyDetailsContainer>
  )
}
