import React from 'react'

export const Learner = ({ width, height }) => {
  const opts = {}

  if (width) {
    opts.width = width
  }

  if (height) {
    opts.height = height
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 135 135.001' {...opts}>
      <g
        id='Group_319'
        data-name='Group 319'
        transform='translate(2596.334 8784.797)'
      >
        <g
          id='Group_289'
          data-name='Group 289'
          transform='translate(-2596.334 -8784.797)'
        >
          <path
            id='Path_295'
            data-name='Path 295'
            d='M318.9,1027.559h5.515v60.663h60.663v5.515H318.9Z'
            transform='translate(-284.486 -993.147)'
            fill='#ccdcff'
          ></path>
          <path
            id='Path_296'
            data-name='Path 296'
            d='M368.7,1062.992v38.6h33.089v2.757a2.7,2.7,0,0,1-2.758,2.757H365.946a2.7,2.7,0,0,1-2.757-2.757v-38.6a2.7,2.7,0,0,1,2.757-2.757Z'
            transform='translate(-314.991 -1017.551)'
            fill='#ccdcff'
          ></path>
          <path
            id='Path_319'
            data-name='Path 319'
            d='M275.849,1052.011a67.5,67.5,0,1,1,47.729-19.771A67.058,67.058,0,0,1,275.849,1052.011Zm0-132.355A64.854,64.854,0,1,0,340.7,984.51,64.928,64.928,0,0,0,275.849,919.656Z'
            transform='translate(-208.349 -917.01)'
            fill='#264694'
          ></path>
          <path
            id='Path_347'
            data-name='Path 347'
            d='M382.147,1092.132H315.97a1.323,1.323,0,0,1-1.323-1.323v-66.177a1.323,1.323,0,0,1,1.323-1.323h66.177a1.323,1.323,0,0,1,1.323,1.323v66.177A1.323,1.323,0,0,1,382.147,1092.132Zm-64.854-2.646h63.531v-63.532H317.293Z'
            transform='translate(-281.559 -990.22)'
            fill='#264694'
          ></path>
          <path
            id='Path_348'
            data-name='Path 348'
            d='M396.108,1105.506H363.019a3.966,3.966,0,0,1-4.08-4.08v-38.6a3.966,3.966,0,0,1,4.08-4.08h8.272a3.966,3.966,0,0,1,4.08,4.08v26.251h20.736a3.965,3.965,0,0,1,4.08,4.08v8.272A3.966,3.966,0,0,1,396.108,1105.506Zm-33.089-44.118a1.327,1.327,0,0,0-1.434,1.434v38.6a1.327,1.327,0,0,0,1.434,1.434h33.089a1.327,1.327,0,0,0,1.434-1.434v-8.272a1.327,1.327,0,0,0-1.434-1.434H374.049a1.323,1.323,0,0,1-1.323-1.323v-27.574a1.327,1.327,0,0,0-1.434-1.434Z'
            transform='translate(-312.064 -1014.623)'
            fill='#264694'
          ></path>
        </g>
      </g>
    </svg>
  )
}
