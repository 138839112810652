import * as yup from 'yup'
import { handleDateOfBirthValidation } from 'utils/driver-details/handle-date-of-birth-validation'
import {
  DateOfBirthValidation,
  FirstNameValidation,
  SurnameValidation,
  TitleValidation,
  VehicleValueValidation,
  LicenceTypeValidation,
  ResidencyTypeValidation,
  UkResidencyDurationValidation,
  LicenceHeldDurationValidation,
} from '../@CommonFields'
import { EmailValidation } from '../../../Email/Email.validation'
import { MobileValidation } from '../../../MobileTelephone/Mobile.validation'
import { VALID_DATE_OF_BIRTH_WORDING } from '../DriverDetailsForm.constants'

const CommercialVehicleFormSchema = yup.object().shape({
  licenceType: LicenceTypeValidation.required('Please select a licence type'),
  title: TitleValidation.required('Please select a title'),
  firstName: FirstNameValidation.required('Please enter a valid first name'),
  surname: SurnameValidation.required('Please enter a valid surname'),
  dateOfBirth: DateOfBirthValidation.test(
    'DOB',
    VALID_DATE_OF_BIRTH_WORDING,
    async (value) => handleDateOfBirthValidation(value, 17, 77),
  ).required('Please enter a date of birth'),
  vehicleValue: VehicleValueValidation.required(),
  postcode: yup
    .string()
    .matches(
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/i,
      { message: 'Please enter a valid postcode' },
    )
    .required('Please enter a valid postcode'),
  addressKey: yup
    .string()
    .typeError("Sorry, we need to know the driver's address")
    .required("Sorry, we need to know the driver's address"),
  licenceHeldDuration: LicenceHeldDurationValidation.required(
    "Sorry, we need to know how long the driver has held their licence",
  ),
  emailAddress: EmailValidation.required('Please enter a valid email'),
  mobile: MobileValidation.required('Please enter a valid phone number'),
  bodyType: yup
    .string()
    .typeError("Sorry, we need to know the van's body type")
    .required("Sorry, we need to know the van's body type"),
})

export const FullVanSchema = yup.object().shape({
  ...CommercialVehicleFormSchema.fields,
  useOfVehicle: yup
    .string()
    .typeError("Sorry, we need to know why the driver is using the vehicle")
    .required("Sorry, we need to know why the driver is using the vehicle"),
  occupation: yup
    .string()
    .min(3, "Sorry, we need to know the driver's occupation")
    .typeError("Sorry, we need to know the driver's occupation")
    .required("Sorry, we need to know the driver's occupation"),
  residencyType: ResidencyTypeValidation.required(
    'Please select a residency type',
  ),
  ukResidencyDuration: yup
    .string()
    .when('residencyType', {
      is: 'EuEeaCountry' || 'Other',
      then: UkResidencyDurationValidation.required(
        'Please select a residency duration',
      ),
    })
    .optional(),
})

export const CustomerCommercialVehicleSchema = yup.object().shape({
  useOfVehicle: yup
    .string()
    .typeError("Sorry, we need to know why the driver is using the vehicle")
    .required("Sorry, we need to know why the driver is using the vehicle"),
})

export default CommercialVehicleFormSchema
