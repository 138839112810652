import { PRIVATE_CAR_SCHEME } from 'containers/Vehicle/constants'
import useAffiliate from 'hooks/useAffiliate'
import useScheme from 'hooks/useScheme'
import useSystemFeatures from 'hooks/useSystemFeatures'
import { useSelector } from 'react-redux'
import { InitialStateType } from '../../initialState'

const useConfusedQuickQuote = () => {
  const { isImpounded, scheme } = useScheme()
  const { affiliate } = useAffiliate()
  const { ConfusedUpsellEnabled } = useSystemFeatures()
  const confusedQuickQuote = useSelector(
    (state: InitialStateType) => state.confusedQuickQuote,
  )
  const validReasonsForPurchase = ['TestDrive', 'ProblemWithAnnualInsurance']

  const showConfusedQuickQuoteOptIn =
    !isImpounded &&
    !affiliate.AffiliateId &&
    ConfusedUpsellEnabled &&
    scheme === PRIVATE_CAR_SCHEME

  return {
    showConfusedQuickQuoteOptIn,
    confusedQuickQuote,
    validReasonsForPurchase,
  }
}

export default useConfusedQuickQuote
