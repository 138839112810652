import styled from 'styled-components'

export const ErrorContainer = styled.div`
  font-family: ${(props) => props.theme.labelFontFamily};
  background: ${(props) => props.theme.errorBackgroundColour};
  border: ${(props) => props.theme.errorBorderProperties};
  border-radius: ${(props) => props.theme.messageBorderRadius};
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
`
