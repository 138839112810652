import initialState from 'initialState'
import { AuthAction } from './auth.actions'

// eslint-disable-next-line @typescript-eslint/default-param-last
const authReducer = (state = initialState.auth, action: AuthAction) => {
  switch (action.type) {
    case 'SET_AUTH_ACCESS_TOKEN': {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default authReducer
