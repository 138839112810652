import { useSelector } from 'react-redux'
import { InitialStateType } from '../../initialState'

const useAffiliate = () => {
  const affiliate = useSelector((state: InitialStateType) => state.affiliate)
  const isConfusedQuote: Boolean = affiliate.Ref?.includes('CF103AE310635')
  const isPriceComparison: Boolean = affiliate?.PriceComparison || false
  return {
    affiliate,
    isConfusedQuote,
    isPriceComparison,
  }
}

export default useAffiliate
