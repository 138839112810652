import styled from 'styled-components'
import { H2 } from '../../components/common/H2'

export const PriceDisplayWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`

export const PriceSummaryWrapper = styled.div`
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`

export const Label = styled.label`
  color: #000000;
  display: block;
  float: left;
  margin: 2px 5px 2px 0;
  width: 51px;
`

export const LicenceHeader = styled(H2)`
  text-transform: uppercase;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 18px;
  color: ${(props) => props.theme.drivingLicence.primaryColour};
`

export const PhotoIcon = styled.i`
  font-size: 70px;
  @media (max-width: 768px) {
    font-size: 50px;
  }
  margin-top: 35px;
`

export const RiskData = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-family: ${(props) => props.theme.labelFontFamily};
  text-align: left;
`

export const RiskDataValue = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-family: ${(props) => props.theme.labelFontFamily};
  text-align: left;
  display: block;
  float: left;
  margin: 2px 5px 2px 0;
  clear: both;
  color: #000000;

  a {
    padding: 10px 0;
    display: block;
    color: ${(props) => props.theme.drivingLicence.primaryColour};
  }
`

export const UkIcon = styled.div`
  background-color: ${(props) => props.theme.drivingLicence.primaryColour};
  display: block;
  width: 70%;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
`

const driverBG = `
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9f0ff+0,bad1ff+0,fcd0e4+51,bad1ff+100 */ 
  background: #e9f0ff; /* Old browsers */
  background: -moz-linear-gradient(45deg, #e9f0ff 0%, #bad1ff 0%, #fcd0e4 51%, #bad1ff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #e9f0ff 0%,#bad1ff 0%,#fcd0e4 51%,#bad1ff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #e9f0ff 0%,#bad1ff 0%,#fcd0e4 51%,#bad1ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9f0ff', endColorstr='#bad1ff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
`

const learnerBG = `
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9f0ff+0,dce9c7+0,a8e0c9+51,dce9c7+100 */
  background: #e9f0ff; /* Old browsers */
  background: -moz-linear-gradient(-45deg,  #e9f0ff 0%, #dce9c7 0%, #a8e0c9 51%, #dce9c7 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  #e9f0ff 0%,#dce9c7 0%,#a8e0c9 51%,#dce9c7 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  #e9f0ff 0%,#dce9c7 0%,#a8e0c9 51%,#dce9c7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9f0ff', endColorstr='#dce9c7',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
`

interface WrapperProps {
  isLearner: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  font-family: ${(props) => props.theme.headerFontFamily};
  color: ${(props) => props.theme.drivingLicence.primaryColour};
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 10px;
  margin: 0 auto;
  ${(props) => (props.isLearner === true ? learnerBG : driverBG)}
`

export const AntiFraudSmallPrint = styled.p`
  font-size: 12px;
`

export const SecurityLogosWrapper = styled.div`
  margin-bottom: 32px;
`

export const QuotesHeader = styled(H2)`
  margin-bottom: 20px;
`

export const PriceComparisonWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  max-width: 1055px;
  margin: auto;

  @media (max-width: 344px) {
    padding: 0 10px;
  }
`

export const KlarnaPriceInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    margin-top: 8px;
  }
`
