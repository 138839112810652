import styled from 'styled-components'

interface Props {
  width?: string | number
  fullWidth?: boolean
}
export const ButtonStyled = styled.button<Props>`
  background: ${(props) =>
    props.disabled
      ? props.theme.button.disabledBackground
      : props.theme.button.backgroundColour};
  color: ${(props) =>
    props.disabled
      ? props.theme.button.disabledFont
      : props.theme.button.textColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  font-size: ${(props) => props.theme.button.fontSize};
  font-family: ${(props) => props.theme.button.fontFamily};
  text-transform: ${(props) => props.theme.button.textTransform};
  text-align: ${(props) => props.theme.button.textAlign};
  box-shadow: none;
  border: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  height: ${(props) => props.theme.formControlHeight};
  width: ${({ fullWidth, width }) => (fullWidth ? '100%' : width || 'auto')};

  :hover {
    filter: ${(props) => (props.disabled ? null : 'brightness(80%)')};
  }
`
