import React from 'react'
import TrustBox from '../TrustBox'
import { Wrapper } from './styles'

class TrustPilot extends React.Component {
  render() {
    return (
      <Wrapper>
        <TrustBox />
      </Wrapper>
    )
  }
}

TrustPilot.propTypes = {}

export default TrustPilot
