import queryString from 'query-string'
import React from 'react'
import { ReactComponent as CtmLogo } from '../../images/affiliates/PE26YS778488.svg'
import { ReactComponent as UswitchLogo } from '../../images/affiliates/uswitch.svg'
import { ReactComponent as TempcoverUswitch } from '../../images/tcblacklogo.svg'
import { ReactComponent as LogoImage } from '../../images/tclogo.svg'

import { useConfig, useWhitelabelLogo } from 'hooks'
import { addMissingUtmTags } from '../../history'
import {
  CTM_REF,
  NON_BRANDED,
  SEMI_BRANDED,
  USWITCH_REF,
  WHITELABEL_LOGOS,
  WHITE_LABEL,
} from '../../services/affiliateConstants'
import {
  AssociationText,
  AssociationTextSemi,
  BrandLogo,
  BrandLogoAlign,
  Link,
  SemiBrandedLogoWrapper,
} from './styles'

const DirectLogo = ({ displayName }) => {
  const { queryStringRef, showAdditionalImage } = useWhitelabelLogo()
  const whiteLabelImageUrl = WHITELABEL_LOGOS[queryStringRef]
  const showTempcoverLogo = !Boolean(queryStringRef) || !whiteLabelImageUrl
  return (
    <>
      {showTempcoverLogo && queryStringRef !== USWITCH_REF.toUpperCase() && (
        <LogoImage
          className={showAdditionalImage ? 'hide-strapline' : ''}
          alt='Tempcover Logo'
        />
      )}
      {!showTempcoverLogo && (
        <img
          src={whiteLabelImageUrl}
          alt={displayName + ' Logo'}
          className={showAdditionalImage ? 'hide-strapline' : ''}
        />
      )}
      {showTempcoverLogo && queryStringRef === USWITCH_REF && (
        <TempcoverUswitch
          className={showAdditionalImage ? 'hide-strapline' : ''}
          alt='Tempcover Logo'
        />
      )}

      {showAdditionalImage && queryStringRef == CTM_REF && (
        <CtmLogo alt={displayName + ' Logo'} className={queryStringRef} />
      )}

      {queryStringRef == USWITCH_REF.toUpperCase() && (
        <>
          <TempcoverUswitch
            className={showAdditionalImage ? 'hide-strapline' : ''}
            alt='Tempcover Logo'
          />
          <UswitchLogo alt={displayName + ' Logo'} className={queryStringRef} />
        </>
      )}
    </>
  )
}

const NonBrandedLogo = ({ DisplayName }) => {
  return (
    <>
      <LogoImage className='hide-strapline' alt='Tempcover Logo' />
      <AssociationText>In partnership with {DisplayName}</AssociationText>
    </>
  )
}

const SemiBrandedLogo = ({ DisplayName, Ref }) => {
  return (
    <div style={{ position: 'relative' }}>
      <LogoImage className='hide-strapline' alt='Tempcover Logo' />
      <AssociationTextSemi>In partnership with</AssociationTextSemi>
      <SemiBrandedLogoWrapper>
        <img
          src={'/images/affiliates/semi-branded/' + Ref + '.png'}
          alt={DisplayName}
        />
      </SemiBrandedLogoWrapper>
    </div>
  )
}

const SemiBrandedOptimiseLogo = ({ UtmBrand, Url }) => {
  const acceptableUtmBrands = ['compareukquotes', 'bobatoo', 'moneycouk']
  if (!UtmBrand || !acceptableUtmBrands.includes(UtmBrand)) {
    return <DirectLogo url={Url} />
  }
  return (
    <>
      <LogoImage className='hide-strapline' alt='Tempcover Logo' />
      <AssociationTextSemi>In partnership with</AssociationTextSemi>
      <SemiBrandedLogoWrapper>
        <img
          src={'/images/affiliates/optimise/' + UtmBrand + '.png'}
          alt={`Optimise Media Campaign ${UtmBrand}`}
        />
      </SemiBrandedLogoWrapper>
    </>
  )
}

export const noShowRefs = [
  'aa',
  'NP108UH465053',
  'gu514bb746985',
  'GU514BB123456',
  'GU514BB544369',
  'GU514BB314550',
  'GU514BB235091',
  'CF103AE310635',
  'moneysavingexpert',
]

const Logo = ({ affiliate, campaignRef }) => {
  const parsedQueryString = queryString.parse(window.location.search)
  const url = '/images/affiliates/' + parsedQueryString.ref + '.png'
  const { AffiliateType, DisplayName, Ref } = affiliate
  const siteUrl = useConfig().SITE_URL

  const queries = addMissingUtmTags(new Object(null))
  let optimiseAffiliate = null
  if (parsedQueryString.utm_source === 'Optimise') {
    optimiseAffiliate = parsedQueryString.utm_brand
  }

  const limitMaxWidth =
    optimiseAffiliate ||
    AffiliateType === SEMI_BRANDED ||
    (AffiliateType === NON_BRANDED && !noShowRefs.includes(Ref))

  return (
    <BrandLogo>
      <BrandLogoAlign limitMaxWidth={limitMaxWidth}>
        <Link
          href={queryString.stringifyUrl({
            url: siteUrl,
            query: { ...queries },
          })}
        >
          {optimiseAffiliate && (
            <SemiBrandedOptimiseLogo UtmBrand={optimiseAffiliate} Url={url} />
          )}
          {!optimiseAffiliate &&
            (!AffiliateType ||
              AffiliateType === WHITE_LABEL ||
              (AffiliateType === NON_BRANDED && noShowRefs.includes(Ref))) && (
              <DirectLogo displayName={DisplayName} />
            )}
          {AffiliateType === NON_BRANDED && !noShowRefs.includes(Ref) && (
            <NonBrandedLogo DisplayName={DisplayName} />
          )}
          {AffiliateType === SEMI_BRANDED && (
            <SemiBrandedLogo DisplayName={DisplayName} Ref={Ref} />
          )}
        </Link>
      </BrandLogoAlign>
    </BrandLogo>
  )
}
export default Logo
