import FormField from 'components/@common/FormField'
import TextField from 'components/@common/TextField'
import React from 'react'

const Surname: React.FC = () => (
  <FormField name='surname' label="Surname">
    <TextField id='Surname' name='surname' placeholder="and the driver's surname is..." />
  </FormField>
)

export default Surname
