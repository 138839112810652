import React from 'react'
import VehicleCustomerAccount from 'containers/VehicleCustomerAccount'
import { useVehicle } from 'hooks'
import { useDispatch } from 'react-redux'
import { setVehicleCustomerAccountListener } from 'containers/VehicleCustomerAccount/actions'
import { StyledVehicalCarouselAddNew } from './VehicleCarouselAddNew.styles'

export interface Props {
  handleUpdate: () => void
  existsInGarage: (result: string) => void
  closeFeaturePopup: () => void
}

const VehicleCarouselAddNew: React.FC<Props> = ({
  handleUpdate,
  existsInGarage,
  closeFeaturePopup,
}) => {
  const { vehicle } = useVehicle()

  const dispatch = useDispatch()

  const onUpdate = () => {
    dispatch(setVehicleCustomerAccountListener(vehicle.searched))
    handleUpdate()
  }

  return (
    <StyledVehicalCarouselAddNew>
      <VehicleCustomerAccount
        handleConfirm={onUpdate}
        checkExistsInGarage={(result) => existsInGarage(result)}
        closeFeaturePopupAndReset={closeFeaturePopup}
      />
    </StyledVehicalCarouselAddNew>
  )
}

export default VehicleCarouselAddNew
