import { InitialStateType } from 'initialState'
import { useSelector } from 'react-redux'

const useVehicle = () => {
  const vehicle = useSelector((state: InitialStateType) => state.vehicle)
  const previousVehicle = useSelector(
    (state: InitialStateType) => state.vehicle.previousRiddenVehicle,
  )
  const registrationNumber = useSelector(
    (state: InitialStateType) => state.vehicle.selected.RegistrationNumber,
  )
  const vehicleValue = useSelector(
    (state: InitialStateType) => state.vehicle.selected.vehicleValue.value,
  )
  const vehicleValueDescription = useSelector(
    (state: InitialStateType) =>
      state.vehicle.selected.vehicleValue.description,
  )
  return {
    vehicle,
    previousVehicle,
    registrationNumber,
    vehicleValue,
    vehicleValueDescription,
  }
}

export default useVehicle
