import { useWindowSize } from 'hooks'
import React, { useState } from 'react'

declare global {
  interface Window {
    Trustpilot: any
  }
}
const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)
  const { isMobile } = useWindowSize()

  const [trustPilotWidget, setTrustPilotWidget] = useState(
    '5419b6ffb0d04a076446a9af',
  )

  React.useEffect(() => {
    if (isMobile) {
      setTrustPilotWidget('5419b732fbfb950b10de65e5')
    } else {
      setTrustPilotWidget('5419b6ffb0d04a076446a9af')
    }

    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [isMobile, trustPilotWidget])
  return (
    <div
      ref={ref}
      data-locale='en-GB'
      className='trustpilot-widget'
      data-template-id={trustPilotWidget}
      data-businessunit-id='4fca033e0000640005160149'
      data-style-height='28px'
      data-style-width='100%'
      data-theme='dark'
      data-font-family='Lato'
    >
      <a
        href='https://uk.trustpilot.com/review/tempcover.com'
        target='_blank'
        rel='noopener noreferrer'
      >
        Trustpilot
      </a>
    </div>
  )
}
export default TrustBox
