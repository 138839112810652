import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import queryString from 'query-string'
import GridContainerWrapper from 'components/@common/GridContainerWrapper'
import Addons from 'components/Addons'
import TimerBanner from 'components/TimerBanner'
import { H2 } from 'components/common/H2'
import config from 'config'
import React, { useState } from 'react'
import PriceBreakdown from 'components/PriceBreakdown'
import { usePrice } from 'hooks'
import { Message } from 'components/Message'
import useOptimizelyExperiment from 'hooks/useOptimizelyExperiment'
import { ReactSDKClient, withOptimizely } from '@optimizely/react-sdk'
import { addMissingUtmTags } from '../../history'
import { BoldLegalText, LegalText } from './components/PaymentContent.styles'
import PaymentForm from './components/PaymentForm'

const stripe = loadStripe(config.STRIPE_KEY)

const queries = addMissingUtmTags({})

interface NewPaymentProps {
  optimizely: ReactSDKClient | null
}

const NewPayment: React.FC<NewPaymentProps> = ({ optimizely }) => {
  const shouldUseSplitAddonsPage = useOptimizelyExperiment(
    optimizely,
    'addons_page',
    'page_enabled',
  )

  // Ideally use react query for this at some point
  const { totalPriceWithoutDecimal } = usePrice()

  const [errorMessage, setErrorMessage] = useState('')

  const stripeOptions: StripeElementsOptions = {
    mode: 'payment',
    currency: 'gbp',
    paymentMethodTypes: ['card'],
    amount: totalPriceWithoutDecimal,
    loader: 'auto',
    paymentMethodCreation: 'manual',
    appearance: {
      variables: {
        borderRadius: '6px',
      },
      rules: {
        '.Input, .Block, .PickerItem': {
          border: '1px solid #264694',
        },
        '.Label': {
          color: '#264694',
          fontWeight: 'bold',
        },
        '.PickerAction, .MenuAction': {
          color: '#264694',
        },
      },
      labels: 'above',
    },
  }

  return (
    <GridContainerWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          textAlign: 'center',
          marginBottom: 32,
        }}
      >
        <H2>Payment</H2>
        {/* Need to look at pulling this via react query eventually */}
        {!shouldUseSplitAddonsPage && <Addons />}
        <div style={{ marginTop: 8 }}>
          <TimerBanner />
        </div>
        <BoldLegalText>
          Our prices can update on the hour. If your price is updated,
          we&apos;ll show you your new price before you buy.
        </BoldLegalText>
        {/* Need to look at pulling this via react query eventually */}
        <PriceBreakdown />
        <LegalText>
          The Tempcover fee is payable under your separate Tempcover contract
          for administering your policy. Once purchased, you cannot change any
          details and no refunds are available under our{' '}
          <a
            id='RefundPolicyLink'
            href={queryString.stringifyUrl({
              url: `${config.SITE_URL}/our-refund-policy`,
              query: { ...queries },
            })}
            target='_blank'
            rel='noopener noreferrer'
          >
            refund policy
          </a>
          .
        </LegalText>

        <Elements stripe={stripe} options={stripeOptions}>
          {errorMessage && (
            <Message
              type='error'
              content='We are sorry your payment has been declined. Please check your details and try again.'
              heading='Payment failed'
            />
          )}
          <PaymentForm
            amount={totalPriceWithoutDecimal}
            onError={(stripeError) => setErrorMessage(stripeError)}
          />
        </Elements>
      </div>
    </GridContainerWrapper>
  )
}

export default withOptimizely(NewPayment)
