import styled from 'styled-components'

export const H5 = styled.h5`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 400;
  font-size: 12pt;
  color: black;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 1em;
`
