import React from 'react'
import { A } from '../common/A'
import { H5 } from '../common/H5'
import config from '../../config'

interface ExternalHyperlinkProps {
  id: string
  href: string
  wording: string
}

const ExternalHyperlink: React.FC<ExternalHyperlinkProps> = ({
  id,
  href,
  wording,
}) => (
  <A
    id={id}
    href={`${config.SITE_URL}/${href}`}
    target='_blank'
    style={{ fontSize: '12pt', borderBottom: 0 }}
  >
    {wording}
  </A>
)

export const ComplianceWording: React.FC = () => (
  <H5>
    To process your application we will verify your identity and may check your
    details with credit reference and fraud prevention agencies. Entering
    incorrect data risks invalidating your policy. By clicking 'continue', you
    confirm you have read and agree to the{' '}
    <ExternalHyperlink
      id='CustomerTOBLink'
      href={'terms-conditions'}
      wording={'customer terms of business'}
    />{' '}
    and{' '}
    <ExternalHyperlink
      id='PrivacyPolicyLink'
      href={'privacy-policy'}
      wording={'privacy policy'}
    />
    .
  </H5>
)
