import CustomerAccountApi from 'api/customerAccount'
import { useQuery } from 'react-query'

const useCustomerAccountFeatureFlag = (featureFlagName: string) =>
  useQuery(
    ['getFeatureFlag_', featureFlagName],
    () => CustomerAccountApi.getFeatureFlag(featureFlagName),
    {
      enabled: !!featureFlagName,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

export default useCustomerAccountFeatureFlag
