import React, { useState, useRef, useEffect } from 'react'
import { useField } from 'formik'
import moment from 'moment'
import {
  DateInputWrapper,
  DateInputFieldWrapper,
  DateInputField,
  YearInputWrapper,
} from './DatePicker.styles'

interface DateInputProps {
  name: string
  value: string
  setValue: (value: string, shouldValidate?: boolean | undefined) => void
  setError?: (value: string) => void
  setTouched?: (value: boolean, shouldValidate?: boolean | undefined) => void
  startDate?: Date
  endDate?: Date
  errorMessage?: string
}

const DatePicker: React.FC<DateInputProps> = ({
  name,
  value,
  setValue,
  setError,
  setTouched,
  errorMessage = "Sorry that isn't a valid date",
}) => {
  const isUseableValue = value && value.split('/').length >= 3
  const initialValues = {
    day: isUseableValue ? value.split('/')[0] : '',
    month: isUseableValue ? value.split('/')[1] : '',
    year: isUseableValue ? value.split('/')[2] : '',
  }

  const [dayValue, setDayValue] = useState<string>(initialValues.day)
  const [monthValue, setMonthValue] = useState<string>(initialValues.month)
  const [yearValue, setYearValue] = useState<string>(initialValues.year)

  const monthFieldRef = useRef<HTMLInputElement>(null)
  const yearFieldRef = useRef<HTMLInputElement>(null)

  const parseCurrentValues = (day: string, month: string, year: string) => {
    // moment has weird thing where YY is 68 or less then it defaults to 20YY otherwise its 19YY
    // Below just makes it so if the year entered is less then 69 and also greater then the current year it will default the date to 19YY
    // else it will use the default moment conversion as thats correct

    const intYear = parseInt(year, 10)
    if (!isNaN(intYear)) {
      if (intYear < 69 && intYear >= parseInt(moment().format('YY'), 10)) {
        year = `19${year}`
      }
    }

    const formattedDate = `${day}/${month}/${year}`

    if (day && month && year) {
      if (setTouched) setTouched(true, false)
      const isValidDate = moment(formattedDate, 'D/M/YYYY').isValid()

      if (!isValidDate && setError) {
        setError(errorMessage)
      } else if (!setTouched && setError) {
        setError('')
      }
    } else if (setTouched) setTouched(false, false)

    setValue(formattedDate, true)
  }

  const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDayValue(e.target.value)
    if (e.target.value.length === 2) {
      monthFieldRef.current!.focus()
    }
  }

  const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMonthValue(e.target.value)
    if (e.target.value.length === 2) {
      yearFieldRef.current!.focus()
    }
  }

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setYearValue(e.target.value)
    if (e.target.value.length === 4) {
      parseCurrentValues(dayValue, monthValue, e.target.value)
    }
  }

  const updateDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    parseCurrentValues(e.target.value, monthValue, yearValue)
  }

  const updateMonth = (e: React.ChangeEvent<HTMLInputElement>) => {
    parseCurrentValues(dayValue, e.target.value, yearValue)
  }

  const updateYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    parseCurrentValues(dayValue, monthValue, e.target.value)
  }

  return (
    <>
      {/* Adding hidden span with the name dateOfBirth for validation scrolling. */}
      {/* @ts-ignore */}
      <span name={name} />
      <DateInputWrapper>
        <DateInputFieldWrapper>
          <DateInputField
            maxLength={2}
            onBlur={updateDay}
            onChange={handleDayChange}
            id={`${name}Day`}
            name={`${name}Day`}
            type='tel'
            placeholder='DD'
            value={dayValue}
          />
        </DateInputFieldWrapper>
        <DateInputFieldWrapper>
          <DateInputField
            maxLength={2}
            ref={monthFieldRef}
            onBlur={updateMonth}
            onChange={handleMonthChange}
            id={`${name}Month`}
            name={`${name}Month`}
            type='tel'
            placeholder='MM'
            value={monthValue}
          />
        </DateInputFieldWrapper>
        <YearInputWrapper>
          <DateInputField
            maxLength={4}
            ref={yearFieldRef}
            onBlur={updateYear}
            onChange={handleYearChange}
            id={`${name}Year`}
            name={`${name}Year`}
            type='tel'
            placeholder='YYYY'
            value={yearValue}
          />
        </YearInputWrapper>
      </DateInputWrapper>
    </>
  )
}

export default DatePicker
