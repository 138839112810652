import delay from '../delay'

const vehicle = {
  VehicleId: 428,
  Make: 'Mercedes',
  Model: 'C-Class',
  VehicleType: 'PrivateCar',
  Abi: '428',
  MakeAndModel: 'Mercedes C-Class',
}

const makes = ['Mercedes']

const models = ['C-Class']

const variants = ['Cabriolet']
const response = {
  data: vehicle,
}

export default class VehicleApi {
  static getVehicle() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(response)
      }, delay)
    })
  }

  static createUnknownVehicle(link, unknownVehicle) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(Object.assign({}, response))
      }, delay)
    })
  }

  static getMakes() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(Object.assign([], makes))
      }, delay)
    })
  }
  static getModels() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(Object.assign([], models))
      }, delay)
    })
  }
  static getVariants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(Object.assign([], variants))
      }, delay)
    })
  }
}
