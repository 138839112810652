import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import config from '../../config'
import { SYSTEM_FEATURES, SCHEMES } from '../../services/constants'
import { requestCallback } from './actions'
import { requestConfusedQuickQuoteResponse } from '../App/actions'
import ConfusedLoadingBanner from './Confused/ConfusedLoadingBanner'
import ConfusedStaticBanner from './Confused/ConfusedStaticBanner'
import ConfusedRTPBanner from './Confused/ConfusedRTPBanner'
import { Container } from './styles'
import { H1 } from 'components/common/H1'

const AnnualUpsell = ({
  callbackRequest,
  affiliate,
  scheme,
  features,
  actions: { requestCallback, requestConfusedQuickQuoteResponse },
  confusedQuickQuote,
  confusedQuickQuote: {
    OptIn,
    RequestAccepted,
    RequestedDateTime,
    PricingLoaded,
    PricingLoadAttempts,
    Pricing,
  },
  vehicle,
}) => {
  const renderBanner = () =>
    useMemo(() => {
      if (RequestAccepted) {
        if (PricingLoaded) {
          return (
            <Container>
              <ConfusedRTPBanner
                callbackRequest={callbackRequest}
                requestCallback={requestCallback}
                pricing={Pricing}
                registrationNumber={vehicle.RegistrationNumber}
              />
            </Container>
          )
        } else if (
          PricingLoadAttempts < config.CONFUSED_QUICK_QUOTE_MAX_LOAD_ATTEMPTS
        ) {
          return (
            <Container>
              <ConfusedLoadingBanner
                requestConfusedQuickQuoteResponse={
                  requestConfusedQuickQuoteResponse
                }
                confusedQuickQuoteDateTimeRequested={RequestedDateTime}
                confusedQuickQuoteLoadCount={PricingLoadAttempts}
                registrationNumber={vehicle.RegistrationNumber}
              />
            </Container>
          )
        }
      }

      return (
        <Container>
          <ConfusedStaticBanner
            callbackRequest={callbackRequest}
            requestCallback={requestCallback}
          />
        </Container>
      )
    }, [features, scheme, confusedQuickQuote])

  if (
    features[SYSTEM_FEATURES.UPSELL_CONFUSED_ENABLED] &&
    scheme === SCHEMES.PRIVATE_CAR_SCHEME &&
    OptIn !== null &&
    !affiliate.AffiliateId
  ) {
    return <>{renderBanner()}</>
  }
  return <></>
}

const mapStateToProps = (state) => {
  return {
    callbackRequest: state.policy.callbackRequest,
    affiliate: state.affiliate,
    scheme: state.scheme.selected,
    features: state.features || {},
    confusedQuickQuote: state.confusedQuickQuote,
    vehicle: state.vehicle.selected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        requestCallback,
        requestConfusedQuickQuoteResponse,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnualUpsell)
