import styled from 'styled-components'

export const IconWrapper = styled.div`
  border: ${(props) => props.theme.icons.border};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  width: ${(props) => props.theme.icons.width};
  height: ${(props) => props.theme.icons.height};
  position: absolute;
  padding-top: 12px;
  background-color: #e9f0ff;
  text-align: center;
  background-color: ${(props) => props.theme.icon.backgroundColor};
`
