import { FETCH_NEXT_PRICE } from 'components/PriceUpsell/constants'
import {
  GET_ANALYSIS_DATA_SUCCESS,
  REQUEST_POLICY_SUCCESS,
} from '../../PolicyConfirmationPage/constants'
import { QUOTE_SUMMARY_MODAL_CLOSE } from '../../QuoteSummary/constants'
import {
  UNDERWRITER_CHANGED_BY_CUE,
  UPDATE_QUOTE,
  UPDATE_UNDERWRITER_SUCCESS,
} from '../../RiskDataCollectionPage/constants'
import {
  CLEAR_PRICE,
  CLEAR_ADDONS,
  REQUEST_ADDONS,
  REQUEST_ADDONS_SUCCESS,
  REQUEST_BREAKDOWN_FAILURE,
  REQUEST_EXCESS_FAILURE,
  REQUEST_LEGALEXPENSES_FAILURE,
  REQUEST_GAPINSURANCE_FAILURE,
  REQUEST_PRICE,
  REQUEST_PRICE_FAILURE,
  REQUEST_PRICE_SUCCESS,
  REQUEST_QUOTE_SUCCESS,
  SELECT_PRICE,
  SET_CAMPAIGN_ACTIVE,
  SET_TEMP_UNDERWRITER,
  SET_UNDERWRITER_CHANGED,
  UPDATE_CAMPAIGN_DATA,
  UPDATE_GAP_ACTIVATION_SELECTED,
} from '../constants'

import initialState from '../../../initialState'

function priceReducer(state = initialState.price, action) {
  switch (action.type) {
    case UPDATE_QUOTE:
    case QUOTE_SUMMARY_MODAL_CLOSE:
      const { getPrice } = action.payload
      return {
        ...state,
        isUpdating: !!getPrice === getPrice ? getPrice : true,
      }
    case REQUEST_PRICE_SUCCESS:
    case REQUEST_ADDONS_SUCCESS:
      let listOfPrices = action.price.Prices
      if (listOfPrices && listOfPrices.length > 0) {
        listOfPrices.map((p) => {
          if (p.TotalPriceBeforeAddons === 0 && p.TotalPrice > 0) {
            p.TotalPriceBeforeAddons = p.TotalPrice
          }
        })

        listOfPrices.sort(
          (a, b) => a.TotalPriceBeforeAddons - b.TotalPriceBeforeAddons,
        )
      }
      return {
        ...state,
        InsurancePremium: action.price.InsurancePremium,
        Ipt: action.price.Ipt,
        AdminFee: action.price.AdminFee,
        Underwriter: action.price.Underwriter,
        UnderwriterDisplayName: action.price.UnderwriterDisplayName,
        UnderwriterId: action.price.UnderwriterId,
        TotalPrice: action.price.TotalPrice,
        EncryptedString: action.price.ValidationData
          ? action.price.ValidationData.Value
          : null,
        CompulsoryExcess: action.price.CompulsoryExcess,
        AffiliateAdminFee: action.price.AffiliateAdminFee,
        UnderwriterChanged: action.price.underwriterChanged,
        TotalPriceBeforeAddons: action.price.TotalPriceBeforeAddons,
        IsAddonUpdating: false,
        ExcessReduction: {
          ExcessReductionIncluded:
            action.price.ExcessReductionPricing?.ExcessReductionIncluded ||
            false,
          ExcessReductionPremiumNet:
            action.price.ExcessReductionPricing?.ExcessReductionPremiumNet || 0,
          ExcessReductionPremiumGross:
            action.price.ExcessReductionPricing?.ExcessReductionPremiumGross ||
            0,
          ExcessReductionPremiumCommissionFactor:
            action.price.ExcessReductionPricing
              ?.ExcessReductionPremiumCommissionFactor || 0,
          ExcessReductionIPT:
            action.price.ExcessReductionPricing?.ExcessReductionIPT || 0,
          ExcessReductionTotalPremium:
            action.price.ExcessReductionPricing?.ExcessReductionTotalPremium ||
            0,
          ExcessReductionPremiumCommissionAmount:
            action.price.ExcessReductionPricing
              ?.ExcessReductionPremiumCommissionAmount || 0,
          ExcessReductionCoverLevel:
            action.price.ExcessReductionPricing?.ExcessReductionCoverLevel || 0,
          HasError: false,
        },
        BreakdownCover: {
          BreakdownIncluded:
            action.price.BreakdownPricing?.BreakdownIncluded || false,
          BreakdownPremiumNet:
            action.price.BreakdownPricing?.BreakdownPremiumNet || 0,
          BreakdownPremiumGross:
            action.price.BreakdownPricing?.BreakdownPremiumGross || 0,
          BreakdownPremiumCommissionFactor:
            action.price.BreakdownPricing?.BreakdownPremiumCommissionFactor ||
            0,
          BreakdownPremiumCommissionAmount:
            action.price.BreakdownPricing?.BreakdownPremiumCommissionAmount ||
            0,
          BreakdownIPT: action.price.BreakdownPricing?.BreakdownIPT || 0,
          BreakdownTotalPremium:
            action.price.BreakdownPricing?.BreakdownTotalPremium || 0,
          BreakdownBrokerCode:
            action.price.BreakdownPricing?.BreakdownBrokerCode || 0,
          BreakdownProductCode:
            action.price.BreakdownPricing?.BreakdownProductCode || 0,
          HasError: false,
        },
        LegalExpensesCover: {
          LegalExpensesIncluded:
            action.price.LegalExpensesPricing?.LegalExpensesIncluded || false,
          LegalExpensesPremiumNet:
            action.price.LegalExpensesPricing?.LegalExpensesPremiumNet || 0,
          LegalExpensesPremiumGross:
            action.price.LegalExpensesPricing?.LegalExpensesPremiumGross || 0,
          LegalExpensesPremiumCommissionFactor:
            action.price.LegalExpensesPricing
              ?.LegalExpensesPremiumCommissionFactor || 0,
          LegalExpensesPremiumCommissionAmount:
            action.price.LegalExpensesPricing
              ?.LegalExpensesPremiumCommissionAmount || 0,
          LegalExpensesIPT:
            action.price.LegalExpensesPricing?.LegalExpensesIPT || 0,
          LegalExpensesPremium:
            action.price.LegalExpensesPricing?.LegalExpensesPremium || 0,
          HasError: false,
        },
        FreeGAPInsuranceCover: {
          Free30DayGAPInsuranceIncluded:
            action.price.FreeGAPInsurancePricing?.FreeGAPInsuranceIncluded ||
            false,
          FreeGAPInsuranceAvailable:
            action.price.FreeGAPInsurancePricing?.FreeGAPInsuranceAvailable ||
            false,
          FreeGapAdded:
            action.price.FreeGAPInsurancePricing
              ?.FreeGapActivationJourneyIncluded || false,
          HasError: false,
        },
        CoverType: action.price.CoverType,
        isUpdating: false,
        Prices: listOfPrices,
        AffiliateCashbackValue: action.price.AffiliateCashbackValue,
        CampaignDiscount: action.price.CampaignDiscount,
        CampaignName: action.price.CampaignName,
        CampaignId: action.price.CampaignId,
      }
    case SET_UNDERWRITER_CHANGED:
      return {
        ...state,
        UnderwriterChanged: true,
      }
    case REQUEST_PRICE_FAILURE:
      return {
        ...initialState.price,
        isUpdating: false,
      }
    case REQUEST_EXCESS_FAILURE:
      return {
        ...state,
        IsAddonUpdating: false,
        ExcessReduction: {
          ...state.ExcessReduction,
          ExcessReductionIncluded:
            state.ExcessReduction.ExcessReductionIncluded,
          HasError: true,
        },
      }
    case REQUEST_BREAKDOWN_FAILURE:
      return {
        ...state,
        IsAddonUpdating: false,
        BreakdownCover: {
          ...state.BreakdownCover,
          BreakdownIncluded: state.BreakdownCover.BreakdownIncluded,
          HasError: true,
        },
      }
    case REQUEST_LEGALEXPENSES_FAILURE:
      return {
        ...state,
        IsAddonUpdating: false,
        LegalExpensesCover: {
          ...state.LegalExpensesCover,
          LegalExpensesIncluded: state.LegalExpensesCover.LegalExpensesIncluded,
          HasError: true,
        },
      }
    case REQUEST_GAPINSURANCE_FAILURE:
      return {
        ...state,
        IsAddonUpdating: false,
        FreeGAPInsuranceCover: {
          ...state.FreeGAPInsuranceCover,
          Free30DayGAPInsuranceIncluded:
            state.FreeGAPInsuranceCover.Free30DayGAPInsuranceIncluded,
          HasError: true,
        },
      }
    case CLEAR_PRICE:
      return {
        ...initialState.price,
        campaign: {
          ...state.campaign,
        },
      }
    case CLEAR_ADDONS:
      return {
        ...state,
        ExcessReduction: {
          ...initialState.price.ExcessReduction,
        },
        BreakdownCover: {
          ...initialState.price.BreakdownCover,
        },
        LegalExpensesCover: {
          ...initialState.price.LegalExpensesCover,
        },
      }
    case REQUEST_ADDONS:
      return {
        ...state,
        IsAddonUpdating: true,
      }
    case REQUEST_POLICY_SUCCESS:
      return {
        ...state,
        TotalPrice: action.policy.Premium,
        CoverType: action.policy.CoverType,
      }
    case GET_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        TotalPrice: action.response.Premium,
        Ipt: action.response.Ipt,
        Underwriter: action.response.Underwriter,
        commission: action.response.Commission,
      }
    case UPDATE_CAMPAIGN_DATA:      
      return {
        ...state,
        campaign: {
          ...state.campaign,
          Ref: state.campaign.Ref || action.campaignData.CampaignRef,
          Name: action.campaignData.CampaignName,
          Discount: action.campaignData.CampaignDiscount,
          Active: action.showCampaignDiscountBanner,
        },
      }
    case SET_CAMPAIGN_ACTIVE:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          Active: action.campaignData.isCampaignActive,
          Ref: action.campaignData.CampaignRef,
          Exists: action.campaignData.isCampaignExists,
        },
      }
    case SELECT_PRICE:
      return {
        ...state,
        InsurancePremium: action.price.InsurancePremium,
        Ipt: action.price.Ipt,
        AdminFee: action.price.AdminFee,
        Underwriter: action.price.Underwriter,
        UnderwriterDisplayName: action.price.UnderwriterDisplayName,
        UnderwriterId: action.price.UnderwriterId,
        TotalPrice: action.price.TotalPrice,
        EncryptedString: action.price.ValidationData
          ? action.price.ValidationData.Value
          : null,
        CompulsoryExcess: action.price.CompulsoryExcess,
        AffiliateAdminFee: action.price.AffiliateAdminFee,
        UnderwriterChanged: action.price.underwriterChanged,
        TotalPriceBeforeAddons: action.price.TotalPriceBeforeAddons,
        ExcessReduction: {
          ExcessReductionIncluded:
            action.price.ExcessReductionPricing?.ExcessReductionIncluded ||
            false,
          ExcessReductionPremiumNet:
            action.price.ExcessReductionPricing?.ExcessReductionPremiumNet || 0,
          ExcessReductionPremiumGross:
            action.price.ExcessReductionPricing?.ExcessReductionPremiumGross ||
            0,
          ExcessReductionPremiumCommissionFactor:
            action.price.ExcessReductionPricing
              ?.ExcessReductionPremiumCommissionFactor || 0,
          ExcessReductionIPT:
            action.price.ExcessReductionPricing?.ExcessReductionIPT || 0,
          ExcessReductionTotalPremium:
            action.price.ExcessReductionPricing?.ExcessReductionTotalPremium ||
            0,
          ExcessReductionPremiumCommissionAmount:
            action.price.ExcessReductionPricing
              ?.ExcessReductionPremiumCommissionAmount || 0,
          ExcessReductionCoverLevel:
            action.price.ExcessReductionPricing?.ExcessReductionCoverLevel || 0,
          HasError: false,
        },
        BreakdownCover: {
          BreakdownIncluded:
            action.price.BreakdownPricing?.BreakdownIncluded || false,
          BreakdownPremiumNet:
            action.price.BreakdownPricing?.BreakdownPremiumNet || 0,
          BreakdownPremiumGross:
            action.price.BreakdownPricing?.BreakdownPremiumGross || 0,
          BreakdownPremiumCommissionFactor:
            action.price.BreakdownPricing?.BreakdownPremiumCommissionFactor ||
            0,
          BreakdownPremiumCommissionAmount:
            action.price.BreakdownPricing?.BreakdownPremiumCommissionAmount ||
            0,
          BreakdownIPT: action.price.BreakdownPricing?.BreakdownIPT || 0,
          BreakdownTotalPremium:
            action.price.BreakdownPricing?.BreakdownTotalPremium || 0,
          BreakdownBrokerCode:
            action.price.BreakdownPricing?.BreakdownBrokerCode || 0,
          BreakdownProductCode:
            action.price.BreakdownPricing?.BreakdownProductCode || 0,
          HasError: false,
        },
        LegalExpensesCover: {
          LegalExpensesIncluded:
            action.price.LegalExpensesPricing?.LegalExpensesIncluded || false,
          LegalExpensesPremiumNet:
            action.price.LegalExpensesPricing?.LegalExpensesPremiumNet || 0,
          LegalExpensesPremiumGross:
            action.price.LegalExpensesPricing?.LegalExpensesPremiumGross || 0,
          LegalExpensesPremiumCommissionFactor:
            action.price.LegalExpensesPricing
              ?.LegalExpensesPremiumCommissionFactor || 0,
          LegalExpensesPremiumCommissionAmount:
            action.price.LegalExpensesPricing
              ?.LegalExpensesPremiumCommissionAmount || 0,
          LegalExpensesIPT:
            action.price.LegalExpensesPricing?.LegalExpensesIPT || 0,
          LegalExpensesPremium:
            action.price.LegalExpensesPricing?.LegalExpensesPremium || 0,
          HasError: false,
        },
        CoverType: action.price.CoverType,
      }
    case REQUEST_QUOTE_SUCCESS:
      // TODO: This needs to be revisited as it's a very hacky solution
      let updatedUnderwriter = action.quote.Underwriter
      if (updatedUnderwriter === 'SouthernRock') {
        updatedUnderwriter = 'SomersetBridgeLimited'
      }
      return {
        ...state,
        Underwriter: updatedUnderwriter,
        UnderwriterDisplayName: action.quote.UnderwriterDisplayName,
      }
    case SET_TEMP_UNDERWRITER:
      return {
        ...state,
        TempUnderwriter: action.underwriter,
      }
    case UNDERWRITER_CHANGED_BY_CUE:
      return {
        ...state,
        UnderwriterChangedByCue: true,
        UnderwriterId: action.underwriterId,
      }
    case REQUEST_PRICE:
      return {
        ...state,
        fetchNextPrice: false,
      }
    case UPDATE_UNDERWRITER_SUCCESS:
      return {
        ...state,
        fetchNextPrice: true,
      }
    case FETCH_NEXT_PRICE:
      return {
        ...state,
        fetchNextPrice: action.shouldFetch,
      }
    case UPDATE_GAP_ACTIVATION_SELECTED:
      return {
        ...state,
        FreeGAPInsuranceCover: {
          ...state.FreeGAPInsuranceCover,
          FreeGapAdded: action.freeGapSelected,
        },
      }
    default:
      return state
  }
}

export default priceReducer
