import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import Header from '../../components/Header'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Route, Switch, withRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import RiskDataCollectionPage from '../RiskDataCollectionPage'
import { PageNotFound } from '../../components/PageNotFound'
import PolicyConfirmationPage from '../PolicyConfirmationPage'
import InvalidRegPage from '../InvalidRegPage'
import ErrorPage from '../../components/ErrorPage'
import AppWrapper from './AppWrapper'
import HoldingPage from '../../components/HoldingPage'
import themeService from '../../services/themeService'
import { BackgroundGradient } from './styles'
import { SWINTON_REF } from '../../services/affiliateConstants'
import { appendSwintonGtmTags } from '../../services/affiliateServices'
import EmailConfirmationPage from '../AnnualUpsell/EmailConfirmationPage'
import TrustPilot from '../../components/TrustPilot'
import { WHITE_LABEL } from '../../services/affiliateConstants'
import {
  createClientSession,
  updateClientSessionQuote,
  setClientSessionLastActivity,
} from './actions'
import useSession from '../../hooks/useSession/useSession'
import { usePrevious } from 'hooks'
import {
  loadEssentialScripts,
  loadRecordingScripts,
  loadTrackingScripts,
} from '../../utils/load-scripts'
import { getCookie, COOKIE_MANAGEMENT_NAME } from '../../utils/cookie-helpers'
import LoggedInCallback from 'pages/DriverDetails/LoggedInCallback'
import Login from 'pages/Auth/Login'
import PolicyInProgress from 'pages/Policy/InProgress'

const handleAllowedScripts = () => {
  loadEssentialScripts()
  const cookieManagementCookie = getCookie(COOKIE_MANAGEMENT_NAME)
  if (cookieManagementCookie) {
    const cookieSessionObject = JSON.parse(cookieManagementCookie)
    if (cookieSessionObject.trackingCookies) {
      loadTrackingScripts()
    }
    if (cookieSessionObject.recordingCookies) {
      loadRecordingScripts()
    }
  } else {
    // Load all scripts as they didn't interact with WP so we dont know preferences.
    loadTrackingScripts()
    loadRecordingScripts()
  }
}

const App = (props) => {
  const prevPath = usePrevious(window.location.pathname)
  const { manageSession } = useSession()

  useEffect(() => {
    const parsedQueryString = queryString.parse(window.location.search)
    if (
      parsedQueryString.ref &&
      parsedQueryString.ref.toLowerCase() === SWINTON_REF.toLowerCase()
    ) {
      appendSwintonGtmTags()
    }
    // handle allowed scripts
    handleAllowedScripts()
    if (window.isHoldingPageOn) {
      props.history.push(`/holding-page`)
    }
  }, [])

  useEffect(() => {
    if (window.location.pathname !== prevPath) {
      manageSession(window.location)
      window.scrollTo(0, 0)
    }
  }, [window.location.pathname])

  return (
    <ThemeProvider theme={themeService.getTheme(props.affiliate)}>
      <AppWrapper>
        <BackgroundGradient isLoading={props.isLoading}>
          <Header
            affiliate={props.affiliate}
            isLoading={props.isLoading}
            campaignRef={props.campaignRef}
          />
          {props.affiliate.AffiliateType !== WHITE_LABEL &&
            !props.isLoading && <TrustPilot />}
          <Switch>
            <Route path='/error' component={ErrorPage} />
            <Route path='/holding-page' component={HoldingPage} />
            <Route
              path='/policy/confirmation'
              component={PolicyConfirmationPage}
            />
            <Route path='/policy/in-progress' component={PolicyInProgress} />
            <Route path='/auth/callback' component={LoggedInCallback} />
            <Route path='/auth/login' component={Login} />
            <Route path='/vehicle/invalid-reg' component={InvalidRegPage} />
            <Route
              path='/AnnualInsurance/confirmation'
              component={EmailConfirmationPage}
            />
            <Route path='/' component={RiskDataCollectionPage} />
            <Route component={PageNotFound} />
            {/* Future roots into app, Admin, MyAccount...... */}
          </Switch>
          {/* <IntercomComponent /> */}
        </BackgroundGradient>
      </AppWrapper>
    </ThemeProvider>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    affiliate: state.affiliate,
    location: ownProps.location,
    step: state.step,
    router: state.router,
    showLiveChat: state.showLiveChat,
    isLoading: state.isLoading,
    campaignRef:
      (state.price && state.price.campaign && state.price.campaign.Ref) || null,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createClientSession,
        updateClientSessionQuote,
        setClientSessionLastActivity,
      },
      dispatch,
    ),
  }
}

App.propTypes = {
  theme: PropTypes.shape({
    logo: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }),
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
