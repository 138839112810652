import React from 'react'
import { Label, Value } from './DriverDetailItem.style'

interface Props {
  label: string
  value: string
  extraElement?: {
    linkName: string
    linkClass: string
    linkLocation: string
  }
}

const DriverDetailItem: React.FC<Props> = ({ label, value, extraElement }) => (
  <div>
    <Label>{label}</Label>
    {extraElement && (
      <a href={extraElement.linkLocation} className={extraElement.linkClass}>
        {extraElement.linkName}
        <i className='fa fa-pencil-alt' />
      </a>
    )}
    <Value>{value}</Value>
  </div>
)

export default DriverDetailItem
