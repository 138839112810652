import { InitialStateType } from 'initialState'
import React from 'react'
import { useSelector } from 'react-redux'
import { CA_LOGGED_IN_COOKIE, getCookie } from 'utils/cookie-helpers'
import RiskDataDiscountBanner from './RiskDataDiscountBanner'

const RiskDataDiscountBannerContainer = () => {
  const campaign = useSelector(
    (state: InitialStateType) => state.price.campaign,
  )

  const searchParams = new URLSearchParams(window.location.search)

  const newParams = new URLSearchParams()

  searchParams.forEach((value, key) => {
    newParams.append(key.toLowerCase(), value)
  })

  const affiliateCheck = newParams.get('ref') || newParams.get('refa')

  const loggedInCookie = getCookie(CA_LOGGED_IN_COOKIE)

  if (loggedInCookie && JSON.parse(loggedInCookie) && !affiliateCheck) {
    return (
      <RiskDataDiscountBanner message='As a Tempcover member, enjoy a discount on policies lasting one day or more!' />
    )
  }

  if (
    campaign.Exists
  ) {
    const validMessage =
      'Complete your quote to receive your Tempcover discount'
    const expiredMessage = 'Sorry! This discount has now expired'

    return (
      <RiskDataDiscountBanner
        expired={!campaign.Active}
        message={campaign.Active ? validMessage : expiredMessage}
      />
    )
  }
  return null
}
export default RiskDataDiscountBannerContainer
