import styled from 'styled-components'

export const CloseButton = styled.div`
  background: #cccccc;
  position: absolute;
  z-index: 2;
  top: 1em;
  right: 1em;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  cursor: pointer;

  svg {
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
  }
`
