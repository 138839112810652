import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'
import { Element } from 'react-scroll'
import {
  PriceComparisonWrapper,
  TotalPriceWrapper,
  TotalPriceValueWrapper,
  TotalPriceMainBlock,
  TotalPriceLabel,
  QuoteInfoWrapper,
  QuoteSummaryWrapper,
  SelectWrapper,
  PriceComparisonSelectButton,
  ImageWrapper,
  PriceComparisonContinueButton,
  ContainedQuoteDetailsWrapper,
  CoverTypeText,
  WasPriceLabel,
  WasPriceValue,
  LogoAndPriceContainer,
} from './styles'
import PriceComparisonDriverDetails from './PriceComparisonDriverDetails'
import { ButtonSpinner } from 'components/common/ButtonSpinner'
import { usePrice, useSystemFeatures, useAffiliate } from 'hooks'

const PriceComparison = ({
  priceData,
  selectedUnderwriter,
  onContinue,
  continueButtonDisabled,
  index,
  addressInfo,
  cheapest,
  isSelected,
  onSelect,
}) => {
  const [showDetails, setShowDetails] = useState(false)
  const { price } = usePrice()
  const { affiliate } = useAffiliate()
  const { IsCashbackEnabled } = useSystemFeatures()

  const wasPrice = priceData.TotalPrice + priceData.CampaignDiscount

  useEffect(() => {
    if (selectedUnderwriter === priceData.UnderwriterId) {
      setShowDetails(true)
    }
  }, [])

  useEffect(() => {
    setShowDetails(isSelected)
  }, [isSelected])

  const UNDERWRITER_STYLES = {
    Allianz: { width: '150px', height: '50px' },
  };  
  const getUnderwriterStyle = (underwriter) => {
    return UNDERWRITER_STYLES[underwriter] || {};
  };

  return (
    <Element name={`PriceBlock_${index}`}>
      <PriceComparisonWrapper
        onClick={() => (showDetails ? onSelect(null) : onSelect(index))}
        className={cheapest ? 'cheapest' : ''}
        selected={showDetails}
        id={`PriceBlock_${index}`}
      >
        <QuoteSummaryWrapper>
          <LogoAndPriceContainer>
            <QuoteInfoWrapper>
            <ImageWrapper>
                    <img
                      src={require(`../../images/Underwriters/${priceData.Underwriter}LogoForUnderwriterSelection.png`)}
                      alt={priceData.Underwriter}
                      className='price-comparison-underwriter-logo'
                      style={getUnderwriterStyle(priceData.Underwriter)}
                    />
            </ImageWrapper>
            </QuoteInfoWrapper>
            <TotalPriceWrapper>
              <TotalPriceLabel>Total&nbsp;price:</TotalPriceLabel>
              <TotalPriceMainBlock>
                {priceData.CampaignDiscount > 0 && (
                  <>
                    <div>
                      <WasPriceLabel id={`WasPriceLabel_${index}`}>WAS&nbsp;</WasPriceLabel>
                      <WasPriceValue>£{wasPrice.toFixed(2)}</WasPriceValue>
                      <TotalPriceValueWrapper id={`TotalPriceValueWrapper_${index}`}>
                        NOW £{priceData.TotalPrice.toFixed(2)}
                      </TotalPriceValueWrapper>
                    </div>
                  </>
                )}
                {priceData.CampaignDiscount == 0 && (
                  <TotalPriceValueWrapper id={`TotalPriceValueWrapper_${index}`}>
                    £{priceData.TotalPrice.toFixed(2)}
                  </TotalPriceValueWrapper>
                )}
                <CoverTypeText id={`CoverTypeText_${index}`}>Fully comprehensive</CoverTypeText>
                {priceData.CampaignDiscount > 0 && (
                  <CoverTypeText id={`CoverTypeText_${index}`}>
                    Includes £{priceData.CampaignDiscount.toFixed(2)} discount
                  </CoverTypeText>
                )}
              </TotalPriceMainBlock>
            </TotalPriceWrapper>
          </LogoAndPriceContainer>
          <Transition in={!showDetails} timeout={200}>
            {(state) => (
              <SelectWrapper>
                <PriceComparisonSelectButton
                  state={state}
                  onClick={() => onSelect(index)}
                  className='PriceComparisonSelectButton'
                >
                  Select
                </PriceComparisonSelectButton>
              </SelectWrapper>
            )}
          </Transition>
        </QuoteSummaryWrapper>
        <Transition in={showDetails} timeout={50}>
          {(state) => (
            <ContainedQuoteDetailsWrapper state={state}>
              <PriceComparisonDriverDetails
                addressInfo={addressInfo}
                showAmendLink={false}
              />
              <PriceComparisonContinueButton
                onClick={(e) => {
                  e.stopPropagation()
                  onContinue(e)
                }}
                disabled={continueButtonDisabled}
                className='PriceComparisonContinueButton'
              >
                {continueButtonDisabled ? (
                  <>
                    Please wait
                    <ButtonSpinner disabled={continueButtonDisabled} />
                  </>
                ) : (
                  <>
                    {price.AffiliateCashbackValue &&
                    affiliate.IsCashbackEnabled &&
                    IsCashbackEnabled ? (
                      <>
                        CONTINUE TO PAYMENT{' '}
                        <span style={{ display: 'inline-block' }}>
                          AND RECEIVE £{price.AffiliateCashbackValue} CASHBACK
                        </span>
                      </>
                    ) : (
                      <>Continue</>
                    )}
                  </>
                )}
              </PriceComparisonContinueButton>
            </ContainedQuoteDetailsWrapper>
          )}
        </Transition>
      </PriceComparisonWrapper>
    </Element>
  )
}

export default PriceComparison

PriceComparison.propTypes = {
  priceData: PropTypes.object.isRequired,
  selectedUnderwriter: PropTypes.number,
  onContinue: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  continueButtonDisabled: PropTypes.bool.isRequired,
  addressInfo: PropTypes.string.isRequired,
  cheapest: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}
