import * as yup from 'yup'

export const SurnameValidation = yup
  .string()
  .typeError('Please enter a valid surname')
  .matches(
    /^[a-zA-Z]+(([’'\-\s][a-zA-Z])?[a-zA-Z]+)?(([’'\-\s][a-zA-Z])?[a-zA-Z]+)$/,
    'Please enter a valid surname',
  )
  .min(2, 'Please enter a valid surname')
  .max(50, 'Please enter a valid surname')
