import styled from 'styled-components'
import React from 'react'
import { BaseInput } from './BaseInput'

export const UppercaseInputStyled = styled(BaseInput)`
  text-transform: uppercase;
  ::placeholder {
    text-transform: none;
  }
`

export const UppercaseInput = ({
  placeholder,
  onChange,
  maxLength,
  name,
  error,
  value,
  className,
  disabled = false,
  id,
}) => {
  return (
    <UppercaseInputStyled
      placeholder={placeholder}
      onChange={onChange}
      maxLength={maxLength}
      name={name}
      error={error}
      value={value}
      autoComplete='off'
      autoCorrect='off'
      className={className}
      disabled={disabled}
      id={id}
    />
  )
}
