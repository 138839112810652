import React, { ReactNode, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './style.css'

import { Navigation, Pagination } from 'swiper'

import {
  CarouselContainer,
  CarouselPrevious,
  CarouselNext,
  CarouselPagination,
  CarouselNavigationContainer,
} from './Carousel.styles'
import { SwiperCarousel } from './Carousel.types'

export interface Props {
  slidesToDisplay?: number
  slideData?: ReactNode[]
  navigationOn?: boolean
  paginationOn?: boolean
  initialSlide?: number
  onSlideChange: (result: number) => void
  slideTo?: number | null
}
const Carousel: React.FC<Props> = ({
  slidesToDisplay,
  slideData,
  navigationOn,
  paginationOn,
  initialSlide,
  onSlideChange,
  slideTo,
}) => {
  const handleSlideChange = (swiperCore: SwiperCarousel) => {
    onSlideChange(swiperCore.activeIndex)
  }

  const [swiper, setSwiper] = useState<SwiperClass | null>(null)

  useEffect(() => {
    if (swiper && slideTo) {
      swiper.slideTo(slideTo)
      slideTo = null
    }
  })

  return (
    <CarouselContainer>
      <div className='swiper-container'>
        <Swiper
          pagination={
            paginationOn
              ? {
                  clickable: true,
                  el: '.swiper-custom-pagination',
                }
              : false
          }
          navigation={
            navigationOn
              ? {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }
              : false
          }
          // navigation={navigationOn}
          slidesPerView={1}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: slidesToDisplay,
              spaceBetween: 10,
            },
          }}
          spaceBetween={10}
          modules={[Pagination, Navigation]}
          className='mySwiper'
          initialSlide={initialSlide}
          centeredSlides
          onSlideChange={(swiperCore) => handleSlideChange(swiperCore)}
          slideToClickedSlide
          observer
          onSwiper={(s) => setSwiper(s)}
        >
          {slideData?.map((data, index) => (
            <SwiperSlide key={index}>{data}</SwiperSlide>
          ))}
        </Swiper>
        <CarouselPagination className='swiper-custom-pagination' />
        <CarouselNavigationContainer>
          <CarouselPrevious className='swiper-button-prev' />
          <CarouselNext className='swiper-button-next' />
        </CarouselNavigationContainer>
      </div>
    </CarouselContainer>
  )
}

export default Carousel
