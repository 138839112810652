import React from 'react'
import PropTypes from 'prop-types'
import { Primary, Large, Medium, Small, Smaller, Smallest } from './I'

const Spinner = ({ size, color }) => {
  const fontAwesomeIcon = 'fas fa-circle-notch fa-spin'
  return (
    <>
      {size === undefined && (
        <Primary className={fontAwesomeIcon} color={color}></Primary>
      )}
      {size === 'primary' && (
        <Primary className={fontAwesomeIcon} color={color}></Primary>
      )}
      {size === 'large' && (
        <Large className={fontAwesomeIcon} color={color}></Large>
      )}
      {size === 'medium' && (
        <Medium className={fontAwesomeIcon} color={color}></Medium>
      )}
      {size === 'small' && (
        <Small className={fontAwesomeIcon} color={color}></Small>
      )}
      {size === 'smaller' && (
        <Smaller className={fontAwesomeIcon} color={color}></Smaller>
      )}
      {size === 'smallest' && (
        <Smallest className={fontAwesomeIcon} color={color}></Smallest>
      )}
    </>
  )
}

Spinner.propTypes = {
  size: PropTypes.string,
}

export default Spinner
