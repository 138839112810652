import { store } from 'api/store'
import LoadingScreen from 'components/LoadingScreen'
import config from 'config'
import { UserManager, UserManagerSettings } from 'oidc-client-ts'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAuthAccessToken } from 'store/auth/auth.actions'
import { CA_LOGGED_IN_TOKEN, getCookie } from 'utils/cookie-helpers'

const LoggedInCallback = () => {
  const [userToken, setUserToken] = useState('')

  const queryParameters = localStorage.getItem('queryParameters')

  const dispatch = useDispatch()

  useEffect(() => {
    const authConfig: UserManagerSettings = {
      authority: config.CUSTOMER_ACCOUNT_ID_SERVER_URL,
      client_id: 'teslaClient',
      redirect_uri: `${config.HOST_PATH}/auth/callback`,
      response_type: 'code id_token token',
      filterProtocolClaims: true,
      loadUserInfo: true,
      scope: 'openid profile api',
      post_logout_redirect_uri: config.SITE_URL,
      response_mode: 'query',
    }
    const mgr = new UserManager(authConfig)
    mgr.signinRedirectCallback().then((user) => {
      const secureToken = getCookie(CA_LOGGED_IN_TOKEN)
      store.sessionToken = secureToken ?? ''
      dispatch(setAuthAccessToken({ accessToken: user.access_token }))
      // eslint-disable-next-line @typescript-eslint/dot-notation
      store.authToken = user.access_token
      setUserToken(user.access_token)
      // redirect back
      localStorage.removeItem('queryParameters')
      if (queryParameters?.includes('ca_source')) {
        window.location.href = `/quote/legal-declaration${queryParameters}`
      } else {
        window.location.href = `/driver-details/logged-in${queryParameters}`
      }
    })
  }, [dispatch, queryParameters])

  return <LoadingScreen message='Loading' />
}

export default LoggedInCallback
