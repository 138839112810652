import React from 'react'
import Modal from '../../components/@common/Modal'
import Vehicle from '../Vehicle'
import './style.css'

export class VehicleModal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Modal
        id='vehicleModal'
        isVisible={this.props.isVisible}
        onClose={this.props.hideModal}
        header='Change vehicle'
        headerAlignment='center'
        fullscreen={window.innerWidth <= 500}
      >
        <Vehicle handleConfirm={this.props.handleUpdate} />
      </Modal>
    )
  }
}

export default VehicleModal
