import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import UAParser from 'ua-parser-js'
import { SESSIONSTATES } from '../../services/constants'
import config from '../../config'
import {
  createClientSession,
  updateClientSessionQuote,
  setClientSessionLastActivity,
} from '../../containers/App/actions'
import { getCookie } from 'utils/cookie-helpers'

const getTagData = (queryStringText, referrer) => {
  let parsed = queryString.parse(queryStringText)
  let results = {
    utmSource: parsed.utm_source || '',
    utmMedium: parsed.utm_medium || '',
    utmCampaign: parsed.utm_campaign || '',
    referrer: referrer || '',
    affiliate: parsed.affiliate || '',
  }
  return results
}

const buildSessionData = (location, quoteId, sessionGuid) => {
  const referrer = document.referrer
  const tagData = getTagData(location.search, referrer)
  const uaDevice = new UAParser(navigator.userAgent).getDevice()
  const confusedGASessionId = getCookie(config.CONFUSED_GA_SESSION_COOKIE_NAME)
  const additionalData = {
    sessionQuoteId: quoteId || null,
    sessionGuid: sessionGuid || null,
    userAgent: navigator.userAgent || null,
    deviceType: uaDevice.type ? 'mobile' : 'desktop', // UAParser returns undefined for desktops, but a value for everything else
    confusedGASessionId,
  }

  return { ...tagData, ...additionalData }
}

const useSession = () => {
  const dispatch = useDispatch()
  const sessionData = useSelector((state) => state.session)
  const quoteId = useSelector((state) => state.riskData.QuoteId)

  const manageSession = (location) => {
    if (config.IS_CLIENT_SESSION_ENABLED) {
      if (
        sessionData.sessionState === SESSIONSTATES.NEW_SESSION ||
        (sessionData.sessionLastActivityTimestamp <
          Date.now() - config.SESSION_EXPIRY_MINUTES * 60000 &&
          (sessionData.sessionState === SESSIONSTATES.SESSION_CREATED ||
            sessionData.sessionState === SESSIONSTATES.SESSION_QUOTE_UPDATED))
      ) {
        dispatch(
          createClientSession(
            buildSessionData(location, quoteId, sessionData.SessionGuid),
          ),
        )
      }

      // existing session with a new quoteId
      if (
        (sessionData.sessionState === SESSIONSTATES.SESSION_CREATED ||
          sessionData.sessionState === SESSIONSTATES.SESSION_QUOTE_UPDATED) &&
        quoteId &&
        quoteId !== sessionData.sessionQuoteId
      ) {
        dispatch(updateClientSessionQuote(quoteId))
      }

      // always update sessionLastActivityTimestamp to NOW after other actions performed
      dispatch(setClientSessionLastActivity(Date.now()))
    }
  }

  return {
    manageSession,
  }
}

export default useSession
