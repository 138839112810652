import { useDispatch, useSelector } from 'react-redux'
import { InitialStateType } from 'initialState'
import { CREATE_QUOTE_REL } from 'containers/App/constants'
import quoteApi from 'api/quoteApi'
import { createQuoteSuccess } from 'containers/App/actions'

import { Scheme } from 'types/global'

const useQuote = () => {
  const quote = useSelector((state: InitialStateType) => state.quote)
  const dispatch = useDispatch()
  const scheme = useSelector((state: InitialStateType) => state.scheme.selected)
  const openSchemes = useSelector(
    (state: InitialStateType) => state.scheme.open,
  )
  const vehicleInfo = useSelector((state: InitialStateType) => state.vehicle)

  const riskData = useSelector((state: InitialStateType) => state.riskData)
  const durationData = useSelector((state: InitialStateType) => state.duration)
  const affiliate = useSelector((state: InitialStateType) => state.affiliate)
  const campaignData = useSelector(
    (state: InitialStateType) => state.price.campaign,
  )
  const { QuidcoClickId } = useSelector(
    (state: InitialStateType) => state.external,
  )

  const createQuote = async (newScheme?: Scheme) => {
    const schemeToUse = newScheme || scheme
    const createQuoteLinks = openSchemes.filter(
      (s) => s.Links.filter((l) => l.Rel === CREATE_QUOTE_REL).length > 0,
    )
    const createQuoteLink = createQuoteLinks?.find(
      (l) => l.Scheme === schemeToUse,
    )?.Links[0]

    const data = {
      VehicleAbi: vehicleInfo.selected.Abi,
      VehicleId: vehicleInfo.selected.VehicleId,
      Duration: durationData.value,
      DurationType: durationData.type,
      CampaignRef: campaignData.Ref,
      Impounded: riskData.Impounded,
      Expat: riskData.Expat,
      Learner: riskData.Learner,
      Motorbike: riskData.Motorbike,
      ResidencyType: riskData.ResidencyType,
      UkResidencyDuration: riskData.UkResidencyDuration,
      QuidcoClickId,
      EncryptedCustomerAccountId: riskData.CustomerId,
    }
    const quoteEndpoint = createQuoteLink!.Href.replace(
      /{[affiliateRef}]*}/,
      affiliate.Ref || 'tempcover',
    )
    const response = await quoteApi.createQuote(quoteEndpoint, data)

    dispatch(createQuoteSuccess(response.data))

    return response.data
  }
  return {
    quote,
    createQuote,
  }
}

export default useQuote
