import styled from 'styled-components'

export const CarouselContainer = styled.div`
  position: relative;
  margin: 0 auto;
`
export const CarouselPrevious = styled.div`
  left: -40px;
  color: ${(props) => props.theme.primaryColour};
`

export const CarouselNext = styled.div`
  right: -40px;
  color: ${(props) => props.theme.primaryColour};
`

export const CarouselPagination = styled.div`
  right: -40px;

  & .swiper-pagination-bullet-active {
    background-color: ${(props) => props.theme.primaryColour};
  }
`
export const CarouselNavigationContainer = styled.div`
  @media screen and (max-width: 769px) {
    display: none;
  }
`
