import moment from 'moment'
import { call, put, select } from 'redux-saga/effects'
import {
  enableAllButtons,
  requestIdentityCheckMoreInfo,
  requestIdentityCheckSuccess,
  underwriterChangedByCue,
  updateValidation,
} from '../actions'

import config from 'config'
import { history } from '../../../'
import identityApi from '../../../api/IdentityApi'
import quoteApi from '../../../api/quoteApi'
import { QuoteJourneyPosition } from '../../../types/global'
import { requestPriceSuccess } from '../../App/actions'
import { getAffiliateFromState, getPriceLink } from '../../App/selector'
import { getPriceData } from '../../LegalDeclarationModal/selector'
import { updateMyLicenceData } from '../../MyLicence/actions'
import { getMyLicenceStatus } from '../../MyLicence/selector'
import { getIsCustomerAccountQuote } from 'containers/RiskDataCollectionPage/selector'
import { updateCustomerAccountExists } from 'containers/App/actions'
import { getVehicleInfo } from 'containers/App/selector'

import {
  drivingLicenceCheckLink,
  getExperiments,
  getQueryString,
  getRiskData,
  identityCheckLinkSelector,
  passportCheckLink,
} from '../selector'
import CustomerAccountApi, {
  SaveCustomerRegistrationRequest,
} from 'api/customerAccount'
import VehicleReg from 'components/VehicleReg'

export function* identitySaga() {
  const identityCheckLink = yield select(identityCheckLinkSelector)
  const riskData = yield select(getRiskData)
  const queryString = yield select(getQueryString)
  const isCustomerAccount = select(getIsCustomerAccountQuote)

  try {
    const identityLink = identityCheckLink.Href.replace(
      /{[encryptedQuoteId}]*}/,
      riskData.QuoteId,
    )
    const identityCheck = yield call(identityApi.identityCheck, identityLink)
    switch (identityCheck.data.FraudWorkFlow) {
      case 'DriverLicenceCheck':
        yield put(
          requestIdentityCheckMoreInfo(
            identityCheck.data.Links,
            'drivingLicence',
          ),
        )
        if (riskData.DrivingLicenceNumber) {
          yield* drivingLicenceCheckSaga()
        } else {
          yield put(
            updateValidation({
              DrivingLicenceNumberError:
                'Please enter your driving licence number',
            }),
          )
          yield put(
            requestIdentityCheckMoreInfo(
              identityCheck.data.Links,
              'drivingLicence',
            ),
          )
          yield put(enableAllButtons())
          history.push(`/quote/driver-details/more-info${queryString}`)
        }
        break
      case 'PassportCheck':
        yield put(
          requestIdentityCheckMoreInfo(identityCheck.data.Links, 'passport'),
        )
        if (
          riskData.PassportLineOne &&
          riskData.PassportLineTwo &&
          riskData.PassportExpiryDate
        ) {
          yield* passportCheckSaga()
        } else {
          yield put(
            updateValidation({
              PassportLineOneError: riskData.PassportLineOne
                ? ''
                : 'Please enter passport line one',
              PassportLineTwoError: riskData.PassportLineTwo
                ? ''
                : 'Please enter passport line two',
              PassportExpiryDateError: riskData.PassportExpiryDate
                ? ''
                : 'Please enter your passport expiry date',
            }),
          )

          history.push(`/quote/driver-details/more-info${queryString}`)
          yield put(enableAllButtons())
        }
        break
      case 'Prepayment':
        const {
          UnderwriterId,
          ExcessReduction,
          BreakdownCover,
          LegalExpensesCover,
          FreeGAPInsuranceCover,
        } = yield select(getPriceData)
        const { PriceComparison } = yield select(getAffiliateFromState)
        const experiments = yield select(getExperiments)
        const priceComparisonAB =
          experiments[config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID]
        const isMultiPriceJourney = PriceComparison || priceComparisonAB

        if (identityCheck.data.UnderwriterId !== UnderwriterId) {
          let { Href: priceLink } = yield select(getPriceLink)

          if (isMultiPriceJourney) {
            priceLink = `${priceLink}/all`
          }

          const { QuoteId } = yield select(getRiskData)

          const header = {
            Accept: isMultiPriceJourney
              ? 'application/tempcover.PricingAllRepresentation+json'
              : 'application/tempcover.PricingRepresentation+json',
          }

          const price = yield call(
            quoteApi.getPrice,
            priceLink,
            {
              value: QuoteId,
              ExcessReductionIncluded: ExcessReduction.ExcessReductionIncluded,
              BreakdownIncluded: BreakdownCover.BreakdownIncluded,
              LegalExpensesIncluded: LegalExpensesCover.LegalExpensesIncluded,
              Free30DayGAPInsuranceIncluded:
                FreeGAPInsuranceCover.Free30DayGAPInsuranceIncluded,
              QuoteJourneyPosition:
                QuoteJourneyPosition.SystemDocumentCheckSubmit,
            },
            header,
          )

          yield put(requestPriceSuccess({ ...price.data }))
          yield put(underwriterChangedByCue(identityCheck.data.UnderwriterId))
          history.push(`/quote/driving-licence${queryString}`)
          yield put(enableAllButtons())
        } else {
          yield put(requestIdentityCheckSuccess(identityCheck.data))
        }
        break
      case 'Price':
        //We will hit this if the underwriter has changed during identity checks
        yield put(drivingLicenceCheckSaga())
        yield put(enableAllButtons())
        break
      default:
        break
    }
  } catch (e) {
    yield put(requestPriceSuccess({ TotalPrice: 0 }))
    yield put(requestIdentityCheckMoreInfo([], 'passport'))
    history.push(`/quote/driving-licence${queryString}`)
    yield put(enableAllButtons())
  }
}

export function* drivingLicenceCheckSaga() {
  const licenceCheck = yield select(drivingLicenceCheckLink)
  const riskData = yield select(getRiskData)
  const queryString = yield select(getQueryString)
  const { PriceComparison } = yield select(getAffiliateFromState)
  const isCustomerAccount = select(getIsCustomerAccountQuote)
  try {
    const licenceCheckLink = licenceCheck.Href.replace(
      /{[encryptedQuoteId}]*}/,
      riskData.QuoteId,
    )
    const data = {
      LicenceNumber: riskData.DrivingLicenceNumberFirstPart.concat(
        riskData.DrivingLicenceNumber.toUpperCase(),
      ),
    }
    const response = yield call(
      identityApi.drivingLicenceCheck,
      licenceCheckLink,
      data,
    )
    const myLicenceData = yield select(getMyLicenceStatus)
    const actionResults = myLicenceData.Actioned
    const myLicenceCheckFailed = response.data.CheckFailed
    const myLicenceFailReasons = response.data.FailReasons

    if (actionResults) {
      //We have done the checks and relevant DB Logging, but we don't want to act on the results

      yield put(
        updateMyLicenceData({
          CheckFailed: myLicenceCheckFailed,
          Actioned: actionResults,
          FailReasons: myLicenceFailReasons,
        }),
      )

      if (
        myLicenceCheckFailed &&
        (myLicenceFailReasons.indexOf('LicenceNumber') >= 0 ||
          myLicenceFailReasons.indexOf('LicenceCategory') >= 0)
      ) {
        yield put(enableAllButtons())
        if (isCustomerAccount) {
          history.push(`/quote/driver-details/logged-in${queryString}`)
        } else {
          history.push(`/quote/driver-details${queryString}`)
        }
        return
      } else {
        let { Href: priceLink } = yield select(getPriceLink)
        const experiments = yield select(getExperiments)
        const priceComparisonAB =
          experiments[config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID]
        const isMultiPriceJourney = PriceComparison || !!priceComparisonAB

        if (isMultiPriceJourney) {
          priceLink = `${priceLink}/all`
        }

        const { QuoteId } = yield select(getRiskData)
        const {
          Underwriter,
          ExcessReduction,
          BreakdownCover,
          LegalExpensesCover,
          FreeGAPInsuranceCover,
        } = yield select(getPriceData)

        const header = {
          Accept: isMultiPriceJourney
            ? 'application/tempcover.PricingAllRepresentation+json'
            : 'application/tempcover.PricingRepresentation+json',
        }

        const price = yield call(
          quoteApi.getPrice,
          priceLink,
          {
            value: QuoteId,
            ExcessReductionIncluded: ExcessReduction.ExcessReductionIncluded,
            BreakdownIncluded: BreakdownCover.BreakdownIncluded,
            LegalExpensesIncluded: LegalExpensesCover.LegalExpensesIncluded,
            Free30DayGAPInsuranceIncluded:
              FreeGAPInsuranceCover.Free30DayGAPInsuranceIncluded,
            QuoteJourneyPosition:
              QuoteJourneyPosition.SystemDocumentCheckSubmit,
          },
          header,
        )

        let previouslySelectedPrice = null
        if (
          isMultiPriceJourney &&
          price.data.Prices &&
          price.data.Prices.length > 0
        ) {
          previouslySelectedPrice = price.data.Prices.find(
            (p) => p.Underwriter === Underwriter,
          )
        }
        // Price Comparison: Does previously selected price still exist in list and have value > 0
        if (
          (!isMultiPriceJourney && price.data.Underwriter != Underwriter) ||
          (isMultiPriceJourney &&
            (!previouslySelectedPrice ||
              (previouslySelectedPrice &&
                previouslySelectedPrice.TotalPrice === 0)))
        ) {
          yield put(
            requestPriceSuccess({ ...price.data, underwriterChanged: true }),
          )

          yield put(enableAllButtons())
          history.push(`/quote/driving-licence${queryString}`)
          return
        } else {
          yield call(identitySaga)
        }
      }
    } else {
      yield call(identitySaga)
    }
  } catch (e) {
    yield put(enableAllButtons())
    if (isCustomerAccount) {
      history.push(`/quote/driver-details/logged-in${queryString}`)
    } else {
      history.push(`/quote/driver-details${queryString}`)
    }
  }
}

export function* passportCheckSaga() {
  const passportCheck = yield select(passportCheckLink)
  const riskData = yield select(getRiskData)
  const queryString = yield select(getQueryString)
  const isCustomerAccount = select(getIsCustomerAccountQuote)
  try {
    const passportCheckLink = passportCheck.Href.replace(
      /{[encryptedQuoteId}]*}/,
      riskData.QuoteId,
    )
    const data = {
      UkPassportLineOne: riskData.PassportLineOne,
      UkPassportLineTwo: riskData.PassportLineTwo,
      UkPassportExpiryDate: moment(
        riskData.PassportExpiryDate,
        'DD/MM/YYYY',
      ).format('yyyy-MM-DD'),
    }
    yield call(identityApi.passportCheck, passportCheckLink, data)
    yield call(identitySaga)
  } catch (e) {
    yield put(enableAllButtons())
    if (isCustomerAccount) {
      history.push(`/quote/driver-details/logged-in${queryString}`)
    } else {
      history.push(`/quote/driver-details${queryString}`)
    }
  }
}

export function* saveCustomerDrivingLicenceSaga() {
  const riskData = yield select(getRiskData)

  const licenceNumber = riskData.DrivingLicenceNumberFirstPart.concat(
    riskData.DrivingLicenceNumber.toUpperCase(),
  )

  yield call(CustomerAccountApi.saveCustomerLicenceDetails, licenceNumber)
}

export function* doesCustomerAccountExist() {
  try {
    const riskData = yield select(getRiskData)

    const vehicle = yield select(getVehicleInfo)

    const hasAccountResponse = yield call(
      CustomerAccountApi.CheckMobileHasAccount,
      riskData.Mobile,
    )

    let accountExists = false

    if (hasAccountResponse.data) {
      accountExists =
        hasAccountResponse.data.accountExists &&
        vehicle.searched.RegistrationNumber
    }

    yield put(updateCustomerAccountExists(accountExists))
  } catch (e) {
    yield put(updateCustomerAccountExists(false))
  }
}
