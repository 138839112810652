import styled from 'styled-components'

export const SuggestionText = styled.span`
  display: inline;
  cursor: pointer;
  text-decoration: underline;
  color: blue;
`

export const IconStyled = styled.i`
  font-size: 20px;
  color: ${(props) => props.theme.icon.color};
`
