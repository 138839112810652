import Digicert from 'components/@Logos/Digicert'
import TrustPilotSummary from 'components/TrustPilotSummary'
import config from 'config'
import { useABExperiment } from 'hooks'
import React from 'react'
import { SecurityLogosWrapper } from './SecurityLogos.style'

const SecurityLogos = () => {
  const { variant } = useABExperiment(
    config.AB_TESTS.SECURITY_EXPERIMENT_ID,
    'Security logos - price page',
  )

  if (variant === '1') {
    return (
      <SecurityLogosWrapper>
        <TrustPilotSummary />
        <Digicert width={100} />
      </SecurityLogosWrapper>
    )
  }
  return null
}

export default SecurityLogos
