import React, { useCallback, useState } from 'react'
import GridContainerWrapper from 'components/@common/GridContainerWrapper'
import { Grid } from 'unsemantic'
import Carousel from 'components/@common/Carousel'
import VehicleDetail from 'components/VehicleDetail'
import { NewVehicleDetails } from 'components/VehicleDetail/VehicleDetail.types'
import { useVehicle } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { setQuoteJourneyPosition } from 'containers/App/actions'
import { QuoteJourneyPosition } from 'types/global'
import VehicleCarouselAddNew from 'components/VehicleCarouselAddNew'
import { useSaveNewVehicleToCustomer } from 'components/VehicleCarouselAddNew/services/VehicleCarouseAddNew'
import { SaveNewVehicleRequest } from 'api/customerAccount'
import {
  clearSearchedVehicle,
  setCustomerAccountCarouselReg,
  setVehicleFromCarousel,
  updateVehicleAlreadyExists,
  updateSelectedVehicleBodyType,
  updateIfManuallyBuilt,
  requestVehicleSuccess,
  setNewCustomerAccountVehicleFromHomePage,
  setVehicle,
} from 'containers/Vehicle/actions'

import vehicleApi from 'api/vehicleApi'
import { InitialStateType } from 'initialState'
import {
  CREATE_UNKNOWN_VEHICLE_REL,
  CREATE_UNKNOWN_COMMERCIAL_VEHICLE_REL,
} from 'containers/Vehicle/constants'
import { updateRiskData } from 'containers/RiskDataCollectionPage/actions'
import { VEHICLE_VALUES } from 'components/VehicleValue/constants'
import { CREATE_QUOTE_REL } from 'containers/App/constants'

export interface Props {
  carouselItems: NewVehicleDetails[]
  includeNewVehicle: boolean
  slidesToDisplay?: number
}

const VehicleCarousel: React.FC<Props> = ({
  carouselItems,
  includeNewVehicle,
  slidesToDisplay,
}) => {
  const dispatch = useDispatch()
  const buildUnknownVehicleLink = useSelector(
    (state: InitialStateType) =>
      state.links.filter((link) => link.Rel === CREATE_UNKNOWN_VEHICLE_REL)[0],
  )

  const createUnknownCommercialVehicleLinkSelect = useSelector(
    (state: InitialStateType) =>
      state.links.filter(
        (link) => link.Rel === CREATE_UNKNOWN_COMMERCIAL_VEHICLE_REL,
      )[0],
  )

  const { Links } = useSelector(
    (state: InitialStateType) =>
      state.scheme.open.filter((s) => s.Scheme === state.scheme.selected)[0],
  )

  const { vehicle } = useVehicle()

  const [addNewVehicle, setAddNewVehicle] = useState(
    vehicle.preloadNewCustomerAccountVehicle,
  )
  const [slideTo, setSlideTo] = useState<number | null>(null)

  if (
    includeNewVehicle &&
    carouselItems.findIndex((x) => x.vehicleId === 0) === -1
  ) {
    carouselItems.splice(0, 0, {
      NewVehicle: true,
      vehicleId: 0,
    } as NewVehicleDetails)
  }
  const [slideData, setSlides] = useState(
    carouselItems.map((item) => (
      <VehicleDetail
        vehicleDetail={item}
        singleSlide={!!(slidesToDisplay !== undefined && slidesToDisplay === 1)}
      />
    )),
  )

  const getInitialSlideValue = () => {
    if (slideData != null && slideData.length > 1) {
      if (vehicle.searched) {
        if (vehicle.preloadNewCustomerAccountVehicle) {
          return 0
        }

        if (
          carouselItems.filter(
            (d) =>
              d.registrationNumber !== null &&
              vehicle.searched.RegistrationNumber !== null &&
              d.registrationNumber &&
              vehicle.searched.RegistrationNumber &&
              d.registrationNumber === vehicle.searched.RegistrationNumber,
          ).length > 0
        ) {
          return carouselItems.findIndex(
            (d) =>
              d.registrationNumber !== null &&
              vehicle.searched.RegistrationNumber !== null &&
              d.registrationNumber &&
              vehicle.searched.RegistrationNumber &&
              d.registrationNumber === vehicle.searched.RegistrationNumber,
          )
        }
        return 1
      }
      return 1
    }
    return 0
  }

  const initialSlide = getInitialSlideValue()

  const [uploadError, setUploadError] = useState(true)

  const saveNewVehicleMutation = useSaveNewVehicleToCustomer()

  const setVehicleToCarouselVehicle = (vehiclePosition: number) => {
    const selectedVehicle = carouselItems[vehiclePosition]

    // manually set selected vehicle from carousel details
    const getQuoteLink = Links.filter((l) => l.Rel === CREATE_QUOTE_REL)

    const vehicleSuccess = {
      Abi: selectedVehicle.abi,
      DateFirstRegistered: selectedVehicle.abi,
      Links: getQuoteLink,
      Make: selectedVehicle.make,
      Model: selectedVehicle.model,
      PartnerPays: false,
      RegistrationNumber: selectedVehicle.registrationNumber,
      VehicleId: selectedVehicle.vehicleId,
      VehicleType: selectedVehicle.vehicleBodyType,
      VehicleValueBandId: selectedVehicle.vehicleValueBandId,
    }

    dispatch(requestVehicleSuccess(vehicleSuccess))

    dispatch(updateIfManuallyBuilt(selectedVehicle.manuallyBuilt))

    dispatch(setCustomerAccountCarouselReg(selectedVehicle.registrationNumber))

    if (selectedVehicle.vehicleBodyType === 'CommercialVehicle') {
      dispatch(
        updateSelectedVehicleBodyType(
          selectedVehicle.commercialVehicleBodyType,
        ),
      )
    } else {
      dispatch(updateSelectedVehicleBodyType(''))
    }

    dispatch(setVehicleFromCarousel(selectedVehicle))
  }
  // dispatch(fetchVehicleSaga({ registrationNumber: selectedVehicle }))

  const handleVehicleChange = (param: number) => {
    if (param === 0 && includeNewVehicle) {
      // Add new vehicle
      dispatch(clearSearchedVehicle())
      setAddNewVehicle(true)
      setSlideTo(null)
      setUploadError(false)
    } else {
      setAddNewVehicle(false)
      setUploadError(false)
      setVehicleToCarouselVehicle(param)
      // Get vehicle details
      // GetCarFunction
    }
    dispatch(setNewCustomerAccountVehicleFromHomePage(false))
  }

  const addVehicle = useCallback(
    (vehicleId: number, manuallyBuilt: boolean) => {
      const newVehicle = {
        vehicleId,
        make: vehicle.searched.Make,
        vehicleValueBandDescription: vehicle.searched.vehicleValue.description,
        vehicleValueBandId: parseInt(vehicle.searched.vehicleValue.value, 10),
        registrationNumber: vehicle.searched.RegistrationNumber,
        model: vehicle.searched.Model,
        NewVehicle: false,
        vehicleBodyType: vehicle.searched.VehicleType,
        grossWeight: vehicle.searched.GrossWeight,
        vehicleYear: vehicle.searched.YearOfManufacture,
        commercialVehicleBodyType: vehicle.searched.BodyType,
        manuallyBuilt,
        abi: vehicle.searched.Abi,
      } as NewVehicleDetails

      carouselItems.push(newVehicle)
      slideData.push(<VehicleDetail vehicleDetail={newVehicle} />)
      setSlides(slideData)
      // setIntialSlide(1)

      // dont need this on ca as they can change the vehicle alot in the carousel
      // dispatch(
      //   setQuoteJourneyPosition(
      //     QuoteJourneyPosition.CustomerAccountVehicleChanged,
      //   ),
      // )

      dispatch(clearSearchedVehicle())

      dispatch(
        updateRiskData({
          Motorbike: false,
        }),
      )

      setSlideTo(carouselItems.length - 1)
      // setVehicleToCarouselVehicle(carouselItems.length - 1)

      // // set this vehicle to selected in the state
      // dispatch(
      //   setCustomerAccountCarouselReg(vehicle.searched.RegistrationNumber),
      // )

      // dispatch(setVehicleFromCarousel())
      dispatch(setNewCustomerAccountVehicleFromHomePage(false))
    },
    [carouselItems, dispatch, slideData, vehicle],
  )

  const saveVehicle = useCallback(
    (vehicleId: number | null, manuallyBuilt: boolean) => {
      const saveNewVehicleRequest: SaveNewVehicleRequest = {
        vehicleId:
          vehicleId != null
            ? vehicleId.toString()
            : vehicle.searched.VehicleId?.toString(),
        vehicleBodyType: vehicle.searched.BodyType
          ? vehicle.searched.BodyType
          : vehicle.searched.VehicleType,
        vehicleValueBandId: vehicle.searched.vehicleValue.value.toString(),
      }

      saveNewVehicleMutation.mutate(saveNewVehicleRequest, {
        onSuccess: () => {
          setUploadError(false)
          // Will always have a value but throws error unless we add null check here
          if (saveNewVehicleRequest.vehicleId) {
            addVehicle(
              parseInt(saveNewVehicleRequest.vehicleId?.toString(), 10),
              manuallyBuilt,
            )
            setSlideTo(null)
          }
        },
        onError: () => {
          if (
            carouselItems.filter(
              (d) => d.vehicleId.toString() === saveNewVehicleRequest.vehicleId,
            ).length > 0
          ) {
            setUploadError(true)
            const carouselItemIndex = carouselItems.findIndex(
              (x) => x.vehicleId.toString() === saveNewVehicleRequest.vehicleId,
            )
            setSlideTo(carouselItemIndex)
          } else {
            setUploadError(true)
          }
        },
      })
    },
    [addVehicle, carouselItems, saveNewVehicleMutation, vehicle],
  )

  const checkVehicleExistsInGarage = (param: string) => {
    if (
      carouselItems.findIndex(
        (x) => x.registrationNumber === param.toUpperCase(),
      ) !== -1
    ) {
      dispatch(updateVehicleAlreadyExists(true))
    } else {
      dispatch(updateVehicleAlreadyExists(false))
    }
  }

  const closeFeaturePopup = () => {
    setSlideTo(1)
  }

  const handleUpdate = useCallback(async () => {
    // no value selected so we need to default a va;ie
    if (vehicle.searched.vehicleValue.value === '') {
      vehicle.searched.vehicleValue.description = VEHICLE_VALUES[0].description
      vehicle.searched.vehicleValue.value = VEHICLE_VALUES[0].value.toString()
    }

    if (vehicle.searched.VehicleType === 'Motorcycle') {
      dispatch(clearSearchedVehicle())
      dispatch(
        updateRiskData({
          Motorbike: false,
        }),
      )
    } else if (
      vehicle.searched.RegistrationNumber &&
      vehicle.searched.VehicleId
    ) {
      // Save vehicle
      saveVehicle(null, false)

      // do something with response when set up
      // if valid then allow saving else dont add to carousel and display reason why
      // if reason is already exists need to find the position of the vheicle and then set the slide to that one
    } else if (vehicle.searched.VehicleType === 'PrivateCar') {
      const data = {
        AbiCode: vehicle.searched.Abi,
        IsRegistrationKnown: !!vehicle.searched.RegistrationNumber,
        Registration: vehicle.searched.RegistrationNumber,
      }

      const manuallyBuiltVehicle = await vehicleApi.createUnknownVehicle(
        buildUnknownVehicleLink.Href,
        data,
      )
      saveVehicle(manuallyBuiltVehicle.data.VehicleId, true)
    } else {
      const data = {
        AbiCode: vehicle.searched.Abi,
        IsRegistrationKnown: !!vehicle.searched.RegistrationNumber,
        Weight: vehicle.searched.GrossWeight,
        YearOfManufacture: vehicle.searched.YearOfManufacture,
        Registration: vehicle.searched.RegistrationNumber,
      }

      const manuallyBuiltVehicle = await vehicleApi.createUnknownVehicle(
        createUnknownCommercialVehicleLinkSelect.Href,
        data,
      )
      saveVehicle(manuallyBuiltVehicle.data.VehicleId, true)
    }
  }, [
    vehicle.searched,
    dispatch,
    saveVehicle,
    buildUnknownVehicleLink.Href,
    createUnknownCommercialVehicleLinkSelect.Href,
  ])

  return (
    <GridContainerWrapper>
      <Grid>
        <Carousel
          slidesToDisplay={slidesToDisplay !== undefined ? slidesToDisplay : 3}
          slideData={slideData}
          navigationOn
          paginationOn
          initialSlide={initialSlide}
          onSlideChange={(result) => handleVehicleChange(result)}
          slideTo={slideTo}
        />
        {addNewVehicle && (
          <VehicleCarouselAddNew
            handleUpdate={handleUpdate}
            existsInGarage={(result) => checkVehicleExistsInGarage(result)}
            closeFeaturePopup={closeFeaturePopup}
          />
        )}
        {uploadError && <p>Sorry something went wrong please try again</p>}
      </Grid>
    </GridContainerWrapper>
  )
}

export default VehicleCarousel
