import React from 'react'
import { BannerText, BannerWrapper } from './DiscountBanner.styles'

interface Props {
  discountExpired: boolean
  id: string
}

const DiscountBanner: React.FC<Props> = ({ id, discountExpired, children }) => (
  <BannerWrapper id={id} expired={discountExpired}>
    <BannerText>{children}</BannerText>
  </BannerWrapper>
)

export default DiscountBanner
