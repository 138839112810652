import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  updatePolicy,
  updatePolicyContactDetails,
  requestPolicy,
  updateValidation,
  updateReasonForTempcover,
  initPolicyConfirmationPage,
  updateAnalysisData,
} from './actions'
import Breadcrumb from '../../components/Breadcrumb'

import { REASON_FOR_PURCHASE_OPTIONS } from './constants'

import { updateStep } from '../App/actions'
import { MainHeading } from './styles.js'
import LoadingScreen from '../../components/LoadingScreen'
import { PRIVATE_CAR, COMMERCIAL_VEHICLE } from '../Vehicle/constants'
import GapActivationConfirmation from '../../components/GapActivationConfirmation'
import AnnualUpsell from '../AnnualUpsell'
import GridContainerWrapper from 'components/@common/GridContainerWrapper'
import MentionMe from 'containers/MentionMe'
import CustomerAccountQuote from 'containers/CustomerAccountQuote'
import { withOptimizely } from '@optimizely/react-sdk'
import { VehicleDescription } from './Sections/VehicleDescription'
import { PolicyDates } from './Sections/PolicyDates'
import { PolicyDetails } from './Sections/PolicyDetails'
import { PolicyDocuments } from './Sections/PolicyDocuments'
import { formatPolicyDateTime } from 'utils/dates'
import { CoverType } from './Sections/CoverType'
import { MobileAppBanner } from './Sections/MobileAppBanner'
import { VehicleRegistration } from './Sections/VehicleRegistration'

/* eslint-disable react/prefer-stateless-function */
export class PolicyConfirmationPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleSelect = this.handleSelect.bind(this)
    this.updateReasonForTempcover = this.updateReasonForTempcover.bind(this)

    this.state = {
      selectedOption: null,
    }
  }

  componentDidMount = () => {
    const { optimizely } = this.props
    if (optimizely) {
      optimizely.onReady().then(() => {
        optimizely.track('purchase')
      })
    }

    if (!window.isHoldingPageOn) {
      this.props.actions.initPolicyConfirmationPage()
    }
  }

  handleSelect = (event) => {
    var index = event.nativeEvent.target.selectedIndex
    this.setState({
      selectedOption: {
        value: event.target.value,
        description: event.nativeEvent.target[index].text,
      },
    })
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.policy.reasonForCover !== prevProps.policy.reasonForCover) {
      window.dataLayer.push({
        event: 'reasonForCover',
        policyConfirmationDataLayerPushEvent: [
          {
            reasonForCover: this.props.policy.reasonForCover.description,
          },
        ],
      })
    }
  }

  updateReasonForTempcover() {
    this.props.actions.updateReasonForTempcover(this.state.selectedOption)
  }

  handleReasonForCoverOptions() {
    return REASON_FOR_PURCHASE_OPTIONS.map((option) => (
      <option key={option.id} value={option.value} selected={option.selected}>
        {option.description}
      </option>
    ))
  }

  render() {
    const { value: licenceType } = this.props.riskData.LicenceType
    const vehicle = this.props.vehicle.selected
    const isProvisional = licenceType === 'ProvisionalUkLicence'
    const policyStart = formatPolicyDateTime(
      this.props.riskData.PolicyStartDate.value,
    )
    const policyEnd = formatPolicyDateTime(this.props.riskData.PolicyEndDate)
    return (
      <>
        {this.props.loading ? (
          <LoadingScreen message='Generating your documents' />
        ) : (
          <GridContainerWrapper>
            <Breadcrumb step={5} disabled />
            <MainHeading>You're covered!</MainHeading>
            <CoverType />
            <VehicleDescription vehicle={vehicle} />
            <VehicleRegistration reg={vehicle.RegistrationNumber} />
            <PolicyDates policyStart={policyStart} policyEnd={policyEnd} />
            {!this.props.affiliate.AffiliateId &&
              !isProvisional &&
              !this.props.riskData.Impounded &&
              [PRIVATE_CAR, COMMERCIAL_VEHICLE].includes(
                this.props.vehicle.selected.VehicleType,
              ) && <MobileAppBanner />}
            <PolicyDetails policyId={this.props.policy.PolicyId} />
            <PolicyDocuments
              policyDocuments={this.props.policy.PolicyDocuments}
              quoteId={this.props.riskData.QuoteId}
            />
            {!this.props.affiliate.AffiliateId &&
              !isProvisional &&
              !this.props.riskData.Motorbike &&
              !this.props.riskData.Impounded && (
                <CustomerAccountQuote quoteId={this.props.riskData.QuoteId} />
              )}
            <MentionMe
              customerId={this.props.riskData.CustomerId}
              forename={this.props.riskData.Forename}
              surname={this.props.riskData.Surname}
              email={this.props.riskData.EmailAddress}
              price={this.props.price.TotalPrice.toFixed(2)}
            />
            <GapActivationConfirmation
              encyrptedQuoteId={this.props.riskData.QuoteId}
            />
            <AnnualUpsell />
            <br />
          </GridContainerWrapper>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    policy: state.policy,
    riskData: state.riskData,
    address: state.address,
    price: state.price,
    vehicle: state.vehicle,
    affiliate: state.affiliate,
    loading: state.isLoading,
    scheme: state.scheme.selected,
    features: state.features || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updatePolicy,
        updatePolicyContactDetails,
        updateValidation,
        requestPolicy,
        updateReasonForTempcover,
        initPolicyConfirmationPage,
        updateStep,
        updateAnalysisData,
      },
      dispatch,
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOptimizely(PolicyConfirmationPage))
