import defaultTheme from './default'

let arnoldClarkTheme = { ...defaultTheme }
const primaryColour = '#2d3737'
const secondaryColour = '#ffde00'
const acGreen = '#12b447'
const fontFamily = 'Verdana, sans-serif'
arnoldClarkTheme.primaryColour = primaryColour
arnoldClarkTheme.primaryTextColour = '#333'
arnoldClarkTheme.headerFontColour = primaryColour
arnoldClarkTheme.generalFontSize = '18px'
arnoldClarkTheme.labelFontFamily = fontFamily
arnoldClarkTheme.fontFamily = fontFamily
arnoldClarkTheme.headerFontFamily = fontFamily
arnoldClarkTheme.breadcrumb = {
  ...defaultTheme.breadcrumb,
  activeColor: '#ffffff',
  activeBorderColor: primaryColour,
  defaultColor: '#ffffff',
  defaultBorderColor: '#CCCCCC',
  defaultBarColor: '#CCCCCC',
  completeColor: primaryColour,
  completeBorderColor: primaryColour,
  completeBarColor: primaryColour,
}
arnoldClarkTheme.spinner = {
  ...defaultTheme.spinner,
  primaryColour: primaryColour,
  textColour: primaryColour,
}
arnoldClarkTheme.durationOptions = {
  ...defaultTheme.durationOptions,
  primaryColour: primaryColour,
  secondaryColour: primaryColour,
}

arnoldClarkTheme.button = {
  ...defaultTheme.button,
  fontFamily: fontFamily,
  backgroundColour: acGreen,
  addon: {
    backgroundColour: acGreen,
    disabledBackground: acGreen,
    disabledBackground: '#808080',
    disabledFont: '#fff',
  },
}
arnoldClarkTheme.errorBorderProperties = '2px solid #e47008'
arnoldClarkTheme.headerLinkStyled.padding = '6px 7px;'

arnoldClarkTheme.headerLinkStyled = {
  ...defaultTheme.headerLinkStyled,
  hover: {
    ...defaultTheme.headerLinkStyled.hover,
    textDecoration: 'none',
    color: secondaryColour,
    backgroundColour: '#242D2C',
  },
}

arnoldClarkTheme.header = {
  ...defaultTheme.header,
  background: primaryColour,
  borderBottom: `6px solid ${secondaryColour}`,
}
arnoldClarkTheme.gradient = {
  ...defaultTheme.gradient,
  background: '#e0e5e5',
}
arnoldClarkTheme.GbIcon = secondaryColour
arnoldClarkTheme.formControlValidIcon = {
  backgroundColour: primaryColour,
  colour: secondaryColour,
}
arnoldClarkTheme.icon = {
  ...defaultTheme.icon,
  color: secondaryColour,
  backgroundColor: primaryColour,
}
arnoldClarkTheme.inputBorderColour = primaryColour
arnoldClarkTheme.successColour = primaryColour
arnoldClarkTheme.marketingPreferencesColour = acGreen
arnoldClarkTheme.svg = {
  ...defaultTheme.svg,
  primaryColour: primaryColour,
  accentColour: secondaryColour,
}
arnoldClarkTheme.icons = {
  border: '',
  height: '33px',
  width: '43px',
}

export default arnoldClarkTheme
