import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  requestVehicle,
  updateSearchedVehicle,
  updateSearchedVehicleBodyType,
  getModels,
  getVariants,
  createUnkownVehicle,
  setVehicle,
  getMakes,
  setVehicleType,
  updateGrossWeight,
  updateYearOfManufacture,
} from '../VehicleCustomerAccount/actions'
import { updateSearchedVehicleValue } from '../Vehicle/actions'
import {
  BODY_TYPE_OPTIONS,
  USE_OF_VEHICLE_OPTIONS,
} from 'components/@forms/DriverDetails/CommercialVehicleForm/CommercialVehicleForm.constants'
import { Button } from '../../components/common/Button'
import { Select } from '../../components/common/Select'
import { VehicleRegistration } from '../../components/VehicleRegistration'
import { ErrorContainer } from '../../components/ErrorContainer'
import { FormControlValidIcon } from '../../components/FormControlValidIcon'
import { RegistrationWrapper } from '../../components/VehicleRegistration/styles'
import { VehicleButtonWrapper, VehicleParagraph } from './styles'
import { VehicleBuilder } from '../../components/VehicleBuilder'
import { GrossWeight } from '../../components/GrossWeight'
import { YearOfManufacture } from '../../components/YearOfManufacture'
import { VehicleTypeMessage } from '../../components/VehicleTypeMessage'
import { VehicleValue } from 'components/VehicleValue'
import { ButtonSpinner } from 'components/common/ButtonSpinner'
import {
  ALLOWED_VEHICLE_TYPES,
  COMMERCIAL_VEHICLE,
  PRIVATE_CAR,
  COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT,
  MOTORCYCLE,
} from './constants'
import { history } from '../../'
import { P } from 'components/common/P'
import LinkButton from 'components/@common/LinkButton'
import NotActiveFeature from 'components/@common/NotActiveFeature'

import './VehicleCustomerAccountStyles.css'

export class VehicleCustomerAccount extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
    }

    this.addVehicle = false

    this.onChange = this.onChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleValueSelect = this.handleValueSelect.bind(this)
    this.existsInGarage = this.existsInGarage.bind(this)
    this.closeFeaturePopup = this.closeFeaturePopup.bind(this)
    this.handleAddVehicle = this.handleAddVehicle.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.vehicle.searched.VehicleType &&
      prevProps.vehicle.searched.VehicleType !==
        this.props.vehicle.searched.VehicleType &&
      this.props.vehicle.error === 'NotFound'
    ) {
      this.props.actions.getMakes()
    }
  }

  updateVehicleTypeParams(params) {
    const query = new URLSearchParams(params)
    const hasVehicleType = query.has('VehicleType')

    if (hasVehicleType) {
      if (
        this.props.vehicle.searched &&
        this.props.vehicle.searched.VehicleType
      ) {
        query.set('VehicleType', this.props.vehicle.searched.VehicleType)
        history.replace(`?${query.toString()}`)
      }
    }
  }

  onChange = (event, message) => {
    switch (event.target.name) {
      case 'RegistrationInput':
        this.props.actions.updateSearchedVehicle({
          RegistrationNumber: event.target.value.trim().replace(/\s/g, ''),
          VehicleId: null,
          Make: null,
          Model: null,
          VehicleType: null,
          Abi: null,
          MakeAndModel: null,
          DateFirstRegistered: null,
          Variant: null,
          GrossWeight: null,
          YearOfManufacture: null,
        })
        this.existsInGarage(event.target.value.trim().replace(/\s/g, ''))
        this.setState({
          error: message,
        })
        break
      case 'GrossWeight':
        this.props.actions.updateGrossWeight(event.target.value)
        break
      default:
        break
    }
  }

  existsInGarage(reg) {
    this.props.checkExistsInGarage(reg)
  }

  handleSearch() {
    this.props.actions.requestVehicle(
      this.props.vehicle.searched.RegistrationNumber,
    )
    this.props.vehicle.searched.BodyType = null
  }

  handleSelect = (event) => {
    const index = event.nativeEvent.target.selectedIndex

    switch (event.target.name) {
      case 'vehicleType':
        this.props.actions.setVehicleType(event.target.value)
        this.props.actions.getMakes()
        break
      case 'makes':
        this.props.actions.updateSearchedVehicle({
          Make: event.target.value,
        })
        this.props.actions.getModels()
        break
      case 'models':
        this.props.actions.updateSearchedVehicle({
          Model: event.target.value,
        })
        this.props.actions.getVariants()
        break
      case 'variants':
        this.props.actions.updateSearchedVehicle({
          Abi: event.target.value,
          Variant: event.nativeEvent.target[index].text,
        })
        break
      case 'bodyType':
        this.props.actions.updateSearchedVehicleBodyType({
          BodyType: event.target.value,
          VehicleFound: this.props.vehicle.searched.VehicleFound,
        })
        break
      default:
        break
    }
  }

  handleValueSelect = (e) => {
    const index = e.target.selectedIndex

    this.props.actions.updateSearchedVehicleValue({
      value: e.target.value.toString(),
      description: e.target[index].text,
    })

    // this.props.vehicle.searched.vehicleValue.value = e.target.value.toString()
    // this.props.vehicle.searched.vehicleValue.description = e.target[index].text
  }

  handleAddVehicle = () => {
    this.addVehicle = true
    this.props.handleConfirm()
  }

  handleDateSet = (e) => {
    this.props.actions.updateYearOfManufacture(e.target.value)
  }

  validVehicle() {
    const { searched } = this.props.vehicle

    let isVehicleFoundAndAllowed =
      (searched.VehicleFound ||
        this.props.vehicle.preloadNewCustomerAccountVehicle) &&
      ALLOWED_VEHICLE_TYPES.includes(searched.VehicleType)
    let isVehicleBuilt = false

    switch (searched.VehicleType) {
      case PRIVATE_CAR:
        isVehicleBuilt =
          searched.VehicleFound === false &&
          searched.Make &&
          searched.Model &&
          searched.Abi
        break
      case COMMERCIAL_VEHICLE:
        if (searched.GrossWeight > COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) {
          isVehicleBuilt =
            searched.VehicleFound === false &&
            searched.GrossWeight &&
            searched.YearOfManufacture &&
            searched.BodyType
        } else if (
          searched.GrossWeight <= COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT
        ) {
          isVehicleBuilt =
            searched.VehicleFound === false &&
            searched.GrossWeight &&
            searched.Make &&
            searched.Model &&
            searched.Abi &&
            searched.BodyType &&
            searched.YearOfManufacture
          isVehicleFoundAndAllowed =
            isVehicleFoundAndAllowed && searched.BodyType != null
        }
        break
      case MOTORCYCLE:
        return false
      default:
        break
    }

    return isVehicleFoundAndAllowed || isVehicleBuilt
  }

  handleJourney = (searchedVehicle, risk) => {
    const { Learner, Motorbike, Impounded, Expat, Courier } = risk
    const { VehicleType } = searchedVehicle

    switch (VehicleType) {
      case PRIVATE_CAR:
        if (Impounded) return true

        if (Motorbike) {
          this.setState({ error: true })
          return false
        }
        if (Courier) {
          this.setState({ error: true })
          return false
        }

        return true
      case COMMERCIAL_VEHICLE:
        if (Learner) {
          this.setState({ error: true })
          return false
        }

        if (Motorbike) {
          this.setState({ error: true })
          return false
        }

        return true
      case MOTORCYCLE:
        if (Impounded) {
          this.setState({ error: true })
          return false
        }

        if (Expat) {
          this.setState({ error: true })
          return false
        }
        if (Courier) {
          this.setState({ error: true })
          return false
        }
        return true
      default:
        return false
    }
  }

  handleVehicleTypeMessage = (searched, risk, urlParams) => {
    const params = new URLSearchParams(urlParams)
    const impoundedVehicle = risk.Impounded
    let journeyType = 'Standard'
    if (impoundedVehicle) {
      journeyType = 'Impounded'
    } else if (risk.Motorbike) {
      journeyType = 'Motorcycle'
    } else if (risk.Learner) {
      journeyType = 'Learner'
    } else if (risk.Expat) {
      journeyType = 'Expat'
    } else if (risk.Courier) {
      journeyType = 'Courier'
    }

    const vehicleType = searched.VehicleType
    const urlVehicleType = params.get('VehicleType') || vehicleType

    const incompatibleJourney = journeyType !== urlVehicleType

    // Clears the error from showing when input changes
    if (!vehicleType) return

    const learnerCommercialWording = `We’re not able to insure learner drivers to drive commercial vehicles at the moment, please enter a commercial reg number to continue.`
    const learnerMotorcycleWording = `We’re not able to insure learner drivers to ride motorcycles at the moment. Please enter a car reg number to continue.`
    const impoundedMotorcycleWording = `We’re not able to insure impounded motorcycles at the moment. Please enter a car or van reg number to continue.`
    const expatMotorcycleWording = `We’re not able to insure expat drivers to ride motorcycles at the moment. Please enter a car or van reg number to continue.`
    const motorcycleWording =
      'This is not a motorcycle registration number. Please check and enter the reg again.'
    const courierWording =
      'We are only able to cover commercial vehicles on our courier scheme at the moment. Please check and enter the reg again.'

    if (incompatibleJourney) {
      switch (journeyType) {
        case 'Standard':
          return true
        case MOTORCYCLE:
          return (
            <VehicleTypeMessage
              message={motorcycleWording}
              vehicleType={vehicleType}
              registration={this.props.vehicle.searched.RegistrationNumber}
              duration={`${this.props.duration.value}+${this.props.duration.type}`}
            />
          )
        case 'Learner':
          switch (vehicleType) {
            case PRIVATE_CAR:
              return true
            case COMMERCIAL_VEHICLE:
              return (
                <VehicleTypeMessage
                  message={learnerCommercialWording}
                  vehicleType={vehicleType}
                  registration={this.props.vehicle.searched.RegistrationNumber}
                  duration={`${this.props.duration.value}+${this.props.duration.type}`}
                />
              )
            case MOTORCYCLE:
              return (
                <VehicleTypeMessage
                  message={learnerMotorcycleWording}
                  vehicleType={vehicleType}
                  registration={this.props.vehicle.searched.RegistrationNumber}
                  duration={`${this.props.duration.value}+${this.props.duration.type}`}
                />
              )
            default:
              return false
          }
        case 'Courier':
          switch (vehicleType) {
            case COMMERCIAL_VEHICLE:
              return true
            case PRIVATE_CAR:
              return (
                <VehicleTypeMessage
                  message={courierWording}
                  vehicleType={vehicleType}
                  registration={this.props.vehicle.searched.RegistrationNumber}
                  duration={`${this.props.duration.value}+${this.props.duration.type}`}
                />
              )
            case MOTORCYCLE:
              return (
                <VehicleTypeMessage
                  message={courierWording}
                  vehicleType={vehicleType}
                  registration={this.props.vehicle.searched.RegistrationNumber}
                  duration={`${this.props.duration.value}+${this.props.duration.type}`}
                />
              )
            default:
              return false
          }
        case 'Impounded':
          if (vehicleType === MOTORCYCLE) {
            return (
              <VehicleTypeMessage
                message={impoundedMotorcycleWording}
                vehicleType={vehicleType}
                registration={this.props.vehicle.searched.RegistrationNumber}
                duration={`${this.props.duration.value}+${this.props.duration.type}`}
              />
            )
          }
          return false
        case 'Expat':
          if (vehicleType === MOTORCYCLE) {
            return (
              <VehicleTypeMessage
                message={expatMotorcycleWording}
                vehicleType={vehicleType}
                registration={this.props.vehicle.searched.RegistrationNumber}
                duration={`${this.props.duration.value}+${this.props.duration.type}`}
              />
            )
          }
          return false
        default:
          return true
      }
    }
  }

  vehicleSelectionOptions() {
    const { Learner, Motorbike, Expat } = this.props.riskData

    if (Learner) {
      return [
        {
          id: 1,
          description: 'Car',
          value: PRIVATE_CAR,
        },
      ]
    } else if (Motorbike) {
      return [
        {
          id: 3,
          description: 'Motorcycle',
          value: MOTORCYCLE,
        },
      ]
    } else if (Expat) {
      return [
        {
          id: 1,
          description: 'Car',
          value: PRIVATE_CAR,
        },
        {
          id: 2,
          description: 'Commercial vehicle',
          value: COMMERCIAL_VEHICLE,
        },
      ]
    } else {
      return [
        {
          id: 1,
          description: 'Car',
          value: PRIVATE_CAR,
        },
        {
          id: 2,
          description: 'Commercial vehicle',
          value: COMMERCIAL_VEHICLE,
        },
      ]
    }
  }

  closeFeaturePopup = () => {
    this.props.closeFeaturePopupAndReset()
  }

  render() {
    const {
      Learner: isLearner,
      Motorbike: isMotorbike,
      Expat: isExpat,
      Courier: isCourier,
    } = this.props.riskData
    const { VehicleType } = this.props.vehicle.searched

    // if (VehicleType) this.updateVehicleTypeParams(window.location.search)

    return (
      <>
        <h4>Enter Vehicle Reg</h4>
        <RegistrationWrapper>
          <FormControlValidIcon
            error={this.state.error}
            className='fas fa-check'
          ></FormControlValidIcon>
          <VehicleRegistration
            value={
              this.props.vehicle.preloadNewCustomerAccountVehicle
                ? this.props.vehicle.searched.RegistrationNumber
                : undefined
            }
            onChange={this.onChange}
            error={this.state.error}
          />
          {this.props.vehicle.existsInGarage && (
            <p className='vehicle-exists-message'>
              You already have this vehicle in your garage.
            </p>
          )}
          <Button
            id='SearchVehicle'
            onClick={this.handleSearch}
            disabled={!!this.state.error || this.props.vehicle.existsInGarage}
          >
            Look up vehicle
          </Button>
        </RegistrationWrapper>
        {this.props.vehicle.error === 'InvalidReg' && (
          <ErrorContainer>
            The above registration number is invalid, please try again
          </ErrorContainer>
        )}
        {this.props.vehicle.searched.VehicleFound &&
          ALLOWED_VEHICLE_TYPES.includes(VehicleType) && (
            <>
              <h5>Vehicle found:</h5>
              <p className='found-new-vehicle'>
                {this.props.vehicle.searched.Make}{' '}
                {this.props.vehicle.searched.Model}
              </p>
            </>
          )}
        {this.props.vehicle.error === 'NotFound' && (
          <>
            <P>
              Sorry we've not found that vehicle, please select from the options
              below
            </P>
            <Select
              name='vehicleType'
              placeholder='My vehicle is a...'
              onChange={this.handleSelect}
              options={this.vehicleSelectionOptions()}
              value={VehicleType}
            />
            {VehicleType && (
              <>
                {VehicleType === COMMERCIAL_VEHICLE && (
                  <GrossWeight
                    onChange={this.onChange}
                    value={this.props.vehicle.searched.GrossWeight}
                  />
                )}
                {(VehicleType === PRIVATE_CAR ||
                  parseInt(this.props.vehicle.searched.GrossWeight) <=
                    COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) && (
                  <VehicleBuilder
                    searchedVehicle={this.props.vehicle.searched}
                    vehicleOptions={{
                      makes: this.props.vehicle.makes,
                      models: this.props.vehicle.models,
                      variants: this.props.vehicle.variants,
                    }}
                    handleSelect={this.handleSelect}
                  />
                )}
                {VehicleType === MOTORCYCLE && (
                  <VehicleBuilder
                    searchedVehicle={this.props.vehicle.searched}
                    vehicleOptions={{
                      makes: this.props.vehicle.makes,
                      models: this.props.vehicle.models,
                      variants: this.props.vehicle.variants,
                    }}
                    handleSelect={this.handleSelect}
                  />
                )}
                {VehicleType === COMMERCIAL_VEHICLE &&
                  (this.props.vehicle.searched.Variant ||
                    this.props.vehicle.searched.GrossWeight >
                      COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) && (
                    <>
                      <YearOfManufacture
                        handleDateSet={this.handleDateSet}
                        value={this.props.vehicle.searched.YearOfManufacture}
                        manufacturedFrom={
                          this.props.vehicle.variants.filter(
                            (v) => v.value === this.props.vehicle.searched.Abi,
                          ).manufacturedFrom
                        }
                        manufacturedTo={
                          this.props.vehicle.variants.filter(
                            (v) => v.value === this.props.vehicle.searched.Abi,
                          ).manufacturedTo
                        }
                      />
                    </>
                  )}
              </>
            )}
          </>
        )}
        {this.props.vehicle.searched.VehicleFound &&
          !ALLOWED_VEHICLE_TYPES.includes(VehicleType) && (
            <>
              <p>
                Sorry, we're busy building our new and improved system and we're
                are only able to provide cover for cars with this system at the
                moment.
              </p>
            </>
          )}
        {(isLearner ||
          isMotorbike ||
          isExpat ||
          isCourier ||
          window.location.search.indexOf('VehicleType=') >= 0) && (
          <>
            {this.handleVehicleTypeMessage(
              this.props.vehicle.searched,
              this.props.riskData,
              window.location.search,
            )}
          </>
        )}
        {this.handleJourney(this.props.vehicle.searched, this.props.riskData) &&
          VehicleType === COMMERCIAL_VEHICLE && (
            <Select
              id='BodyType'
              name='bodyType'
              onChange={this.handleSelect}
              options={BODY_TYPE_OPTIONS}
              placeholder="My van's body type is..."
            />
          )}
        {this.handleJourney(this.props.vehicle.searched, this.props.riskData) &&
          this.validVehicle() && (
            <>
              <h5 className='vehicle-value-label'>Vehicle value</h5>
              <VehicleValue
                name='VehicleValue'
                onChange={this.handleValueSelect}
                value={
                  this.props.vehicle.searched
                    ? this.props.vehicle.searched.vehicleValue.value
                    : 5
                }
                isMotorcycle={
                  this.props.vehicle.selected.VehicleType === MOTORCYCLE
                }
              />
              <VehicleButtonWrapper>
                <Button
                  id='SetVehicleCustomerAccount'
                  className='add-new-vehicle-button'
                  onClick={this.handleAddVehicle}
                  disabled={this.addVehicle}
                >
                  {!this.addVehicle ? 'Add vehicle' : 'Please wait'}
                  <ButtonSpinner disabled={this.addVehicle} />
                </Button>
              </VehicleButtonWrapper>
            </>
          )}
        {/* {!this.validVehicle() && VehicleType === MOTORCYCLE && (
          <>
            <p>
              Sorry we currently don't allow motorbikes at this moment time.
            </p>
            <p>
              You can still get a quote via our guest journey by clicking the
              link below.
            </p>
            <LinkButton
              url={
                '/?motorbike=true&registration=' +
                this.props.vehicle.searched.RegistrationNumber +
                '&duration=1+day&tp=20%2C648'
              }
              id='getBikeQuote'
              linkName='Get Bike quote'
              className='get-a-quote'
            />
          </>
        )} */}

        <NotActiveFeature
          key={this.props.vehicle.searched.RegistrationNumber}
          isVisible={!this.validVehicle() && VehicleType === MOTORCYCLE}
          feature='Motorbike policies'
          product='motorbikes'
          buttonText='Get a motorbike quote'
          buttonId='getABikeQuote'
          buttonLink={
            '/?motorbike=true&registration=' +
            this.props.vehicle.searched.RegistrationNumber +
            '&duration=1+day&tp=20%2C648'
          }
          onClose={this.closeFeaturePopup}
        />
      </>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    riskData: state.riskData,
    vehicle: state.vehicle,
    validation: state.vehicle.validation,
    duration: state.duration,
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateSearchedVehicle,
        updateSearchedVehicleBodyType,
        requestVehicle,
        getMakes,
        getModels,
        getVariants,
        createUnkownVehicle,
        setVehicle,
        setVehicleType,
        updateGrossWeight,
        updateYearOfManufacture,
        updateSearchedVehicleValue,
      },
      dispatch,
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleCustomerAccount)
