export const REQUEST_VEHICLE = 'src/VehicleInformation/REQUEST_VEHICLE'
export const REQUEST_PREVIOUS_VEHICLE =
  'src/VehicleInformation/REQUEST_PREVIOUS_VEHICLE'

export const REQUEST_VEHICLE_SUCCESS =
  'src/VehicleInformation/REQUEST_VEHICLE_SUCCESS'
export const REQUEST_VEHICLE_FAILED =
  'src/VehicleInformation/REQUEST_VEHICLE_FAILED'

export const REQUEST_PREVIOUS_VEHICLE_SUCCESS =
  'src/VehicleInformation/REQUEST_PREVIOUS_VEHICLE_SUCCESS'
export const REQUEST_PREVIOUS_VEHICLE_FAILED =
  'src/VehicleInformation/REQUEST_PREVIOUS_VEHICLE_FAILED'
export const REQUEST_PREVIOUS_MANUAL_BUILDER =
  'src/VehicleInformation/REQUEST_PREVIOUS_MANUAL_BUILDER'
export const REQUEST_VEHICLE_LINK_REL = 'VehicleLookupByRegistration'

export const UPDATE_SEARCHED_VEHICLE =
  'src/VehicleInformation/UPDATE_SEARCHED_VEHICLE'
export const UPDATE_PREVIOUS_VEHICLE =
  'src/VehicleInformation/UPDATE_PREVIOUS_VEHICLE'
export const SET_VEHICLE = 'src/VehicleInformation/SET_VEHICLE'
export const SET_VEHICLE_LISTENER =
  'src/VehicleInformation/SET_VEHICLE_LISTENER'

export const GET_MAKES = 'src/VehicleInformation/GET_MAKES'
export const GET_MAKES_SUCCESS = 'src/VehicleInformation/GET_MAKES_SUCCESS'
export const GET_MAKES_FAILURE = 'src/VehicleInformation/GET_MAKES_FAILURE'
export const GET_MAKES_REL = 'VehicleMakes'

export const GET_MODELS = 'src/VehicleInformation/GET_MODELS'
export const GET_MODELS_SUCCESS = 'src/VehicleInformation/GET_MODELS_SUCCESS'
export const GET_MODELS_FAILURE = 'src/VehicleInformation/GET_MODELS_FAILURE'
export const GET_MODELS_REL = 'GetModels'

export const GET_VARIANTS = 'src/VehicleInformation/GET_VARIANTS'
export const GET_VARIANTS_SUCCESS =
  'src/VehicleInformation/GET_VARIANTS_SUCCESS'
export const GET_VARIANTS_FAILURE =
  'src/VehicleInformation/GET_VARIANTS_FAILURE'
export const GET_VARIANTS_REL = 'VehicleVariantByMakeAndModel'

export const CREATE_UNKNOWN_VEHICLE =
  'src/VehicleInformation/CREATE_UNKNOWN_VEHICLE'
export const CREATE_UNKNOWN_VEHICLE_SUCCESS =
  'src/VehicleInformation/CREATE_UNKNOWN_VEHICLE_SUCCESS'
export const CREATE_UNKNOWN_VEHICLE_FAILURE =
  'src/VehicleInformation/CREATE_UNKNOWN_VEHICLE_FAILURE'
export const CREATE_UNKNOWN_VEHICLE_REL = 'BuildVehicleManually'
export const CREATE_UNKNOWN_COMMERCIAL_VEHICLE_REL =
  'BuildCommercialVehicleManually'
export const CREATE_UNKNOWN_MOTORCYCLE_VEHICLE_REL = 'BuildMotorcycleManually'

export const UNKNOWN_VEHICLE_REG = 'src/VehicleInformation/UNKNOWN_VEHICLE_REG'
export const UNKNOWN_VEHICLE_REG_SUCCESS =
  'src/VehicleInformation/UNKNOWN_VEHICLE_REG_SUCCESS'
export const UNKNOWN_VEHICLE_REG_FAILURE =
  'src/VehicleInformation/UNKNOWN_VEHICLE_REG_FAILURE'

export const SET_VEHICLE_TYPE = 'src/VehicleInformation/SET_VEHICLE_TYPE'

export const SET_VEHICLE_SEARCHED_REG =
  'src/VehicleInformation/SET_VEHICLE_SEARCHED_REG'
export const SET_VEHICLE_ERROR = 'src/VehicleInformation/SET_VEHICLE_ERROR'

export const UPDATE_VEHICLE_VALUE =
  'src/VehicleInformation/UPDATE_VEHICLE_VALUE'
export const UPDATE_SEARCHED_VEHICLE_VALUE =
  'src/VehicleInformation/UPDATE_SEARCHED_VEHICLE_VALUE'
export const UPDATE_GROSS_WEIGHT = 'src/Van/UPDATE_GROSS_WEIGHT'
export const UPDATE_YEAR_OF_MANUFACTURE = 'src/Van/UPDATE_YEAR_OF_MANUFACTURE'

export const VEHICLE_TYPE_CHANGE = 'src/VehicleInformation/VEHICLE_TYPE_CHANGE'

export const ALLOWED_VEHICLE_TYPES = [
  'PrivateCar',
  'CommercialVehicle',
  'Motorcycle',
]

export const COMMERCIAL_VEHICLE = 'CommercialVehicle'
export const PRIVATE_CAR = 'PrivateCar'
export const MOTORCYCLE = 'Motorcycle'

export const COMMERCIAL_VEHICLE_SCHEME = 'VehicleCommercialVehicleBaseScheme'
export const PRIVATE_CAR_SCHEME = 'VehiclePrivateCarBaseScheme'
export const IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME =
  'VehicleCommercialVehicleImpoundedScheme'
export const IMPOUNDED_PRIVATE_CAR_SCHEME = 'VehiclePrivateCarImpoundedScheme'
export const VEHICLE_LEARNER_SCHEME = 'VehicleLearnerScheme'

export const MOTORCYCLE_VEHICLE_SCHEME =
  'VehicleMotorcycleLearnerAndFullLicenceScheme'

export const COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT = 3500

export const SET_NEW_CUSTOMER_ACCOUNT_VEHICLE_FROM_HOME_PAGE =
  'src/VehicleInformation/SET_NEW_CUSTOMER_ACCOUNT_VEHICLE_FROM_HOME_PAGE'

export const CLEAR_SEARCHED_VEHICLE =
  'src/VehicleInformation/CLEAR_SEARCHED_VEHICLE'

export const SET_VEHICLE_FROM_CAROUSEL =
  'src/VehicleInformation/SET_VEHICLE_FROM_CAROUSEL'

export const SET_CUSTOMER_ACCOUNT_CAROUSEL_REG =
  'src/VehicleInformation/SET_CUSTOMER_ACCOUNT_CAROUSEL_REG'

export const UPDATE_VEHICLE_ALREADY_EXISTS =
  'src/VehicleInformation/UPDATE_VEHICLE_ALREADY_EXISTS'

export const UPDATE_SELECTED_VEHICLE_BODY_TYPE =
  'src/VehicleInformation/UPDATE_SELECTED_VEHICLE_BODY_TYPE'

export const UPDATE_IF_MANUALLY_BUILT =
  'src/VehicleInformation/UPDATE_IF_MANUALLY_BUILT'
