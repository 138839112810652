import React, { useState, useMemo } from 'react'
import Moment from 'moment'
import { useRiskData } from 'hooks'
import PolicyStartTimeModal from 'components/PolicyStartTimeModal'
import { SummarySection } from 'containers/QuoteSummary/SummarySectionStyles'

interface PolicyStartTimeSummaryProps {
  id: string
  handleDateTimeSet: (dateTime: string, manuallyChanged: boolean) => void
}

const PolicyStartTimeSummary: React.FC<PolicyStartTimeSummaryProps> = ({
  id,
  handleDateTimeSet,
}) => {
  const [isModalVisible, setModalVisible] = useState(false)

  const { policyStartDate } = useRiskData()

  const formattedDate = useMemo(() => {
    if (policyStartDate.value && policyStartDate.manuallyEdited) {
      return Moment(policyStartDate.value).format('ddd DD/MM/YYYY HH:mm')
    }

    return 'Policy starts immediately'
  }, [policyStartDate])

  const togglePolicyDateModal = () => {
    setModalVisible(!isModalVisible)
  }

  return (
    <>
      <SummarySection id={id} onClick={togglePolicyDateModal}>
        <p className='quote-summary-title'>Start date &amp; time</p>
        <p className='quote-summary-text'>{formattedDate}</p>
        <i className='fa fa-pencil-alt' />
      </SummarySection>
      <PolicyStartTimeModal
        isVisible={isModalVisible}
        hideModal={togglePolicyDateModal}
        handleDateTimeSet={handleDateTimeSet}
      />
    </>
  )
}

export default PolicyStartTimeSummary
