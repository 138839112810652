import initialState from '../../../initialState'
import {
  GET_QUOTE_SUMMARY_SUCCESS,
  REQUEST_ADDONS_SUCCESS,
  REQUEST_PRICE_SUCCESS,
  SET_QUOTE_JOURNEY_POSITION,
  UPDATE_IS_CUSTOMER_ACCOUNT_QUOTE,
  UPDATE_CUSTOMER_ACCOUNT_EXISTS,
} from '../constants'

function quoteReducer(state = initialState.quote, action) {
  switch (action.type) {
    case REQUEST_PRICE_SUCCESS:
    case REQUEST_ADDONS_SUCCESS:
      return {
        ...state,
        Underwriter:
          action.price.UnderwriterTotalPrice &&
          action.price.UnderwriterTotalPrice.Underwriter,
        TotalPrice:
          action.price.UnderwriterTotalPrice &&
          action.price.UnderwriterTotalPrice.TotalPrice,
        CoverType: action.price.CoverType,
      }
    case GET_QUOTE_SUMMARY_SUCCESS:
      return {
        ...state,
        ReferenceNumber: action.ReferenceNumber,
      }
    case SET_QUOTE_JOURNEY_POSITION:
      return {
        ...state,
        QuoteJourneyPosition: action.journeyPosition,
      }
    case UPDATE_IS_CUSTOMER_ACCOUNT_QUOTE:
      return {
        ...state,
        isCustomerAccountQuote: action.isCustomerAccountQuote,
      }
    case UPDATE_CUSTOMER_ACCOUNT_EXISTS:
      return {
        ...state,
        accountExists: action.accountExists,
      }
    default:
      return state
  }
}

export default quoteReducer
