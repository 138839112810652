import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: #264694;
  padding: 10px 0;
  margin-top: 1px;

  @media (min-width: 530px) {
    display: block;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
  }
`

export const Link = styled.a`
  display: block;
  text-decoration: none;
`

export const Image = styled.img`
  max-width: 100%;
  height: 2rem;
`

export const ReviewsText = styled.p`
  font-size: 0.75rem;
  width: 100%;
  margin: 0;
  margin-left: 0.5rem;
  color: #000;
  text-decoration: none;

  @media (max-width: 1024px) {
    font-size: 10px;
  }
`
