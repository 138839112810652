import styled from 'styled-components'

interface ModalWrapperProps {
  isVisible?: boolean
  fullscreen?: boolean
}

interface ModalContentProps {
  isLarge?: boolean
  fullscreen?: boolean
  fixedHeight?: boolean
}

interface ModalHeaderProps {
  alignment: 'left' | 'center' | 'right'
  hasHeaderBorder: boolean
  headerColour: string
}

interface ModalHeaderTextProps {
  headerColour: string
}

const HEADER_MARGIN = 16
const MODAL_PADDING = 8
const TOTAL_GAP = HEADER_MARGIN + MODAL_PADDING

export const ModalWrapperStyled = styled.div<ModalWrapperProps>`
  position: fixed;
  top: ${({ isVisible, fullscreen }) => {
    if (fullscreen) {
      return isVisible ? 0 : '100%'
    }

    return 0
  }};
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ isVisible, fullscreen }) =>
    !fullscreen && !isVisible ? 0 : 1};
  transition: all ${({ fullscreen }) => (fullscreen ? 0.5 : 0.3)}s ease-in-out;
  pointer-events: ${({ isVisible }) => (isVisible ? 'visible' : 'none')};
  z-index: 1000000;
`

export const ModalContentStyled = styled.div<ModalContentProps>`
    background-color: #fff;
    max-width: ${({ fullscreen, isLarge }) => {
      if (fullscreen) return '100%;'

      return `${isLarge ? 800 : 500}px;`
    }}
    width: 100%;
    ${({ fixedHeight, fullscreen }) =>
      // eslint-disable-next-line no-nested-ternary
      fixedHeight ? (fullscreen ? 'height: 100%;' : 'height:70vh;') : ''}
    box-sizing: border-box;
    border-radius: ${({ fullscreen }) => (fullscreen ? 0 : 4)}px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`

export const ModalHeaderStyled = styled.div<ModalHeaderProps>`
  border-bottom: ${(props) => (props.hasHeaderBorder ? 1 : 0)}px solid
    ${(props) => (props.headerColour ? props.headerColour : '#000')};
  position: relative;
  box-sizing: border-box;
  flex: 0 0 auto;
  padding: ${MODAL_PADDING}px ${TOTAL_GAP}px;
  display: flex;
  justify-content: ${(props) => {
      switch (props.alignment) {
        case 'center':
          return 'center'
        case 'right':
          return 'flex-end'
        default:
          return 'flex-start'
      }
    }}
    @media screen and (max-width: 380px) {
    padding: ${MODAL_PADDING / 2}px ${TOTAL_GAP / 2}px;
  }
`

export const CloseButtonStyled = styled.button`
  position: absolute;
  top: ${HEADER_MARGIN}px;
  right: ${HEADER_MARGIN}px;
  background: transparent;
  border: none;
  color: #000;
  opacity: 0.5;
  :hover {
    cursor: pointer;
    opacity: 0.75;
  }

  @media screen and (max-width: 420px) {
    right: 6px;
    top: 6px;
  }
`

export const ModalHeaderText = styled.h3<ModalHeaderTextProps>`
  display: block;
  margin: 25px 0 ${HEADER_MARGIN}px 0;
  color: ${({ headerColour }) => headerColour};
  font-family: ${(props) => props.theme.fontFamily};
`

export const ModalInner = styled.div`
  padding: ${MODAL_PADDING}px ${TOTAL_GAP}px;
  overflow-y: auto;
  max-height: 100%;
  box-sizing: border-box;
  flex: 1;
  @media screen and (max-width: 380px) {
    padding: ${MODAL_PADDING / 2}px ${TOTAL_GAP / 2}px;
  }
`

export const ModalHeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ModalLogoWrapper = styled.div`
  margin-top: 15px;
  margin-left: 10px;
`
