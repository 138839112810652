import defaultTheme from './default'

let oneAnswerTheme = { ...defaultTheme }
const primaryColour = '#f60'
oneAnswerTheme.primaryColour = primaryColour
oneAnswerTheme.primaryTextColour = '#333'
oneAnswerTheme.headerFontColour = primaryColour
oneAnswerTheme.breadcrumb = {
  ...defaultTheme.breadcrumb,
  activeColor: '#ffffff',
  activeBorderColor: primaryColour,
  defaultColor: '#ffffff',
  defaultBorderColor: '#CCCCCC',
  defaultBarColor: '#CCCCCC',
  completeColor: primaryColour,
  completeBorderColor: primaryColour,
  completeBarColor: primaryColour,
}
oneAnswerTheme.spinner = {
  ...defaultTheme.spinner,
  primaryColour: primaryColour,
  textColour: primaryColour,
}
oneAnswerTheme.durationOptions = {
  ...defaultTheme.durationOptions,
  primaryColour: primaryColour,
  secondaryColour: primaryColour,
}
oneAnswerTheme.button = {
  ...defaultTheme.button,
  backgroundColour: primaryColour,
}
oneAnswerTheme.header = {
  ...defaultTheme.header,
  background: primaryColour,
  borderBottom: 'none',
}
oneAnswerTheme.headerLinkStyled = {
  padding: '0px',
  hover: {
    textDecoration: 'underline',
    color: '#fff',
    backgroundColour: primaryColour,
    padding: '0px',
  },
}
oneAnswerTheme.gradient = {
  ...defaultTheme.gradient,
  background: '#F5DFB0',
}
oneAnswerTheme.icon = {
  ...defaultTheme.icon,
  color: primaryColour,
  backgroundColor: '#E9F0FF',
}
oneAnswerTheme.GbIcon = '#fff'
oneAnswerTheme.formControlValidIcon = {
  colour: '#fff',
  backgroundColour: primaryColour,
}
oneAnswerTheme.headerFontColour = primaryColour
oneAnswerTheme.inputBorderColour = '#ddd'
oneAnswerTheme.successColour = primaryColour
oneAnswerTheme.marketingPreferencesColour = primaryColour
oneAnswerTheme.svg = {
  ...defaultTheme.svg,
  primaryColour: '#f76900',
  accentColour: '#FFBA8A',
}

export default oneAnswerTheme
