import React from 'react'
import { PolicyDocumentsList } from './styles'
import { PolicyDocument } from './PolicyDocument'

interface Props {
  policyDocuments: Record<string, any>[]
  quoteId: string
}

export const PolicyDocuments = ({ policyDocuments, quoteId }: Props) => (
  <PolicyDocumentsList>
    {policyDocuments.map((document, index) => (
      <PolicyDocument
        document={document}
        quoteId={quoteId}
        key={`PolicyDocument-${index}`}
      />
    ))}
  </PolicyDocumentsList>
)
