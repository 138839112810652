import { useSelector } from 'react-redux'
import { InitialStateType } from '../../initialState'

const useAddress = () => {
  const selectedAddress = useSelector(
    (state: InitialStateType) => state.address.SelectedAddress,
  )
  const addressLineOne = useSelector(
    (state: InitialStateType) => state.address.AddressLineOne,
  )
  const addressLineTwo = useSelector(
    (state: InitialStateType) => state.address.AddressLineTwo,
  )
  const addressLineThree = useSelector(
    (state: InitialStateType) => state.address.AddressLineThree,
  )
  const addressLineFour = useSelector(
    (state: InitialStateType) => state.address.AddressLineFour,
  )
  const postcode = useSelector(
    (state: InitialStateType) => state.address.Postcode,
  )

  const formattedAddress =
    selectedAddress ||
    `${addressLineOne} ${addressLineTwo} ${addressLineThree} ${addressLineFour} ${postcode}`

  return {
    selectedAddress,
    addressLineOne,
    addressLineTwo,
    addressLineThree,
    addressLineFour,
    postcode,
    formattedAddress,
  }
}

export default useAddress
