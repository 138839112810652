export const motorcycleExperienceOptions = [
  {
    id: 2,
    value: 'HasRiddenOnTheRoadInTheLastTwoYears',
    description: 'The driver has ridden on the road in the last 2 years',
  },
  {
    id: 3,
    value: 'HasNotRiddenOnTheRoadInTheLastTwoYears',
    description: 'The driver has not ridden on the road in the last 2 years',
  },
]
