import { combineReducers } from 'redux'
import { isLoadingReducer as isLoading } from './containers/App/reducers'
import correlation from './containers/App/reducers/correlationIdReducer'
import links from './containers/App/reducers/linksReducer'
import features from './containers/App/reducers/featureTogglesReducer'
import step from './containers/App/reducers/stepReducer'
import customerAccountLoggedIn from './containers/App/reducers/customerAccountLoggedInReducer'
import queryString from './containers/App/reducers/queryStringReducer'
import vehicle from './containers/Vehicle/reducer'
import price from './containers/App/reducers/priceReducer'
import scheme from './containers/App/reducers/schemeReducer'
import ui from './containers/App/reducers/uiReducer'
import policy from './containers/PolicyConfirmationPage/reducer'
import riskData from './containers/RiskDataCollectionPage/reducer'
import myLicence from './containers/MyLicence/reducer'
import address from './containers/Address/reducer'
import legalDeclaration from './containers/LegalDeclarationModal/reducer'
import payment from './containers/PaymentPage/reducer'
import affiliate from './containers/App/reducers/affiliateReducer'
import duration from './containers/App/reducers/durationReducer'
import { validationReducer as riskDataCollectionPage } from './containers/RiskDataCollectionPage/reducer'
import { validationReducer as policyConfirmationPage } from './containers/PolicyConfirmationPage/reducer'
import { connectRouter } from 'connected-react-router'
import formButtons from './containers/RiskDataCollectionPage/formButtonsReducer'
import camera from './containers/DrivingLicenceCamera/reducer'
import occupation from './containers/Occupation/reducer'
import quote from './containers/App/reducers/quoteReducer'
import marketingPreferences from './containers/MarketingPreferencesContainer/reducer'
import experiments from './store/experiment/experiment.reducer'
import external from './store/external/external.reducer'
import session from './containers/App/reducers/sessionReducer'
import driverDetailsFormFields from './store/driverDetailsFormFields/driverDetailsFormFields.reducer'
import confusedQuickQuote from './containers/App/reducers/confusedQuickQuoteReducer'
import auth from './store/auth/auth.reducer'

const validation = combineReducers({
  riskDataCollectionPage,
  policyConfirmationPage,
})

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    formButtons,
    isLoading,
    correlation,
    links,
    vehicle,
    price,
    policy,
    riskData,
    validation,
    address,
    legalDeclaration,
    step,
    customerAccountLoggedIn,
    payment,
    camera,
    affiliate,
    queryString,
    occupation,
    scheme,
    ui,
    quote,
    myLicence,
    marketingPreferences,
    experiments,
    features,
    session,
    duration,
    external,
    driverDetailsFormFields,
    confusedQuickQuote,
    auth,
  })
