import config from 'config'
import ky from 'ky'
import { QuoteJourneyPosition } from 'types/global'

export interface PaymentIntentPriceCheckResponse {
  Price: Price
  StatusMessage: string
  underwriter: number
}

export interface Price {
  InsurancePremium: number
  Ipt: number
  AdminFee: number
  Underwriter: string
  UnderwriterDisplayName: string
  UnderwriterId: number
  UnderwriterSchemeId: number
  TotalPrice: number
  CompulsoryExcess: number
  ValidationData: ValidationData
  AffiliateAdminFee: number
  UnderwriterSchemeInfo: any
  UnderwriterTotalPrice: UnderwriterTotalPrice
  CampaignDiscount: number
  CampaignName: any
  CoverType: string
  TotalPriceBeforeAddons: number
  ExcessReductionPricing: ExcessReductionPricing
  BreakdownPricing: BreakdownPricing
  LegalExpensesPricing: LegalExpensesPricing
  FreeGAPInsurancePricing: FreeGapinsurancePricing
  KlarnaPaymentOptionAvailable: boolean
  AffiliateCashbackValue: any
  ETag: any
  ExtensionData: any
  Links: any
}

export interface ValidationData {
  Value: string
}

export interface UnderwriterTotalPrice {
  TotalPrice: number
  Underwriter: string
}

export interface ExcessReductionPricing {
  ExcessReductionIncluded: boolean
  ExcessReductionPremiumNet: number
  ExcessReductionPremiumGross: number
  ExcessReductionPremiumCommissionFactor: number
  ExcessReductionIPT: number
  ExcessReductionTotalPremium: number
  ExcessReductionPremiumCommissionAmount: number
  ExcessReductionCoverLevel: number
  ExcessReductionProductCode: string
}

export interface BreakdownPricing {
  BreakdownIncluded: boolean
  BreakdownPremiumNet: number
  BreakdownPremiumGross: number
  BreakdownPremiumCommissionFactor: number
  BreakdownIPT: number
  BreakdownTotalPremium: number
  BreakdownPremiumCommissionAmount: number
  BreakdownBrokerCode: string
  BreakdownProductCode: string
}

export interface LegalExpensesPricing {
  LegalExpensesIncluded: boolean
  LegalExpensesPremiumNet: number
  LegalExpensesPremiumGross: number
  LegalExpensesPremiumCommissionFactor: number
  LegalExpensesPremiumCommissionAmount: number
  LegalExpensesIPT: number
  LegalExpensesPremium: number
}

export interface FreeGapinsurancePricing {
  FreeGAPInsuranceIncluded: boolean
  FreeGAPInsuranceAvailable: boolean
  FreeGapActivationJourneyIncluded: boolean
}

export interface PaymentIntentFinalResponse {
  status: string
  clientSecret: string
  paymentIntentId: string
  // This upper vs lowercase on statusMessage due to  PropertyNamingPolicy = null on endpoint response
  statusMessage: string
}

type PaymentIntentResponse =
  | PaymentIntentPriceCheckResponse
  | PaymentIntentFinalResponse

interface PaymentIntentRequest {
  paymentMethodId: string
  amount: number
  quoteId: string
  excessReductionIncluded: boolean
  breakdownIncluded: boolean
  legalExpensesIncluded: boolean
  free30DayGAPInsuranceIncluded: boolean
  quoteJourneyPosition: QuoteJourneyPosition
  validationData: string
  allowPriceComparison: boolean
  underwriterId: number
}

const paymentApi = ky.create({
  prefixUrl: config.PAYMENT_API_URL,
  headers: { 'Content-Type': 'application/json' },
})

export const createPaymentIntent = async (
  paymentIntentRequest: PaymentIntentRequest,
) => {
  const res = await paymentApi.post('PaymentIntents', {
    body: JSON.stringify(paymentIntentRequest),
    timeout: 60000,
  })

  const data: PaymentIntentResponse = await res.json()
  return data
}

export const confirmPaymentIntent = async (
  paymentIntentId: string,
  paymentIntentRequest: PaymentIntentRequest,
) => {
  const res = await paymentApi.post(
    `PaymentIntents/${paymentIntentId}/Confirm`,
    {
      body: JSON.stringify(paymentIntentRequest),
      timeout: 60000,
    },
  )
  const data: PaymentIntentResponse = await res.json()
  return data
}
