import {
  UPDATE_POLICY_LINK_REL,
  GET_POLICY_LINK_REL,
  UPDATE_REASON_FOR_TEMPCOVER_REL,
} from './constants'

export const updatePolicyLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === UPDATE_POLICY_LINK_REL)[0]

export const getPolicyLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === GET_POLICY_LINK_REL)[0]
export const getReasonforTempcoverLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === UPDATE_REASON_FOR_TEMPCOVER_REL)[0]

export const getPolicy = (state) => state.policy
export const getPrice = (state) => state.price
export const getRiskData = (state) => state.riskData
export const getAddress = (state) => state.address
export const getAffiliate = (state) => state.affiliate
export const getValidation = (state) => state.validation.policyConfirmationPage
