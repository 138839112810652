import React from 'react'
import { EllipsisLoaderWrapper, EllipsisLoaderDot } from './styles'

export const EllipsisLoader = ({ size = 12 }) => (
  <EllipsisLoaderWrapper>
    <EllipsisLoaderDot size={size} />
    <EllipsisLoaderDot size={size} />
    <EllipsisLoaderDot size={size} />
  </EllipsisLoaderWrapper>
)
