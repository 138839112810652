import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import addressApi from '../../api/addressApi'
import { InitialStateType } from '../../initialState'

const useAddressSearch = (postcode: string) => {
  // TODO: Look into a nicer way to do api calls, really dislike having to select links

  const addressPostcodeLink = useSelector(
    (state: InitialStateType) =>
      state.links.filter((link) => link.Rel === 'SearchAddressByPostCode')[0],
  )
  const addressEndpoint = addressPostcodeLink.Href.replace(
    /(\{.+\})/g,
    postcode,
  )

  const query = useQuery(
    'findAddresses',
    () => addressApi.getAddresses(addressEndpoint),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )

  return {
    query,
  }
}

export default useAddressSearch
