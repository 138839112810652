import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { AutoCompleteOption } from './AutoCompleteOption'

const BaseAutoComplete = styled.ul`
  list-style: none;
  border: 1px solid #7a9cd3;
  padding: 0;
  width: auto;
  max-height: 200px;
  overflow-y: auto;
  margin-top: -11px;
  text-align: left;
`

export const AutoComplete = ({ options, searchTerm, onClick, properties }) => {
  const defaultedProperties = {
    id: properties.id || 'id',
    value: properties.value || 'value',
  }
  return (
    <BaseAutoComplete>
      {options &&
        options.map((option, i) => (
          <AutoCompleteOption
            key={i}
            option={option}
            searchTerm={searchTerm}
            onClick={onClick}
            properties={defaultedProperties}
          />
        ))}
    </BaseAutoComplete>
  )
}

AutoComplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchTerm: PropTypes.string,
  properties: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
}
