import config from 'config'
import ky from 'ky'
import antiForgeryApi from 'api/antiForgeryApi'
import { store } from '../store'

const policyApi = ky.create({
  prefixUrl: config.BASE_API_URL,
  headers: { 'Content-Type': 'application/json' },
  credentials: 'include',
  hooks: {
    afterResponse: [
      async (request, _options, response) => {
        // If 401 then get new token
        if (response.status === 401) {
          const antiForgeryTokenRes = await antiForgeryApi.getAntiForgeryToken()
          const antiForgeryToken =
            antiForgeryTokenRes.headers['x-requestverificationtoken']
          store.antiForgeryToken = antiForgeryToken
          request.headers.set('X-RequestVerificationToken', antiForgeryToken)
          ky(request)
        }
      },
    ],
  },
})

export const getPolicySummary = async (quoteId: string) => {
  const response = await policyApi.get(
    `api/motorQuotePolicies/${quoteId}/summary`,
    {
      headers: {
        'Content-Type':
          'application/tempcover.MotorPolicySummaryRepresentation+json',
        'X-RequestVerificationToken': store.antiForgeryToken || '',
      },
    },
  )

  const res = await response.json()

  return res
}
