import styled from 'styled-components';
import { SelectStyled } from '../Select/Select.styles';

interface ActiveProps {
  isActive: boolean
}

interface ErrorProps {
  hasError: boolean
}

export const DateTimeContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
`

export const TimeRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`

export const ColonDivider = styled.div`
  padding: 0 6px;
`

export const TimeColon = styled.span`
  font-size: ${(props) => props.theme.generalFontSize};
  font-weight: bold;
  color: ${(props) => props.theme.labelTextColour};
`

export const TimeText = styled.p`
  margin: 8px 0;
  margin-right: 1em;
`
export const RadioGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
`

export const RadioField = styled.div<ActiveProps>`
  border: 1px solid ${(props) => props.theme.inputBorderColour};
  box-sizing: border-box;
  overflow: auto;
  flex: 0 0 50%;
  flex-basis: calc(50% - 0.25em);
  text-align: left;
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  background: ${(props) => props.isActive ? props.theme.radioFieldbackground : '#FFF'};
  position: relative;

  @media (max-width: 515px) {
    flex: 1 1 300px;
    margin: 0;

    :not(:first-child) {
      margin-top: 0.5em;
    }
  }

  input[type='radio'] {
    appearance: none;
    display: none;
    visibility: hidden;
  }

  label {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    font-size: ${(props) => props.theme.generalFontSize};
    font-family: ${(props) => props.theme.labelFontFamily};
    cursor: pointer;
    line-height: 1.5em;
    width: 100%;
  }

  .radio-field-contents {
    label:first-child,
    select:first-child {
      padding: 0.5em 2.5em;
    }

    select:first-child {
      padding-right: 1em;
    }

    :before,
    :after {
      content: '';
      position: absolute;
      top: 50%;
      left: 1.25em;
      height: 20px;
      width: 20px;
      box-sizing: border-box;
      border-radius: 10px;
      cursor: pointer;
      pointer-events: none;
    }

    :before {
      border: 1px solid ${(props) => props.theme.durationOptions.primaryColour};
      transform: translateY(-50%) translateX(-50%);
      background: white;
    }

    :after {
      background: ${(props) => props.theme.durationOptions.primaryColour};
      transform: translateY(-50%) translateX(-50%) scale(0);
      transition: transform 0.3s ease-in-out;
    }
  }

  input[type='radio']:checked + .radio-field-contents::after {
    transform: translateY(-50%) translateX(-50%) scale(0.6);
  }
`

export const RadioSelect = styled(SelectStyled)`
  width: 100%;
  margin-left: 0;
  background: transparent;
  border-width: 0;
  color: black;
  margin-bottom: 0;
  height: 2.5em;
  padding: 0.5em 10px;
  margin-right: 0;
  cursor: pointer;
  outline: none;
`

export const TimeContainer = styled.div<ErrorProps>`
  border-left: 1px solid ${(props) => props.hasError ? props.theme.errorIconColour : props.theme.durationOptions.primaryColour}
  box-sizing: border-box;
  margin-top: 1em;
  padding: 1em 0 1em 1em;
  text-align: left;

  @media (max-width: 515px){
    width: 100%;
    margin-left: 0;
  }
`

export const TimeBox = styled.div`
  display: inline-flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: baseline;
`

export const ShowExtraLink = styled.button.attrs(() => ({
  id: 'ShowExtraDaysButton',
  type: 'button',
}))`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: ${({ theme }) => theme.fontStyle};
  font-weight: ${({ theme }) => theme.fontWeight};
  font-size: ${({ theme }) => theme.generalFontSize};
  padding: 0;
  margin: 0.5em 0;
  color: ${(props) => props.theme.primaryTextColour};
  outline: none;
  border-width: 0px;
  cursor: pointer;
  appearence: none;
  background: none;
  text-decoration: underline;
`
