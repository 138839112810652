import initialState from 'initialState'
import { ExternalAction } from './external.actions'

const externalReducer = (
  state = initialState.external,
  action: ExternalAction,
) => {
  switch (action.type) {
    case 'SET_EXTERNAL_DATA': {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default externalReducer
