import { P } from 'components/common/P'
import styled from 'styled-components'

interface AddonStyleProps {
  active?: boolean
  error?: boolean
  disabled?: boolean
}

export const AddonSection = styled.div<AddonStyleProps>`
  display: flex;
  padding: 15px 30px;
  column-gap: 30px;
  width: 100%;
  max-width: 628px;
  border: 1px solid ${(props) => props.theme.inputBorderColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius}
  box-sizing: border-box;
  background: #FFFFFF;

  ${({ active, theme }) =>
    active &&
    `
    background: theme.button.disabledBackground;
  `}

  @media (max-width: 768px) {
    padding: 10px 15px;
    column-gap: 15px;
  }
`

export const Price = styled.p<AddonStyleProps>`
  font-family: ${(props) => props.theme.headerFontFamily};
  font-size: 44px;
  margin: 0;
  color: ${(props) => props.theme.primaryTextColour};
  line-height: 1em;
  margin-bottom: 0.3em;
  white-space: nowrap;
  ${({ active }) =>
    active &&
    `
    font-size: 22px;
  `}

  @media (max-width: 768px) {
    font-size: 26px;

    ${({ active }) =>
    active &&
    `
      font-size: 16px;
  `}
  }
`

export const AddonButton = styled.button<AddonStyleProps>`
  background: ${(props) =>
    props.disabled
      ? props.theme.button.addon.disabledBackground
      : props.theme.button.addon.backgroundColour};
  color: ${(props) =>
    props.disabled
      ? props.theme.button.addon.disabledFont
      : props.theme.button.textColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  font-size: ${(props) => props.theme.button.fontSize};
  font-family: ${(props) => props.theme.button.fontFamily};
  text-transform: ${(props) => props.theme.button.textTransform};
  text-align: ${(props) => props.theme.button.textAlign};
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: ${(props) => props.theme.formControlHeight};
  width: 130px;

  :hover {
    filter: brightness(80%);
  }

  @media (max-width: 768px) {
    width: 85px;
  }

  ${({ active, theme }) =>
    active &&
    `
    :hover {
      filter: none;
    }
    cursor: default;
    background: theme.button.disabledBackground;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
  `}
`

export const AddonPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 0 0 130px;

  @media (max-width: 768px) {
    flex: 0 0 85px;
  }
`

export const AddonLogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 66px;
  flex: 0 0 66px;

  svg {
    width: 66px;
    height: 66px;

    #Path_538 {
      display: none;
    }
  }

  @media (max-width: 768px) {
    width: 40px;
    flex: 0 0 40px;

    svg {
      width: 40px;
      height: 40px;
    }
  }
`

export const AddonContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 1 1 auto;
  justify-content: space-between;

  p,
  a,
  h3 {
    margin: 0;
  }
`

export const AddonError = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 3px;
`

export const AddonContentHeader = styled.h3`
  font-family: ${(props) => props.theme.headerFontFamily};
  color: ${(props) => props.theme.headerFontColour};
  font-size: 22px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`

export const AddonContentBody = styled(P)`
  font-family: ${(props) => props.theme.secondaryFontFamily};
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`
export const TextForGapPrice = styled.p`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 3px;

  @media (max-width: 768px) {
    font-size: 8px;
`
export const LinkContainer = styled.a<AddonStyleProps>`
  font-size: 22px;
  cursor: pointer;
  color: ${(props) => props.theme.primaryTextColour};
  font-family: ${(props) => props.theme.headerFontFamily};
  width: 100%;
  text-decoration: underline;
  max-width: 130px;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
  `}

  :visited {
    color: ${(props) => props.theme.primaryTextColour};
  }

  @media (max-width: 768px) {
    font-size: 16px;
    max-width: 85px;
  }
`
