import {
  ApplePayMerchantSessionRepresentation,
  ApplePayRepresentation,
  CreatePaymentRequestLogRepresentation,
  JudoPurchasePolicyRepresentation,
  KlarnaPurchasePolicyRepresentation,
  PaypalPurchaseRepresentation,
  ProcessUnsuccessfulPaymentRepresentation,
  StripePurchasePolicyRepresentation,
} from 'types/webAPI.definition'
import http from './http'

export default class paymentApi {
  static getApplePayMerchantSession(
    endpoint: string,
    data: ApplePayMerchantSessionRepresentation,
  ) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.ApplePayMerchantSessionRepresentation+json',
      },
    })
  }

  static createApplePayment(endpoint: string, data: ApplePayRepresentation) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type': 'application/tempcover.ApplePayRepresentation+json',
      },
    })
  }

  static createJudoPayment(
    endpoint: string,
    data: JudoPurchasePolicyRepresentation,
  ) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.judopurchasepolicyrepresentation+json',
        Accept: 'application/tempcover.JudoResultRepresentation+json',
      },
    })
  }

  static createPayPalPayment(
    endpoint: string,
    data: PaypalPurchaseRepresentation,
  ) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.paypalpurchaserepresentation+json',
      },
    })
  }

  static prePurchase(
    endpoint: string,
    data: CreatePaymentRequestLogRepresentation,
  ) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.CreatePaymentRequestLogRepresentation+json',
      },
    })
  }

  static failedPayment(
    endpoint: string,
    data: ProcessUnsuccessfulPaymentRepresentation,
  ) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.ProcessUnsuccessfulPaymentRepresentation+json',
      },
    })
  }

  static createStripePayment(
    endpoint: string,
    data: StripePurchasePolicyRepresentation,
  ) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.StripePurchasePolicyRepresentation+json',
      },
    })
  }
  static createKlarnaPayment(data: KlarnaPurchasePolicyRepresentation) {
    return http.post('/api/policy/payment/klarna', data)
  }
}
