import React from 'react'
import { Grid } from 'unsemantic'
import queryString from 'query-string'
import { useConfig, usePayment } from 'hooks'
import { ErrorContainer } from 'components/ErrorContainer'
import { Message } from 'components/Message'
import { ErrorIcon } from 'components/Message/ErrorIcon'
import { MessageHeader } from 'components/Message/MessageHeader'
import { ClearFix } from 'components/common/ClearFix'
import { MessageContent } from 'components/Message/MessageContent'
import TimerBanner from 'components/TimerBanner'
import PriceBreakdown from 'components/PriceBreakdown'
import Addons from 'components/Addons'
import useOptimizelyExperiment from 'hooks/useOptimizelyExperiment'
import { ReactSDKClient, withOptimizely } from '@optimizely/react-sdk'
import { addMissingUtmTags } from '../../../history'
import {
  LegalText,
  PaymentHeader,
  BoldLegalText,
  AddonsWrapper,
} from './PaymentContent.styles'

interface PaymentContentProps {
  optimizely: ReactSDKClient | null
}

const PaymentContent: React.FC<PaymentContentProps> = ({ optimizely }) => {
  const shouldUseSplitAddonsPage = useOptimizelyExperiment(
    optimizely,
    'addons_page',
    'page_enabled',
  )
  const config = useConfig()
  const { payment } = usePayment()
  const parsedQueryString = queryString.parse(window.location.search)
  let error: string | null = null
  if (parsedQueryString.message) {
    error = parsedQueryString.message as string
  }

  // eslint-disable-next-line no-new-object
  const queries = addMissingUtmTags(new Object(null))

  return (
    <>
      <PaymentHeader>Payment</PaymentHeader>
      {!shouldUseSplitAddonsPage && (
        <AddonsWrapper>
          <Addons />
        </AddonsWrapper>
      )}
      {!payment.priceMatch && (
        <Message
          heading='We are sorry, your price has now updated'
          content='Please double check before proceeding.'
          type='info'
        />
      )}
      {payment.paymentError && (
        <ErrorContainer>
          <Grid desktop='20' tablet='20' mobile='20'>
            <ErrorIcon className='fa fa-exclamation-triangle' />
          </Grid>
          <Grid desktop='80' tablet='80' mobile='80'>
            <MessageHeader>Payment Error</MessageHeader>
            <MessageContent>{payment.paymentError}</MessageContent>
          </Grid>
          <ClearFix />
        </ErrorContainer>
      )}
      {error && (
        <ErrorContainer>
          <Grid desktop='20' tablet='20' mobile='20'>
            <ErrorIcon className='fa fa-exclamation-triangle' />
          </Grid>
          <Grid desktop='80' tablet='80' mobile='80'>
            <MessageHeader>Payment Error</MessageHeader>
            <MessageContent>{error}</MessageContent>
          </Grid>
          <ClearFix />
        </ErrorContainer>
      )}
      <TimerBanner />
      <BoldLegalText>
        Our prices can update on the hour. If your price is updated, we'll show
        you your new price before you buy.
      </BoldLegalText>
      <PriceBreakdown />
      <LegalText>
        The Tempcover fee is payable under your separate Tempcover contract for
        administering your policy. Once purchased, you cannot change any details
        and no refunds are available under our{' '}
        <a
          href={queryString.stringifyUrl({
            url: `${config.SITE_URL}/our-refund-policy`,
            query: { ...queries },
          })}
          target='_blank'
          rel='noopener noreferrer'
          id='refundPolicyLnk'
        >
          refund policy
        </a>
        .
      </LegalText>
    </>
  )
}

export default withOptimizely(PaymentContent)
