type ActionTypes = 'SET_REASON_FOR_TEMPCOVER_PURCHASE'

type PayloadType = {
  Description: string
  Value: string
  DisplayOrder: string
}[]

export interface DriverDetailsFormAction {
  type: ActionTypes
  payload?: PayloadType[]
}

export const setReasonForTempcoverPurchases: (
  reasonForTempcoverPurchases: PayloadType,
) => { type: ActionTypes; payload: PayloadType } = (
  reasonForTempcoverPurchases,
) => ({
  type: 'SET_REASON_FOR_TEMPCOVER_PURCHASE',
  payload: reasonForTempcoverPurchases,
})
