import Button from 'components/@common/Button'
import styled from 'styled-components'
import { P } from '../../components/common/P'
import { H1 } from '../../components/common/H1'
import { ReactComponent as confusedLogo } from '../../images/affiliates/confused-logo.svg'

export const Container = styled.div`
  border: 1px solid #2e458f;
  border-radius: 20px;
  margin: 24px 10px 0px 10px;
`

const Banner = styled.div`
  background-color: ${(props) => props.theme.confusedDarkColour};
  padding: 32px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`

export const LoadingBanner = styled(Banner)`
  font-size: 64px;
  color: #ffffff;
  border-radius: 20px;
  padding: 16px 32px;
  @media (max-width: 1024px) {
    padding: 16px;
  }
`

export const RTPBanner = styled(Banner)`
  border-radius: 20px;
  padding: 16px 32px;
  @media (max-width: 1024px) {
    padding: 16px;
  }
`

export const StaticBanner = styled(Banner)`
  display: grid;
  grid-gap: 24px;
  padding: 24px 24px 12px 24px;
  justify-content: center;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    padding: 12px 12px 8px 12px;
    grid-gap: 16px;
  }
`

export const BannerTextPrimary = styled.h2`
  font-size: 24px;
  line-height: 27px;
  font-weight: normal;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: #264694;
  text-align: center;
  margin-top: 0;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`
export const BannerTitle = styled(H1)`
  margin: 0;
  font-size: 24px;
  @media (max-width: 768px) {
    line-height: 1;
    padding-bottom: 8px;
    font-size: 22px;
  }
`

export const BannerH2Secondary = styled(BannerTextPrimary)`
  font-size: 18px;
  margin-bottom: 16px;
  margin-top: 8px;
  color: #000;
  font-family: 'Lato';
  @media (max-width: 768px) {
    font-size: 16px;
    margin-top: 0px;
  }
`

export const BannerTextSecondary = styled.h3`
  font-size: 18px;
  line-height: 26px;
  font-weight: normal;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: #000;
  text-align: center;
  margin: 0;

  strong {
    font-weight: 600;
  }
`

export const BannerTextTertiary = styled(BannerTextSecondary)`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  font-family: 'Lato';
`

export const StaticBannerTextTertiary = styled(BannerTextTertiary)`
  margin-bottom: 0;
`
export const SpinnerContainer = styled.div`
  padding: 12px;
  display: flex;
  justify-content: center;
`

export const ConfusedLogo = styled(confusedLogo)`
  height: 20px;
  @media (max-width: 768px) {
    height: 16px;
  }
`

export const ButtonWrapper = styled.div``

export const BannerButton = styled(Button)`
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 32px;
  margin-bottom: 0;
  color: #264694;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;

  @media (max-width: 650px) {
    font-size: 18px;
  }

  :hover {
    filter: ${(props) => (props.disabled ? null : 'brightness(80%)')};
  }
  font-weight: 600;
  text-decoration: none;
`

export const ButtonTextPrimary = styled.h4`
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
`

export const ButtonTextSecondary = styled.h4`
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: #1f1f1f;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
`

export const SmallPrint = styled.p`
  padding: 0px 24px;
  text-align: center;
  font-size: 10px;
  @media (max-width: 650px) {
    padding: 0px 12px;
  }
`

export const PricingContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 8px;
  margin: 16px 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const PriceContainer = styled.div`
  display: grid;
  grid-template-columns: 78px auto;
  border: 1px solid #2e458f;
  background-color: #f7fbff;
  border-radius: 12px;
`

export const ImageContainer = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b1b1b1;
  background-color: #fff;

  border-radius: 4px;
  & > div {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Image = styled.img`
  width: 90%;
`

export const PriceResult = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 8px;
    flex-direction: column;
  }
`
export const InsurerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin-right: auto;
  }
`
export const PriceWrapper = styled(InsurerWrapper)`
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: row;
  }
`

export const PriceText = styled(P)`
  margin-left: auto !important;
  text-align: right;
  font-size: 23px;
  font-size: 16px;
  margin: -3px;
  color: #000 & > strong {
    color: #264694;
    font-size: 23px;
  }
  @media (max-width: 768px) {
    margin-left: 0 !important;
    font-size: 18px;
    padding: 2px;
    margin: 0;
  }
`
export const AnnualPremium = styled(PriceText)`
  font-weight: bold;
  color: #264694;
  font-size: 23px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`
export const InsurerText = styled(P)`
  font-size: 21px;
  font-weight: bold;
  margin: 0;
  color: #264694;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`
export const InsurerName = styled(InsurerText)`
  color: #000;
  font-weight: normal;
  padding-left: 4px;
`
