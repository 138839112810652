import defaultTheme from './default'
const quidcoTheme = { ...defaultTheme }
const primaryColour = '#1d7bce'
const quidcoMagenta = '#da0a5b'
const quidcoDarkBlue = '#004A92'
const fontFamily = 'Rubik, sans-serif'
quidcoTheme.primaryColour = primaryColour
quidcoTheme.primaryTextColour = '#000'
quidcoTheme.headerFontColour = '#000'
quidcoTheme.breadcrumb = {
  ...defaultTheme.breadcrumb,
  activeColor: '#ffffff',
  activeBorderColor: primaryColour,
  defaultColor: '#ffffff',
  defaultBorderColor: '#CCCCCC',
  defaultBarColor: '#CCCCCC',
  completeColor: primaryColour,
  completeBorderColor: primaryColour,
  completeBarColor: primaryColour,
}
quidcoTheme.spinner = {
  ...defaultTheme.spinner,
  primaryColour: primaryColour,
  textColour: primaryColour,
}
quidcoTheme.durationOptions = {
  ...defaultTheme.durationOptions,
  primaryColour: primaryColour,
  secondaryColour: primaryColour,
}
quidcoTheme.priceComparison = {
  textColour: '#000',
  headerColour: '#000',
}

quidcoTheme.button = {
  ...defaultTheme.button,
  backgroundColour: primaryColour,
  fontFamily: fontFamily,
  disabledBackground: '#BBD7F0',
  disabledFont: '#95BEE7',
  addon: {
    backgroundColour: quidcoMagenta,
    disabledBackground: quidcoMagenta,
    disabledFont: '#e984ac',
  },
}

quidcoTheme.headerLinkStyled.padding = '6px 7px;'
quidcoTheme.generalFontSize = '16px'
quidcoTheme.headerLinkStyled = {
  ...defaultTheme.headerLinkStyled,
  hover: {
    ...defaultTheme.headerLinkStyled.hover,
    textDecoration: 'underline',
    color: '#fff',
    backgroundColour: 'transparent',
    padding: '0px',
  },
}

quidcoTheme.header = {
  ...defaultTheme.header,
  background: primaryColour,
}
quidcoTheme.fontFamily = fontFamily
quidcoTheme.headerFontFamily = fontFamily
quidcoTheme.labelFontFamily = fontFamily
quidcoTheme.gradient = {
  ...defaultTheme.gradient,
  background: '#ffffff',
}
quidcoTheme.GbIcon = '#ffffff'
quidcoTheme.formControlValidIcon = {
  ...defaultTheme.formControlValidIcon,
  backgroundColour: primaryColour,
  colour: '#fff',
}
quidcoTheme.icon = {
  ...defaultTheme.icon,
  color: '#ffffff',
  backgroundColor: primaryColour,
}
quidcoTheme.inputBorderColour = '#1d7bce'
quidcoTheme.formControlBorderRadius = '4px'
quidcoTheme.mobileLabelFontSize = '12px'
quidcoTheme.linkColour = primaryColour
quidcoTheme.linkUnderline = primaryColour

quidcoTheme.multiPrice = {
  ...defaultTheme.multiPrice,
  fontSize: '14px',
  mobile: {
    ...defaultTheme.multiPrice.mobile,
    lowestPriceBannerFontSize: '11px',
    coverTypeFontSize: '12px',
    mobileCoverTypeFontSize: '10px',
    continueButtonFontSize: '16px',
  },
  continueButtonFontSize: '22px',
  button: {
    backgroundColour: quidcoMagenta,
    disabledFont: '#e984ac',
  },
}
quidcoTheme.icons = {
  ...defaultTheme.icons,
  border: '',
  height: '33px',
  width: '43px',
}

quidcoTheme.inputActiveBorderColour = primaryColour
quidcoTheme.successColour = '#77b232'
quidcoTheme.marketingPreferencesColour = primaryColour

quidcoTheme.errorBackgroundColour = '#FFEDE8'
quidcoTheme.backgroundColour = '#cee4f8'
quidcoTheme.infoBackgroundColour = '#5bc0de'
quidcoTheme.errorBorderProperties = `2px solid #EF5831`
quidcoTheme.infoBorderProperties = `2px solid ${quidcoDarkBlue}`
quidcoTheme.errorIconColour = '#EF5831'
quidcoTheme.infoIconColour = quidcoDarkBlue
quidcoTheme.editIconColour = primaryColour

export default quidcoTheme
