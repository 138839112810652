import {
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE,
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_AND_FORWARD,
  SAVE_MARKETING_PREFERENCES_OPT_IN_STATE,
  RESET_MARKETING_PREFERENCES_OPT_IN_STATE,
} from './constants'

import { call, put, takeLatest, select } from 'redux-saga/effects'

import {
  getEmail,
  getMobile,
  getMarketingPreferenceLink,
  saveMarketingPreferenceLink,
  getMobileOptIn,
  getEmailOptIn,
  getAffiliateRef,
} from './selector'
import marketingPreferencesApi from '../../api/marketingPreferencesApi'
import {
  checkMarketingOptInStateSuccess,
  checkMarketingOptInStateFailure,
  saveMarketingPreferencesSuccess,
  saveMarketingPreferencesFailure,
  resetMarketingPreferences,
} from './actions'
import { enableAllButtons } from '../RiskDataCollectionPage/actions'
import { takeEvery } from 'redux-saga/effects'

function* checkMarketingOptInState() {
  const email = yield select(getEmail)
  const mobile = yield select(getMobile)

  if (email && mobile) {
    try {
      const response = yield call(
        marketingPreferencesApi.getMarketingPreferences,
        {
          emailAddress: email,
          telephoneNumber: mobile,
        },
      )

      yield put(checkMarketingOptInStateSuccess({ ...response.data }))
      yield put(enableAllButtons())
    } catch (e) {
      yield put(checkMarketingOptInStateFailure(e.message))
    }
  }
}

function* checkMarketingOptInStateAndForward() {
  yield checkMarketingOptInState()
}

function* resetMarketingPreferencesState() {
  yield put(resetMarketingPreferences)
}

function* saveMarketingPreferences() {
  const mobile = yield select(getMobile)
  const mobileOptIn = yield select(getMobileOptIn)
  const email = yield select(getEmail)
  const emailOptIn = yield select(getEmailOptIn)
  const affiliateRef = yield select(getAffiliateRef)

  var url = (yield select(saveMarketingPreferenceLink)).Href

  const data = {
    TelephoneNumber: mobile,
    TelephoneNumberOptIn: mobileOptIn,
    EmailAddress: email,
    EmailAddressOptIn: emailOptIn,
    AffiliateRef: affiliateRef,
  }

  try {
    yield call(marketingPreferencesApi.update, url, data)

    yield put(saveMarketingPreferencesSuccess())
  } catch (e) {
    yield put(saveMarketingPreferencesFailure(e.message))
  }
}

export default function* marketingPreferencesSaga() {
  yield takeEvery(
    CHECK_MARKETING_PREFERENCES_OPT_IN_STATE,
    checkMarketingOptInState,
  )
  yield takeEvery(
    CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_AND_FORWARD,
    checkMarketingOptInStateAndForward,
  )
  yield takeLatest(
    RESET_MARKETING_PREFERENCES_OPT_IN_STATE,
    resetMarketingPreferencesState,
  )
  yield takeLatest(
    SAVE_MARKETING_PREFERENCES_OPT_IN_STATE,
    saveMarketingPreferences,
  )
}
