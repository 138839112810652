import React, { useMemo, useState } from 'react'
import {
  useABExperiment,
  useAddress,
  useIdentity,
  usePrice,
  useRiskData,
} from 'hooks'
import { connect, useDispatch, useSelector } from 'react-redux'
import { InitialStateType } from 'initialState'
import LoadingScreen from 'components/LoadingScreen'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripePaymentButton } from 'containers/Stripe/styles'
import { ButtonWrapper } from 'components/PriceComparison/styles'
import {
  fraudFailure,
  setPaymentInProgress,
  setPaymentNotInProgress,
  priceMatch,
} from 'containers/PaymentPage/actions'
import {
  isButtonDisabled,
  enableAllButtons,
} from 'containers/RiskDataCollectionPage/actions'
import {
  createStripePaymentFailure,
  getPriceInfo,
  createStripePayment,
} from 'containers/Stripe/actions'
import { bindActionCreators } from 'redux'
import config from 'config'
import useCustomerAccountFeatureFlag from 'hooks/useCustomerAccountFeatureFlag'

// @ts-ignore
const CheckoutForm: React.FC = ({ actions }) => {
  const stripeActions = useStripe()
  const dispatch = useDispatch()
  const stripeElements = useElements()
  const { myLicence } = useIdentity()
  const { totalPrice, underwriter } = usePrice()
  const { riskData } = useRiskData()
  const { postcode } = useAddress()
  const [loading, setLoading] = useState(true)

  const paymentButtonDisabled = useSelector(
    (state: InitialStateType) => state.formButtons.paymentButton.disabled,
  )

  const className = useMemo(() => {
    if (myLicence.DisabledBySetting) {
      return `uw_${underwriter}`
    }
    if (myLicence.Enabled) {
      return `ab_mylicence_on uw_${underwriter}`
    }
    return `ab_mylicence_off uw_${underwriter}`
  }, [myLicence.DisabledBySetting, myLicence.Enabled, underwriter])

  // const { variant: multiPriceVariant } = useABExperiment(config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID, 'Price comparison experiment');

  const handleReady = () => {
    setLoading(false)
    // setStripeElements(elements)
  }

  const {
    data: useStripePaymentElementsFlagEnabled,
    isLoading: useStripePaymentElementsFlagLoaded,
  } = useCustomerAccountFeatureFlag('UseStripePaymentElements')

  let useStripePaymentElements = false

  if (
    !useStripePaymentElementsFlagLoaded &&
    useStripePaymentElementsFlagEnabled &&
    useStripePaymentElementsFlagEnabled?.data
  ) {
    useStripePaymentElements = true
  }

  const { variant: useStripeVariant } = useABExperiment(
    config.AB_TESTS.USE_STRIPE_ELEMENTS_EXPERIMENT_ID,
    'payment elements',
  )

  const useStripeElements = useStripeVariant === '1' || useStripePaymentElements

  const { variant: multiPriceVariant } = useABExperiment(
    config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID,
    'Price comparison experiment',
  )

  const handleClick = async () => {
    if (stripeElements) {
      dispatch(createStripePaymentFailure(''))
      dispatch(
        isButtonDisabled({
          buttonName: 'paymentButton',
          disabled: true,
        }),
      )

      setTimeout(() => {
        dispatch(
          isButtonDisabled({
            buttonName: 'paymentButton',
            disabled: false,
          }),
        )
      }, 120000) // extend the time by a lot

      // TODO: Investigate a better approach than this.
      // Passing all actions through is nasty.
      // await stripeElements.submit()

      dispatch(
        getPriceInfo({
          stripeActions,
          actions,
          stripeElement: stripeElements,
          isMultiPrice: Boolean(multiPriceVariant),
          useStripeElements,
        }),
      )
    }
  }

  return (
    <>
      {loading && <LoadingScreen message='Loading' />}

      <PaymentElement
        options={{
          defaultValues: {
            billingDetails: {
              email: riskData.EmailAddress,
              address: {
                postal_code: postcode,
                country: 'GB',
              },
              phone: riskData.Mobile,
              name: `${riskData.Forename} ${riskData.Surname}`,
            },
          },
          fields: {
            billingDetails: { address: 'never' },
          },
        }}
        onReady={handleReady}
      />
      {!loading && !!totalPrice && totalPrice > 0 && (
        <ButtonWrapper>
          <StripePaymentButton
            onClick={handleClick}
            id='StripePaymentButton'
            data-email={riskData.EmailAddress}
            className={className}
            disabled={paymentButtonDisabled}
          >
            Pay &pound;
            {totalPrice.toFixed(2)}
          </StripePaymentButton>
        </ButtonWrapper>
      )}
    </>
  )
}

// @ts-ignore
export const mapDispatchtoProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      createStripePayment,
      isButtonDisabled,
      enableAllButtons,
      createStripePaymentFailure,
      fraudFailure,
      setPaymentInProgress,
      setPaymentNotInProgress,
      getPriceInfo,
      priceMatch,
    },
    dispatch,
  ),
})

export default connect(null, mapDispatchtoProps)(CheckoutForm)
// export default CheckoutForm
