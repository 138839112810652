import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCar,
  faPlus,
  faTruck,
  faCarSide,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { H2 } from 'components/common/H2'
import { BODY_TYPE_OPTIONS } from 'components/@forms/DriverDetails/CommercialVehicleForm/CommercialVehicleForm.constants'
import { VEHICLE_VALUES } from 'components/VehicleValue/constants'
import {
  StyledVehicleDetail,
  StyledVehicleContent,
} from './VehicleDetail.styles'
import { NewVehicleDetails } from './VehicleDetail.types'

import './style.css'

library.add(faCar, faPlus, faTruck, faCarSide)

export interface Props {
  vehicleDetail: NewVehicleDetails
  singleSlide?: boolean
}

const AddNewVehicle = () => (
  <>
    <FontAwesomeIcon icon='plus' className='fa-2x styled-icon' />
    <H2>ADD A NEW VEHICLE</H2>
  </>
)

const VehicleDetail: React.FC<Props> = ({ vehicleDetail, singleSlide }) => (
  <StyledVehicleDetail
    className={`vehicle-details ${
      vehicleDetail.NewVehicle ? 'add-new-vehicle' : ''
    } ${singleSlide ? 'single-slide' : ''}`}
  >
    <StyledVehicleContent className='vehicle-container'>
      {vehicleDetail.NewVehicle && <AddNewVehicle />}
      {!vehicleDetail.NewVehicle && (
        <>
          <FontAwesomeIcon
            icon={
              vehicleDetail.vehicleBodyType === 'PrivateCar'
                ? 'car-side'
                : 'truck'
            }
            className='styled-icon fa-2x'
          />
          <p className='vehicle-registration'>
            {vehicleDetail.registrationNumber}
          </p>
          <p className='vehicle-make'>
            {vehicleDetail.make} {vehicleDetail.model}
          </p>
          {vehicleDetail.make == null && (
            <>
              <p className='no-make-info'>
                Weight: {vehicleDetail.grossWeight}KG
              </p>
              <p className='no-make-info'>Year: {vehicleDetail.vehicleYear}</p>
              {BODY_TYPE_OPTIONS.findIndex(
                (f) => f.value === vehicleDetail.commercialVehicleBodyType,
              ) !== -1 && (
                <p className='no-make-info'>
                  {
                    BODY_TYPE_OPTIONS.filter(
                      (f) =>
                        f.value === vehicleDetail.commercialVehicleBodyType,
                    )[0].description
                  }
                </p>
              )}
            </>
          )}

          <p className='vehicle-value'>
            Vehicle value{' '}
            {VEHICLE_VALUES.filter(
              (item) => item.value === vehicleDetail.vehicleValueBandId,
            ).map((v) => v.description)}
          </p>
        </>
      )}
    </StyledVehicleContent>
  </StyledVehicleDetail>
)

export default VehicleDetail
