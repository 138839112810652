import PolicyDuration from 'components/@forms/PolicyDuration'
import Modal from '../@common/Modal'
import React from 'react'
import { DurationType } from 'types/global'

interface Props {
  isVisible: boolean
  hideModal: () => void
  handleUpdate: (duration: number | null, durationType: DurationType) => void
}

const PolicyDurationModal: React.FC<Props> = ({
  isVisible,
  hideModal,
  handleUpdate,
}) => {
  const onSubmit = (duration: number | null, durationType: DurationType) => {
    handleUpdate(duration, durationType)
    hideModal()
  }

  return (
    <Modal
      id='policyDurationModal'
      isVisible={isVisible}
      onClose={hideModal}
      header='Cover for how long?'
      headerAlignment='center'
      fullscreen={window.innerWidth <= 500}
    >
      <PolicyDuration
        onSubmit={(duration, durationType) => onSubmit(duration, durationType)}
      />
    </Modal>
  )
}

export default PolicyDurationModal
