import React from 'react'
import { SubHeading } from '../styles'

interface Props {
  vehicle: Record<string, any>
}

export const VehicleDescription = ({ vehicle }: Props) => (
  <SubHeading>{`${vehicle.Make} ${vehicle.Model}`}</SubHeading>
)
