import { SchemeEnum } from '@enums'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isLoading } from '../../containers/App/actions'
import { initRiskDataCollectionPage } from '../../containers/RiskDataCollectionPage/actions'
import {
  createUnkownVehicle,
  setVehicleListener,
} from '../../containers/Vehicle/actions'
import {
  COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_PRIVATE_CAR_SCHEME,
  MOTORCYCLE_VEHICLE_SCHEME,
  VEHICLE_LEARNER_SCHEME,
} from '../../containers/Vehicle/constants'
import { H2 } from 'components/common/H2'
import { H3 } from '../common/H3'
import { CommercialVehicle } from '../SVG/scheme-icons/CommercialVehicle'
import CourierIcon from '../SVG/scheme-icons/Courier'
import { Expat } from '../SVG/scheme-icons/Expat'
import { Impounded } from '../SVG/scheme-icons/Impounded'
import { Learner } from '../SVG/scheme-icons/Learner'
import { Motorbike } from '../SVG/scheme-icons/Motorbike'
import { PrivateCar } from '../SVG/scheme-icons/PrivateCar'
import { BaseIconTitleWrapper } from './BaseIconTitleWrapper'
import { TextIcon } from './TextIcon'

const SchemeTitle = ({ scheme, riskData, vehicle, actions }) => {
  const schemeName = useMemo(() => {
    let policyType = 'temporary car'

    if (riskData.Expat) {
      policyType = 'temporary expat'
    }

    switch (scheme) {
      case VEHICLE_LEARNER_SCHEME:
        policyType = 'temporary learner'
        break
      case COMMERCIAL_VEHICLE_SCHEME:
        policyType = 'temporary van'
        break
      case IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME:
      case IMPOUNDED_PRIVATE_CAR_SCHEME:
        policyType = 'impounded vehicle'
        break
      case MOTORCYCLE_VEHICLE_SCHEME:
        policyType = 'temporary bike'
        break
      case SchemeEnum.COURIER:
        policyType = 'courier'
        break
      default:
        break
    }

    return policyType
  }, [scheme, riskData])

  const SchemeIcon = useMemo(() => {
    let policyIcon = PrivateCar

    if (riskData.Expat) {
      policyIcon = Expat
    }

    switch (scheme) {
      case VEHICLE_LEARNER_SCHEME:
        policyIcon = Learner
        break
      case COMMERCIAL_VEHICLE_SCHEME:
        policyIcon = CommercialVehicle
        break
      case SchemeEnum.COURIER:
        policyIcon = CourierIcon
        break
      case IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME:
      case IMPOUNDED_PRIVATE_CAR_SCHEME:
        policyIcon = Impounded
        break
      case MOTORCYCLE_VEHICLE_SCHEME:
        policyIcon = Motorbike
        break
      default:
        break
    }

    return policyIcon
  }, [scheme, riskData])

  return (
    <>
      <BaseIconTitleWrapper>
        <H2>Enter details for your {schemeName} insurance</H2>
        {/* <TextIcon>
          <SchemeIcon />
        </TextIcon> */}
      </BaseIconTitleWrapper>
    </>
  )
}

export const mapStateToProps = (state) => {
  return {
    scheme: state.scheme.selected,
    riskData: state.riskData,
    vehicle: state.vehicle,
    actions: state.actions,
  }
}

export const mapDispatchtoProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        isLoading,
        initRiskDataCollectionPage,
        setVehicleListener,
        createUnkownVehicle,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchtoProps)(SchemeTitle)
