import styled from 'styled-components'

export const StyledVehicleDetail = styled.div`
  position: relative;
  border: 1px solid #070707;
  border-radius: ${(props) => props.theme.vehicleDetailBorderRadius};
  background: #fff;
  margin: 10px 0;
  padding: 20px;
  cursor: pointer;
  text-align: left;

  .add-new-vehicle {
    border-style: dashed;
    border-width: 2px;
    background-color: #264b941a;
  }

  & .styled-icon {
    color: ${(props) => props.theme.primaryColour};
  }

  & @media screen and (max-width: 769px) {
    border-style: solid !important;
  }
`

export const StyledVehicleContent = styled.div`
  text-align: center;
  min-height: 140px;
  cursor: pointer;
  font-family: ${(props) => props.theme.labelFontFamily};

  & .vehicle-registration {
    font-size: 24px;
    font-family: ${(props) => props.theme.numberPlateFontFamily};
    margin: 0;
  }

  & .vehicle-make {
    overflow-wrap: anywhere;
    margin: 0;
    font-size: 16px;
  }

  & .vehicle-value {
    margin: 0;
    font-size: 10px;
  }

  & .no-make-info {
    margin: 0;
  }
`
