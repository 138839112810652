import { takeLatest } from 'redux-saga/effects'
import { underwriterSaga } from '../RiskDataCollectionPage/sagas/underwriter'
import { PRICE_CHECK_PAYMENT_PAGE } from './constants'

export function* priceCheckPaymentPageSaga() {
  try {
    yield* underwriterSaga()
  } catch (e) {}
}

export default function* paymentPageSaga() {
  yield takeLatest(PRICE_CHECK_PAYMENT_PAGE, priceCheckPaymentPageSaga)
}
