import styled, { keyframes } from 'styled-components'

interface EllipsisLoaderProps {
  size?: number
}

const loadingAnimation = keyframes`
  0% {transform: translateY(0%)}
  33% {transform: translateY(-30%)}
  66% {transform: translateY(0%)}
  100% {transform: translateY(0%)}
`

export const EllipsisLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const EllipsisLoaderDot = styled.div<EllipsisLoaderProps>`
  font-size: ${({ size = 12 }) => `${size}px`};
  width: 1em;
  height: 1em;
  background: ${(props) => props.theme.buttonTextColour};
  border-radius: 0.5em;
  margin: 0 0.5em;
  animation-name: ${loadingAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-delay: 0;

  &:nth-child(2) {
    animation-delay: 0.33s;
  }

  &:nth-child(3) {
    animation-delay: 0.66s;
  }
`
