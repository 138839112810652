import React from 'react'
import PropTypes from 'prop-types'
import validator from 'validator'

import { Select } from '../common/Select'
import { bodyTypeOptions } from './constants'
import { ErrorMessage } from '../common/ErrorMessage'

const handleChange = (event, onChange, handleValidation) => {
  let message = !validator.isEmpty(event.target.value)
    ? ''
    : "Sorry, we need to know your van's body type"

  handleValidation(event.target.name, message)

  onChange(event)
}

const BodyType = ({ onChange, value, handleValidation, error }) => {
  return (
    <>
      <Select
        placeholder="My van's body type is..."
        onChange={(event) => {
          handleChange(event, onChange, handleValidation)
        }}
        id='BodyType'
        options={bodyTypeOptions}
        name='BodyType'
        value={value}
      />
      <ErrorMessage>{error}</ErrorMessage>
    </>
  )
}

export default BodyType

BodyType.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  handleValidation: PropTypes.func.isRequired,
  error: PropTypes.string,
}
