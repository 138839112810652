import React from 'react'
import { Grid } from 'unsemantic'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import validator from 'validator'
import _ from 'lodash'

import {
  requestOccupations,
  updateOccupationSearch,
  selectOccupation,
} from './actions'
import { InputWrapper } from '../../components/common/Input/InputWrapper'
import { bindActionCreators } from 'redux'
import { Button } from '../../components/common/Button'
import { Input } from '../../components/common/Input'
import {
  updateValidation,
  updateQuote,
  updateRiskData,
} from '../RiskDataCollectionPage/actions'
import { ErrorMessage } from '../../components/common/ErrorMessage'
import { Label } from '../../components/common/Label'
import { AutoComplete } from '../../components/common/AutoComplete'

export const AddressInputWrapper = styled(InputWrapper)`
  margin-bottom: 0;
`

export const AddressSearch = styled(Button)`
  margin-top: 1.5em;
  width: 100%;
`

export class Occupation extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      occupationSearch: false,
      editing: false,
    }

    this.onChange = this.onChange.bind(this)
    this.onOccupationSelect = this.onOccupationSelect.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)

    this.searchOccupations = _.debounce(this.searchOccupations, 200)
  }

  onChange = (event) => {
    const occupationSearch = event.target.value
    this.props.actions.updateOccupationSearch(occupationSearch)
    if (occupationSearch.length >= 3) {
      this.searchOccupations(occupationSearch)
    }
  }

  onFocus = () => {
    this.setState({ editing: true })
  }

  onBlur = () => {
    this.updateValidation(this.props.occupation)
    this.setState({ editing: false })
  }

  onOccupationSelect = (event) => {
    this.setState({ editing: false })
    const occupation = this.props.occupations.find(
      (o) => o.Id === parseInt(event.nativeEvent.target.id),
    )

    this.props.actions.selectOccupation(occupation)
    this.updateValidation(occupation)
    this.props.actions.updateRiskData({ Occupation: occupation })
  }

  searchOccupations = (searchTerm) => {
    this.props.actions.requestOccupations(searchTerm)
  }

  updateValidation = (occupation) => {
    let message =
      occupation && validator.isNumeric(`${occupation.Id}`)
        ? ''
        : 'Sorry, we need to know your occupation'
    this.props.actions.updateValidation({
      OccupationError: message,
    })
  }

  render() {
    const { editing } = this.state

    const style = {
      position: 'relative',
      zIndex: '9',
    }

    return (
      <div>
        <Grid desktop='100' tablet='100' mobile='100' parent>
          <Label>Occupation</Label>
          <Input
            placeholder='I am a...'
            type='text'
            value={this.props.occupationSearch}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            style={
              editing &&
              this.props.occupations &&
              this.props.occupations.length > 0
                ? {
                    ...style,
                    borderBottomRightRadius: '0',
                    borderBottomLeftRadius: '0',
                  }
                : style
            }
          />
          {editing &&
            this.props.occupations &&
            this.props.occupations.length > 0 && (
              <>
                <AutoComplete
                  searchTerm={this.props.occupationSearch}
                  onClick={this.onOccupationSelect}
                  id='Occupation'
                  options={this.props.occupations}
                  name='Occupation'
                  properties={{ id: 'Id', value: 'OccupationName' }}
                />
              </>
            )}
          <ErrorMessage>{this.props.occupationError}</ErrorMessage>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = ({ occupation, validation }) => {
  return {
    occupations: occupation.SearchResults,
    occupation: occupation.SelectedOccupation,
    occupationSearch: occupation.OccupationSearch,
    occupationError: validation.riskDataCollectionPage.fields.OccupationError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        requestOccupations,
        updateValidation,
        selectOccupation,
        updateOccupationSearch,
        updateQuote,
        updateRiskData,
      },
      dispatch,
    ),
  }
}

Occupation.propTypes = {
  occupations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    }),
  ),
  occupationSearch: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(Occupation)
