import React from 'react'
import styled from 'styled-components'

const ArrowWrapper = styled.div`
  margin-left: ${(props) => (props.left ? '0' : '16px')};
  margin-right: ${(props) => (props.left ? '8px' : '0')};
  height: ${(props) => (props.left ? '18px' : '24px')};
  width: ${(props) => (props.left ? '18px' : '24px')};
  display: flex;
  align-items: center;
  transform: ${(props) => (props.left ? 'rotate(-180deg)' : 'rotate(-0deg)')};

  & svg {
    height: ${(props) => (props.left ? '18px' : '24px')};
    width: ${(props) => (props.left ? '18px' : '24px')};
  }
`

const Arrow = (props) => {
  return (
    <ArrowWrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
      >
        <path
          id='Path_18826'
          data-name='Path 18826'
          d='M681.988,341.566l-3,3.055,6.759,6.8H669.91v4.283h15.832l-6.758,6.8,3,3.055,11.922-12Z'
          transform='translate(-669.91 -341.566)'
        />
      </svg>
    </ArrowWrapper>
  )
}

export default Arrow
