import { call, put, select, takeLatest } from 'redux-saga/effects'
import myLicenceApi from '../../api/myLicenceApi'
import { requestMyLicenceStatusSuccess } from './actions'
import { getMyLicenceSelector } from './selector'
import { REQUEST_MYLICENCE_STATUS } from './constants'
import { getQuoteId } from '../RiskDataCollectionPage/selector'

function* getMyLicenceStatus() {
  try {
    const quoteId = yield select(getQuoteId)
    const myLicenceLink = yield select(getMyLicenceSelector)
    const response = yield call(
      myLicenceApi.getStatus,
      myLicenceLink.Href.replace(/{[encryptedQuoteId}]*}/, quoteId),
    )
    yield put(requestMyLicenceStatusSuccess(response.data))
  } catch (e) {}
}

export default function* myLicenceSaga() {
  yield takeLatest(REQUEST_MYLICENCE_STATUS, getMyLicenceStatus)
}
