import React from 'react'
import { AutoCompleteWrapper } from './AutoComplete.styles'
import AutoCompleteOption from './components/AutoCompleteOption'

interface Props {
  options?: { id: number; value: string }[]
  searchTerm: string
  onClick: (
    _e:
      | React.TouchEvent<HTMLLIElement>
      | React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => void
  properties?: { id: string; value: string }
  id?: string
}

const AutoComplete: React.FC<Props> = ({
  options,
  searchTerm,
  onClick,
  properties = {
    id: 'id',
    value: 'value',
  },
  id,
}) => (
  <AutoCompleteWrapper id={id}>
    {options?.map((option, i) => (
      <AutoCompleteOption
        key={option.id}
        option={option}
        searchTerm={searchTerm}
        onClick={onClick}
        properties={properties}
      />
    ))}
  </AutoCompleteWrapper>
)

export default AutoComplete
