import React, { useEffect, useMemo, useState } from 'react'
import { ErrorMessage } from '../../common/ErrorMessage'
import { FormControlValidIcon, FormFieldWrapper } from './FormField.styles'

interface FormFieldContentProps {
  isValidIcon: boolean
  field: any
  form: any
  meta: any
}

const FormFieldContent: React.FC<FormFieldContentProps> = ({
  isValidIcon,
  field,
  form,
  meta,
  children,
}) => {
  const [isError, setIsError] = useState(false)
  const { error, touched } = meta
  const showIcon = useMemo(() => touched && !error, [error, touched])

  useEffect(() => {
    if (touched) {
      if (error) {
        setIsError(true)
      } else if (field.value) {
        setIsError(false)
      }
    }
  }, [touched, error, field.value])

  useEffect(() => {
    if (form.isSubmitting) {
      setIsError(true)
    }
  }, [form.isSubmitting])

  return (
    <>
      <FormFieldWrapper>
        <>
          {isValidIcon && (
            <FormControlValidIcon
              showIcon={showIcon}
              className='fas fa-check'
            />
          )}
          {children}
        </>
      </FormFieldWrapper>
      {isError && error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  )
}

export default FormFieldContent
