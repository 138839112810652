import {
  GET_MARKETING_PREFERENCES_URL,
  SAVE_MARKETING_PREFERENCES_URL,
} from './constants'

export const getEmail = (state) => state.riskData.EmailAddress
export const getEmailOptIn = (state) =>
  state.marketingPreferences.EmailAddressOptIn
export const getMobile = (state) => state.riskData.Mobile
export const getMobileOptIn = (state) =>
  state.marketingPreferences.TelephoneNumberOptIn
export const getAffiliateRef = (state) => state.affiliate.Ref

export const getMarketingPreferenceLink = (state) =>
  state.links.filter((link) => link.Rel === GET_MARKETING_PREFERENCES_URL)[0]
export const saveMarketingPreferenceLink = (state) =>
  state.links.filter((link) => link.Rel === SAVE_MARKETING_PREFERENCES_URL)[0]
