export const AFFILIATE_REFS = [
  {
    AffiliateId: 31,
    DisplayName: 'A-Plan Holdings',
    AffiliateType: 3,
    Value: 'aplan',
  },
  {
    AffiliateId: 21,
    DisplayName: 'Brightside',
    AffiliateType: 3,
    Value: 'BS354BL302216BS',
  },
  {
    AffiliateId: 1,
    DisplayName: 'One Answer Insurance Ltd',
    AffiliateType: 3,
    Value: 'BN266JF432453',
  },
  {
    AffiliateId: 16,
    DisplayName: 'Admiral Insurance',
    AffiliateType: 2,
    Value: 'CF103AZ219824',
  },
  {
    AffiliateId: 2,
    DisplayName: 'A J Insurance Service',
    AffiliateType: 4,
    Value: 'RM187BS303916',
  },
]

export const POLICY_DURATION_TYPES = ['hours', 'days', 'weeks']

export const LICENCE_HELD_DURATIONS = [
  {
    id: 8,
    value: 'ZeroToFiveMonths',
    description: '0 to 5 months',
    yearDiff: 0,
    order: 0,
  },
  {
    id: 1,
    value: 'SixToElevenMonths',
    description: '6 to 11 months',
    yearDiff: 0.5,
    order: 1,
  },
  {
    id: 2,
    value: 'OneYear',
    description: '1 year',
    yearDiff: 1,
    order: 2,
  },
  {
    id: 3,
    value: 'TwoYears',
    description: '2 years',
    yearDiff: 2,
    order: 3,
  },
  {
    id: 4,
    value: 'ThreeYears',
    description: '3 years',
    yearDiff: 3,
    order: 4,
  },
  {
    id: 5,
    value: 'FourYears',
    description: '4 years',
    yearDiff: 4,
    order: 5,
  },
  {
    id: 6,
    value: 'FiveToNineYears',
    description: '5-9 years',
    yearDiff: 5,
    order: 6,
  },
  {
    id: 7,
    value: 'TenYearsOrMore',
    description: '10 years +',
    yearDiff: 10,
    order: 7,
  },
]

export const QUOTE_URL = '/quote'

export const SYSTEM_FEATURES = {
  SYSTEM_DOWN_FOR_MAINTAINANCE: 'SystemDownForMaintenance',
  UPSELL_CTM_ENABLED: 'UpsellCtmEnabled',
  UPSELL_FLOW_ENABLED: 'UpsellFlowEnabled',
  IS_CASHBACK_ENABLED: 'IsCashbackEnabled',
  UPSELL_CONFUSED_ENABLED: 'ConfusedUpsellEnabled',
}

export const SESSIONSTATES = {
  NEW_SESSION: 'NEW_SESSION', // new session started
  CREATING_SESSION: 'CREATING_SESSION', // api called to get new session guid
  SESSION_CREATED: 'SESSION_CREATED', // session api called, session guid stored
  UPDATING_SESSION_QUOTE: 'UPDATING_SESSION_QUOTE', // quote id new/changed
  SESSION_QUOTE_UPDATED: 'SESSION_QUOTE_UPDATED', // session api called with quoteid
}

export const LICENCE_REGEXP =
  /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/i

export const HTTP_CODES = {
  SUCCESS: {
    OK: 200,
  },
  ERROR: {
    BadRequest: 400,
    Forbidden: 403,
    TooManyRequests: 429,
    Unauthorized: 401,
    NotFound: 404,
    InternalServerError: 500,
    ServiceUnavailable: 503,
  },
}

export const SCHEMES = {
  COMMERCIAL_VEHICLE_SCHEME: 'VehicleCommercialVehicleBaseScheme',
  PRIVATE_CAR_SCHEME: 'VehiclePrivateCarBaseScheme',
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME:
    'VehicleCommercialVehicleImpoundedScheme',
  IMPOUNDED_PRIVATE_CAR_SCHEME: 'VehiclePrivateCarImpoundedScheme',
  VEHICLE_LEARNER_SCHEME: 'VehicleLearnerScheme',
  MOTORCYCLE_VEHICLE_SCHEME: 'VehicleMotorcycleLearnerAndFullLicenceScheme',
}
