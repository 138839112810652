import defaultTheme from './default'

let aPlanTheme = { ...defaultTheme }

// Howden official colours
const howdenLightAccent = '#cefdc9'
const howdenDarkAccent_1 = '#244C5A'
const howdenDarkAccent_2 = '#65818b'
const howdenLightAccent_3 = '#B9D8EA'

// Howden styling extras
// Pantone shift
const howdenMenuBackground = '#B8FCB0'
const howdenTileBackground = '#EDFEEB'
const howdenBackgroundGradientStart = '#EDFEEB'
const howdenHeaderSmall_text = '#EDFEEB'
const howdenContainerBackground = '#EDFEEB'

const lightTextColour = howdenLightAccent_3
const darkTextColour = howdenDarkAccent_1
const iconColour = lightTextColour

const primaryColour = darkTextColour // '#CEFDC9';
const secondaryColour = '#244C5A' // '#B8FCB0';
const gradiantStart = '#EDFEEB'
const gradientEnd = '#FFFFFF'
const headerBackgroundColour = '#DDFED9'
const labelColour = '#244c5a'

const darkAccent = howdenDarkAccent_1

aPlanTheme.labelTextColour = labelColour
//aPlanTheme.backgroundColour = '#cefdc9';
aPlanTheme.backgroundColour = howdenLightAccent_3
aPlanTheme.primaryColour = primaryColour
aPlanTheme.primaryTextColour = '#333'
aPlanTheme.headerFontColour = primaryColour
aPlanTheme.buttonAccentColour = darkAccent
aPlanTheme.buttonBackgroundColour = darkAccent
//aPlanTheme.infoBackgroundColour= '#000';

aPlanTheme.breadcrumb = {
  ...defaultTheme.breadcrumb,
  activeColor: darkAccent,
  activeBorderColor: darkAccent,
  defaultColor: howdenLightAccent_3,
  defaultBorderColor: howdenLightAccent_3,
  defaultBarColor: howdenLightAccent_3,
  completeColor: darkAccent,
  completeBorderColor: darkAccent,
  completeBarColor: darkAccent,
}
aPlanTheme.spinner = {
  ...defaultTheme.spinner,
  primaryColour: primaryColour,
  textColour: primaryColour,
}
aPlanTheme.durationOptions = {
  ...defaultTheme.durationOptions,
  primaryColour: primaryColour,
  secondaryColour: secondaryColour,
  durationButtonForeColour: howdenLightAccent_3,
}
aPlanTheme.button = {
  ...defaultTheme.button,
  backgroundColour: secondaryColour,
  addon: {
    backgroundColour: secondaryColour,
    disabledBackground: secondaryColour,
    disabledFont: '#fff',
  },
}
aPlanTheme.header = {
  ...defaultTheme.header,
  background: headerBackgroundColour,
  borderBottom: 'none',
}
aPlanTheme.headerLinkStyled = {
  ...defaultTheme.headerLinkStyled,
  textColour: darkTextColour,
  padding: '0px',
  hover: {
    textDecoration: 'underline',
    color: '#fff',
    backgroundColour: primaryColour,
    padding: '0px',
  },
}

aPlanTheme.gradient = {
  ...defaultTheme.gradient,

  background: gradiantStart,
  startColour: gradientEnd,
}
aPlanTheme.icon = {
  ...defaultTheme.icon,
  color: iconColour,
  backgroundColor: darkAccent,
}
aPlanTheme.GbIcon = iconColour
aPlanTheme.formControlValidIcon = {
  ...defaultTheme.formControlValidIcon,
  colour: '#fff',
  backgroundColour: primaryColour,
}
aPlanTheme.headerFontColour = primaryColour
aPlanTheme.inputBorderColour = darkAccent
aPlanTheme.successColour = primaryColour
aPlanTheme.marketingPreferencesColour = secondaryColour
aPlanTheme.svg = {
  ...defaultTheme.svg,
  primaryColour: primaryColour,
  accentColour: '#FFBABF',
}
aPlanTheme.icons = {
  border: '',
  height: '33px',
  width: '43px',
}

export default aPlanTheme
