import moment from 'moment'
import { DateFormat } from 'types/global'

export const formatDateMoment = (
  dateToFormat: string,
  formatType: DateFormat,
  dateToFormatType?: DateFormat,
) => {
  return moment(dateToFormat, dateToFormatType || undefined)
    .utc()
    .format(formatType)
}
