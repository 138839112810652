import styled from 'styled-components'
import { FormControlWrapper } from '../FormControlWrapper'
import { GBIcon } from '../VehicleReg/styles'
import { UppercaseInputStyled } from '../common/Input/UppercaseInput'
export const RegistrationInput = styled(UppercaseInputStyled)`
  font-family: ${(props) => props.theme.regFont};
  color: #000;
  letter-spacing: 1px;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  border: 1px solid ${(props) => props.theme.primaryColour};
  box-sizing: border-box;
  margin: 0 0 0.5em 0;
  ::placeholder {
    text-transform: none;
    letter-spacing: 0;
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 23px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
  }
`

export const RegistrationWrapper = styled(FormControlWrapper)`
  text-align: center;

  // i {
  //   @media (max-width: 767px) {
  //     top: 2px;
  //   }
  // }
`

export const ChangeVehicleGBIcon = styled(GBIcon)`
  left: 0;
  height: 45px;
  top: 0;
  line-height: 44px;
  border-radius: ${(props) => props.theme.formControlBorderRadius} 0px 0px
    ${(props) => props.theme.formControlBorderRadius};
`
