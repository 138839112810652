import { H2 } from 'components/common/H2'
import styled from 'styled-components'

export const LegalDeclarationWrapper = styled.div`
  text-align: left;

  ul {
    padding: 0 15px;
  }

  ul ul {
    padding: 26px 15px;
    border-top: 1px solid #264b94;
    margin-top: 8px;
  }

  li {
    padding: 3px 0;
    font-family: ${(props) => props.theme.fontFamily};
    font-style: ${(props) => props.theme.fontStyle};
    font-weight: ${(props) => props.theme.fontWeight};
    font-size: ${(props) => props.theme.generalFontSize};
  }

  /* Targeting headers of each unordered list */
  ul li {
    color: ${(props) => props.theme.headerFontColour};
    font-size: 20px;
    list-style: none;
    padding: 8px 0;
    font-weight: bold;
    border-top: 1px solid ${(props) => props.theme.primaryColour};
  }
  /* Targeting nested child list items  */
  ul ul li {
    list-style: initial;
    color: #000000;
    font-size: ${(props) => props.theme.generalFontSize};
    font-weight: normal;
    border-top: none;
    border-bottom: none;
  }
  ul ul li::marker {
    color: ${(props) => props.theme.primaryColour};
  }

  #DisallowedOccupationsLink {
    color: ${(props) => props.theme.primaryColour};
    display: inline;
    cursor: pointer;
    text-decoration: underline;
  }
`

export const LegalDeclarationHeader = styled(H2)`
  color: #000;
  margin: 2px 0 16px;
`

export const LegalDeclarationParagraph = styled.p`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 700;
  margin: 1em 0 1em 0;
`
