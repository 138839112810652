import { ReactSDKClient } from '@optimizely/react-sdk'
import { useState } from 'react'

const useOptimizelyExperiment = (
  optimizely: ReactSDKClient | null,
  featureName: string,
  variationKey: string,
) => {
  const [useVariation, setUseVariation] = useState(false)
  if (!optimizely) return useVariation
  optimizely.onReady().then(() => {
    const decision = optimizely.decide(featureName)
    setUseVariation(decision.variationKey === variationKey)
  })
  return useVariation
}

export default useOptimizelyExperiment
