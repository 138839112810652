import { takeLatest, call, select, put } from 'redux-saga/effects'
import paymentApi from '../../api/paymentApi'
import { CREATE_STRIPE_PAYMENT, GET_PRICE_INFO } from './constants'
import { history } from '../..'
import { getRiskData, getPriceData } from '../LegalDeclarationModal/selector'
import {
  getExperiments,
  getQueryString,
  getAddressData,
} from '../RiskDataCollectionPage/selector'
import { requestPrice } from '../App/sagas/priceSaga'
import { fetchQuote } from '../App/sagas/quoteSaga'
import { setUnderwriterChanged, setQuoteJourneyPosition } from '../App/actions'
import { priceCheckPaymentPageSaga } from '../PaymentPage/saga'
import { underwriterSaga } from 'containers/RiskDataCollectionPage/sagas/underwriter'
import { QuoteJourneyPosition } from '../../types/global'
import config from '../../config'
import useCustomerAccountFeatureFlag from 'hooks/useCustomerAccountFeatureFlag'
import { useStripe } from '@stripe/react-stripe-js'

function* createStripePayment({ payload }) {
  const riskData = yield select(getRiskData)
  const priceData = yield select(getPriceData)
  const queryString = yield select(getQueryString)
  const experiments = yield select(getExperiments)
  const isMultiPrice =
    Boolean(experiments[config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID]) ||
    false

  try {
    const result = yield call(
      paymentApi.createStripePayment,
      '/api/policy/payment/stripe',
      payload.paymentDetails,
    )
    handleServerResponse(
      result.data,
      payload.stripeActions,
      riskData,
      queryString,
      priceData,
      payload.actions,
      isMultiPrice,
      payload.useStripeElements,
    )
  } catch (e) {
    handleStripeErrors(e.response.status, payload.actions, queryString)
  }
}

function handleServerResponse(
  response,
  stripeActions,
  riskData,
  queryString,
  priceData,
  actions,
  isMultiPrice,
  useStripeElements,
) {
  const { QuoteId } = riskData
  const { EncryptedString, TotalPrice } = priceData
  if (response.PaymentStatus !== 200) {
    switch (response.PaymentStatus) {
      case 1:
        actions.createStripePaymentFailure(
          'We are sorry your payment has been declined. Please try again.',
        )
        actions.enableAllButtons()
        break
      case 2:
        actions.createStripePaymentFailure(
          'We are sorry there has been an error with your payment. Please try again.',
        )
        actions.enableAllButtons()
        break
      case 3:
        stripeActions
          .handleCardAction(response.PaymentIntent)
          .then((result) => {
            if (result.error) {
              // Show error in payment form
              actions.createStripePaymentFailure(result.error.message)
              actions.enableAllButtons()
            } else {
              const paymentDetails = {
                amount: TotalPrice.toFixed(2).toString().replace('.', ''),
                payment_intent_id: result.paymentIntent.id,
                QuoteId: {
                  Value: QuoteId,
                },
                ValidationData: {
                  Value: EncryptedString,
                },
                ExcessReductionIncluded:
                  priceData.ExcessReduction.ExcessReductionIncluded,
                BreakdownIncluded: priceData.BreakdownCover.BreakdownIncluded,
                LegalExpensesIncluded:
                  priceData.LegalExpensesCover.LegalExpensesIncluded,
                Free30DayGAPInsuranceIncluded:
                  priceData.FreeGAPInsuranceCover.Free30DayGAPInsuranceIncluded,
                AllowPriceComparison: isMultiPrice,
              }

              try {
                paymentApi
                  .createStripePayment(
                    '/api/policy/payment/stripe',
                    paymentDetails,
                  )
                  .then((response) => {
                    handleServerResponse(
                      response.data,
                      stripeActions,
                      riskData,
                      queryString,
                      priceData,
                      actions,
                      isMultiPrice,
                      useStripeElements,
                    )
                  })
              } catch (e) {
                handleStripeErrors(e.response, actions, queryString)
              }
            }
          })
        break
      case 8:
        actions.createStripePaymentFailure(
          'We are sorry your payment has been declined. Please use an alternative payment method and try again.',
        )
        actions.enableAllButtons()
        break
      case 9:
        actions.createStripePaymentFailure(
          'We are sorry your payment has been declined. Please double check your card details and try again.',
        )
        actions.enableAllButtons()
        break
      case 10:
        actions.createStripePaymentFailure(
          'We are already processing your payment. Please wait for further instruction.',
        )
        actions.enableAllButtons()
        break
      case 11:
        actions.createStripePaymentFailure(
          'We are sorry your payment has been declined. Please contact your card issuer.',
        )
        actions.enableAllButtons()
        break
      case 12:
        actions.createStripePaymentFailure(
          'We are sorry this payment method has expired. Please use an alternative and try again.',
        )
        actions.enableAllButtons()
        break
      case 13:
        actions.createStripePaymentFailure(
          'There has been a problem with your payment. Please try again.',
        )
        actions.enableAllButtons()
        break
      case 14:
        actions.createStripePaymentFailure(
          'There has been an issue with your payment. Please contact your card issuer.',
        )
        actions.enableAllButtons()
        break
      default:
        actions.createStripePaymentFailure(
          'There has been an issue with your payment. Please try again.',
        )
        actions.enableAllButtons()
        break
    }
  } else {
    history.push(`/policy/confirmation${queryString}&t=${Date.now()}`)
  }
}

const handleStripeErrors = (statusCode, actions, queryString) => {
  switch (statusCode) {
    case 400:
      actions.createStripePaymentFailure(
        'We are sorry there has been an issue. Please try your payment again.',
      )
      actions.enableAllButtons()
      break
    case 403:
      actions.fraudFailure()
      history.push({
        pathname: `/quote/driving-licence`,
        search: `${queryString}`,
        state: {
          fraudFailure: true,
        },
      })
      break
    default:
      actions.createStripePaymentFailure(
        'We are sorry there has been an issue. Please try your payment again.',
      )
      actions.enableAllButtons()
      break
  }
}

const startStripePayment = (
  stripeActions,
  actions,
  {
    riskData,
    addressData,
    priceData,
    stripeElement,
    isMultiPrice,
    useStripeElements,
  },
) => {
  let stripePromise = undefined

  if (useStripeElements) {
    stripeElement.submit()
    stripePromise = stripeActions.createPaymentMethod({
      elements: stripeElement,
      params: {
        billing_details: {
          name: riskData.name,
          address: {
            postal_code: addressData.Postcode,
            line1: addressData.AddressLineOne,
            line2: '',
            country: 'GB',
            city: addressData.city ?? '',
            state: addressData.state ?? '',
          },
        },
      },
    })
  } else {
    stripePromise = stripeActions.createPaymentMethod('card', stripeElement)
  }

  stripePromise.then((result) => {
    if (result.error) {
      // Show error in payment form
      actions.createStripePaymentFailure(result.error.message)
      actions.enableAllButtons()
    } else {
      const { TotalPrice, EncryptedString } = priceData
      const paymentDetails = {
        payment_method_id: result.paymentMethod.id,
        amount: TotalPrice.toFixed(2).toString().replace('.', ''),
        QuoteId: {
          value: riskData.QuoteId,
        },
        ExcessReductionIncluded:
          priceData.ExcessReduction.ExcessReductionIncluded,
        BreakdownIncluded: priceData.BreakdownCover.BreakdownIncluded,
        LegalExpensesIncluded:
          priceData.LegalExpensesCover.LegalExpensesIncluded,
        Free30DayGAPInsuranceIncluded:
          priceData.FreeGAPInsuranceCover.Free30DayGAPInsuranceIncluded,
        QuoteJourneyPosition: QuoteJourneyPosition.SystemPolicyPayment,
        ValidationData: {
          Value: EncryptedString,
        },
        AllowPriceComparison: isMultiPrice,
      }
      actions.createStripePayment({
        paymentDetails: paymentDetails,
        stripeActions,
        actions,
        useStripeElements,
      })
    }
  })
}

function* getPriceInfo({ payload }) {
  const {
    stripeActions,
    actions,
    stripeElement,
    isMultiPrice,
    useStripeElements,
  } = payload
  actions.setPaymentInProgress()
  const currentPriceData = yield select(getPriceData)
  yield put(setQuoteJourneyPosition(QuoteJourneyPosition.SystemPriceRefresh))

  yield* fetchQuote()
  yield* requestPrice()
  yield* underwriterSaga()
  const newPriceData = yield select(getPriceData)
  const riskData = yield select(getRiskData)
  const queryString = yield select(getQueryString)
  const addressData = yield select(getAddressData)
  if (!newPriceData.Underwriter && newPriceData.TotalPrice === 0) {
    actions.enableAllButtons()
    actions.setPaymentNotInProgress()
    return
  }
  if (
    currentPriceData.Underwriter.toLowerCase() !==
    newPriceData.Underwriter.toLowerCase()
  ) {
    yield* priceCheckPaymentPageSaga()
    yield put(setUnderwriterChanged())
    actions.priceMatch(true)
    actions.setPaymentNotInProgress()
    history.push({
      pathname: `/quote/driving-licence`,
      search: `${queryString}`,
    })
    actions.enableAllButtons()
  } else if (currentPriceData.TotalPrice !== newPriceData.TotalPrice) {
    actions.priceMatch(false)
    actions.setPaymentNotInProgress()
    history.push({
      pathname: `/quote/payment`,
      search: `${queryString}`,
    })
    actions.enableAllButtons()
    yield* priceCheckPaymentPageSaga()
  } else {
    // prices are correct
    startStripePayment(stripeActions, actions, {
      riskData,
      addressData,
      priceData: newPriceData,
      stripeElement,
      isMultiPrice,
      useStripeElements,
    })
  }
}

export default function* stripeSaga() {
  yield takeLatest(CREATE_STRIPE_PAYMENT, createStripePayment)
  yield takeLatest(GET_PRICE_INFO, getPriceInfo)
}
