import queryString from 'query-string'
import {
  REQUEST_VEHICLE,
  REQUEST_PREVIOUS_VEHICLE,
  GET_MAKES,
  GET_MODELS,
  GET_VARIANTS,
  CREATE_UNKNOWN_VEHICLE,
  SET_VEHICLE_CUSTOMER_ACCOUNT_LISTENER,
  COMMERCIAL_VEHICLE,
  PRIVATE_CAR,
  MOTORCYCLE,
} from './constants'
import {
  vehicleLinkSelector,
  getMakesLinkSelector,
  getModelsLinkSelector,
  getVariantsLinkSelector,
  transformVehicleType,
  transformVariants,
  createUnkownVehicleLinkSelector,
  getVariant,
  getSelectedVehicle,
  createUnkownCommercialVehicleLinkSelector,
  createUnkownMotorcycleVehicleLinkSelector,
} from './selector'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import vehicleApi from '../../api/vehicleApi'

import {
  requestVehicleFailed,
  requestVehicleSuccess,
  requestPreviousVehicleFailed,
  requestPreviousVehicleSuccess,
  getMakesSuccess,
  getModelsSuccess,
  getVariantsSuccess,
  createUnkownVehicleSuccess,
  getMakesFailure,
  getModelsFailure,
  getVariantsFailure,
  createUnkownVehicleFailure,
  unknownRegSuccess,
  unknownRegFailure,
  setVehicle,
  vehicleTypeChange,
} from './actions'
import { getVehicleInfo } from '../App/selector'
import {
  updateRiskData,
  updateValidation,
} from '../RiskDataCollectionPage/actions'
import {
  getRiskData,
  getQuoteId,
  getQueryString,
} from '../RiskDataCollectionPage/selector'
import { createQuoteSaga } from '../App/sagas/quoteSaga'
import { history } from '../..'
import { setQueryString } from '../App/actions'

export default function* fetchVehicleSaga({ registrationNumber }) {
  try {
    const vehicleLinkSelect = yield select(vehicleLinkSelector)
    let vehicleLink = vehicleLinkSelect.Href.replace(
      /(\{.+\})/g,
      registrationNumber,
    )
    const vehicle = yield call(vehicleApi.getVehicle, vehicleLink)
    yield put(requestVehicleSuccess(vehicle.data))
    return vehicle.status
  } catch (e) {
    const error = e.response.status === 400 ? 'InvalidReg' : 'NotFound'
    yield put(requestVehicleFailed(registrationNumber, error))
    return e.response.status
  }
}

export function* getMakesSaga() {
  try {
    const getMakesLinkSelect = yield select(getMakesLinkSelector)
    const vehicle = yield select(getVehicleInfo)
    let getMakesLink = getMakesLinkSelect.Href.replace(
      /{[vehicleType}]*}/,
      vehicle.searched.VehicleType,
    )
    let response = yield call(vehicleApi.getMakes, getMakesLink)
    const makes = transformVehicleType(response.data.Makes)
    yield put(getMakesSuccess(makes, response.data.Links))
  } catch (e) {
    yield put(getMakesFailure(e))
  }
}

export function* getModels() {
  try {
    const vehicle = yield select(getVehicleInfo)
    const getModelsLinkSelect = yield select(getModelsLinkSelector)
    const getModelsLink = getModelsLinkSelect.Href.replace(
      /{[make}]*}/,
      btoa(vehicle.searched.Make),
    )
    let response = yield call(vehicleApi.getModels, getModelsLink)
    const models = transformVehicleType(response.data.Models)
    yield put(getModelsSuccess(models, response.data.Links))
  } catch (e) {
    yield put(getModelsFailure(e))
  }
}

export function* getVariants() {
  try {
    const vehicle = yield select(getVehicleInfo)
    const getVariantsLinkSelect = yield select(getVariantsLinkSelector)
    const getVariantsLink = getVariantsLinkSelect.Href.replace(
      /{[vehicleType}]*}/,
      vehicle.searched.VehicleType,
    )
      .replace(/{[make}]*}/, btoa(vehicle.searched.Make))
      .replace(/{[model}]*}/, btoa(vehicle.searched.Model))
    let response = yield call(vehicleApi.getVariants, getVariantsLink)

    const variants = transformVariants(
      response.data.Variants,
      vehicle.searched.VehicleType,
    )
    yield put(getVariantsSuccess(variants, response.data.Links))
  } catch (e) {
    yield put(getVariantsFailure(e))
  }
}

export function* createUnknownVehicle() {
  try {
    let vehicle = yield select(getVehicleInfo)
    let { searched } = vehicle

    const { data, createVehicleLink } = yield buildUnknownVehicleLink(searched)

    let manuallyBuiltVehicle = yield call(
      vehicleApi.createUnknownVehicle,
      createVehicleLink,
      data,
    )

    const risk = yield select(getRiskData)
    if (risk.Impounded) {
      manuallyBuiltVehicle.data.Impounded = true
      yield put(
        updateValidation({
          BodyTypeError: '',
          UseOfVehicleError: '',
          OccupationError: '',
        }),
      )
    }

    vehicle.IsLearnerVehicle = risk.Learner

    yield put(createUnkownVehicleSuccess(manuallyBuiltVehicle.data))
    yield* setVehicleSaga()
  } catch (e) {
    yield put(createUnkownVehicleFailure(e))
  }
}

export function* createUnknownVehicleFromUnknownReg(unknownVehicle) {
  try {
    const { data, createVehicleLink } = yield buildUnknownVehicleLink(
      unknownVehicle,
    )
    let manuallyBuiltVehicle = yield call(
      vehicleApi.createUnknownVehicle,
      createVehicleLink,
      data,
    )

    yield put(unknownRegSuccess(manuallyBuiltVehicle.data, unknownVehicle))
    let vehicle = yield select(getSelectedVehicle)

    if (unknownVehicle.Variant) {
      yield call(getVariants)
      const variant = yield select(getVariant)
      vehicle.Variant = variant.description
    }

    const risk = yield select(getRiskData)
    if (risk.Impounded) {
      vehicle.Impounded = true
      yield put(
        updateValidation({
          BodyTypeError: '',
          UseOfVehicleError: '',
          OccupationError: '',
        }),
      )
    }

    vehicle.IsLearnerVehicle = risk.Learner

    yield put(setVehicle(vehicle))
  } catch (e) {
    yield put(unknownRegFailure(e))
  }
}

export function* buildUnknownVehicleLink(vehicle) {
  let data = {}
  let createVehicleLink = ''
  switch (vehicle.VehicleType) {
    case PRIVATE_CAR:
      data = {
        AbiCode: vehicle.Abi,
        IsRegistrationKnown: !!vehicle.RegistrationNumber,
        Registration: vehicle.RegistrationNumber,
      }
      const createUnknownVehicleLinkSelect = yield select(
        createUnkownVehicleLinkSelector,
      )
      createVehicleLink = createUnknownVehicleLinkSelect.Href
      break
    case COMMERCIAL_VEHICLE:
      data = {
        AbiCode: vehicle.Abi,
        IsRegistrationKnown: !!vehicle.RegistrationNumber,
        Weight: vehicle.GrossWeight,
        YearOfManufacture: vehicle.YearOfManufacture,
        Registration: vehicle.RegistrationNumber,
      }
      const createUnknownCommercialVehicleLinkSelect = yield select(
        createUnkownCommercialVehicleLinkSelector,
      )
      createVehicleLink = createUnknownCommercialVehicleLinkSelect.Href
      break
    case MOTORCYCLE:
      data = {
        AbiCode: vehicle.Abi,
        IsRegistrationKnown: !!vehicle.RegistrationNumber,
        Registration: vehicle.RegistrationNumber,
      }
      const createUnknownMotorcycleVehicleLinkSelect = yield select(
        createUnkownMotorcycleVehicleLinkSelector,
      )
      createVehicleLink = createUnknownMotorcycleVehicleLinkSelect.Href
      break
    default:
      break
  }

  return { data, createVehicleLink }
}

export function* setVehicleSaga() {
  const vehicle = yield select(getVehicleInfo)
  const risk = yield select(getRiskData)
  const { searched, selected } = vehicle
  switch (searched.VehicleType) {
    case COMMERCIAL_VEHICLE:
      yield put(
        updateValidation({
          BodyTypeError: !risk.BodyType.value ? null : '',
          UseOfVehicleError: !risk.UseOfVehicle.value ? null : '',
          OccupationError: !risk.Occupation.Id ? null : '',
        }),
      )
      break
    case PRIVATE_CAR:
      yield put(
        updateValidation({
          BodyTypeError: '',
          UseOfVehicleError: '',
          OccupationError: '',
        }),
      )
      break
    case MOTORCYCLE:
      yield put(
        updateRiskData({
          Motorbike: true,
        }),
      )
      yield put(
        updateValidation({
          UseOfVehicleError: !risk.MotorcycleUseOfVehicle.value || '',
          OccupationError: !risk.Occupation.Id || '',
          MotorcycleEntitlementError: !risk.MotorcycleEntitlement.value || '',
          MotorcycleRidingExperienceError:
            !risk.MotorcycleRidingExperience.value || '',
          MotorcycleOvernightParkingError:
            !risk.MotorcycleOvernightParking.value || '',
          MotorcycleReasonForTempcoverPurchaseError:
            !risk.MotorcycleReasonForTempcoverPurchase.value || '',
          PreviouslyRiddenMotorcycleVehicleError:
            !risk.PreviouslyRiddenMotorcycleVehicle || '',
        }),
      )
      break
    default:
      break
  }
  if (risk.Impounded) {
    searched.Impounded = true
    yield put(
      updateValidation({
        BodyTypeError: '',
        UseOfVehicleError: '',
        OccupationError: '',
      }),
    )
  }
  searched.Courier = risk.Courier
  searched.IsLearnerVehicle = risk.Learner
  if (
    searched.RegistrationNumber !== selected.RegistrationNumber ||
    searched.Abi !== selected.Abi
  ) {
    yield put(setVehicle(searched))
    if (searched.VehicleType !== selected.VehicleType && selected.VehicleType) {
      //yield* vehicleTypeChangeSaga()
    }
  }
}

export function* vehicleTypeChangeSaga() {
  const parsedQueryString = queryString.parse(window.location.search)
  yield put(vehicleTypeChange())
  yield* createQuoteSaga()

  const quoteId = yield select(getQuoteId)
  yield put(
    setQueryString(
      '?q=' +
        quoteId +
        (parsedQueryString.ref ? '&ref=' + parsedQueryString.ref : ''),
    ),
  )
  const stateQueryString = yield select(getQueryString)
  history.push(`/quote/driver-details${stateQueryString}`)
}

export function* fetchVehicleCustomerAccountListenerSaga() {
  yield takeLatest(REQUEST_VEHICLE, fetchVehicleSaga)
  yield takeLatest(GET_MAKES, getMakesSaga)
  yield takeLatest(GET_MODELS, getModels)
  yield takeLatest(GET_VARIANTS, getVariants)
  yield takeLatest(CREATE_UNKNOWN_VEHICLE, createUnknownVehicle)
  yield takeLatest(SET_VEHICLE_CUSTOMER_ACCOUNT_LISTENER, setVehicleSaga)
}
