import { usePrice, useAddons } from 'hooks'
import React, { useMemo } from 'react'
import {
  BrandonText,
  PriceWrapper,
  TotalPriceText,
  LegalText,
  PriceBreakdownDiscount,
} from './PriceBreakdown.styles'

const PriceBreakdown = () => {
  const { totalPriceFixed, price } = usePrice()
  const { excess, breakdown, legalexpenses, gapinsurance } = useAddons()

  const positiveDiscountValue = useMemo(
    () => Math.abs(price.campaign.Discount),
    [price.campaign.Discount],
  )

  const discountedTotalPrice = useMemo(
    () => `£${(+totalPriceFixed + positiveDiscountValue).toFixed(2)}`,
    [totalPriceFixed, positiveDiscountValue],
  )

  const hasAffiliateDiscount = useMemo(
    () => positiveDiscountValue > 0,
    [positiveDiscountValue],
  )

  const priceBreakdownText = useMemo(() => {
    const prices: string[] = [
      `Insurer premium £${(price.InsurancePremium + price.Ipt).toFixed(2)}`,
      `Tempcover fee £${price.AdminFee.toFixed(2)}`,
    ]
    if (breakdown && breakdown.selected) {
      prices.push(
        `RAC UK Breakdown Cover £${(
          price.BreakdownCover.BreakdownPremiumGross +
          price.BreakdownCover.BreakdownIPT
        ).toFixed(2)}`,
      )
    }
    if (excess && excess.selected) {
      prices.push(
        `Excess Reduction £${(
          price.ExcessReduction.ExcessReductionPremiumGross +
          price.ExcessReduction.ExcessReductionIPT
        ).toFixed(2)}`,
      )
    }
    if (legalexpenses && legalexpenses.selected) {
      prices.push(
        `Legal Expenses Cover £${(
          price.LegalExpensesCover.LegalExpensesPremiumGross +
          price.LegalExpensesCover.LegalExpensesIPT
        ).toFixed(2)}`,
      )
    }
    if (gapinsurance && gapinsurance.selected) {
      prices.push('Free 30 Day GAP Insurance')
    }
    if (price.AffiliateAdminFee > 0) {
      prices.push(`Introducer fee £${price.AffiliateAdminFee.toFixed(2)}`)
    }

    return prices.join(', ')
  }, [price, breakdown, excess, legalexpenses, gapinsurance])

  return (
    <>
      <PriceWrapper>
        <BrandonText>Total</BrandonText>
        <TotalPriceText>£{totalPriceFixed}</TotalPriceText>
        {hasAffiliateDiscount && (
          <p style={{ color: 'red', fontWeight: 'bold' }}>
            WAS <del>{discountedTotalPrice}</del>
          </p>
        )}
      </PriceWrapper>
      <LegalText id='priceBreakdownText'>
        The total payable of £{totalPriceFixed} for your insurance comprises of{' '}
        {priceBreakdownText}.{' '}
        {!!(Math.abs(price.campaign.Discount) > 0) && (
          <>
            Discount applied{' '}
            <PriceBreakdownDiscount>
              £{price.campaign.Discount.toFixed(2)}
            </PriceBreakdownDiscount>
            .
          </>
        )}
      </LegalText>
    </>
  )
}

export default PriceBreakdown
