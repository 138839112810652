import {
  REQUEST_ADDRESS,
  REQUEST_ADDRESS_SUCCESS,
  REQUEST_ADDRESS_FAILED,
  UPDATE_POSTCODE,
  SELECT_ADDRESS,
} from './constants'

export function updatePostcode(postcode) {
  return {
    type: UPDATE_POSTCODE,
    postcode,
  }
}

export function requestAddress() {
  return {
    type: REQUEST_ADDRESS,
  }
}

export function requestAddressSuccess(addresses) {
  return {
    type: REQUEST_ADDRESS_SUCCESS,
    addresses,
  }
}

export function requestAddressFailed(message) {
  return {
    type: REQUEST_ADDRESS_FAILED,
    message,
  }
}

export function selectAddress(address) {
  return {
    type: SELECT_ADDRESS,
    address,
  }
}
