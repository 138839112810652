export const REQUEST_OCCUPATIONS = 'src/Occupation/REQUEST_OCCUPATIONS'
export const REQUEST_OCCUPATIONS_SUCCESS =
  'src/Occupation/REQUEST_OCCUPATIONS_SUCCESS'
export const REQUEST_OCCUPATIONS_FAILED =
  'src/Occupation/REQUEST_OCCUPATIONS_FAILED'

export const SELECT_OCCUPATION = 'src/Occupation/SELECT_OCCUPATION'

export const UPDATE_OCCUPATION_SEARCH =
  'src/Occupation/UPDATE_OCCUPATION_SEARCH'

export const REQUEST_OCCUPATIONS_REL = 'GetOccupations'
