import React, { useState } from 'react'
import { Button } from 'components/common/Button'
import { Label } from 'components/common/Label'
import {
  DiscountAreaHeader,
  DiscountContainer,
  DiscountVoucherContainer,
  TextInputStyled,
} from './DiscountVoucher.styles'

const DiscountVoucher: React.FC = () => {
  const [discountApplied, setDiscountApplied] = useState(false)
  const [discountSuccess, setDiscountSuccess] = useState(false)
  const [discountCode, setDiscountCode] = useState('')

  const setDiscountValue = (e) => {
    setDiscountCode(e.target.value)
  }

  const applyDiscount = () => {
    setDiscountApplied(true)
    if (discountCode === 'test') {
      setDiscountSuccess(true)
    } else {
      setDiscountSuccess(false)
    }
  }

  return (
    <DiscountVoucherContainer>
      <DiscountAreaHeader>Discount code</DiscountAreaHeader>
      <DiscountContainer>
        <TextInputStyled
          placeholder='DiscountCode'
          id='voucherDiscountCode'
          name='voucherDiscountCode'
          onChange={(e) => setDiscountValue(e)}
        />
        <Button id='applyDiscount' onClick={applyDiscount}>
          Apply discount
        </Button>
      </DiscountContainer>
      {discountApplied && (
        <>
          {discountSuccess && (
            <Label className='success-code'>
              Discount successfully applied
            </Label>
          )}
          {!discountSuccess && (
            <Label className='error-code'>Invalid code</Label>
          )}
        </>
      )}
    </DiscountVoucherContainer>
  )
}

export default DiscountVoucher
