import PolicyApi from 'api/policyApi'
import { useQuery } from 'react-query'

const useFreeGapActivationJourney = (quoteId: string) =>
  useQuery(
    ['useFreeGapActivationJourney', quoteId],
    () => PolicyApi.getQuoteHasFreeGapActivation(quoteId),
    {
      enabled: !!quoteId,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

export default useFreeGapActivationJourney
