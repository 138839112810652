import EditLink from 'components/EditLink'
import { useAddress, usePrice, useQueryString, useRiskData } from 'hooks'
import useCustomerAccount from 'hooks/useCustomerAccount'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import config from 'config'
// import useCustomerAccountFeatureFlag from 'hooks/useCustomerAccountFeatureFlag'
import { QUOTE_URL } from '../../services/constants'
import DriverDetailItem from './components/DriverDetailItem/DriverDetailItem'
import PriceDetails from './components/PriceDetails'
import {
  DriverDetailItemsWrapper,
  DriverDetailItemWrapper,
  DriverDetailsHeaderWrapper,
  DriverDetailsInlineWrapper,
  Wrapper,
} from './DriverDetailsCard.style'

export const DriverDetailsCard: React.FC = () => {
  const { queryString } = useQueryString()
  const { riskData } = useRiskData()
  const { price } = usePrice()
  const {
    selectedAddress,
    addressLineOne,
    addressLineTwo,
    addressLineThree,
    addressLineFour,
    postcode,
  } = useAddress()
  const formattedAddress = `${addressLineOne!} ${addressLineTwo!} ${addressLineThree!} ${addressLineFour!} ${postcode!}`

  const { isCustomerAccountQuote } = useCustomerAccount()

  // const { data: featureFlagEnabled, isLoading: featureFlagLoaded } =
  // useCustomerAccountFeatureFlag('EditCustomerAccountDetails')

  const addressInfo = selectedAddress || formattedAddress
  return (
    <Wrapper hasPrice={price.TotalPrice > 0}>
      {price.TotalPrice > 0 && (
        <>
          <PriceDetails />
          <hr />
        </>
      )}
      <DriverDetailsHeaderWrapper>
        <h3>Your details</h3>
        {!isCustomerAccountQuote && (
          <Link
            id='AmendDetails'
            to={`${QUOTE_URL}/driver-details${queryString}`}
          >
            <EditLink>Edit</EditLink>
          </Link>
        )}
        {isCustomerAccountQuote && (
          // !featureFlagLoaded &&
          // featureFlagEnabled &&
          // featureFlagEnabled?.data &&
          <a
            id='CustomerAccountAmendDetails'
            href={`${config.CUSTOMER_ACCOUNT_URL_HOME}account/details/edit?redirect=quote`}
          >
            <EditLink>Edit</EditLink>
          </a>
        )}
      </DriverDetailsHeaderWrapper>
      <DriverDetailItemsWrapper>
        <DriverDetailsInlineWrapper>
          <DriverDetailItemWrapper>
            <DriverDetailItem
              label='Name'
              value={`${riskData.Forename} ${riskData.Surname}`}
            />
          </DriverDetailItemWrapper>

          {riskData.DateOfBirth && (
            <DriverDetailItemWrapper>
              <DriverDetailItem
                label='Date of birth'
                value={moment(riskData.DateOfBirth).format('DD/MM/YYYY')}
              />
            </DriverDetailItemWrapper>
          )}
          <DriverDetailItemWrapper>
            {riskData.LicenceHeldDuration.description &&
              !riskData.Motorbike && (
                <DriverDetailItem
                  label='Licence held for'
                  value={`${riskData.LicenceHeldDuration.description}`}
                />
              )}
            {riskData.Motorbike &&
              riskData.MotorcycleEntitlement.description && (
                <DriverDetailItem
                  label='Motorbike entitlement:'
                  value={`${riskData.MotorcycleEntitlement.description}`}
                />
              )}
          </DriverDetailItemWrapper>
        </DriverDetailsInlineWrapper>
        <DriverDetailItem label='Address' value={addressInfo} />
      </DriverDetailItemsWrapper>
    </Wrapper>
  )
}

export default DriverDetailsCard
