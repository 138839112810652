import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  requestVehicle,
  updateSearchedVehicle,
  getModels,
  getVariants,
  createUnkownVehicle,
  setVehicle,
  getMakes,
  setVehicleType,
  updateGrossWeight,
  updateYearOfManufacture,
} from '../Vehicle/actions'
import { Button } from '../../components/common/Button'
import { Select } from '../../components/common/Select'
import { VehicleRegistration } from '../../components/VehicleRegistration'
import { ErrorContainer } from '../../components/ErrorContainer'
import { FormControlValidIcon } from '../../components/FormControlValidIcon'
import { RegistrationWrapper } from '../../components/VehicleRegistration/styles'
import { VehicleButtonWrapper, VehicleParagraph } from './styles'
import { VehicleBuilder } from '../../components/VehicleBuilder'
import { GrossWeight } from '../../components/GrossWeight'
import { YearOfManufacture } from '../../components/YearOfManufacture'
import { VehicleTypeMessage } from '../../components/VehicleTypeMessage'
import {
  ALLOWED_VEHICLE_TYPES,
  COMMERCIAL_VEHICLE,
  PRIVATE_CAR,
  COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT,
  MOTORCYCLE,
} from './constants'
import { history } from '../../'
import { P } from 'components/common/P'

export class Vehicle extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
    }

    this.onChange = this.onChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.vehicle.searched.VehicleType &&
      prevProps.vehicle.searched.VehicleType !==
        this.props.vehicle.searched.VehicleType &&
      this.props.vehicle.error === 'NotFound'
    ) {
      this.props.actions.getMakes()
    }
  }

  updateVehicleTypeParams(params) {
    const query = new URLSearchParams(params)
    const hasVehicleType = query.has('VehicleType')

    if (hasVehicleType) {
      if (
        this.props.vehicle.searched &&
        this.props.vehicle.searched.VehicleType
      ) {
        query.set('VehicleType', this.props.vehicle.searched.VehicleType)
        history.replace(`?${query.toString()}`)
      }
    }
  }

  onChange = (event, message) => {
    switch (event.target.name) {
      case 'RegistrationInput':
        this.props.actions.updateSearchedVehicle({
          RegistrationNumber: event.target.value.trim().replace(/\s/g, ''),
          VehicleId: null,
          Make: null,
          Model: null,
          VehicleType: null,
          Abi: null,
          MakeAndModel: null,
          DateFirstRegistered: null,
          Variant: null,
          GrossWeight: null,
          YearOfManufacture: null,
        })
        this.setState({
          error: message,
        })
        break
      case 'GrossWeight':
        this.props.actions.updateGrossWeight(event.target.value)
        break
      default:
        break
    }
  }

  handleSearch() {
    this.props.actions.requestVehicle(
      this.props.vehicle.searched.RegistrationNumber,
    )
  }

  handleSelect = (event) => {
    const index = event.nativeEvent.target.selectedIndex
    switch (event.target.name) {
      case 'vehicleType':
        this.props.actions.setVehicleType(event.target.value)
        this.props.actions.getMakes()
        break
      case 'makes':
        this.props.actions.updateSearchedVehicle({
          Make: event.target.value,
        })
        this.props.actions.getModels()
        break
      case 'models':
        this.props.actions.updateSearchedVehicle({
          Model: event.target.value,
        })
        this.props.actions.getVariants()
        break
      case 'variants':
        this.props.actions.updateSearchedVehicle({
          Abi: event.target.value,
          Variant: event.nativeEvent.target[index].text,
        })
        break
      default:
        break
    }
  }

  handleDateSet = (e) => {
    this.props.actions.updateYearOfManufacture(e.target.value)
  }

  validVehicle() {
    const { searched } = this.props.vehicle
    const isVehicleFoundAndAllowed =
      searched.VehicleFound &&
      ALLOWED_VEHICLE_TYPES.includes(searched.VehicleType)
    let isVehicleBuilt = false

    switch (searched.VehicleType) {
      case PRIVATE_CAR:
        isVehicleBuilt =
          searched.VehicleFound === false &&
          searched.Make &&
          searched.Model &&
          searched.Abi
        break
      case COMMERCIAL_VEHICLE:
        if (searched.GrossWeight > COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) {
          isVehicleBuilt =
            searched.VehicleFound === false &&
            searched.GrossWeight &&
            searched.YearOfManufacture
        } else if (
          searched.GrossWeight <= COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT
        ) {
          isVehicleBuilt =
            searched.VehicleFound === false &&
            searched.GrossWeight &&
            searched.Make &&
            searched.Model &&
            searched.Abi &&
            searched.YearOfManufacture
        }
        break
      case MOTORCYCLE:
        isVehicleBuilt =
          searched.VehicleFound === false &&
          searched.Make &&
          searched.Model &&
          searched.Abi
        break
      default:
        break
    }
    return isVehicleFoundAndAllowed || isVehicleBuilt
  }

  handleJourney = (searchedVehicle, risk) => {
    const { Learner, Motorbike, Impounded, Expat, Courier } = risk
    const { VehicleType } = searchedVehicle

    switch (VehicleType) {
      case PRIVATE_CAR:
        if (Impounded) return true

        if (Motorbike) {
          this.setState({ error: true })
          return false
        }
        if (Courier) {
          this.setState({ error: true })
          return false
        }

        return true
      case COMMERCIAL_VEHICLE:
        if (Learner) {
          this.setState({ error: true })
          return false
        }

        if (Motorbike) {
          this.setState({ error: true })
          return false
        }

        return true
      case MOTORCYCLE:
        if (Impounded) {
          this.setState({ error: true })
          return false
        }

        if (Expat) {
          this.setState({ error: true })
          return false
        }
        if (Courier) {
          this.setState({ error: true })
          return false
        }
        return true
      default:
        return false
    }
  }

  handleVehicleTypeMessage = (searched, risk, urlParams) => {
    const params = new URLSearchParams(urlParams)
    const impoundedVehicle = risk.Impounded
    let journeyType = 'Standard'
    if (impoundedVehicle) {
      journeyType = 'Impounded'
    } else if (risk.Motorbike) {
      journeyType = 'Motorcycle'
    } else if (risk.Learner) {
      journeyType = 'Learner'
    } else if (risk.Expat) {
      journeyType = 'Expat'
    } else if (risk.Courier) {
      journeyType = 'Courier'
    }

    const vehicleType = searched.VehicleType
    const urlVehicleType = params.get('VehicleType') || vehicleType

    const incompatibleJourney = journeyType !== urlVehicleType

    // Clears the error from showing when input changes
    if (!vehicleType) return

    const learnerCommercialWording = `We’re not able to insure learner drivers to drive commercial vehicles at the moment, please enter a commercial reg number to continue.`
    const learnerMotorcycleWording = `We’re not able to insure learner drivers to ride motorcycles at the moment. Please enter a car reg number to continue.`
    const impoundedMotorcycleWording = `We’re not able to insure impounded motorcycles at the moment. Please enter a car or van reg number to continue.`
    const expatMotorcycleWording = `We’re not able to insure expat drivers to ride motorcycles at the moment. Please enter a car or van reg number to continue.`
    const motorcycleWording =
      'This is not a motorcycle registration number. Please check and enter the reg again.'
    const courierWording =
      'We are only able to cover commercial vehicles on our courier scheme at the moment. Please check and enter the reg again.'

    if (incompatibleJourney) {
      switch (journeyType) {
        case 'Standard':
          return true
        case MOTORCYCLE:
          return (
            <VehicleTypeMessage
              message={motorcycleWording}
              vehicleType={vehicleType}
              registration={this.props.vehicle.searched.RegistrationNumber}
              duration={`${this.props.duration.value}+${this.props.duration.type}`}
            />
          )
        case 'Learner':
          switch (vehicleType) {
            case PRIVATE_CAR:
              return true
            case COMMERCIAL_VEHICLE:
              return (
                <VehicleTypeMessage
                  message={learnerCommercialWording}
                  vehicleType={vehicleType}
                  registration={this.props.vehicle.searched.RegistrationNumber}
                  duration={`${this.props.duration.value}+${this.props.duration.type}`}
                />
              )
            case MOTORCYCLE:
              return (
                <VehicleTypeMessage
                  message={learnerMotorcycleWording}
                  vehicleType={vehicleType}
                  registration={this.props.vehicle.searched.RegistrationNumber}
                  duration={`${this.props.duration.value}+${this.props.duration.type}`}
                />
              )
            default:
              return false
          }
        case 'Courier':
          switch (vehicleType) {
            case COMMERCIAL_VEHICLE:
              return true
            case PRIVATE_CAR:
              return (
                <VehicleTypeMessage
                  message={courierWording}
                  vehicleType={vehicleType}
                  registration={this.props.vehicle.searched.RegistrationNumber}
                  duration={`${this.props.duration.value}+${this.props.duration.type}`}
                />
              )
            case MOTORCYCLE:
              return (
                <VehicleTypeMessage
                  message={courierWording}
                  vehicleType={vehicleType}
                  registration={this.props.vehicle.searched.RegistrationNumber}
                  duration={`${this.props.duration.value}+${this.props.duration.type}`}
                />
              )
            default:
              return false
          }
        case 'Impounded':
          if (vehicleType === MOTORCYCLE) {
            return (
              <VehicleTypeMessage
                message={impoundedMotorcycleWording}
                vehicleType={vehicleType}
                registration={this.props.vehicle.searched.RegistrationNumber}
                duration={`${this.props.duration.value}+${this.props.duration.type}`}
              />
            )
          }
          return false
        case 'Expat':
          if (vehicleType === MOTORCYCLE) {
            return (
              <VehicleTypeMessage
                message={expatMotorcycleWording}
                vehicleType={vehicleType}
                registration={this.props.vehicle.searched.RegistrationNumber}
                duration={`${this.props.duration.value}+${this.props.duration.type}`}
              />
            )
          }
          return false
        default:
          return true
      }
    }
  }

  vehicleSelectionOptions() {
    const { Learner, Motorbike, Expat } = this.props.riskData

    if (Learner) {
      return [
        {
          id: 1,
          description: 'Car',
          value: PRIVATE_CAR,
        },
      ]
    } else if (Motorbike) {
      return [
        {
          id: 3,
          description: 'Motorcycle',
          value: MOTORCYCLE,
        },
      ]
    } else if (Expat) {
      return [
        {
          id: 1,
          description: 'Car',
          value: PRIVATE_CAR,
        },
        {
          id: 2,
          description: 'Commercial vehicle',
          value: COMMERCIAL_VEHICLE,
        },
      ]
    } else {
      return [
        {
          id: 1,
          description: 'Car',
          value: PRIVATE_CAR,
        },
        {
          id: 2,
          description: 'Commercial vehicle',
          value: COMMERCIAL_VEHICLE,
        },
        {
          id: 3,
          description: 'Motorcycle',
          value: MOTORCYCLE,
        },
      ]
    }
  }

  render() {
    const {
      Learner: isLearner,
      Motorbike: isMotorbike,
      Expat: isExpat,
      Courier: isCourier,
    } = this.props.riskData
    const { VehicleType } = this.props.vehicle.searched

    if (VehicleType) this.updateVehicleTypeParams(window.location.search)

    return (
      <>
        <P>To get started, tell us the reg of the vehicle you want cover for</P>
        <RegistrationWrapper>
          <FormControlValidIcon
            error={this.state.error}
            className='fas fa-check'
          ></FormControlValidIcon>
          <VehicleRegistration
            value={this.props.vehicle.searched.RegistrationNumber}
            onChange={this.onChange}
            error={this.state.error}
          />
          <Button
            id='SearchVehicle'
            onClick={this.handleSearch}
            disabled={!!this.state.error}
            type='button'
          >
            Search
          </Button>
        </RegistrationWrapper>
        {this.props.vehicle.error === 'InvalidReg' && (
          <ErrorContainer>
            The above registration number is invalid, please try again
          </ErrorContainer>
        )}
        {this.props.vehicle.searched.VehicleFound &&
          ALLOWED_VEHICLE_TYPES.includes(VehicleType) && (
            <VehicleParagraph>
              Vehicle:&nbsp;
              {this.props.vehicle.searched.Make}{' '}
              {this.props.vehicle.searched.Model}
            </VehicleParagraph>
          )}
        {this.props.vehicle.error === 'NotFound' && (
          <>
            <P>
              Sorry we've not found that vehicle, please select from the options
              below
            </P>
            <Select
              name='vehicleType'
              placeholder='My vehicle is a...'
              onChange={this.handleSelect}
              options={this.vehicleSelectionOptions()}
              value={VehicleType}
            />
            {VehicleType && (
              <>
                {VehicleType === COMMERCIAL_VEHICLE && (
                  <GrossWeight
                    onChange={this.onChange}
                    value={this.props.vehicle.searched.GrossWeight}
                  />
                )}
                {(VehicleType === PRIVATE_CAR ||
                  parseInt(this.props.vehicle.searched.GrossWeight) <=
                    COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) && (
                  <VehicleBuilder
                    searchedVehicle={this.props.vehicle.searched}
                    vehicleOptions={{
                      makes: this.props.vehicle.makes,
                      models: this.props.vehicle.models,
                      variants: this.props.vehicle.variants,
                    }}
                    handleSelect={this.handleSelect}
                  />
                )}
                {VehicleType === MOTORCYCLE && (
                  <VehicleBuilder
                    searchedVehicle={this.props.vehicle.searched}
                    vehicleOptions={{
                      makes: this.props.vehicle.makes,
                      models: this.props.vehicle.models,
                      variants: this.props.vehicle.variants,
                    }}
                    handleSelect={this.handleSelect}
                  />
                )}
                {VehicleType === COMMERCIAL_VEHICLE && (
                  <YearOfManufacture
                    handleDateSet={this.handleDateSet}
                    value={this.props.vehicle.searched.YearOfManufacture}
                  />
                )}
              </>
            )}
          </>
        )}
        {this.props.vehicle.searched.VehicleFound &&
          !ALLOWED_VEHICLE_TYPES.includes(VehicleType) && (
            <>
              <p>
                Sorry, we're busy building our new and improved system and we're
                are only able to provide cover for cars with this system at the
                moment.
              </p>
            </>
          )}
        {(isLearner ||
          isMotorbike ||
          isExpat ||
          isCourier ||
          window.location.search.indexOf('VehicleType=') >= 0) && (
          <>
            {this.handleVehicleTypeMessage(
              this.props.vehicle.searched,
              this.props.riskData,
              window.location.search,
            )}
          </>
        )}
        {this.handleJourney(this.props.vehicle.searched, this.props.riskData) &&
          this.validVehicle() && (
            <VehicleButtonWrapper>
              <Button
                id='SetVehicle'
                onClick={this.props.handleConfirm}
                type='button'
              >
                Set vehicle
              </Button>
            </VehicleButtonWrapper>
          )}
      </>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    riskData: state.riskData,
    vehicle: state.vehicle,
    validation: state.vehicle.validation,
    duration: state.duration,
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateSearchedVehicle,
        requestVehicle,
        getMakes,
        getModels,
        getVariants,
        createUnkownVehicle,
        setVehicle,
        setVehicleType,
        updateGrossWeight,
        updateYearOfManufacture,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle)
