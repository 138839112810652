import { LicenceDuration } from 'types/global'

export const LICENCE_HELD_DURATIONS: LicenceDuration[] = [
  {
    id: 8,
    value: 'ZeroToFiveMonths',
    description: '0 to 5 months',
    yearDiff: 0,
    order: 0,
  },
  {
    id: 1,
    value: 'SixToElevenMonths',
    description: '6 to 11 months',
    yearDiff: 0.5,
    order: 1,
  },
  {
    id: 2,
    value: 'OneYear',
    description: '1 year',
    yearDiff: 1,
    order: 2,
  },
  {
    id: 3,
    value: 'TwoYears',
    description: '2 years',
    yearDiff: 2,
    order: 3,
  },
  {
    id: 4,
    value: 'ThreeYears',
    description: '3 years',
    yearDiff: 3,
    order: 4,
  },
  {
    id: 5,
    value: 'FourYears',
    description: '4 years',
    yearDiff: 4,
    order: 5,
  },
  {
    id: 6,
    value: 'FiveToNineYears',
    description: '5-9 years',
    yearDiff: 5,
    order: 6,
  },
  {
    id: 7,
    value: 'TenYearsOrMore',
    description: '10 years +',
    yearDiff: 10,
    order: 7,
  },
]

export const LEARNER_LICENCE_HELD_DURATIONS: LicenceDuration[] = [
  {
    id: 1,
    value: 'LessThanOneMonth',
    description: '< 1 month',
    yearDiff: 0,
    order: 1,
  },
  {
    id: 2,
    value: 'OneToFiveMonths',
    description: '1-5 months',
    yearDiff: 1 / 12,
    order: 2,
  },
  {
    id: 3,
    value: 'SixToElevenMonths',
    description: '6-11 months',
    yearDiff: 6 / 12,
    order: 3,
  },
  {
    id: 4,
    value: 'OneYear',
    description: '1 year',
    yearDiff: 1,
    order: 4,
  },
  {
    id: 5,
    value: 'TwoYears',
    description: '2 years',
    yearDiff: 2,
    order: 5,
  },
  {
    id: 6,
    value: 'ThreeYears',
    description: '3 years',
    yearDiff: 3,
    order: 6,
  },
  {
    id: 7,
    value: 'FourYears',
    description: '4 years',
    yearDiff: 4,
    order: 7,
  },
  {
    id: 8,
    value: 'FiveYears',
    description: '5 years',
    yearDiff: 5,
    order: 8,
  },
  {
    id: 9,
    value: 'SixYearsOrMore',
    description: '6+ years',
    yearDiff: 6,
    order: 9,
  },
]
