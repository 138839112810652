import http from './http'

export default class affiliateApi {
  static fetchAffiliate(endpoint) {
    return http.get(endpoint)
  }
  static getAffiliate(endpoint) {
    return http.get(endpoint)
  }
}
