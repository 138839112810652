import React, { useEffect } from 'react'
import config from 'config'
import useCustomerAccountFeatureFlag from 'hooks/useCustomerAccountFeatureFlag'
import useCustomerAccount from 'hooks/useCustomerAccount'
export interface Props {
  customerId: string
  forename: string
  surname: string
  email: string
  price: number
}

const MentionMe: React.FC<Props> = ({
  customerId,
  forename,
  surname,
  email,
  price,
}) => {
  const { data: featureFlagEnabled, isLoading: featureFlagLoaded } =
    useCustomerAccountFeatureFlag('MentionMePocEnabled')

  const { isCustomerAccountQuote } = useCustomerAccount()

  useEffect(() => {
    if (
      !featureFlagLoaded &&
      featureFlagEnabled &&
      featureFlagEnabled?.data &&
      isCustomerAccountQuote &&
      forename !== '' &&
      surname !== '' &&
      email !== '' &&
      price > 0
    ) {
      const script = document.createElement('script')
      const divEl = document.createElement('div')

      divEl.setAttribute('id', 'mmWrapper')

      const body = document.getElementsByTagName('body')[0]

      const scriptPath = `${config.MENTION_ME_URL}/api/v2/referreroffer/${
        config.MENTION_ME_ID
      }?firstname=${encodeURI(forename)}&surname=${encodeURI(
        surname,
      )}&email=${encodeURI(
        email,
      )}&signup_id=${customerId}&order_subtotal=${price}&order_currency=GBP&situation=postpurchase&locale=en_GB`

      script.src = scriptPath

      body.appendChild(divEl)
      body.appendChild(script)
    }
  }, [
    featureFlagLoaded,
    featureFlagEnabled,
    isCustomerAccountQuote,
    customerId,
    forename,
    surname,
    email,
    price,
  ])

  return <></>
}

export default MentionMe
