import { InitialStateType } from 'initialState'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DurationType } from 'types/global'

const useDuration = () => {
  const {
    value: duration,
    durationExtraOptionsSet,
    isEditable,
    hourDisplayOptions,
    dayDisplayOptions,
    weekDisplayOptions,
  } = useSelector((state: InitialStateType) => state.duration)

  let durationType = useSelector(
    (state: InitialStateType) => state.duration.type,
  )

  let nextValidDuration = duration
  let nextValidDurationType = durationType as DurationType

  durationType = durationType?.toLowerCase() as DurationType

  const daysAndExtraOptions = useMemo(() => {
    const extraDays = durationExtraOptionsSet.map(
      (extraOption) => extraOption.value,
    )
    return [...dayDisplayOptions, ...extraDays]
  }, [durationExtraOptionsSet])

  switch (durationType) {
    case 'hours': {
      const durationIndex = hourDisplayOptions.indexOf(+duration!)
      if (durationIndex + 1 !== hourDisplayOptions.length) {
        nextValidDuration = hourDisplayOptions[durationIndex + 1]
      } else {
        ;[nextValidDuration] = dayDisplayOptions
        nextValidDurationType = 'days'
      }
      break
    }
    case 'days': {
      const durationIndex = daysAndExtraOptions.indexOf(+duration!)
      if (durationIndex + 1 !== daysAndExtraOptions.length) {
        nextValidDuration = daysAndExtraOptions[durationIndex + 1]
      }
      break
    }
    case 'weeks': {
      const durationIndex = weekDisplayOptions.indexOf(+duration!)
      if (durationIndex + 1 !== weekDisplayOptions.length) {
        const dayDurationIndex = daysAndExtraOptions.indexOf(+duration! * 7)

        nextValidDuration = daysAndExtraOptions[dayDurationIndex + 1]
        nextValidDurationType = 'days'
      }
      break
    }
    default:
      break
  }

  return {
    duration,
    durationType,
    isEditable,
    nextValidDuration,
    nextValidDurationType,
    durationExtraOptionsSet,
    hourDisplayOptions,
    dayDisplayOptions,
    weekDisplayOptions,
  }
}

export default useDuration
