import FormField from 'components/@common/FormField'
import TextField from 'components/@common/TextField'
import React from 'react'

const ReasonForPurchaseOtherText: React.FC = () => (
  <FormField name='reasonForPurchaseOtherText' label='Other'>
    <TextField
      id='ReasonForPurchaseOtherText'
      name='reasonForPurchaseOtherText'
      placeholder='Please specify'
    />
  </FormField>
)

export default ReasonForPurchaseOtherText
