export const motorcycleReasonOptions = [
  {
    id: 6,
    value: 'TemporaryBusinessUseNeeded',
    description: 'Temporary business use needed',
  },
  {
    id: 8,
    value: 'CoverInAnEmergency',
    description: 'Cover in an emergency',
  },
  {
    id: 13,
    value: 'ObtainVehicleTax',
    description: 'Obtain vehicle tax',
  },
  {
    id: 14,
    value: 'ProblemWithAnnualInsurance',
    description: 'Problem with annual insurance',
  },
  {
    id: 17,
    value: 'TestRide',
    description: 'Test ride',
  },
  {
    id: 18,
    value: 'RideawayAfterBuying',
    description: 'Rideaway after buying',
  },
  {
    id: 19,
    value: 'BorrowingSomeonesBike',
    description: 'Borrowing someones bike',
  },
  {
    id: 21,
    value: 'CourtesyBikeFromGarage',
    description: 'Courtesy bike from garage',
  },
  {
    id: 22,
    value: 'BikeIsBeingHiredButNeedsInsurance',
    description: 'Bike is being hired but needs insurance',
  },
  {
    id: 23,
    value: 'BikeIsImpoundedByThePolice',
    description: 'Bike is impounded by the police',
  },
  {
    id: 26,
    value: 'SellingABike',
    description: 'Selling a bike',
  },
]
