import moment from 'moment'

export const formatPolicyDate = (date: string): string =>
  moment(date).format('DD/MM/YYYY')

export const formatPolicyTime = (date: string): string =>
  moment(date).format('HH:mm')

export const formatPolicyDateTime = (date: string): string =>
  `${formatPolicyDate(date)} at ${formatPolicyTime(date)}`
