import { SchemeEnum } from '@enums'
import moment from 'moment'
import { motorcycleEntitlementOptions } from '../../components/MotorcycleEntitlement/constants'
import { motorcycleOvernightParkingOptions } from '../../components/MotorcycleOvernightParking/constants'
import { motorcycleExperienceOptions } from '../../components/MotorcycleRidingExperience/constants'
import { motorcycleReasonOptions } from '../../components/ReasonForCover/constants'
import { useOfMotorcycleOptions } from '../../components/UseOfVehicle/constants'
import { VEHICLE_HAS_ANNUAL_INSURANCE } from '../../components/VehicleHasInsurance/constants'
import { VEHICLE_OWNERS } from '../../components/VehicleOwner/constants'
import initialState from '../../initialState'
import {
  convertValueToEnum,
  handleLicenceDurations,
  humanize,
  toTitleCase,
} from '../../services/handlers'
import {
  CREATE_QUOTE_SUCCESS,
  REQUEST_QUOTE_SUCCESS,
  UPDATE_DURATION,
} from '../App/constants'
import { IS_EMAIL_VALID } from '../DriverDetails/constants'
import {
  REQUEST_OCR,
  REQUEST_OCR_FRONT_SUCCESS,
} from '../DrivingLicenceCamera/constants'
import { REQUEST_LEGAL_DECLARATION_SUCCESS } from '../LegalDeclarationModal/constants'
import {
  GET_ANALYSIS_DATA_SUCCESS,
  REQUEST_POLICY_SUCCESS,
} from '../PolicyConfirmationPage/constants'
import {
  COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
  MOTORCYCLE_VEHICLE_SCHEME,
  PRIVATE_CAR,
  REQUEST_PREVIOUS_VEHICLE_FAILED,
  REQUEST_PREVIOUS_VEHICLE_SUCCESS,
  SET_VEHICLE,
  VEHICLE_LEARNER_SCHEME,
} from '../Vehicle/constants'
import {
  CLEAR_IDENTITY_CHECK,
  FORM_MANUALLY_EDITED,
  REQUEST_IDENTITY_CHECK_FAILURE,
  REQUEST_IDENTITY_CHECK_MORE_INFO,
  TOGGLE_FORM_IS_VALID_RISK_DATA_COLLECTION,
  UPDATE_QUOTE_FAILURE,
  UPDATE_QUOTE_SUCCESS,
  UPDATE_RISK_DATA,
  UPDATE_VALIDATION_RISK_DATA_COLLECTION,
} from './constants'

function riskDataCollectionReducer(state = initialState.riskData, action) {
  switch (action.type) {
    case UPDATE_RISK_DATA:
      return {
        ...state,
        ...action.riskData,
      }
    case CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        QuoteId: action.quote.QuoteId.Value,
      }
    case REQUEST_PREVIOUS_VEHICLE_SUCCESS:
      return {
        ...state,
        PreviouslyRiddenMotorcycleVehicle:
          action.previousRiddenVehicle.VehicleId,
      }
    case REQUEST_PREVIOUS_VEHICLE_FAILED:
      return {
        ...state,
        PreviouslyRiddenMotorcycleVehicle: null,
      }
    case REQUEST_OCR:
      return {
        ...state,
        Forename: '',
        Surname: '',
        DateOfBirth: null,
        DrivingLicenceNumber: '',
        LicenceHeldDuration: {
          value: null,
          description: null,
        },
        LicenceType: {
          value: 'FullUkLicence',
          description: 'Full UK licence',
        },
        AddressKey: '',
      }
    case REQUEST_OCR_FRONT_SUCCESS:
      return {
        ...state,
        Forename: toTitleCase(action.response.Forename.split(' ')[0]),
        Surname: toTitleCase(action.response.Surname),
        DateOfBirth:
          action.response.DateOfBirth !== ''
            ? action.response.DateOfBirth
            : null,
        DrivingLicenceNumber:
          action.response.DrivingLicenceNumber.split(' ')[0],
        LicenceHeldDuration: action.response.LicenceHeldDuration,
        LicenceType: {
          value: 'FullUkLicence',
          description: 'Full UK licence',
        },
      }
    case REQUEST_POLICY_SUCCESS:
      return {
        ...state,
        Forename: action.policy.Forename || '',
        Surname: action.policy.Surname || '',
        PolicyStartDate: {
          value: action.policy.PolicyStart || '',
        },
        PolicyEndDate: action.policy.PolicyEnd || '',
        QuoteId: action.policyId,
      }
    case REQUEST_QUOTE_SUCCESS:
      const address = action.quote.QuoteAddresses.filter(
        (a) => a.QuoteAddressType === 'MainAddress',
      )[0]
      const driver = action.quote.QuoteDrivers.filter((d) => d.Proposer)[0]
      const licenceHeldDuration = handleLicenceDurations(
        driver.DateOfBirth,
      ).filter((r) => r.value === driver.LicenceHeldDuration)[0]
      const vehicle = action.quote.Vehicle
      const scheme = action.scheme

      let vanDetails = {}
      let learnerDetails = {}
      let motorcycleDetails = {}
      let startDate = action.quote.PolicyStartDateTime

      if (scheme === COMMERCIAL_VEHICLE_SCHEME) {
        vanDetails = {
          BodyType: {
            value: vehicle.BodyType,
            description: humanize(vehicle.BodyType || ''),
          },
          UseOfVehicle: {
            value: vehicle.UseOfVehicle,
            description: humanize(vehicle.UseOfVehicle || ''),
          },
          Occupation: action.quote.Occupation,
        }
      }

      if (scheme === SchemeEnum.COURIER) {
        vanDetails = {
          BodyType: {
            value: vehicle.BodyType,
            description: humanize(vehicle.BodyType || ''),
          },
          Occupation: action.quote.Occupation,
          Courier: true,
        }
      }

      if (scheme === IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME) {
        vanDetails = {
          BodyType: {
            value: vehicle.BodyType,
            description: humanize(vehicle.BodyType || ''),
          },
        }
      }

      if (scheme === VEHICLE_LEARNER_SCHEME) {
        learnerDetails = {
          Learner: true,
          OwnerOfVehicle: convertValueToEnum(
            VEHICLE_OWNERS,
            action.quote.OwnerOfVehicle,
          ),
          HasVehicleGotAnnualInsuranceEnumeration: convertValueToEnum(
            VEHICLE_HAS_ANNUAL_INSURANCE,
            action.quote.HasVehicleGotAnnualInsuranceEnumeration,
          ),
          Occupation: action.quote.Occupation,
          LicenceType: {
            value: 'ProvisionalUkLicence',
            description: 'Provisional UK licence',
          },
        }
      }

      if (scheme === MOTORCYCLE_VEHICLE_SCHEME) {
        motorcycleDetails = {
          Motorbike: true,
          Occupation: action.quote.Occupation,
          OwnerOfVehicle: convertValueToEnum(
            VEHICLE_OWNERS,
            action.quote.OwnerOfVehicle.Id,
          ),
          UseOfVehicle: convertValueToEnum(
            useOfMotorcycleOptions,
            action.quote.MotorcycleUseOfVehicle.Id,
          ),
          MotorcycleUseOfVehicle: convertValueToEnum(
            useOfMotorcycleOptions,
            action.quote.MotorcycleUseOfVehicle.Id,
          ),
          MotorcycleEntitlement: convertValueToEnum(
            motorcycleEntitlementOptions,
            action.quote.MotorcycleEntitlement.Id,
          ),
          MotorcycleRidingExperience: convertValueToEnum(
            motorcycleExperienceOptions,
            action.quote.MotorcycleRidingExperience.Id,
          ),
          MotorcycleOvernightParking: convertValueToEnum(
            motorcycleOvernightParkingOptions,
            action.quote.MotorcycleOvernightParking.Id,
          ),
          MotorcycleReasonForTempcoverPurchase: convertValueToEnum(
            motorcycleReasonOptions,
            action.quote.MotorcycleReasonForTempcoverPurchase.Id,
          ),
          PreviouslyRiddenMotorcycleVehicle:
            action.quote.PreviouslyRiddenMotorcycleVehicle,
        }
      }

      // current date time picker can't show minutes in its drop down that aren't multiple of 5 so this is just to add to the automatic BE adjustment
      if (
        action.quote.UserSelectedPolicyStartDate &&
        moment(startDate).minute() % 5 !== 0
      ) {
        startDate = moment(
          Math.ceil(+moment(startDate) / +moment.duration(5, 'minutes')) *
            +moment.duration(5, 'minutes'),
        ).toISOString()
      }

      return {
        ...state,
        ...vanDetails,
        ...motorcycleDetails,
        PolicyStartDate: {
          value: startDate,
          manuallyEdited: action.quote.UserSelectedPolicyStartDate,
        },
        QuoteStatus: action.quote.QuoteStatus,
        Forename: driver.Forename,
        Surname: driver.Surname,
        DateOfBirth: driver.DateOfBirth,
        AddressKey: address.AddressKey,
        Title: { value: driver.Title === 'NotSet' ? '' : driver.Title },
        LicenceType: {
          value:
            driver.LicenceType === 'NotSet'
              ? 'FullUkLicence'
              : driver.LicenceType,
          description: humanize(driver.LicenceType),
        },
        LicenceHeldDuration: {
          value:
            driver.LicenceHeldDuration === 'Uninitialised'
              ? ''
              : driver.LicenceHeldDuration,
          description: licenceHeldDuration && licenceHeldDuration.description,
        },
        DrivingLicenceNumber: driver.LicenceNumber || '',
        EmailAddress: action.quote.EmailAddress || '',
        Mobile: action.quote.TelephoneNumber || '',
        Impounded: action.quote.Vehicle.Impounded,
        Expat:
          (driver.ResidencyType || 'PermanentUkResident') !==
          'PermanentUkResident',
        ResidencyType: {
          value: driver.ResidencyType || 'PermanentUkResident',
          description: 'Permanent UK Resident',
        },
        UkResidencyDuration: {
          value:
            driver.UkResidencyDuration === 'NotSet'
              ? ''
              : driver.UkResidencyDuration,
          description: driver.UkResidencyDuration || null,
        },
        ReasonForPurchase: {
          value:
            action.quote.ReasonForPurchase === 'Uninitialised'
              ? ''
              : action.quote.ReasonForPurchase,
        },
        ReasonForPurchaseOtherText: action.quote.ReasonForPurchaseOtherText,
        ...learnerDetails,
        ...motorcycleDetails,
      }
    case GET_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        Title: action.response.Title,
        LicenceHeldDuration: {
          value: action.response.LicenceHeldDuration,
          description: humanize(action.response.LicenceHeldDuration),
        },
        DateOfBirth: action.response.DateOfBirth,
      }
    case SET_VEHICLE:
      const vehicleType = action.vehicle.VehicleType
      const defaultValue = {
        ...state,
      }

      if (action.vehicle.IsLearnerVehicle) {
        return defaultValue
      }

      switch (vehicleType) {
        case PRIVATE_CAR:
          return {
            ...defaultValue,
            BodyType: {
              value: '',
              description: '',
            },
            UseOfVehicle: {
              value: '',
              description: '',
            },
            Occupation: {
              Id: 0,
              OccupationCode: '',
              OccupationName: '',
            },
          }
        default:
          return defaultValue
      }
    case UPDATE_DURATION:
      return {
        ...state,
      }
    default:
      return state
  }
}

export function validationReducer(
  state = initialState.validation.riskDataCollectionPage,
  action,
) {
  switch (action.type) {
    case UPDATE_VALIDATION_RISK_DATA_COLLECTION:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.fields,
        },
      }
    case UPDATE_RISK_DATA:
      if (action.PolicyStartDate) {
        return {
          ...state,
          AllFieldsValid: true,
        }
      }
    case UPDATE_QUOTE_SUCCESS:
      return {
        ...state,
        AllFieldsValid: true,
      }
    case UPDATE_QUOTE_FAILURE:
      return {
        ...state,
        AllFieldsValid: false,
      }
    case TOGGLE_FORM_IS_VALID_RISK_DATA_COLLECTION:
      const newState = { ...state }
      newState.ValidForm = action.form.valid

      return newState
    case REQUEST_OCR:
    case REQUEST_OCR_FRONT_SUCCESS:
      return {
        ...state,
        IdentityCheck: null,
      }
    case FORM_MANUALLY_EDITED:
      return {
        ...state,
        ...(state.ManuallyEdited = true),
      }
    case REQUEST_LEGAL_DECLARATION_SUCCESS:
      return {
        ...state,
        IdentityCheck: null,
      }
    case REQUEST_IDENTITY_CHECK_FAILURE:
      return {
        ...state,
        ...(state.ValidIdentity = false),
      }
    case REQUEST_IDENTITY_CHECK_MORE_INFO:
      return {
        ...state,
        IdentityCheck: action.check,
      }

    case CLEAR_IDENTITY_CHECK:
      return {
        ...state,
        IdentityCheck: null,
        fields: {
          ...state.fields,
          DrivingLicenceNumberError: '',
          PassportLineOneError: '',
          PassportLineTwoError: '',
          PassportExpiryDateError: '',
        },
      }
    case CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        fields: {
          ...state.fields,
        },
      }
    case REQUEST_QUOTE_SUCCESS:
      const driver = action.quote.QuoteDrivers.filter((d) => d.Proposer)[0]
      const address = action.quote.QuoteAddresses.filter(
        (a) => a.QuoteAddressType === 'MainAddress',
      )[0]
      const vehicle = action.quote.Vehicle
      const scheme = action.scheme

      let vanValidation = {}
      let learnerValidation = {}
      let motorcycleValidation = {}
      if (scheme === COMMERCIAL_VEHICLE_SCHEME) {
        vanValidation = {
          BodyTypeError: vehicle.BodyType === null ? null : '',
          UseOfVehicleError: vehicle.UseOfVehicle === null ? null : '',
          OccupationError: !action.quote.Occupation ? null : '',
        }
      }
      if (scheme === IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME) {
        vanValidation = {
          BodyTypeError: vehicle.BodyType === null ? null : '',
          UseOfVehicleError: '',
          OccupationError: '',
        }
      }
      if (scheme === VEHICLE_LEARNER_SCHEME) {
        learnerValidation = {
          LicenceHeldDurationError:
            !driver.LicenceHeldDuration === 'Uninitialised' ? null : '',
          OccupationError: !action.quote.Occupation ? null : '',
          HasVehicleGotAnnualInsuranceEnumerationError: !action.quote
            .HasVehicleGotAnnualInsuranceEnumeration
            ? null
            : '',
        }
      }
      if (scheme === MOTORCYCLE_VEHICLE_SCHEME) {
        motorcycleValidation = {
          OccupationError: '',
          UseOfVehicleError: '',
          MotorcycleUseOfVehicleError: '',
          MotorcycleEntitlementError: '',
          MotorcycleRidingExperienceError: '',
          MotorcycleOvernightParkingError: '',
          MotorcycleReasonForTempcoverPurchaseError: '',
          PreviouslyRiddenMotorcycleVehicleError: '',
        }
      }

      return {
        ...state,
        fields: {
          ...state.fields,
          ...vanValidation,
          TitleError: driver.Title === null ? null : '',
          ForenameError: driver.Forename === null ? null : '',
          SurnameError: driver.Surname === null ? null : '',
          DateOfBirthError: driver.DateOfBirth === null ? null : '',
          DrivingLicenceNumberError:
            driver.DrivingLicenceNumber === null ? null : '',
          LicenceHeldDurationError:
            driver.LicenceHeldDuration === 'Uninitialised' ? null : '',
          PostcodeError: address.Postcode === null ? null : '',
          AddressError: address.AddressKey === null ? null : '',
          EmailAddressError: action.quote.EmailAddress === null ? null : '',
          MobileError: action.quote.TelephoneNumber === null ? null : '',
          ResidencyTypeError: action.quote.ResidencyType === null ? null : '',
          ...learnerValidation,
          ...motorcycleValidation,
        },
      }
    case IS_EMAIL_VALID:
      return {
        ...state,
        fields: {
          ...state.fields,
          EmailAddressError: action.emailError,
        },
      }
    default:
      return state
  }
}

export default riskDataCollectionReducer
