import { ShowExtraLink } from 'components/@common/DateTimePicker/DateTimePicker.styles'
import { DurationFormData } from 'components/@forms/DriverDetails/DriverDetails.types'
import { DurationButton } from 'components/PolicyDurationModal/styles'
import { useFormikContext } from 'formik'
import { useAffiliate, useDuration } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { DurationType } from 'types/global'
import {
  DurationsWrapper,
  DurationTypesWrapper,
} from './DurationSelector.styles'
import { useDispatch } from 'react-redux'
import { updateDurationValue } from 'containers/App/actions'

const DurationSelector: React.FC = () => {
  const dispatch = useDispatch()
  const {
    dayDisplayOptions,
    durationExtraOptionsSet,
    hourDisplayOptions,
    weekDisplayOptions,
  } = useDuration()

  const { values, setFieldValue } = useFormikContext<DurationFormData>()
  const {
    affiliate: { Ref: ref },
  } = useAffiliate()
  const [showExtraOptions, setShowExtraOptions] = useState(false)

  const formattedDurationType: DurationType = useMemo(() => {
    const durationTypeLowerCase: DurationType =
      (values.durationType?.toLowerCase() as DurationType) || 'days'
    return durationTypeLowerCase.slice(-1) === 's'
      ? durationTypeLowerCase
      : (`${durationTypeLowerCase}s` as DurationType)
  }, [values.durationType])

  const activeDisplaySet = useMemo(() => {
    const lowerCaseDurationType = values.durationType?.toLowerCase() || 'days'
    switch (lowerCaseDurationType) {
      case 'days':
        return dayDisplayOptions
      case 'hours':
        if (!ref) return hourDisplayOptions

        if (
          ref &&
          (ref.includes('PE26YS778488') ||
            ref.includes('GU513QH42') ||
            ref.includes('Quidco') ||
            ref.includes('uswitchwl') ||
            ref.includes('uswitch'))
        ) {
          return hourDisplayOptions
        }

        if (ref) {
          setFieldValue('durationType', 'days', false)
          setFieldValue('duration', 1, false)

          return dayDisplayOptions
        }

        return hourDisplayOptions
      case 'weeks':
        return weekDisplayOptions
      default:
        return []
    }
  }, [values.durationType])

  const isExtraOptionValue = useMemo(
    () =>
      values.durationType.toLowerCase() === 'days' &&
      +values.duration >=
        Math.min(...durationExtraOptionsSet.map((o) => o.value)),
    [values],
  )

  const handleDurationTypeClick = (durationType: DurationType) => {
    let duration = dayDisplayOptions[0]
    switch (durationType) {
      case 'hours':
        duration = hourDisplayOptions[0]
        break
      case 'weeks':
        duration = weekDisplayOptions[0]
        break
      default:
        break
    }

    setFieldValue('durationType', durationType, false)
    setFieldValue('duration', duration, false)
    const durationData = {
      value: duration,
      type: durationType,
    }
    dispatch(updateDurationValue(durationData))
  }

  const handleDurationClick = (duration: number) => {
    setFieldValue('duration', duration, false)
    const durationData = {
      value: duration,
      type: values.durationType,
    }
    dispatch(updateDurationValue(durationData))
  }

  const validDurationOptions = useMemo(() => {
    switch (values.durationType) {
      case 'hours':
        return hourDisplayOptions
      case 'days':
        return dayDisplayOptions
      case 'weeks':
        return weekDisplayOptions
      default:
        return []
    }
  }, [values.durationType])

  const extraOptions = useMemo(
    () => [
      ...durationExtraOptionsSet.filter(
        (o) =>
          activeDisplaySet.indexOf(o.value) === -1 &&
          o.type === formattedDurationType,
      ),
    ],
    [durationExtraOptionsSet, activeDisplaySet, formattedDurationType],
  )

  const toggleExtraOptions = () => {
    if (showExtraOptions) {
      setShowExtraOptions(false)
      if (isExtraOptionValue) {
        handleDurationClick(
          validDurationOptions[validDurationOptions.length - 1],
        )
      }
    } else {
      setShowExtraOptions(true)
    }
  }

  useEffect(() => {
    if (isExtraOptionValue) {
      setShowExtraOptions(true)
    } else {
      setShowExtraOptions(false)
    }
  }, [values.duration, values.durationType])

  return (
    <>
      <DurationTypesWrapper>
        {(!ref ||
          ref === 'PE26YS778488' ||
          ref === 'GU513QH42' ||
          ref === 'Quidco' ||
          ref === 'uswitchwl' ||
          ref === 'uswitch') &&
          hourDisplayOptions &&
          hourDisplayOptions.length > 0 && (
            <DurationButton
              id='hoursDurationType'
              type='button'
              onClick={() => handleDurationTypeClick('hours')}
              disabled={values.durationType === 'hours'}
            >
              Hours
            </DurationButton>
          )}
        <DurationButton
          id='daysDurationType'
          type='button'
          onClick={() => handleDurationTypeClick('days')}
          disabled={values.durationType === 'days'}
        >
          Days
        </DurationButton>
        {weekDisplayOptions && weekDisplayOptions.length > 0 && (
          <DurationButton
            id='weeksDurationType'
            type='button'
            onClick={() => handleDurationTypeClick('weeks')}
            disabled={values.durationType === 'weeks'}
          >
            Weeks
          </DurationButton>
        )}
      </DurationTypesWrapper>
      <hr
        style={{
          borderColor: '#264B94',
          borderWidth: '1px',
          margin: '1em auto',
          borderBottomWidth: 0,
          width: '85%',
          maxWidth: 340,
        }}
      />
      {values.durationType && (
        <>
          <DurationsWrapper className={values.durationType.toLowerCase()}>
            {validDurationOptions.map((durationOption) => (
              <DurationButton
                id={`durationOption${durationOption}`}
                type='button'
                onClick={() => handleDurationClick(durationOption)}
                disabled={values.duration === durationOption}
                key={durationOption}
              >
                {durationOption}
              </DurationButton>
            ))}
            {values.durationType === 'days' &&
              showExtraOptions &&
              extraOptions.map((option) => (
                <DurationButton
                  id={`durationOption${option.value}`}
                  type='button'
                  onClick={() => handleDurationClick(option.value)}
                  disabled={values.duration === option.value}
                  key={option.id}
                >
                  {option.value}
                </DurationButton>
              ))}
          </DurationsWrapper>
          {values.durationType === 'days' && !showExtraOptions && (
            <ShowExtraLink onClick={toggleExtraOptions}>
              + See more days
            </ShowExtraLink>
          )}
        </>
      )}
    </>
  )
}

export default DurationSelector
