import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestCallback } from '../actions'
import { REQUEST_CALLBACK_STATUS } from '../constants'
import queryString from 'query-string'
import {
  AnnualInsuranceText,
  AnnualInsuranceConfirmationHeaderWrapper,
  AnnualInsuranceConfirmationHeader,
  ConfirmationWrapper,
  AnnuaLInsuranceLogo,
  AnnuaLInsuranceSpinner,
} from '../CTMAdverts/styles'
import Logo from '../../../images/CtmUpsell/CtmLogo.png'
import GridContainerWrapper from 'components/@common/GridContainerWrapper'

const AnnualInsuranceConfirmationPage = ({ actions, callbackRequest }) => {
  useEffect(() => {
    const parsedQueryString = queryString.parse(window.location.search)
    const leadSource = parsedQueryString.e === '1' ? 'EmailExpiration' : 'Email'
    actions.requestCallback(leadSource)
  })

  switch (callbackRequest.status) {
    case REQUEST_CALLBACK_STATUS.SUCCESS:
    default:
      return (
        <GridContainerWrapper>
          <ConfirmationWrapper>
            <AnnualInsuranceConfirmationHeaderWrapper>
              <AnnualInsuranceConfirmationHeader>
                Redirecting to Compare the Market
              </AnnualInsuranceConfirmationHeader>
              <AnnualInsuranceText>
                <AnnuaLInsuranceSpinner className='fa fa-pulse fa-spinner' />
              </AnnualInsuranceText>
              <AnnuaLInsuranceLogo src={Logo} alt={'Compare the Market Logo'} />
            </AnnualInsuranceConfirmationHeaderWrapper>
          </ConfirmationWrapper>
        </GridContainerWrapper>
      )
  }
}

const mapStateToProps = (state) => {
  return {
    callbackRequest: state.policy.callbackRequest,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        requestCallback,
      },
      dispatch,
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnnualInsuranceConfirmationPage)
