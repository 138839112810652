import React, { useEffect, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { useIdentity } from 'hooks'
import { TextFieldStyled } from 'components/@common/TextField/TextField.styles'
import { BaseDriverFormData } from 'components/@forms/DriverDetails/DriverDetails.types'

interface Props {
  fullLicence?: boolean
}

const DrivingLicenceNumber: React.FC<Props> = ({ fullLicence = false }) => {
  const { values } = useFormikContext<BaseDriverFormData>()
  const { licenceNumber } = useIdentity(values)

  const [field, , helpers] = useField('drivingLicenceNumber')
  const [firstPart, setFirstPart] = useState(licenceNumber.substring(0, 12))
  const [lastPart, setLastPart] = useState(field.value.substring(12, 16))

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastPart(e.target.value.toUpperCase())
    helpers.setValue(firstPart + e.target.value.toUpperCase(), true)
  }

  const handleFullChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(e.target.value.toUpperCase(), true)
  }

  useEffect(() => {
    if (!fullLicence) {
      setFirstPart(licenceNumber.substring(0, 12))
      helpers.setValue(
        licenceNumber.substring(0, 12) + lastPart.toUpperCase(),
        true,
      )
    }
  }, [licenceNumber])

  return (
    <>
      {/* Hidden span for validation */}
      <span hidden id='drivingLicenceNumber' />
      <div style={{ display: 'flex' }}>
        {fullLicence ? (
          <TextFieldStyled
            placeholder='My driving licence number is...'
            uppercase
            maxLength={16}
            //@ts-ignore
            value={values.drivingLicenceNumber}
            id='DrivingLicenceNumber'
            name='drivingLicenceNumber'
            onChange={handleFullChange}
          />
        ) : (
          <>
            <TextFieldStyled
              uppercase
              id='DrivingLicenceNumberFirstPart'
              name='drivingLicenceNumberFirstPart'
              value={firstPart}
              disabled
            />
            <TextFieldStyled
              uppercase
              maxLength={4}
              id='DrivingLicenceNumberSecondPart'
              name='drivingLicenceNumberSecondPart'
              onChange={handleChange}
              value={lastPart}
            />
          </>
        )}
      </div>
    </>
  )
}

export default DrivingLicenceNumber
