import { useState, useEffect } from 'react'
import moment from 'moment'
import { TimeUnits } from './useTimer.types'

export const calculateTimeDifference = (targetTime: string) => {
  const difference = +new Date(targetTime) - +new Date()
  let timeLeft: TimeUnits = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }
  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    }
  }

  if (timeLeft.minutes < 10) {
    timeLeft.minutes = `0${timeLeft.minutes}`.slice(-2)
  }
  if (timeLeft.seconds < 10) {
    timeLeft.seconds = `0${timeLeft.seconds}`.slice(-2)
  }
  return timeLeft
}

const useTimer = (
  targetTime = moment().startOf('hour').add(1, 'hour').toString(),
) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeDifference(targetTime))
  const [hasHitZero, setHasHitZero] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeDifference(targetTime))
    }, 1000)

    return () => clearTimeout(timer)
  })

  useEffect(() => {
    if (timeLeft.minutes === '00' && timeLeft.seconds === '00') {
      setHasHitZero(true)
    }
  }, [timeLeft.minutes, timeLeft.seconds])

  return { timeLeft, hasHitZero }
}

export default useTimer
