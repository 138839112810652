import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export const LegalExpenses = ({ width = 0, height = 0, scale = 1 }) => {
  const opts = {}
  const { svg } = useContext(ThemeContext)

  if (width) {
    opts.width = width
  }

  if (height) {
    opts.height = height
  }

  return (
    <svg
      version='1.1'
      id='icons'
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      width='64'
      height='64'
      viewBox='0 0 64 64'
      style={{ transform: `scale(${scale})` }}
    >
      <path
        id='Path_8'
        fill={svg.accentColour}
        d='M13.6,15.5L11,31H6.4L13.6,15.5z'
      />
      <path
        id='Path_9'
        fill={svg.accentColour}
        d='M50.6,15.5L48,31h-4.6L50.6,15.5z'
      />
      <path
        id='Path_10'
        fill={svg.accentColour}
        d='M31.8,4.9c-3.4,1.4-5.6,4.8-5.6,8.5c0,3.6,2.2,6.8,5.6,8c0,0-9.1-0.2-9-8S31.8,4.9,31.8,4.9z'
      />
      <path
        id='Path_11'
        fill={svg.accentColour}
        d='M31.8,43.7c-0.8,0.4-1.4,1.2-1.4,2.1c0.1,1,0.6,1.9,1.4,2.4c0,0-2.1-0.5-2.1-2.4
			C29.8,43.9,31.8,43.7,31.8,43.7z'
      />
      <path
        id='Path_12'
        fill={svg.accentColour}
        d='M22.9,54H29l-3.2,5.9h-6.7L22.9,54z'
      />
      <g id='Group_2'>
        <g>
          <defs>
            <clipPath id='scalesClipping'>
              <rect x='5' y='3' width='53.9' height='58' />
            </clipPath>
          </defs>
          <g id='Group_1' clipPath='url(#scalesClipping)'>
            <path
              id='Path_1'
              fill='none'
              stroke={svg.primaryColour}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M40.4,54.1H23.5c-1.6,0-4.9,5.9-4.9,5.9h26.6C45.3,60,42,54.1,40.4,54.1z'
            />
            <line
              id='Line_1'
              fill='none'
              stroke={svg.primaryColour}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              x1='29.8'
              y1='54.1'
              x2='29.8'
              y2='48.2'
            />
            <line
              id='Line_2'
              fill='none'
              stroke={svg.primaryColour}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              x1='29.8'
              y1='43.5'
              x2='29.8'
              y2='21.9'
            />
            <line
              id='Line_3'
              fill='none'
              stroke={svg.primaryColour}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              x1='34.2'
              y1='43.5'
              x2='34.2'
              y2='21.9'
            />
            <line
              id='Line_4'
              fill='none'
              stroke={svg.primaryColour}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              x1='34.2'
              y1='54.1'
              x2='34.2'
              y2='48.2'
            />
            <path
              id='Path_2'
              fill='none'
              stroke={svg.primaryColour}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M35.2,45.9c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2s1.4-3.2,3.2-3.2
						c0.8,0,1.6,0.3,2.2,0.9C34.9,44.1,35.2,45,35.2,45.9z'
            />
            <path
              id='Path_3'
              fill='none'
              stroke={svg.primaryColour}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M21.3,31.4H6c0,4.5,3.4,6.5,7.6,6.5S21.3,35.9,21.3,31.4z'
            />
            <path
              id='Path_4'
              fill='none'
              stroke={svg.primaryColour}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M43.3,31.2H58c0,4.5-3.3,6.5-7.3,6.5S43.3,35.7,43.3,31.2z'
            />
            <path
              id='Path_5'
              fill='none'
              stroke={svg.primaryColour}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M21.3,31.4l-7.6-18.3L6,31.4'
            />
            <path
              id='Path_6'
              fill='none'
              stroke={svg.primaryColour}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M58,31.2l-7.3-18.3l-7.3,18.3'
            />
            <line
              id='Line_7'
              fill='none'
              stroke={svg.primaryColour}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              x1='13.7'
              y1='13.1'
              x2='22.9'
              y2='13.1'
            />
            <line
              id='Line_8'
              fill='none'
              stroke={svg.primaryColour}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              x1='50.6'
              y1='12.9'
              x2='41.1'
              y2='12.9'
            />
            <circle
              id='Ellipse_1'
              fill='none'
              stroke={svg.primaryColour}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              cx='32'
              cy='13.1'
              r='9.1'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
