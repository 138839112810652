import React from 'react'
import { Select } from '../common/Select'
import { MOTORCYCLE_VEHICLE_VALUES, VEHICLE_VALUES } from './constants'

export class VehicleValue extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }
  handleChange = (event) => {
    this.props.onChange(event)
  }

  render() {
    const { isMotorcycle } = this.props

    return (
      <div
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <Select
          id='VehicleValue'
          options={isMotorcycle ? MOTORCYCLE_VEHICLE_VALUES : VEHICLE_VALUES}
          name={this.props.name}
          onChange={this.handleChange}
          value={this.props.value}
        />
      </div>
    )
  }
}
