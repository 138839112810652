import React from 'react'
import validator from 'validator'
import { ErrorMessage } from '../common/ErrorMessage'
import { UppercaseInput } from '../common/Input/UppercaseInput'
import { FormControlWrapper } from '../FormControlWrapper'
import { FormControlValidIcon } from '../FormControlValidIcon'

export class DrivingLicenceFullNumber extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (event) => {
    this.handleValidation(event)
    this.props.onChange(event)
  }

  handleValidation = (event) => {
    let message = validator.matches(
      event.target.value.toUpperCase(),
      /^[A-Z9]{5}\d{6}[A-Z9]{2}\d[A-Z]{2}$/,
    )
      ? ''
      : 'Please enter a valid driving licence number'

    this.props.handleValidation(event.target.name, message)
  }
  render() {
    return (
      <>
        <FormControlWrapper>
          <FormControlValidIcon
            error={this.props.error}
            className='fas fa-check'
          ></FormControlValidIcon>
          <UppercaseInput
            placeholder='My driving licence number is...'
            onChange={this.handleChange}
            maxLength='16'
            name='DrivingLicenceNumber'
            error={this.props.error}
            value={this.props.value}
            className={this.props.className}
            id='DrivingLicenceNumber'
          />
        </FormControlWrapper>
        <ErrorMessage>{this.props.error}</ErrorMessage>
      </>
    )
  }
}

export default DrivingLicenceFullNumber
