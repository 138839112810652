import { call, put, select, takeLatest } from 'redux-saga/effects'
import quoteApi from '../../../api/quoteApi'

import {
  getPriceLink,
  getRiskData,
  getAffiliateFromState,
  getQuoteState,
} from '../selector'
import {
  requestPrice as getPrice,
  setQuoteJourneyPosition,
  requestAddonsSuccess,
  setTempUnderwriter,
  updateCampaignData,
} from '../actions'
import { requestPriceSuccess, requestPriceFailure } from '../actions'
import { sendEmailQuote } from '../../EmailQuoteModal/saga'
import getAffiliate from './affiliateSaga'
import { REQUEST_ADDONS, SELECT_PRICE } from '../constants'
import { updateQuote } from '../../RiskDataCollectionPage/actions'
import { getPriceData } from '../../LegalDeclarationModal/selector'
import { QuoteJourneyPosition } from 'types/global'
import { getExperiments } from 'containers/RiskDataCollectionPage/selector'
import config from '../../../config'
import _ from 'lodash'
import { showExperiment } from 'store/experiment/experiment.actions'
import experimentApi from 'api/experimentApi'

export function* requestPrice() {
  try {
    const affiliate = yield select(getAffiliateFromState)
    yield* put(getPrice())

    const price = yield* fetchPriceData()
    yield put(requestPriceSuccess(price.data))

    // dont set campaign data here for ctm as it could overwriter it on price check
    if (!affiliate.PriceComparison) {
      yield put(updateCampaignData(price.data))
    }

    yield call(sendEmailQuote)
  } catch (e) {
    yield put(requestPriceFailure(e))
  }
}

export function* requestAddons(action) {
  const { addonOptions, addonType } = action
  try {
    yield put(
      setQuoteJourneyPosition(QuoteJourneyPosition.CustomerAddOnsChanged),
    )
    const price = yield* fetchPriceData(addonOptions)
    yield put(requestAddonsSuccess(price.data))
  } catch {
    switch (addonType) {
      case 'excess':
        put(requestExcessFailure())
        break
      case 'breakdown':
        put(requestBreakdownFailure())
        break
      case 'legalexpenses':
        put(requestLegalExpensesFailure())
        break
      case 'gapinsurance':
        put(requestGAPInsuranceFailure())
        break
      default:
        break
    }
  }
}

export function* selectPriceSaga() {
  yield put(
    updateQuote({
      partialValidation: true,
      confirmRiskData: true,
      getPrice: false,
    }),
  )
}

function* fetchPriceData(addonOptions = {}) {
  yield* select(getAffiliate)

  const { PriceComparison, Ref } = yield select(getAffiliateFromState)
  const quoteState = yield select(getQuoteState)
  let { Href: priceLink } = yield select(getPriceLink)
  const experiments = yield select(getExperiments)
  const riskData = yield select(getRiskData)

  let priceComparisonAB = 0
  if (
    experiments &&
    _.isEmpty(experiments) &&
    (typeof Ref === 'undefined' || Ref.length === 0) &&
    window.dataLayer &&
    window.google_optimize
  ) {
    window.dataLayer.push({ event: 'optimize.activate' })
    const experimentValue = window.google_optimize.get(
      config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID,
    )
    yield put(
      showExperiment({
        [config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID]:
          Number(experimentValue),
      }),
    )

    priceComparisonAB = Number(experimentValue)
    if (
      config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID &&
      riskData.QuoteId &&
      !!experimentValue
    ) {
      experimentApi.logExperiment({
        Description: 'Price comparison experiment',
        ExternalExperimentId: config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID,
        QuoteId: { value: riskData.QuoteId },
        Variant: Number(experimentValue),
      })
    }
  } else if (Ref && Ref.length > 0) {
    priceComparisonAB = 0
    yield put(
      showExperiment({ [config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID]: 0 }),
    )
  } else {
    priceComparisonAB =
      experiments[config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID] || 0
  }

  const isMultiPriceJourney = PriceComparison || priceComparisonAB

  if (isMultiPriceJourney) {
    priceLink = `${priceLink}/all`
  }

  const { QuoteId } = yield select(getRiskData)
  const {
    Underwriter,
    TempUnderwriter,
    UnderwriterDisplayName,
    ExcessReduction,
    BreakdownCover,
    LegalExpensesCover,
    FreeGAPInsuranceCover,
  } = yield select(getPriceData)

  const header = {
    Accept: isMultiPriceJourney
      ? 'application/tempcover.PricingAllRepresentation+json'
      : 'application/tempcover.PricingRepresentation+json',
  }
  const price = yield call(
    quoteApi.getPrice,
    priceLink,
    {
      value: QuoteId,
      ExcessReductionIncluded: ExcessReduction.ExcessReductionIncluded,
      BreakdownIncluded: BreakdownCover.BreakdownIncluded,
      LegalExpensesIncluded: LegalExpensesCover.LegalExpensesIncluded,
      QuoteJourneyPosition: quoteState.QuoteJourneyPosition,
      Free30DayGAPInsuranceIncluded:
        FreeGAPInsuranceCover.Free30DayGAPInsuranceIncluded,
      ...addonOptions,
    },
    header,
  )

  if (isMultiPriceJourney && Underwriter && UnderwriterDisplayName) {
    const currentSelectedPrice = price.data.Prices.find((p) =>
      TempUnderwriter
        ? parseInt(TempUnderwriter) === p.UnderwriterId
        : p.Underwriter === Underwriter.replace(' ', '') ||
          p.UnderwriterDisplayName === UnderwriterDisplayName,
    )

    if (TempUnderwriter) {
      yield put(setTempUnderwriter(null))
    }

    if (currentSelectedPrice) {
      Object.assign(price.data, {
        ...currentSelectedPrice,
        AffiliateCashbackValue: price.data.AffiliateCashbackValue,
      })
    }
  }

  return price
}

export default function* priceSaga() {
  yield takeLatest(SELECT_PRICE, selectPriceSaga)
  yield takeLatest(REQUEST_ADDONS, requestAddons)
}
