import http from './http'

export default class marketingPreferencesApi {
  static getState(endpoint) {
    return http.get(endpoint)
  }
  static update(endpoint, date) {
    return http.put(endpoint, date)
  }
  static getMarketingPreferences(data) {
    return http.post('/api/marketingPreferences', data)
  }
}
