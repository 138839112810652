import { BaseDriverFormData } from 'components/@forms/DriverDetails/DriverDetails.types'
import { ErrorMessage } from 'components/common/ErrorMessage'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Option } from 'types/global'
import { Grid } from 'unsemantic'
import Button from '../@common/Button'
import FormField from '../@common/FormField'
import TextField from '../@common/TextField'

interface Props {
  onClick: (postcode: string) => void
  isLoading: boolean
  onChange?: (value: string) => void
  onBlur?: () => void
  options?: Option[]
}

export const AddressSearchButton = styled(Button)`
  margin-top: 1.5em;
`

const PostcodeLookup: React.FC<Props> = ({
  onClick,
  isLoading,
  onChange = () => {},
  options = [],
  onBlur = () => {},
}) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const {
    errors,
    values,
    setFieldValue,
    setFieldTouched,
    touched,
    validateField,
  } = useFormikContext<BaseDriverFormData>()

  const handleClick = () => {
    setButtonDisabled(true)
    validateField('postcode')
    setFieldTouched('postcode')
    const postCodeValue = values.postcode
    if (!errors.postcode && touched.postcode && postCodeValue) {
      onClick(postCodeValue)
    } else {
      setButtonDisabled(false)
    }
  }

  const handleBlur = () => {
    onBlur()
  }

  useEffect(() => {
    setButtonDisabled(isLoading)
  }, [isLoading])

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFieldValue('postcode', event.currentTarget.value)
    // Any logic passed from parent
    onChange(event.currentTarget.value)
  }
  return (
    <>
      <Grid desktop='45' tablet='45' mobile='45' parent>
        <FormField name='postcode' label="Postcode">
          {' '}
          <TextField
            uppercase
            id='Postcode'
            onChange={handleChange}
            name='postcode'
            onBlur={handleBlur}
            placeholder="The driver's postcode is..."
          />
        </FormField>
      </Grid>
      <Grid
        desktop='50'
        desktop-prefix='5'
        tablet='50'
        tablet-prefix='5'
        mobile='50'
        mobile-prefix='5'
        parent
      >
        <>
          <AddressSearchButton
            id='PostcodeSearch'
            disabled={isButtonDisabled}
            loading={isLoading}
            type='button'
            fullWidth
            onClick={handleClick}
          >
            Look up address
          </AddressSearchButton>
          {!errors.postcode &&
            touched.postcode &&
            !isButtonDisabled &&
            values.postcode &&
            errors.addressKey &&
            options.length === 0 && (
              <ErrorMessage id='addressKey'>
                Please use the button above to select the driver's address
              </ErrorMessage>
            )}
        </>
      </Grid>
    </>
  )
}

export default PostcodeLookup
