import { H2 } from 'components/common/H2'
import { H3 } from 'components/common/H3'
import styled from 'styled-components'

export const PriceDetailsWithUnderwriterWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  img {
    width: 100px;
    height: 40px;
    object-fit: contain;
  }

  @media (max-width: 320px) {
    img {
      width: 50px;
      height: 20px;
    }
  }
`

export const PriceDetailsWrapper = styled.div`
  p {
    margin: 0;
    font-size: 14px;
  }
  h2 {
    font-size: 36px;
    margin: 0;
    text-align: left;
  }
  color: ${({ theme }) => theme.primaryColour};
  text-align: left;

  @media (max-width: 768px) {
    h2 {
      font-size: 34px;
    }
  }

  @media (max-width: 320px) {
    h2 {
      font-size: 24px;
    }
  }
`

export const UnderwriterImageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`

export const PriceWrapper = styled.div`
  display: flex;
`

export const PriceInfoKlarnaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const PriceUpsellWrapper = styled.div`
  flex-grow: 1;
`

export const DiscountedPriceWrapper = styled.div`
  margin-right: 8px;
`

export const DiscountedPrice = styled(H2)`
  color: ${({ theme }) => theme.secondaryColour};
`

export const CrossedOutPrice = styled(H3)`
  font-weight: 100;
  font-size: 20px;
  color: '#000';
  align-self: flex-end;
  margin: 0;
`
