import Form from 'components/@templates/Form'
import Address from 'components/Address'
import {
  clearIdentityCheck,
  updateRiskData,
} from 'containers/RiskDataCollectionPage/actions'
import { updateVehicleValue } from 'containers/Vehicle/actions'
import { useFormikContext } from 'formik'
import {
  useScheme,
  useConfusedQuickQuote,
  useRiskData,
  useVehicle,
  useIdentity,
} from 'hooks'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomerAccountApi, {
  GetCustomerDetailsResponse,
} from 'api/customerAccount'
import { useQuery } from 'react-query'
import DriverDetailItem from 'components/DriverDetailsCard/components/DriverDetailItem'
import moment from 'moment'
import { LICENCE_HELD_DURATIONS } from 'components/@forms/DriverDetails/@CommonFields/LicenceHeldDuration/LIcenceHeldDuration.constants'
import { LICENCE_TYPES } from 'components/LicenceType/constants'
import { RESIDENCY_TYPES } from 'components/@forms/DriverDetails/@CommonFields/ResidencyType/ResidencyType.constants'
import {
  ReasonForPurchase,
  ReasonForPurchaseOtherText,
} from '../DriverDetails/@CommonFields'

import { ReasonForPurchaseEnum } from '../DriverDetails/@CommonFields/ReasonForPurchase/ReasonForPurchaseEnumeration'
import QuoteParametersForm from '../DriverDetails/SubForms/QuoteParametersForm'
import { PrivateCarFormData } from '../DriverDetails/PrivateCarForm/PrivateCarForm.types'

import './styles.css'
import Button from 'components/@common/Button'
import ContinueButton from 'components/ContinueButton'
import { ButtonSpinner } from 'components/common/ButtonSpinner'
import { InitialStateType } from 'initialState'
import { selectAddress } from 'containers/Address/actions'
import FormField from 'components/@common/FormField'
import Select from 'components/@common/Select'
import Occupation from 'containers/Occupation'
import NotActiveFeature from 'components/@common/NotActiveFeature'
import config from 'config'
// import useCustomerAccountFeatureFlag from 'hooks/useCustomerAccountFeatureFlag'
import { USE_OF_VEHICLE_OPTIONS } from '../DriverDetails/CommercialVehicleForm/CommercialVehicleForm.constants'
import IdentityCheckForm from '../DriverDetails/SubForms/IdentityCheckForm'
import ConfusedQuickQuoteOptIn from '../DriverDetails/@CommonFields/ConfusedQuickQuoteOptIn'

export interface Props {
  customerDetails: GetCustomerDetailsResponse
  formSubmitted: boolean
}

const CustomerDriverDetails: React.FC<Props> = ({
  customerDetails,
  formSubmitted,
}) => {
  const dispatch = useDispatch()

  // const mainContinueButtonRef = useRef<HTMLDivElement | null>(null)
  const mainContinueButtonRef = useRef<HTMLDivElement | null>(null)
  const isFormButtonDisabled = useSelector(
    (state: InitialStateType) => state.formButtons.driverDetails.disabled,
  )

  const { riskData } = useRiskData()
  const { identityCheck } = useIdentity()

  const { showConfusedQuickQuoteOptIn, validReasonsForPurchase } =
    useConfusedQuickQuote()

  const { values, setFieldValue, errors } =
    useFormikContext<PrivateCarFormData>()

  const vehicleData = useSelector(
    (state: InitialStateType) => state.vehicle.selected,
  )

  const { reasonForPurchase: reasonForPurchaseValue } = values
  const { reasonForPurchaseOtherText: reasonForPurchaseOtherTextValue } = values

  dispatch(selectAddress(customerDetails.fullAddress))

  const { vehicle } = useVehicle()

  // TODO: Revisit this, at the minute we are just
  // reusing logic from before to store descriptions in redux.
  const handleSelect = (name: string, value: string, label: string) => {
    // We need to capitalise first letter, as in state they are using capitals at the start
    const capitalisedName = name.charAt(0).toUpperCase() + name.slice(1)

    switch (capitalisedName) {
      case 'LicenceType':
        dispatch(
          updateRiskData({
            [capitalisedName]: {
              value,
              description: label,
            },
          }),
        )
        return dispatch(clearIdentityCheck())
      case 'ResidencyType':
        if (value === 'PermanentUkResident') {
          setFieldValue('ukResidencyDuration', '')
          return dispatch(
            updateRiskData({
              Expat: false,
              UkResidencyDuration: {
                value: '',
                description: '',
              },
            }),
          )
        }
        return dispatch(
          updateRiskData({
            Expat: true,
          }),
        )

      case 'VehicleValue':
        return dispatch(
          updateVehicleValue({
            value,
            description: label,
          }),
        )
      default:
        return dispatch(
          updateRiskData({
            [capitalisedName]: {
              value,
              description: label,
            },
          }),
        )
    }
  }

  const closePopup = () => {
    dispatch(
      updateRiskData({
        ReasonForPurchase: {
          value: '',
        },
      }),
    )
  }

  // const { data: featureFlagEnabled, isLoading: featureFlagLoaded } =
  // useCustomerAccountFeatureFlag('EditCustomerAccountDetails')

  return (
    <>
      <QuoteParametersForm />
      {customerDetails && (
        <>
          <h4 className='your-details-title'>Your details</h4>
          <div className='customer-account-customer-details'>
            {/* { !(
               !featureFlagLoaded &&
               featureFlagEnabled &&
               featureFlagEnabled?.data
               )
               <DriverDetailItem label='Title' value={customerDetails.title} /> 
            } */}

            {
              // !featureFlagLoaded &&
              //   featureFlagEnabled &&
              //   featureFlagEnabled?.data &&
              <DriverDetailItem
                label='Title'
                value={customerDetails.title}
                extraElement={{
                  linkName: 'Edit in my account',
                  linkClass: 'edit-details-link',
                  linkLocation: `${config.CUSTOMER_ACCOUNT_URL_HOME}account/details/edit?redirect=quote`,
                }}
              />
            }

            <DriverDetailItem
              label='First name'
              value={customerDetails.forename}
            />
            <DriverDetailItem
              label='Last name'
              value={customerDetails.surname}
            />
            <DriverDetailItem
              label='Date of birth'
              value={moment(customerDetails.dateOfBirth).format('DD/MM/yyyy')}
            />
            <DriverDetailItem
              label='Licence type'
              value={
                LICENCE_TYPES.findIndex(
                  (x) => x.value === customerDetails.licenceType,
                ) !== -1
                  ? LICENCE_TYPES.filter(
                      (x) => x.value === customerDetails.licenceType,
                    )[0].description
                  : 'Not set'
              }
            />
            <DriverDetailItem
              label='Licence held duration'
              value={
                LICENCE_HELD_DURATIONS.findIndex(
                  (x) =>
                    x.value === customerDetails.licenceHeldDurationDescription,
                ) !== -1
                  ? LICENCE_HELD_DURATIONS.filter(
                      (x) =>
                        x.value ===
                        customerDetails.licenceHeldDurationDescription,
                    )[0].description
                  : ''
              }
            />
            <DriverDetailItem
              label='Residency'
              value={
                RESIDENCY_TYPES.findIndex(
                  (x) => x.id === customerDetails.residencyType,
                ) !== -1
                  ? RESIDENCY_TYPES.filter(
                      (x) => x.id === customerDetails.residencyType,
                    )[0].description
                  : ''
              }
            />
            <DriverDetailItem
              label='Address'
              value={customerDetails.fullAddress}
            />
            {customerDetails.occupationId <= 0 &&
              vehicle.selected.VehicleType === 'CommercialVehicle' && (
                // <FormField label='Occupation' name='occupation'>
                <Occupation />
                // </FormField>
              )}
            {customerDetails.occupationId > 0 &&
              vehicle.selected.VehicleType === 'CommercialVehicle' && (
                <DriverDetailItem
                  label='Occupation'
                  value={customerDetails.occupationName}
                />
              )}
          </div>
        </>
      )}
      {vehicle.selected.VehicleType === 'CommercialVehicle' && (
        <FormField
          name='useOfVehicle'
          label='Use of vehicle'
          isValidIcon={false}
        >
          <Select
            id='UseOfVehicle'
            onChange={handleSelect}
            name='useOfVehicle'
            options={USE_OF_VEHICLE_OPTIONS}
            placeholder='I am using my van for...'
          />
        </FormField>
      )}
      <ReasonForPurchase onSelect={handleSelect} />
      {reasonForPurchaseValue &&
        reasonForPurchaseValue === ReasonForPurchaseEnum.other && (
          <ReasonForPurchaseOtherText />
        )}
      <NotActiveFeature
        isVisible={
          riskData.ReasonForPurchase?.value === 'VehicleImpoundedByThePolice' &&
          vehicle.selected.VehicleId !== null
        }
        feature='Impounded policies'
        product='impounded vehicles'
        buttonText='Get an impounded quote'
        buttonId='getAnImpoundQuote'
        buttonLink={`/?impounded=true&registration=${vehicle.selected.RegistrationNumber}&duration=30+days&tp=20%2C648&IsVehicleOwner=on`}
        onClose={closePopup}
      />
      {!vehicleData ||
        (vehicleData.VehicleId === null && (
          <p className='missing-vehicle-message'>
            Please select a vehicle to continue
          </p>
        ))}

      {showConfusedQuickQuoteOptIn &&
        vehicleData.VehicleType === 'PrivateCar' &&
        validReasonsForPurchase.includes(reasonForPurchaseValue || '') && (
          <ConfusedQuickQuoteOptIn onSelect={handleSelect} />
        )}

      <IdentityCheckForm />

      <p>
        To process your application we will verify your identity and may check
        your details with credit reference and fraud prevention agencies.
        Entering incorrect data risks invalidating your policy. By clicking
        &apos;continue&apos;, you confirm you have read and agree to the{' '}
        <a href='https://www.tempcover.com/terms-conditions'>
          customer terms of business
        </a>{' '}
        and{' '}
        <a href='https://www.tempcover.com/privacy-policy'>privacy policy</a>.
      </p>

      <ContinueButton
        innerRef={mainContinueButtonRef}
        id='TeslaCustomerAccountSubmitManualDetailsNextButton'
        disabled={
          (errors !== null &&
            Object.keys(errors).length > 0 &&
            (identityCheck === 'passport' ||
              identityCheck === 'drivingLicence')) ||
          isFormButtonDisabled ||
          formSubmitted ||
          vehicleData.VehicleId === null ||
          !riskData.ReasonForPurchase ||
          (riskData.ReasonForPurchase &&
            (riskData.ReasonForPurchase?.value === '' ||
              riskData.ReasonForPurchase?.value ===
                'VehicleImpoundedByThePolice' ||
              (reasonForPurchaseValue === ReasonForPurchaseEnum.other &&
                !reasonForPurchaseOtherTextValue))) ||
          vehicle.preloadNewCustomerAccountVehicle
        }
        loading={isFormButtonDisabled}
        fullWidth
        type='submit'
        className='customer-account-continue'
      >
        {formSubmitted ? 'Please wait' : 'Continue'}
        <ButtonSpinner disabled={formSubmitted} />
      </ContinueButton>
    </>
  )
}

export default CustomerDriverDetails
