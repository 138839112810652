/* eslint-disable react/jsx-no-useless-fragment */
import { InitialStateType } from 'initialState'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GapActivationAddon from 'components/GapActivationAddon'
import useCustomerAccountFeatureFlag from 'hooks/useCustomerAccountFeatureFlag'
import { updateFreeGapActivationSelected } from 'containers/App/actions'
import { useAddons, usePayment, usePrice } from '../../hooks'
import { updateQuote } from '../../containers/RiskDataCollectionPage/actions'
// eslint-disable-next-line import/no-cycle
import AddonPriceComparisonContainer from './AddonPriceComparisonContainer'
import { AddonSubHeading, AddonWrapper } from './Addons.styles'

export interface Excess {
  compulsory: number
  reduced: string
}

const Addons: React.FC = () => {
  const dispatch = useDispatch()
  const { availableAddons, excess } = useAddons()
  const { price } = usePrice()

  const [freeGapSelected, setFreeGapSelected] = useState(
    price.FreeGAPInsuranceCover.FreeGapAdded,
  )

  const { data: featureFlagEnabled, isLoading: featureFlagLoading } =
    useCustomerAccountFeatureFlag('FreeGAPActivationJourney')

  const isGapActivationEnabled =
    price.FreeGAPInsuranceCover.FreeGAPInsuranceAvailable &&
    !featureFlagLoading &&
    featureFlagEnabled &&
    featureFlagEnabled?.data

  const {
    payment: { paymentInProgress },
  } = usePayment()
  const isAddonLoading = useSelector(
    (state: InitialStateType) => state.price.IsAddonUpdating,
  )

  const selectFreeGapSelected = (selected: boolean) => {
    setFreeGapSelected(selected)

    // call update quote
    dispatch(
      updateQuote({
        gapActivationIncluded: selected,
      }),
    )
    dispatch(updateFreeGapActivationSelected(selected))
  }

  if (availableAddons && availableAddons.length) {
    // if (isPriceComparison || variant !== '0') {
    return (
      <>
        <AddonSubHeading>Choose your extras</AddonSubHeading>
        <AddonWrapper>
          {isGapActivationEnabled && (
            <GapActivationAddon
              selectGap={selectFreeGapSelected}
              gapSelected={freeGapSelected}
            />
          )}

          {availableAddons &&
            availableAddons.map((addon, i) => (
              <AddonPriceComparisonContainer
                key={i}
                type={addon.type}
                price={addon.totalPrice}
                selected={addon.selected}
                disabled={isAddonLoading || paymentInProgress}
                error={addon.hasError}
                excess={
                  {
                    compulsory: price.CompulsoryExcess,
                    reduced: excess.excessAmount,
                  } as Excess
                }
              />
            ))}
        </AddonWrapper>
      </>
    )
  }

  return null
}

export default Addons
