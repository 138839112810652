// @ts-ignore
import { ReactComponent as ModalCloseButton } from 'images/modal-close-button.svg'
import React, { useEffect, useLayoutEffect } from 'react'
import { useTheme } from 'styled-components'
import {
  CloseButtonStyled,
  ModalContentStyled,
  ModalHeaderStyled,
  ModalHeaderText,
  ModalHeaderTextWrapper,
  ModalInner,
  ModalLogoWrapper,
  ModalWrapperStyled,
} from './Modal.styles'

interface Props {
  id: string
  isVisible: boolean
  onClose: () => void
  header?: string
  headerAlignment?: 'left' | 'center' | 'right'
  headerColour?: string
  hasHeaderBorder?: boolean
  isLarge?: boolean
  hasCloseButton?: boolean
  allowOutsideClose?: boolean
  HeaderLogo?: React.ReactNode
  fullscreen?: boolean
  fixedHeight?: boolean
}

const Modal: React.FC<Props> = ({
  isVisible,
  onClose,
  id,
  header,
  isLarge = false,
  hasCloseButton = true,
  allowOutsideClose = true,
  fullscreen = false,
  headerAlignment = 'left',
  hasHeaderBorder = false,
  headerColour,
  HeaderLogo,
  children,
  fixedHeight = true,
}) => {
  const theme = useTheme()
  // @ts-ignore
  const headerStyle = headerColour || theme.primaryTextColour

  // Unmounting logic. When the page is redirected with modal open we need to set the overflow style.
  useLayoutEffect(
    () => () => {
      document.querySelector('body')!.style.overflow = 'visible'
    },
    [],
  )

  useEffect(() => {
    // TODO: Investigate if there's a better way to stop scrolling outside of modal?
    if (isVisible) {
      document.querySelector('body')!.style.overflow = 'hidden'
    } else {
      document.querySelector('body')!.style.overflow = 'visible'
    }
  }, [isVisible])

  const handleClose = () => {
    onClose()
  }
  return (
    <ModalWrapperStyled
      id={id}
      isVisible={isVisible}
      fullscreen={fullscreen}
      onClick={allowOutsideClose ? handleClose : () => {}}
    >
      <ModalContentStyled
        isLarge={isLarge}
        fullscreen={fullscreen}
        fixedHeight={fixedHeight}
        onClick={(e) => e.stopPropagation()}
      >
        {(header || hasCloseButton) && (
          <ModalHeaderStyled
            alignment={headerAlignment}
            hasHeaderBorder={hasHeaderBorder}
            headerColour={headerStyle}
          >
            <ModalHeaderTextWrapper>
              {header && (
                <ModalHeaderText id={`${id}Title`} headerColour={headerStyle}>
                  {header}
                </ModalHeaderText>
              )}
              {HeaderLogo && <ModalLogoWrapper>{HeaderLogo}</ModalLogoWrapper>}
            </ModalHeaderTextWrapper>
            {hasCloseButton && (
              <CloseButtonStyled type='button' onClick={handleClose}>
                <ModalCloseButton />
              </CloseButtonStyled>
            )}
          </ModalHeaderStyled>
        )}
        <ModalInner id={`${id}Content`}>{children}</ModalInner>
      </ModalContentStyled>
    </ModalWrapperStyled>
  )
}

export default Modal
