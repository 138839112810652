import React from 'react'
import config from '../../../config'
import {
  PolicyDocumentLink,
  PolicyDocumentName,
  PolicyDocumentListItem,
} from './styles'

interface Props {
  document: Record<string, any>
  quoteId: string
}

export const PolicyDocument = ({ document, quoteId }: Props) => {
  const href =
    `${config.BASE_API_URL}/api/motorQuotePolicies/{encryptedPolicyId}/documents/{documentTypeId}`
      .replace(/{[encryptedPolicyId}]*}/, quoteId)
      .replace('{documentTypeId}', document.DocumentUnitTypeId)
  const id = `DocumentDownload${document.DocumentUnitTypeId}`
  return (
    <PolicyDocumentListItem>
      <PolicyDocumentLink href={href} target='_blank' id={id}>
        <i className='far fa-file-pdf' />
        <PolicyDocumentName>{document.DocumentName}</PolicyDocumentName>
      </PolicyDocumentLink>
    </PolicyDocumentListItem>
  )
}
