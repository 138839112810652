import { useSelector } from 'react-redux'
import { InitialStateType } from 'initialState'

const useQueryString = () => {
  const queryString = useSelector(
    (state: InitialStateType) => state.queryString,
  )
  return {
    queryString,
  }
}

export default useQueryString
