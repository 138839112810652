export const UPDATE_QUOTE = 'src/RiskCollection/UPDATE_QUOTE'
export const UPDATE_QUOTE_SUCCESS = 'src/RiskCollection/UPDATE_QUOTE_SUCCESS'

export const UPDATE_QUOTE_FAILURE = 'src/RiskCollection/UPDATE_QUOTE_FAILURE'

export const UPDATE_RISK_DATA = 'src/RiskCollection/UPDATE_RISK_DATA'

export const UPDATE_VALIDATION_RISK_DATA_COLLECTION =
  'src/RiskCollection/UPDATE_VALIDATION_RISK_DATA_COLLECTION'

export const UPDATE_QUOTE_LINK_REL = 'UpdateQuote'

export const TOGGLE_FORM_IS_VALID_RISK_DATA_COLLECTION =
  'src/RiskCollection/TOGGLE_FORM_IS_VALID_RISK_DATA_COLLECTION'

export const INIT_RISK_DATA_COLLECTION_PAGE =
  'src/RiskCollection/INIT_RISK_DATA_COLLECTION_PAGE'
export const CONFIRM_RISK_DATA_COLLECTION_PAGE =
  'src/RiskCollection/CONFIRM_RISK_DATA_COLLECTION_PAGE'

export const REQUEST_IDENTITY_CHECK =
  'src/RiskCollection/REQUEST_IDENTITY_CHECK'
export const REQUEST_IDENTITY_CHECK_SUCCESS =
  'src/RiskCollection/REQUEST_IDENTITY_CHECK_SUCCESS'
export const REQUEST_IDENTITY_CHECK_MORE_INFO =
  'src/RiskCollection/REQUEST_IDENTITY_CHECK_MORE_INFO'
export const REQUEST_IDENTITY_CHECK_FAILURE =
  'src/RiskCollection/REQUEST_IDENTITY_CHECK_FAILURE'

export const DRIVING_LICENCE_CHECK = 'src/RiskCollection/DRIVING_LICENCE_CHECK'
export const PASSPORT_CHECK = 'src/RiskCollection/PASSPORT_CHECK'

export const REQUEST_IDENTITY_LINK_REL = 'CheckIdentity'
export const REQUEST_IDENTITY_DRIVING_LICENCE_REL = 'DrivingLicenceCheck'
export const REQUEST_IDENTITY_PASSPORT_REL = 'PassportCheck'

export const UPDATE_UNDERWRITER = 'src/RiskCollection/UPDATE_UNDERWRITER'
export const UPDATE_UNDERWRITER_SUCCESS =
  'src/RiskCollection/UPDATE_UNDERWRITER_SUCCESS'

export const UPDATE_UNDERWRITER_LINK_REL = 'UpdateQuotePriceAndUnderwriter'

export const GET_DECLARATIONS_LINK_REL = 'GetAllQuoteDeclarations'

export const FORM_MANUALLY_EDITED = 'src/RiskCollection/FORM_MANUALLY_EDITED'

export const CLEAR_IDENTITY_CHECK = 'src/RiskCollection/CLEAR_IDENTITY_CHECK'

export const IS_LOADING = 'src/RiskCollection/IS_LOADING'

export const IS_BUTTON_DISABLED = 'src/RiskCollection/IS_BUTTON_DISABLED'
export const ENABLE_ALL_BUTTONS = 'src/RiskCollection/ENABLE_ALL_BUTTONS'
export const UNDERWRITER_CHANGED_BY_CUE =
  'src/RiskCollection/UNDERWRITER_CHANGED_BY_CUE'

export const SAVE_CUSTOMER_DRIVING_LICENCE =
  'src/RiskCollection/SAVE_CUSTOMER_DRIVING_LICENCE'

export const DOES_ACCOUNT_EXIST = 'src/RiskCollection/DOES_ACCOUNT_EXIST'
