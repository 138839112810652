import { call, takeLatest, select, put } from 'redux-saga/effects'
import queryString from 'query-string'
import { REQUEST_CALLBACK } from './constants'
import { requestAnnualCallbackRelSelector } from './selector'
import policyApi from '../../api/policyApi'
import { requestCallbackSuccess, requestCallbackFailure } from './actions'
import { HTTP_CODES } from '../../services/constants'

export function* requestAnnualCallback(action) {
  const requestAnnualCallbackLink = yield select(
    requestAnnualCallbackRelSelector,
  )
  const parsedQueryString = queryString.parse(window.location.search)
  let policyId
  if (parsedQueryString.q) {
    policyId = parsedQueryString.q
    const requestAnnualCallbackEndpoint = requestAnnualCallbackLink.Href

    let redirectUrl = action.data.provider.RedirectUrl

    ;(action.data.urlReplacement || []).forEach((element) => {
      redirectUrl = redirectUrl.replace(`{${element.key}}`, element.value)
    })

    const data = {
      policyId: {
        Value: policyId,
      },
      Source: action.data.linkType,
      AnnualInsuranceProvider: action.data.provider.Id,
    }

    try {
      const response = yield call(
        policyApi.requestAnnualCallback,
        requestAnnualCallbackEndpoint,
        data,
      )

      if (response.status === HTTP_CODES.SUCCESS.OK) {
        yield put(requestCallbackSuccess())
      } else {
        yield put(
          requestCallbackFailure({
            httpCode: (response && response.status) || 400,
          }),
        )
      }

      window.location = redirectUrl
    } catch (e) {
      yield put(
        requestCallbackFailure({
          httpCode: (e.response && e.response.status) || 400,
        }),
      )
      window.location = redirectUrl
    }
  }
}

export default function* annualUpsellSaga() {
  yield takeLatest(REQUEST_CALLBACK, requestAnnualCallback)
}
