import styled from 'styled-components'
import PropTypes from 'prop-types'

export const Button = styled.button`
  background: ${(props) =>
    props.disabled
      ? props.theme.button.disabledBackground
      : props.theme.button.backgroundColour};
  color: ${(props) =>
    props.disabled
      ? props.theme.button.disabledFont
      : props.theme.button.textColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  font-size: ${(props) => props.theme.button.fontSize};
  font-family: ${(props) => props.theme.button.fontFamily};
  text-transform: ${(props) => props.theme.button.textTransform};
  text-align: ${(props) => props.theme.button.textAlign};
  box-shadow: none;
  border: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  height: ${(props) => props.theme.formControlHeight};

  :hover {
    filter: ${(props) => (props.disabled ? null : 'brightness(80%)')};
  }
`

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}
