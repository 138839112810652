import React from 'react'
import { Grid } from 'unsemantic'
import { ErrorIcon, InfoIcon, Container } from './ErrorIcon'
import { MessageHeader } from './MessageHeader'
import { MessageContent } from './MessageContent'

export const Message = ({ heading, content, type, ...props }) => {
  return (
    <>
      <Container ref={props.reference} type={type}>
        <Grid desktop='20' tablet='20' mobile='20'>
          {type === 'error' && (
            <ErrorIcon type={type} className='fa fa-exclamation-triangle' />
          )}
          {type === 'info' && (
            <InfoIcon type={type} className='fa fa-info-circle' />
          )}
        </Grid>
        <Grid desktop='80' tablet='80' mobile='80'>
          <MessageHeader>{heading}</MessageHeader>
          <MessageContent>{content}</MessageContent>
        </Grid>
      </Container>
    </>
  )
}
