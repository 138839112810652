export const store = {
  _antiForgeryToken: '',
  _authToken: '',
  _sessionToken: '',

  set antiForgeryToken(token) {
    this._antiForgeryToken = token
    localStorage.setItem('antiForgeryToken', token)
  },

  set authToken(token) {
    this._authToken = token
    localStorage.setItem('authToken', token)
  },

  set sessionToken(token) {
    this._sessionToken = token
    localStorage.setItem('sessionToken', token)
  },

  get authToken() {
    return this._authToken || localStorage.getItem('authToken')
  },

  get antiForgeryToken() {
    return this._antiForgeryToken || localStorage.getItem('antiForgeryToken')
  },

  get sessionToken() {
    return this._sessionToken || localStorage.getItem('sessionToken')
  },
}
