import React from 'react'

export const CommercialVehicle = ({ width, height }) => {
  const opts = {}

  if (width) {
    opts.width = width
  }

  if (height) {
    opts.height = height
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 135 135.001' {...opts}>
      <g id='Group_317' data-name='Group 317' transform='translate(2825 8785)'>
        <path
          id='Path_317'
          data-name='Path 317'
          d='M-716.277,1052.011a67.058,67.058,0,0,1-47.73-19.771,67.058,67.058,0,0,1-19.77-47.73,67.058,67.058,0,0,1,19.77-47.73,67.057,67.057,0,0,1,47.73-19.77,67.058,67.058,0,0,1,47.73,19.77,67.059,67.059,0,0,1,19.77,47.73,67.059,67.059,0,0,1-19.77,47.73A67.059,67.059,0,0,1-716.277,1052.011Zm0-132.355a64.928,64.928,0,0,0-64.854,64.854,64.928,64.928,0,0,0,64.854,64.855,64.928,64.928,0,0,0,64.854-64.855A64.928,64.928,0,0,0-716.277,919.656Z'
          transform='translate(-2041.223 -9702.01)'
          fill='#264694'
        ></path>
        <g
          id='Group_284'
          data-name='Group 284'
          transform='translate(-2799.341 -8743.149)'
        >
          <rect
            id='Rectangle_516'
            data-name='Rectangle 516'
            width='5.514'
            height='16.544'
            transform='translate(64.738 9.596)'
            fill='#ccdcff'
          ></rect>
          <rect
            id='Rectangle_517'
            data-name='Rectangle 517'
            width='8.272'
            height='41.361'
            transform='translate(1.323 1.324)'
            fill='#ccdcff'
          ></rect>
          <path
            id='Path_283'
            data-name='Path 283'
            d='M-611.22,1139.373l5.514-5.515h19.3v5.515Z'
            transform='translate(645.63 -1096.688)'
            fill='#ccdcff'
          ></path>
          <g
            id='Group_275'
            data-name='Group 275'
            transform='translate(4.08 31.655)'
          >
            <path
              id='Path_284'
              data-name='Path 284'
              d='M-694.875,1118.9c.228,0,.452.012.675.029a8.251,8.251,0,0,0-6.19-2.787,8.272,8.272,0,0,0-8.271,8.272,8.256,8.256,0,0,0,.472,2.757h5.042A8.272,8.272,0,0,1-694.875,1118.9Z'
              transform='translate(708.661 -1116.142)'
              fill='#ccdcff'
            ></path>
            <path
              id='Path_285'
              data-name='Path 285'
              d='M-526.568,1118.9c.227,0,.452.012.675.029a8.25,8.25,0,0,0-6.19-2.787,8.272,8.272,0,0,0-8.271,8.272,8.253,8.253,0,0,0,.472,2.757h5.042A8.272,8.272,0,0,1-526.568,1118.9Z'
              transform='translate(592.74 -1116.142)'
              fill='#ccdcff'
            ></path>
          </g>
          <path
            id='Path_322'
            data-name='Path 322'
            d='M-685.6,1139.94a9.606,9.606,0,0,1-9.594-9.595,9.606,9.606,0,0,1,9.594-9.6,9.606,9.606,0,0,1,9.594,9.6A9.606,9.606,0,0,1-685.6,1139.94Zm0-16.544a6.957,6.957,0,0,0-6.949,6.949,6.957,6.957,0,0,0,6.949,6.949,6.957,6.957,0,0,0,6.949-6.949A6.957,6.957,0,0,0-685.6,1123.4Z'
            transform='translate(703.466 -1087.66)'
            fill='#264694'
          ></path>
          <path
            id='Path_323'
            data-name='Path 323'
            d='M-517.294,1139.94a9.606,9.606,0,0,1-9.594-9.595,9.606,9.606,0,0,1,9.594-9.6,9.605,9.605,0,0,1,9.594,9.6A9.606,9.606,0,0,1-517.294,1139.94Zm0-16.544a6.957,6.957,0,0,0-6.949,6.949,6.957,6.957,0,0,0,6.949,6.949,6.957,6.957,0,0,0,6.949-6.949A6.957,6.957,0,0,0-517.294,1123.4Z'
            transform='translate(587.546 -1087.66)'
            fill='#264694'
          ></path>
          <path
            id='Path_329'
            data-name='Path 329'
            d='M-592.677,1149.971h-30.329a1.323,1.323,0,0,1-1.323-1.323,1.323,1.323,0,0,1,1.323-1.323h30.329a1.323,1.323,0,0,1,1.323,1.323A1.323,1.323,0,0,1-592.677,1149.971Z'
            transform='translate(654.658 -1105.964)'
            fill='#264694'
          ></path>
          <path
            id='Path_330'
            data-name='Path 330'
            d='M-469.488,1151.575'
            transform='translate(548.012 -1108.892)'
            fill='none'
            stroke='#264694'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='8.5'
          ></path>
          <path
            id='Path_331'
            data-name='Path 331'
            d='M-637.732,1058.458h-5.515a1.323,1.323,0,0,1-1.323-1.323,1.323,1.323,0,0,1,1.323-1.323h5.515a4.671,4.671,0,0,0,3.909-2.869h-1.152a1.323,1.323,0,0,1-1.323-1.323v-10.629l-10.414-15.622H-665.3a1.323,1.323,0,0,1-1.323-1.323V1017.1h-52.5v37.281a1.327,1.327,0,0,0,1.434,1.434h5.515a1.323,1.323,0,0,1,1.323,1.323,1.323,1.323,0,0,1-1.323,1.323h-5.515a3.965,3.965,0,0,1-4.08-4.08v-38.6a1.323,1.323,0,0,1,1.323-1.323H-665.3a1.323,1.323,0,0,1,1.323,1.323v6.949H-646a1.321,1.321,0,0,1,1.1.589l11.029,16.544a1.326,1.326,0,0,1,.222.734v9.707h1.435a1.323,1.323,0,0,1,1.323,1.323A7.2,7.2,0,0,1-637.732,1058.458Z'
            transform='translate(721.77 -1014.451)'
            fill='#264694'
          ></path>
          <path
            id='Path_332'
            data-name='Path 332'
            d='M-579.888,1068.488h-16.543a1.323,1.323,0,0,1-1.323-1.323,1.323,1.323,0,0,1,1.323-1.323h15.22v-23.493a1.323,1.323,0,0,1,1.323-1.323,1.323,1.323,0,0,1,1.323,1.323v24.817A1.323,1.323,0,0,1-579.888,1068.488Z'
            transform='translate(636.355 -1032.754)'
            fill='#264694'
          ></path>
          <path
            id='Path_333'
            data-name='Path 333'
            d='M-494.649,1060.216h-22.057a1.323,1.323,0,0,1-1.323-1.323v-16.545a1.323,1.323,0,0,1,1.323-1.323,1.323,1.323,0,0,1,1.323,1.323v15.222h20.734a1.323,1.323,0,0,1,1.323,1.323A1.323,1.323,0,0,1-494.649,1060.216Z'
            transform='translate(581.445 -1032.754)'
            fill='#264694'
          ></path>
          <path
            id='Path_376'
            data-name='Path 376'
            d='M-451.941,1132.254H-454.7a1.323,1.323,0,0,1-1.323-1.323,1.323,1.323,0,0,1,1.323-1.323h2.757a1.323,1.323,0,0,1,1.323,1.323A1.323,1.323,0,0,1-451.941,1132.254Z'
            transform='translate(538.737 -1093.762)'
            fill='#264694'
          ></path>
        </g>
      </g>
    </svg>
  )
}
