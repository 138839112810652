import styled from 'styled-components'

export const BrandLogo = styled.div`
  width: 100%;
  max-width: calc(100vw - 95px);
  margin-left: 10px;
`

export const BrandLogoAlign = styled.div`
  width: 100%;
  transform: none;
  display: flex;
  max-width: 500px;
  height: 68px;

  @media (max-width: 1024px) {
    height: 36px;
  }

  @media (max-width: 400px) {
    max-width: ${(prop) => (prop.limitMaxWidth ? '120px' : '500px')};
  }
`

export const Link = styled.a`
  display: flex;
  height: 100%;
  column-gap: 10px;
  position: relative;

  @media (max-width: 530px) {
    column-gap: 5px;
  }

  img,
  svg {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    max-height: 68px;
    max-width: 350px;

    @media (max-width: 1024px) {
      max-height: 100%;
      max-width: 200px;
    }

    &.hide-strapline {
      .strapline {
        display: none;
      }
    }
  }
`

export const AssociationText = styled.p`
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  margin: 0;
  width: auto;
  white-space: nowrap;
  color: #fff;
  font-size: 18px;

  @media (max-width: 1024px) {
    font-size: 10px;
  }

  @media (max-width: 530px) {
    line-height: 1;
  }

  @media (max-width: 400px) {
    left: 10px;
  }
`
export const AssociationTextSemi = styled.p`
  position: absolute;
  width: auto;
  white-space: nowrap;
  bottom: -1rem;
  right: 1rem;
  color: white;
  font-size: 0.85rem;

  @media (max-width: 1024px) {
    bottom: -1.2rem;
    left: 49%;
    font-size: 12px;
  }

  @media (max-width: 530px) {
    font-size: 10px;
    bottom: -0.9rem;
  }

  @media (max-width: 400px) {
    left: 20px;
  }
`

export const SemiBrandedLogoWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 9rem;
  left: calc(100% - 0.5rem);
  display: grid;
  align-items: end;

  @media(max-width: 1024px) {
    left: calc(100% + 0.5rem);
    margin-bottom: -0.2rem;
  }

  @media(max-width: 530px) {
    left: 100%;
  }

  @media(max-width: 400px) {
    left: calc(100% - 0.2rem);
  }

  img {
    position: absolute;
    height: auto;
    padding: 0.25rem;
    border-radius: 0.25rem;
    background: white;
    width: auto;
    max-height: 100%
    max-width: 100%;
  }
`
