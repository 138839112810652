import {
  REQUEST_CREATE_UNKNOWN_VEHICLE,
  REQUEST_CREATE_UNKNOWN_VEHICLE_SUCCESS,
  REQUEST_CREATE_UNKNOWN_VEHICLE_FAILED,
} from './constants'

export function requestCreateUnkownVehicle(unknownVehicle) {
  return {
    type: REQUEST_CREATE_UNKNOWN_VEHICLE,
    unknownVehicle,
  }
}

export function requestCreateUnknownVehicleSuccess(vehicle) {
  return {
    type: REQUEST_CREATE_UNKNOWN_VEHICLE_SUCCESS,
    vehicle,
  }
}

export function requestCreateUnknownVehicleFailed(message) {
  return {
    type: REQUEST_CREATE_UNKNOWN_VEHICLE_FAILED,
    message,
  }
}
