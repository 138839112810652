import React from 'react'

export const Impounded = ({ width, height }) => {
  const opts = {}

  if (width) {
    opts.width = width
  }

  if (height) {
    opts.height = height
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 134.997 134.998'
      {...opts}
    >
      <g
        id='Group_321'
        data-name='Group 321'
        transform='translate(2481.958 8785)'
      >
        <path
          id='Path_343'
          data-name='Path 343'
          d='M771.91,1052.008a67.5,67.5,0,1,1,47.729-19.77A67.057,67.057,0,0,1,771.91,1052.008Zm0-132.352a64.853,64.853,0,1,0,64.853,64.853A64.927,64.927,0,0,0,771.91,919.656Z'
          transform='translate(-3186.369 -9702.01)'
          fill='#264694'
        ></path>
        <g
          id='Group_292'
          data-name='Group 292'
          transform='translate(-2472.374 -8751.25)'
        >
          <rect
            id='Rectangle_519'
            data-name='Rectangle 519'
            width='5.515'
            height='8.272'
            transform='translate(85.43 23.753)'
            fill='#ccdcff'
          ></rect>
          <path
            id='Path_351'
            data-name='Path 351'
            d='M953.766,1056.285a1.345,1.345,0,0,1-.349-.047,1.324,1.324,0,0,1-.928-1.625l8.272-30.331a1.323,1.323,0,0,1,2.425-.308l11.03,19.3a1.323,1.323,0,0,1-1.149,1.979h-4.192v6.949a1.323,1.323,0,1,1-2.646,0v-8.272a1.323,1.323,0,0,1,1.323-1.323h3.235l-8.314-14.549-7.431,27.25A1.324,1.324,0,0,1,953.766,1056.285Z'
            transform='translate(-882.122 -1020.179)'
            fill='#264694'
          ></path>
          <path
            id='Path_289'
            data-name='Path 289'
            d='M751.536,1145.8l24.478-4.085,6.347,4.531-32.636,5.447Z'
            transform='translate(-742.504 -1101.729)'
            fill='#ccdcff'
          ></path>
          <path
            id='Path_290'
            data-name='Path 290'
            d='M915.627,1081.569l-1.362-8.159,7.705-4.082,2.72-.454,2.723,16.319a5.93,5.93,0,0,1-4.531,6.347l-2.72.454Z'
            transform='translate(-855.828 -1051.561)'
            fill='#ccdcff'
          ></path>
          <g
            id='Group_276'
            data-name='Group 276'
            transform='translate(54.152 29.893)'
          >
            <path
              id='Path_291'
              data-name='Path 291'
              d='M910.682,1119.829a8.271,8.271,0,0,0-9.521-6.8c-.225.037-.444.086-.661.14a8.271,8.271,0,0,1,15.252,5.074,4.991,4.991,0,0,1-2.351,1.129Z'
              transform='translate(-900.5 -1109.29)'
              fill='#ccdcff'
            ></path>
          </g>
          <path
            id='Path_294'
            data-name='Path 294'
            d='M753.344,1086.5a41.684,41.684,0,0,0,5.741-5.193c3.2-3.274,6.216-6.366,10.623-7.1l15.612-2.605.681,4.082-16.764,2.8C762.009,1079.69,762.5,1084.975,753.344,1086.5Z'
            transform='translate(-744.997 -1053.442)'
            fill='#ccdcff'
          ></path>
          <path
            id='Path_305'
            data-name='Path 305'
            d='M1009.843,1116.142'
            transform='translate(-921.655 -1084.115)'
            fill='none'
            stroke='#264694'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='8.5'
          ></path>
          <path
            id='Path_306'
            data-name='Path 306'
            d='M997.641,1116.142v19.3h-2.757a2.7,2.7,0,0,1-2.757-2.757v-16.544Z'
            transform='translate(-909.453 -1084.115)'
            fill='#ccdcff'
          ></path>
          <path
            id='Path_307'
            data-name='Path 307'
            d='M1024.216,1065.749v8.272h5.515v5.514H1018.7v-16.544Z'
            transform='translate(-927.756 -1047.51)'
            fill='#ccdcff'
          ></path>
          <path
            id='Path_315'
            data-name='Path 315'
            d='M1000.4,1142.717a8.272,8.272,0,0,0-8.272,8.271,2.7,2.7,0,0,0,2.757,2.758h2.757a8.272,8.272,0,0,1,8.272-8.272c.228,0,.452.012.675.03A8.25,8.25,0,0,0,1000.4,1142.717Z'
            transform='translate(-909.453 -1102.419)'
            fill='#ccdcff'
          ></path>
          <path
            id='Path_334'
            data-name='Path 334'
            d='M885.76,1135.887a9.6,9.6,0,1,1,1.585-.132A9.61,9.61,0,0,1,885.76,1135.887Zm.009-16.545a6.949,6.949,0,1,0,6.85,5.805A6.923,6.923,0,0,0,885.769,1119.342Z'
            transform='translate(-829.59 -1084.498)'
            fill='#264694'
          ></path>
          <path
            id='Path_336'
            data-name='Path 336'
            d='M746.8,1159.112a1.323,1.323,0,0,1-.216-2.628l35.356-5.9a1.323,1.323,0,1,1,.435,2.61l-35.356,5.9A1.342,1.342,0,0,1,746.8,1159.112Z'
            transform='translate(-739.577 -1107.825)'
            fill='#264694'
          ></path>
          <path
            id='Path_337'
            data-name='Path 337'
            d='M750.418,1084.9a1.323,1.323,0,0,1-.76-2.407,40.769,40.769,0,0,0,5.553-5.034c3.205-3.282,6.518-6.675,11.352-7.482l15.612-2.606a1.326,1.326,0,0,1,1.523,1.087l.681,4.083a1.323,1.323,0,0,1-2.61.436l-.463-2.778L767,1072.587c-3.98.665-6.721,3.47-9.894,6.72a42.71,42.71,0,0,1-5.93,5.353A1.316,1.316,0,0,1,750.418,1084.9Z'
            transform='translate(-742.07 -1050.515)'
            fill='#264694'
          ></path>
          <path
            id='Path_338'
            data-name='Path 338'
            d='M790.861,1081.376a1.323,1.323,0,0,1-.216-2.628l2.72-.454a4.606,4.606,0,0,0,3.444-4.825l-2.505-15.013-1.415.236a1.344,1.344,0,0,1-.472-.006l-22.884-4.482-18.8,3.138c-5.4.9-8.922,4.505-12.325,7.99-3.149,3.225-6.123,6.271-10.337,6.974a1.323,1.323,0,1,1-.435-2.61c3.36-.561,5.918-3.18,8.879-6.213,3.528-3.612,7.526-7.707,13.783-8.751l19.038-3.177a1.321,1.321,0,0,1,.472.007l22.884,4.481,2.483-.414a1.325,1.325,0,0,1,1.522,1.087l2.723,16.318a7.2,7.2,0,0,1-5.619,7.87l-2.72.454A1.338,1.338,0,0,1,790.861,1081.376Z'
            transform='translate(-726.528 -1039.621)'
            fill='#264694'
          ></path>
          <path
            id='Path_344'
            data-name='Path 344'
            d='M1015.188,1166.515a9.6,9.6,0,1,1,9.595-9.6A9.606,9.606,0,0,1,1015.188,1166.515Zm0-16.544a6.949,6.949,0,1,0,6.949,6.949A6.957,6.957,0,0,0,1015.188,1149.971Z'
            transform='translate(-918.728 -1105.593)'
            fill='#264694'
          ></path>
          <path
            id='Path_349'
            data-name='Path 349'
            d='M994.714,1133.839h-2.758a3.965,3.965,0,0,1-4.08-4.08v-16.544a1.323,1.323,0,0,1,1.323-1.323h27.573a1.323,1.323,0,0,1,0,2.646h-26.25v15.221a1.327,1.327,0,0,0,1.434,1.434h2.758a1.323,1.323,0,1,1,0,2.646Z'
            transform='translate(-906.526 -1081.189)'
            fill='#264694'
          ></path>
          <path
            id='Path_350'
            data-name='Path 350'
            d='M1026.8,1077.932a1.323,1.323,0,0,1-1.323-1.323V1066.4l-8.383-4.192v14.4a1.323,1.323,0,0,1-2.646,0v-16.544a1.323,1.323,0,0,1,1.915-1.183l11.029,5.514a1.324,1.324,0,0,1,.731,1.184v11.029A1.323,1.323,0,0,1,1026.8,1077.932Z'
            transform='translate(-924.829 -1044.584)'
            fill='#264694'
          ></path>
          <path
            id='Path_352'
            data-name='Path 352'
            d='M991.371,1030.062a1.322,1.322,0,0,1-1.15-.667l-7.2-12.6-1.714,1.825a1.323,1.323,0,1,1-1.928-1.812l2.939-3.128a1.323,1.323,0,0,1,2.113.249l8.091,14.158a1.323,1.323,0,0,1-1.148,1.979Z'
            transform='translate(-900.424 -1013.258)'
            fill='#264694'
          ></path>
          <path
            id='Path_353'
            data-name='Path 353'
            d='M1065.58,1176.545h-5.515a1.323,1.323,0,1,1,0-2.646h5.515a1.323,1.323,0,0,1,0,2.646Z'
            transform='translate(-955.333 -1123.895)'
            fill='#264694'
          ></path>
        </g>
      </g>
    </svg>
  )
}
