export const motorcycleOvernightParkingOptions = [
  {
    id: 2,
    value: 'BrickLockedBuilding',
    description: 'Brick, locked building',
  },
  {
    id: 3,
    value: 'WoodenLockedBuilding',
    description: 'Wooden, locked building',
  },
  {
    id: 4,
    value: 'OnDrive',
    description: 'On drive',
  },
  {
    id: 5,
    value: 'OnRoad',
    description: 'On road',
  },
  {
    id: 6,
    value: 'Other',
    description: 'Other, please specify',
  },
]
