import { getPolicySummary } from 'api/policy/policy.api'
import LoadingScreen from 'components/LoadingScreen'
import { useQueryString, useRiskData } from 'hooks'
import { history } from 'index'
import React from 'react'
import { useQuery } from 'react-query'

const PolicyInProgress: React.FC = () => {
  const { quoteId } = useRiskData()
  const { queryString } = useQueryString()
  const { data, isSuccess } = useQuery(
    ['getPolicy', quoteId],
    () => getPolicySummary(quoteId),
    {
      refetchInterval: 500,
      retry: true,
    },
  )

  // Get quote with query string
  // If is policy then redirect
  // Otherwise stay here and keep polling.
  if (data && isSuccess) {
    history.push({
      pathname: '/policy/confirmation',
      search: `${queryString}`,
    })
  }
  return <LoadingScreen message='Please wait, your policy is generating...' />
}

export default PolicyInProgress
