import {
  REQUEST_OCCUPATIONS_SUCCESS,
  REQUEST_OCCUPATIONS_FAILED,
  SELECT_OCCUPATION,
  UPDATE_OCCUPATION_SEARCH,
} from './constants'
import { REQUEST_QUOTE_SUCCESS, CREATE_QUOTE_SUCCESS } from '../App/constants'
import initialState from '../../initialState'

function occupationReducer(state = initialState.occupation, action) {
  switch (action.type) {
    case REQUEST_OCCUPATIONS_SUCCESS:
      return {
        ...state,
        SearchResults: action.occupations,
        FailedSearch: false,
      }
    case REQUEST_OCCUPATIONS_FAILED:
      return {
        ...state,
        SearchResults: [],
        FailedSearch: true,
      }
    case REQUEST_QUOTE_SUCCESS:
      const occupation = action.quote.Occupation
      return {
        ...state,
        SelectedOccupation: occupation,
        OccupationSearch: occupation == null ? '' : occupation.OccupationName,
      }
    case SELECT_OCCUPATION:
      return {
        ...state,
        SelectedOccupation: action.occupation,
        OccupationSearch: action.occupation.OccupationName,
      }
    case UPDATE_OCCUPATION_SEARCH:
      return {
        ...state,
        SelectedOccupation: null,
        OccupationSearch: action.occupationSearch,
      }
    case CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        OccupationSearch: '',
        SearchResults: [],
        SelectedOccupation: null,
      }
    default:
      return state
  }
}

export default occupationReducer
