import { useIdentity } from 'hooks'
import React, { useState } from 'react'
import styled from 'styled-components'
import validator from 'validator'
import { ErrorMessage } from '../common/ErrorMessage'
import { UppercaseInput } from '../common/Input/UppercaseInput'
import { FormControlValidIcon } from '../FormControlValidIcon'
import { FormControlWrapper } from '../FormControlWrapper'

const inputStyle = {
  width: '50%',
  display: 'inline-block',
}

const LicenceEntryWrapper = styled.div`
  display: inline;
`

const DrivingLicenceNumber = ({ error, onChange, handleValidation, value }) => {
  const { licenceNumber } = useIdentity()
  value.DrivingLicenceNumberFirstPart = licenceNumber
  const [firstPart] = useState(licenceNumber)

  const handleChangeValidation = (event) => {
    const licenceNumber = firstPart + event.target.value.toUpperCase()
    let message = validator.matches(
      licenceNumber,
      /^[A-Z9]{5}\d{6}[A-Z9]{2}\d[A-Z]{2}$/,
    )
      ? ''
      : 'Please enter a valid driving licence number'

    handleValidation(event.target.name, message)
  }

  const handleChange = (event) => {
    handleChangeValidation(event)
    onChange(event)
  }

  return (
    <>
      <FormControlWrapper>
        <FormControlValidIcon
          error={error}
          className='fas fa-check'
        ></FormControlValidIcon>
        <LicenceEntryWrapper>
          <div style={inputStyle}>
            <UppercaseInput
              maxLength='12'
              name='DrivingLicenceNumberFirstPart'
              value={value.DrivingLicenceNumberFirstPart}
              disabled
            />
          </div>
          <div style={inputStyle}>
            <UppercaseInput
              placeholder='The last 4 digits are...'
              onChange={(e) => handleChange(e)}
              maxLength='4'
              name='DrivingLicenceNumber'
              error={error}
              style={inputStyle}
              value={value.DrivingLicenceNumber}
              id='DrivingLicenceNumber'
            />
          </div>
        </LicenceEntryWrapper>
      </FormControlWrapper>
      <ErrorMessage>{error}</ErrorMessage>
    </>
  )
}

export default DrivingLicenceNumber
