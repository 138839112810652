import { requestPrice } from 'containers/App/sagas/priceSaga'
import { fetchQuote } from 'containers/App/sagas/quoteSaga'
import { getPriceData } from './Klarna.selector'
import { underwriterSaga } from 'containers/RiskDataCollectionPage/sagas/underwriter'
import { put, select, takeLatest } from 'redux-saga/effects'
import { PRICE_CHECK } from './Klarna.constants'
import { getQueryString } from 'containers/RiskDataCollectionPage/selector'
import { enableAllButtons } from 'containers/RiskDataCollectionPage/actions'
import {
  priceMatch,
  setPaymentInProgress,
  setPaymentNotInProgress,
} from 'containers/PaymentPage/actions'
import { priceCheckPaymentPageSaga } from 'containers/PaymentPage/saga'
import { setUnderwriterChanged } from 'containers/App/actions'
import { history } from 'index'
import { klarnaFailureAction, startKlarnaPayment } from './Klarna.actions'

// TODO: Re-evaluate this.
// We have a PBI written up to use an API call for this logic.
function* priceCheck() {
  yield put(startKlarnaPayment(false))
  const currentPriceData = yield select(getPriceData)
  yield* fetchQuote()
  yield* requestPrice()
  yield* underwriterSaga()
  const newPriceData = yield select(getPriceData)
  const queryString = yield select(getQueryString)

  if (!newPriceData.Underwriter && newPriceData.TotalPrice === 0) {
    yield put(enableAllButtons())
    yield put(setPaymentNotInProgress())
    return
  }
  if (
    currentPriceData.Underwriter.toLowerCase() !==
    newPriceData.Underwriter.toLowerCase()
  ) {
    yield* priceCheckPaymentPageSaga()
    yield put(klarnaFailureAction(true))
    yield put(setUnderwriterChanged())
    yield put(priceMatch(true))
    yield put(setPaymentNotInProgress())
    history.push({
      pathname: `/quote/driving-licence`,
      search: `${queryString}`,
    })
    yield put(enableAllButtons())
  } else if (currentPriceData.TotalPrice !== newPriceData.TotalPrice) {
    yield put(klarnaFailureAction(true))
    yield put(priceMatch(false))
    yield put(setPaymentNotInProgress())
    history.push({
      pathname: `/quote/payment`,
      search: `${queryString}`,
    })
    yield put(enableAllButtons())
    yield* priceCheckPaymentPageSaga()
  } else {
    yield put(startKlarnaPayment(true))
  }
}

export default function* klarnaSaga() {
  yield takeLatest(PRICE_CHECK, priceCheck)
}
