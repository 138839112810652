import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { select } from 'redux-saga/effects'
import { Grid, GridClear } from 'unsemantic'
import './style.css'
import { updateRiskData, updateQuote } from '../RiskDataCollectionPage/actions'
import { setVehicleListener, createUnkownVehicle } from '../Vehicle/actions'
import {
  SummaryBorderMobile,
  SummaryBorderLeftMobile,
  SummaryBorderBottomMobile,
} from './SummarySectionStyles'
import {
  updateDurationValue,
  clearAddons,
  setQuoteJourneyPosition,
} from '../App/actions'
import { QuoteJourneyPosition } from 'types/global'
import VehicleSummary from 'components/VehicleSummary'
import PolicyStartTimeSummary from 'components/PolicyStartTimeSummary'
import PolicyDurationSummary from 'components/PolicyDurationSummary'
import { getIsCustomerAccountQuote } from 'containers/RiskDataCollectionPage/selector'

// TODO: Revisit this whole component as it feels really messy
export class QuoteSummary extends React.Component {
  constructor(props) {
    super(props)

    this.updateData = this.updateData.bind(this)
    this.handleDateTimeSet = this.handleDateTimeSet.bind(this)
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.updateQuote) {
      // if vehicle is changed and matches then update quote
      if (
        (prevProps.vehicle.Selected &&
          prevProps.vehicle.Selected.RegistrationNumber !==
            this.props.vehicle.selected.RegistrationNumber) ||
        prevProps.duration !== this.props.duration ||
        prevProps.riskData.PolicyStartDate !==
          this.props.riskData.PolicyStartDate
      ) {
        if (
          prevProps.vehicle.selected.VehicleType ===
          this.props.vehicle.selected.VehicleType
        ) {
          this.props.actions.updateQuote({
            partialValidation: true,
            getPrice: true,
          })
        }
      }
    }
  }

  updateData(payload) {
    if (payload.durationDetails) {
      const isDiffDuration =
        `${this.props.duration.value} ${this.props.duration.type}`.toLowerCase() !==
        `${payload.durationDetails.value} ${payload.durationDetails.type}`.toLowerCase()
      if (isDiffDuration) {
        this.props.actions.updateDurationValue({
          value: payload.durationDetails.value,
          type: payload.durationDetails.type,
        })
        this.props.actions.clearAddons()
      }
    }
    if (payload.vehicle) {
      this.props.actions.setQuoteJourneyPosition(
        QuoteJourneyPosition.CustomerVehicleChanged,
      )
      if (this.props.vehicle.searched.VehicleFound) {
        this.props.actions.setVehicleListener(this.props.vehicle.searched)
      } else {
        this.props.actions.createUnkownVehicle()
      }

      this.props.actions.clearAddons()
    }
  }

  handleDateTimeSet = (dateTime, startImmediatly) => {
    const isCustomerAccount = select(getIsCustomerAccountQuote)

    if (isCustomerAccount) {
      this.props.actions.setQuoteJourneyPosition(
        QuoteJourneyPosition.CustomerAccountPolicyDateChanged,
      )
    } else {
      this.props.actions.setQuoteJourneyPosition(
        QuoteJourneyPosition.CustomerPolicyDateChanged,
      )
    }

    this.props.actions.updateRiskData({
      PolicyStartDate: { value: dateTime, manuallyEdited: startImmediatly },
    })
    this.props.actions.clearAddons()
  }

  render() {
    return (
      <Grid parent>
        <Grid desktop-hide tablet-hide mobile='100'>
          <SummaryBorderMobile noBottomRadius={this.props.noBottomRadius}>
            <Grid parent mobile='60'>
              <VehicleSummary
                id='VehicleSummaryMobile'
                handleUpdate={() =>
                  this.updateData({
                    vehicle: true,
                  })
                }
                hideValue
              />
            </Grid>
            <Grid parent mobile='40'>
              <SummaryBorderLeftMobile>
                <Grid parent mobile='100'>
                  <SummaryBorderBottomMobile>
                    <PolicyDurationSummary
                      id='PolicyDurationSummaryMobile'
                      handleUpdate={(duration, durationType) =>
                        this.updateData({
                          durationDetails: {
                            value: duration,
                            type: durationType,
                          },
                        })
                      }
                    />
                  </SummaryBorderBottomMobile>
                </Grid>
                <Grid parent mobile='100'>
                  <PolicyStartTimeSummary
                    id='PolicyStartSummaryMobile'
                    handleDateTimeSet={this.handleDateTimeSet}
                  />
                </Grid>
              </SummaryBorderLeftMobile>
            </Grid>
            <GridClear />
          </SummaryBorderMobile>
        </Grid>

        <Grid parent desktop='100' tablet='100' mobile-hide>
          <Grid desktop='100' tablet='100'>
            <VehicleSummary
              id='VehicleSummaryDesktop'
              handleUpdate={() =>
                this.updateData({
                  vehicle: true,
                })
              }
              hideValue
            />
          </Grid>
          <Grid desktop='33' tablet='33'>
            <PolicyDurationSummary
              id='PolicyDurationSummaryDesktop'
              handleUpdate={(duration, durationType) =>
                this.updateData({
                  durationDetails: {
                    value: duration,
                    type: durationType,
                  },
                })
              }
            />
          </Grid>
          <Grid desktop='66' tablet='66'>
            <PolicyStartTimeSummary
              id='PolicyStartSummaryDesktop'
              handleDateTimeSet={this.handleDateTimeSet}
            />
          </Grid>
          <Grid desktop='100' tablet='100' parent />
        </Grid>
      </Grid>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    riskData: state.riskData,
    duration: state.duration,
    vehicle: state.vehicle,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateRiskData,
        updateQuote,
        clearAddons,
        setVehicleListener,
        createUnkownVehicle,
        updateDurationValue,
        setQuoteJourneyPosition,
      },
      dispatch,
    ),
  }
}

const QuoteSummaryConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuoteSummary)

export default QuoteSummaryConnected
