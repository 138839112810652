import {
  UPDATE_MYLICENCE_DATA,
  REQUEST_MYLICENCE_STATUS_SUCCESS,
} from './constants'
import initialState from '../../initialState'

export function myLicenceReducer(state = initialState.myLicence, action) {
  switch (action.type) {
    case UPDATE_MYLICENCE_DATA:
      return {
        ...state,
        ...action.payload,
      }
    case REQUEST_MYLICENCE_STATUS_SUCCESS:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}

export default myLicenceReducer
