import React from 'react'
import PropTypes from 'prop-types'
import validator from 'validator'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { updateRiskData } from '../../containers/RiskDataCollectionPage/actions'
import { Select } from '../common/Select'
import { ErrorMessage } from '../common/ErrorMessage'

export class UseOfVehicle extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleValidation = this.handleValidation.bind(this)
  }

  handleChange = (event) => {
    this.handleValidation(event)
    this.props.onChange(event)
  }

  handleValidation = (event) => {
    let message = !validator.isEmpty(event.target.value)
      ? ''
      : "Sorry, we need to know why you're using your vehicle"

    this.props.handleValidation(event.target.name, message)
  }

  render() {
    return (
      <>
        <Select
          id='UseOfVehicle'
          name={this.props.name}
          value={this.props.value}
          placeholder='Please select an option'
          onChange={this.handleChange}
          options={this.props.options}
        />
        <ErrorMessage>{this.props.error}</ErrorMessage>
      </>
    )
  }
}

UseOfVehicle.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  handleValidation: PropTypes.func.isRequired,
  error: PropTypes.string,
}

export const mapStateToProps = (state) => {
  return {
    scheme: state.scheme.selected,
    riskData: state.riskData,
  }
}

export const mapDispatchtoProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateRiskData,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchtoProps)(UseOfVehicle)
