import { H4 } from 'components/common/H4'
import styled from 'styled-components'

export const StripePaymentWrapper = styled.div`
  margin-bottom: 7em;
`

export const PaymentHeaderWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.primaryColour};
  padding: 4px;
  margin-bottom: 16px;
`

export const KlarnaButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const KlarnaText = styled(H4)`
  margin-top: 0;
`
