import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  user-select: none;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`

interface Props {}

export const AddonMoreInfoWrapper = styled.div<Props>`
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  cursor: default;
  width: 70%;
  max-height: 90vh;
  background: #fff;
  color: ${(props) => props.theme.primaryTextColour};
  box-shadow: 0px 0px 15px 0px #707070;
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  padding: 10px;
  z-index: 2;

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;

    & li {
      font-family: ${(props) => props.theme.fontFamily};
      font-size: 14px;
      color: #000;
      text-align: left;

      &:last-child {
        border: none;
      }

      & span {
        font-family: 'BrandonText-Bold', sans-serif;
        display: block;
        color: ${(props) => props.theme.primaryTextColour};
      }
    }
  }

  a,
  p {
    margin: 1em 0 !important;
  }

  @media (max-width: 767px) {
    font-size: 12px;

    & ul li {
      font-size: 12px;
    }
  }
`

export const AddonMoreInfoTitle = styled.h2`
  font-family: ${(props) => props.theme.labelFontFamily};
  font-size: 22px;
  margin-left: 0 !important;
  margin: 5px;
`

export const AddonMoreInfoBody = styled.div`
  font-family: ${(props) => props.theme.labelFontFamily};
  max-height: 60vh;
  overflow-y: auto;
`

export const AddonMoreInfoText = styled.p`
  font-family: ${(props) => props.theme.labelFontFamily};
  font-size: 14px;
`

export const PaymentPageLinkContainer = styled.a`
  font-size: 14px;
  cursor: pointer;
  color: ${(props) => props.theme.primaryTextColour};
  font-family: ${(props) => props.theme.fontFamily};
  text-decoration: underline;

  :visited {
    color: ${(props) => props.theme.primaryTextColour};
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

export const RACBreakDownDetailsContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: block;
  }
`
export const RACBreakdownFeature = styled.div`
  width: 50%;
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 5px;
  }
`

export const RACBreakdownFeatureList = styled.ul`
  list-style-type: none;
`

export const RACBreakdownIncludedFeatureListItem = styled.li`
  list-style-type: none; /* Remove default bullet points */
  position: relative;
  padding-left: 30px; /* Space for the image */
  padding-top: 5px;
  padding-bottom: 5px;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px; /* Adjust the size as needed */
    height: 24px;
    
    /* Background image with the URL-encoded SVG */
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%22100%22%20height%3D%22100%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2245%22%20fill%3D%22green%22%20/%3E%3Cpath%20d%3D%22M30%2050%20L45%2065%20L70%2035%22%20stroke%3D%22white%22%20stroke-width%3D%228%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22/%3E%3C/svg%3E');
    background-size: cover;
    background-repeat: no-repeat;
  }
  }
`

export const RACBreakdownExcludedFeatureListItem = styled.li`
  list-style-type: none; /* Remove default bullet points */
  position: relative;
  padding-left: 30px; /* Space for the image */
  padding-top: 5px;
  padding-bottom: 5px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px; /* Adjust the size as needed */
    height: 24px;

    /* Background image with the URL-encoded SVG */
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%22100%22%20height%3D%22100%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2245%22%20fill%3D%22red%22%20/%3E%3Cline%20x1%3D%2230%22%20y1%3D%2230%22%20x2%3D%2270%22%20y2%3D%2270%22%20stroke%3D%22white%22%20stroke-width%3D%228%22%20stroke-linecap%3D%22round%22%20/%3E%3Cline%20x1%3D%2270%22%20y1%3D%2230%22%20x2%3D%2230%22%20y2%3D%2270%22%20stroke%3D%22white%22%20stroke-width%3D%228%22%20stroke-linecap%3D%22round%22%20/%3E%3C/svg%3E');
    background-size: cover;
    background-repeat: no-repeat;
  }
`

export const RACInlineDocs = styled.ul`
  padding-top: 10px !important;
  display: flex;
  & li {
    padding-right: 10px;
  }
`
