/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
import config from 'config'
import React, { useCallback } from 'react'
import { useAddons, useComponentVisible, useScheme } from '../../../hooks'
import { LinkContainer } from '../Addons.styles'
import {
  AddonMoreInfoBody,
  AddonMoreInfoText,
  AddonMoreInfoTitle,
  AddonMoreInfoWrapper,
  PaymentPageLinkContainer,
  RACBreakDownDetailsContainer,
  RACBreakdownExcludedFeatureListItem,
  RACBreakdownFeature,
  RACBreakdownFeatureList,
  RACBreakdownIncludedFeatureListItem,
  RACInlineDocs,
  Wrapper,
} from './AddonMoreInfo.styles'
import AddonMoreInfomItem from './AddonMoreInfoItem'

interface Props {
  type: string
  isPaymentPage: boolean
}

export const AddonMoreInfo: React.FC<Props> = ({ type, isPaymentPage }) => {
  const { isComponentVisible, ref, setIsComponentVisible } =
    useComponentVisible(false)
  const { scheme } = useScheme()
  const {
    excess: { staticDocuments: excessDocuments },
    breakdown: { staticDocuments: breakdownDocuments },
    legalexpenses: { staticDocuments: legalexpensesDocuments },
    gapinsurance: { staticDocuments: gapinsuranceDocuments },
  } = useAddons()

  const documentStartDate = new Date().toISOString().slice(0, 10)

  const handleClick = useCallback(() => {
    setIsComponentVisible(!isComponentVisible)
  }, [isComponentVisible])

  const AddonMoreInfoContent = () => {
    if (type === 'excess') {
      return (
        <>
          <AddonMoreInfoTitle>
            More info about excess reduction
          </AddonMoreInfoTitle>
          <AddonMoreInfoBody>
            <div>
              <AddonMoreInfoText>
                If you are unfortunate enough to make a claim under your short
                term motor insurance policy, you are responsible for the excess
                shown under the policy. You will need to pay this amount in
                order to get your vehicle repaired or replaced.
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                In the event that the incident was your fault, partly your fault
                or the third party cannot be traced, then your excess reduction
                policy will enable you to recover most of the excess you have
                paid.
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                You will need to provide evidence of the amount you have paid,
                or has been deducted from the settlement figure, to Coplus who
                manage all claims on our behalf; they will send you up to the
                full amount of cover under this policy.
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                View full details of our excess reduction policy here:
              </AddonMoreInfoText>
            </div>
            <ul>
              <AddonMoreInfomItem
                content='Excess Reduction IPID'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${excessDocuments.ipid}/versions/${documentStartDate}`}
              />
              <AddonMoreInfomItem
                content='Excess Reduction Policy Wording'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${excessDocuments.policyWording}/versions/${documentStartDate}`}
              />
            </ul>
          </AddonMoreInfoBody>
        </>
      )
    }

    if (type === 'breakdown') {
      return (
        <>
          <AddonMoreInfoTitle>
            RAC UK Breakdown Cover
          </AddonMoreInfoTitle>
          <AddonMoreInfoBody>
            <div>
              <AddonMoreInfoText>
                Whether you're at home or on the road when you break down, get complete peace of mind with our RAC UK Breakdown Cover. RAC fix 4 out of 5 vehicles at the roadside, and on average within 30 minutes.
              </AddonMoreInfoText>
            </div>
            <RACBreakDownDetailsContainer>
              <RACBreakdownFeature>
                <b>Our RAC UK Breakdown Cover features:</b>
                <RACBreakdownFeatureList>
                  <RACBreakdownIncludedFeatureListItem>
                  Assistance at the roadside
                  </RACBreakdownIncludedFeatureListItem>
                  <RACBreakdownIncludedFeatureListItem>Recovery of you, your vehicle and passengers to any UK destination if the vehicle can't be fixed</RACBreakdownIncludedFeatureListItem>
                  <RACBreakdownIncludedFeatureListItem>Assistance at home</RACBreakdownIncludedFeatureListItem>
                  <RACBreakdownIncludedFeatureListItem>No call out charge</RACBreakdownIncludedFeatureListItem>
                  <RACBreakdownIncludedFeatureListItem>Unlimited labour time if the vehicle can be fixed</RACBreakdownIncludedFeatureListItem>
                  <RACBreakdownIncludedFeatureListItem>Unlimited call outs</RACBreakdownIncludedFeatureListItem>
                </RACBreakdownFeatureList>
              </RACBreakdownFeature>
              <RACBreakdownFeature>
                <b>Our RAC UK Breakdown Cover exclusions:</b>
                <RACBreakdownFeatureList>
                  <RACBreakdownExcludedFeatureListItem>Cost of replacement parts</RACBreakdownExcludedFeatureListItem>
                  <RACBreakdownExcludedFeatureListItem>Garage labour costs</RACBreakdownExcludedFeatureListItem>
                  <RACBreakdownExcludedFeatureListItem>Cover in Europe</RACBreakdownExcludedFeatureListItem>
                </RACBreakdownFeatureList>
              </RACBreakdownFeature>
            </RACBreakDownDetailsContainer>
            <div>
              <AddonMoreInfoText>
                Please note, the vehicle you want to recover must be taxed and have a valid MOT
              </AddonMoreInfoText>
            </div>
            <RACInlineDocs>
              <AddonMoreInfomItem
                content='Breakdown IPID'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${breakdownDocuments.ipid}/versions/${documentStartDate}`}
              />
              <AddonMoreInfomItem
                content='Breakdown Policy Wording'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${breakdownDocuments.policyWording}/versions/${documentStartDate}`}
              />
            </RACInlineDocs>
          </AddonMoreInfoBody>
        </>
      )
    }

    if (type === 'legalexpenses') {
      return (
        <>
          <AddonMoreInfoTitle>
            More info about legal expenses cover
          </AddonMoreInfoTitle>
          <AddonMoreInfoBody>
            <div>
              <AddonMoreInfoText>
                If you are unfortunate enough to be involved in an accident that
                wasn’t your fault, this cover provides up to £100,000 in legal
                costs to help you recover any uninsured losses that occur; for
                example
              </AddonMoreInfoText>
              <AddonMoreInfoText>Loss of earnings</AddonMoreInfoText>
              <AddonMoreInfoText>Travel expenses</AddonMoreInfoText>
              <AddonMoreInfoText>Damage to personal effects</AddonMoreInfoText>
              <AddonMoreInfoText>
                Seek compensation for any injuries you or your passengers may
                have received as a result of the accident
              </AddonMoreInfoText>
              <AddonMoreInfoText>Towing and storage</AddonMoreInfoText>
              <AddonMoreInfoText>
                Attempt recovery of all your out of pocket expenses from any
                negligent third party
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                The success of your claim will depend upon being able to prove
                that the third party was at fault.
              </AddonMoreInfoText>
            </div>
            <ul>
              <AddonMoreInfomItem
                content='Legal Expenses IPID'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${legalexpensesDocuments.ipid}/versions/${documentStartDate}`}
              />
              <AddonMoreInfomItem
                content='Legal Expenses Policy Wording'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${legalexpensesDocuments.policyWording}/versions/${documentStartDate}`}
              />
            </ul>
          </AddonMoreInfoBody>
        </>
      )
    }

    if (type === 'gapinsurance') {
      return (
        <>
          <AddonMoreInfoTitle>More info about GAP Insurance</AddonMoreInfoTitle>
          <AddonMoreInfoBody>
            <div>
              <AddonMoreInfoText>
                GAP Insurance protects the lifetime value of your vehicle by
                topping up your main insurer&apos;s payout in the unfortunate
                event your vehicle is written off or stolen (and not recovered).
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                <b>Why would you need GAP Insurance?</b>
                <br />
                If your vehicle is declared a total loss, your motor insurer
                will payout the value of your vehicle at the time of your claim.
                Given most vehicles lose up to 60% of their original value
                during their first 3 years, that payout can be less than
                expected and usually, a lot less than you originally paid for
                your vehicle.
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                <b>How does it work?</b>
                <br />
                Our Free 30 Days GAP Insurance works by topping up a motor
                insurer&apos;s payout, covering the shortfall, up to the
                vehicle&apos;s original value when your GAP policy was issued.
                This will allow you to get the additional funds needed to
                purchase a replacement vehicle or payoff any outstanding finance
                you might owe.
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                <b>
                  After the 30 days free period, it&apos;s your choice whether
                  you extend as this policy will expire. We will email you
                  details on how you can do this.
                </b>
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                To be valid for a claim, the following must apply:
                <ol style={{ listStyle: 'disc' }}>
                  <li>
                    You must be the registered owner and, or keeper of the
                    vehicle
                  </li>
                  <li>The vehicle must be under 8 years old</li>
                  <li>Less than 70,000 recorded miles</li>
                  <li>Insured value of less than £75,000.</li>
                  <li>Be UK specification and not modified</li>
                </ol>
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                See links below for details:
              </AddonMoreInfoText>
            </div>
            <ul>
              <AddonMoreInfomItem
                content='GAP Insurance IPID'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${gapinsuranceDocuments.ipid}/versions/${documentStartDate}`}
              />
              <AddonMoreInfomItem
                content='GAP Insurance Policy Wording'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${gapinsuranceDocuments.policyWording}/versions/${documentStartDate}`}
              />
            </ul>
          </AddonMoreInfoBody>
        </>
      )
    }

    return (
      <>
        <AddonMoreInfoTitle>Failed to load information</AddonMoreInfoTitle>
        <div>
          <AddonMoreInfoText>Please try again.</AddonMoreInfoText>
        </div>
      </>
    )
  }

  return (
    <Wrapper ref={ref}>
      {isPaymentPage && (
        <PaymentPageLinkContainer
          id={`addon-detailslink-${type}`}
          onClick={handleClick}
        >
          Full details
        </PaymentPageLinkContainer>
      )}
      {!isPaymentPage && (
        <LinkContainer id={`addon-detailslink-${type}`} onClick={handleClick}>
          View full details <i className='fa fa-external-link-alt fa-xs' />
        </LinkContainer>
      )}
      {isComponentVisible && (
        <AddonMoreInfoWrapper id={`addon-detailsmodal-${type}`}>
          <AddonMoreInfoContent />
          <hr />
          <div>
            <PaymentPageLinkContainer
              id={`addon-closedetails-${type}`}
              onClick={() => setIsComponentVisible(false)}
            >
              Close
            </PaymentPageLinkContainer>
          </div>
        </AddonMoreInfoWrapper>
      )}
    </Wrapper>
  )
}

export default AddonMoreInfo
