import { updateStep } from 'containers/App/actions'
import { useAddons, usePrice, useQueryString, useWindowSize } from 'hooks'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Stepper from 'react-stepper-horizontal'
import { useTheme } from 'styled-components'
// @ts-ignore
import useCustomerAccount from 'hooks/useCustomerAccount'
import config from 'config'
import useOptimizelyExperiment from 'hooks/useOptimizelyExperiment'
import {
  ReactSDKClient,
  WithOptimizelyProps,
  withOptimizely,
} from '@optimizely/react-sdk'
import tick from '../../images/breadcrumb-tick-white.png'
import { Wrapper } from './Breadcrumb.styles'

interface Props extends WithOptimizelyProps {
  step: number
  disabled?: boolean
}

const BreadCrumb: React.FC<Props> = ({
  optimizely,
  step,
  disabled = false,
}) => {
  const shouldUseSplitAddonsPage = useOptimizelyExperiment(
    optimizely,
    'addons_page',
    'page_enabled',
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const { price } = usePrice()
  const { availableAddons } = useAddons()
  const { queryString } = useQueryString()
  const { isCustomerAccountQuote } = useCustomerAccount()

  const sourceParameter = queryString.includes('ca_source')

  const theVehiclePath = useMemo(() => {
    if (sourceParameter) {
      return config.CUSTOMER_ACCOUNT_URL
    }

    if (isCustomerAccountQuote) {
      return '/quote/driver-details/logged-in'
    }

    return '/quote/driver-details'
  }, [isCustomerAccountQuote, sourceParameter])

  const yourQuotePath = useMemo(() => {
    if (sourceParameter) {
      return config.CUSTOMER_ACCOUNT_URL
    }

    return '/quote/driving-licence'
  }, [sourceParameter])

  const steps = useMemo(() => {
    const handleClick = (
      e: MouseEvent,
      pathname: string,
      stepNumber: number,
    ) => {
      e.preventDefault()

      if (sourceParameter && pathname.includes(config.CUSTOMER_ACCOUNT_URL)) {
        window.location.href = config.CUSTOMER_ACCOUNT_URL
      }

      if (!disabled) {
        dispatch(updateStep(stepNumber))
        history.push({ pathname, search: queryString })
      }
    }
    const initialStepArray = [
      {
        icon: tick,
        href: theVehiclePath,
        onClick: (e) => handleClick(e, theVehiclePath, 0),
        title: 'The vehicle',
      },
      {
        icon: tick,
        href: yourQuotePath,
        onClick: (e) => handleClick(e, yourQuotePath, 1),
        title: 'Your quote',
      },
      {
        icon: tick,
        href: '/quote/legal-declaration',
        onClick: (e) => handleClick(e, '/quote/legal-declaration', 2),
        // eslint-disable-next-line quotes
        title: "What's covered",
      },
      {
        icon: tick,
        href: '/quote/payment',
        onClick: (e) => handleClick(e, '/quote/payment', 3),
        title: 'Payment',
      },
    ]
    if (shouldUseSplitAddonsPage) {
      const updatedStepArray = [...initialStepArray]
      updatedStepArray.splice(
        3,
        1,
        {
          icon: tick,
          href: '/quote/addons',
          onClick: (e) => handleClick(e, '/quote/addons', 3),
          title: 'Add-ons',
        },
        {
          icon: tick,
          href: '/quote/payment',
          onClick: (e) => handleClick(e, '/quote/payment', 4),
          title: 'Payment',
        },
      )

      if (price.TotalPrice && (!availableAddons || !availableAddons.length)) {
        updatedStepArray.splice(3, 2, {
          icon: tick,
          href: '/quote/payment',
          onClick: (e) => handleClick(e, '/quote/payment', 3),
          title: 'Payment',
        })
      }

      return updatedStepArray
    }
    return initialStepArray
  }, [
    disabled,
    dispatch,
    history,
    queryString,
    shouldUseSplitAddonsPage,
    sourceParameter,
    theVehiclePath,
    yourQuotePath,
    price.TotalPrice,
    availableAddons,
  ])

  const theme = useTheme()
  const { isMobile } = useWindowSize()

  return (
    <Wrapper id='Breadcrumb'>
      <Stepper
        steps={steps}
        activeStep={step}
        // @ts-ignore
        activeColor={theme.breadcrumb.activeColor}
        activeBorderStyle='solid'
        // @ts-ignore
        activeBorderColor={theme.breadcrumb.activeBorderColor}
        completeBorderStyle='solid'
        defaultBorderStyle='solid'
        titleFontSize={isMobile ? 13 : 16}
        // @ts-ignore
        defaultTitleColor={theme.breadcrumb.defaultTitleColor}
        // @ts-ignore
        defaultBorderColor={theme.breadcrumb.defaultBorderColor}
        // @ts-ignore
        defaultBarColor={theme.breadcrumb.defaultBarColor}
        // @ts-ignore
        defaultColor={theme.breadcrumb.defaultColor}
        // @ts-ignore
        completeBarColor={theme.breadcrumb.completeBarColor}
        // @ts-ignore
        completeBorderColor={theme.breadcrumb.completeBorderColor}
        // @ts-ignore
        completeColor={theme.breadcrumb.completeColor}
        // @ts-ignore
        size={theme.breadcrumb.circleSize}
        // @ts-ignore
        circleTop={theme.breadcrumb.circleMarginTop}
      />
    </Wrapper>
  )
}

export default withOptimizely(BreadCrumb)
