import * as yup from 'yup'

const ConfusedQuickQuoteOptInValidation = yup
  .string()
  .nullable()
  .required("Please select whether you'd like an annual car insurance quote")

export const ConfusedQuickQuoteSchema = yup.object().shape({
  confusedQuickQuoteOptIn: ConfusedQuickQuoteOptInValidation,
})
