import React, { useRef } from 'react'
import ContinueButton from 'components/ContinueButton'
import useCustomerAccountFeatureFlag from 'hooks/useCustomerAccountFeatureFlag'
import useFreeGapActivationJourney from 'hooks/useFreeGapActivationJourney'
import config from 'config'
import {
  GapActivationConfirmationContatiner,
  GapHeader,
  GapText,
  GapTitle,
  MotorEasyLogo,
} from './styles'

interface Props {
  encyrptedQuoteId: string
}

const GapActivationConfirmation: React.FC<Props> = ({ encyrptedQuoteId }) => {
  const activateGapButtonRef = useRef<HTMLDivElement | null>(null)

  const { data: featureFlagEnabled, isLoading: featureFlagLoading } =
    useCustomerAccountFeatureFlag('FreeGAPActivationJourney')

  const { data: useFreeGapActivation, isLoading: freeGapActivationLoading } =
    useFreeGapActivationJourney(encyrptedQuoteId)

  const gapActivationJourneyActive =
    !featureFlagLoading &&
    featureFlagEnabled &&
    featureFlagEnabled.data &&
    !freeGapActivationLoading &&
    useFreeGapActivation &&
    useFreeGapActivation.data &&
    useFreeGapActivation.data.FreeGapActivationJourneyValid

  const activateGapInsurance = () => {
    if (config.GAP_ACTIVATION_LINK !== '') {
      window.open(config.GAP_ACTIVATION_LINK, '_blank')?.focus()
    }
  }

  return (
    <>
      {gapActivationJourneyActive && (
        <GapActivationConfirmationContatiner>
          <GapHeader>
            <GapTitle>Don't forget to activate your GAP policy</GapTitle>
            <MotorEasyLogo />
          </GapHeader>
          <GapText id='GapActivationWording'>
            Your 30-day free GAP policy won't come into effect until you visit
            our partner MotorEasy. Click below to activate your free GAP policy
            in a few simple steps.
          </GapText>
          <ContinueButton
            innerRef={activateGapButtonRef}
            id='ActivateGapInsurance'
            fullWidth
            type='button'
            onClick={activateGapInsurance}
          >
            Activate Gap Insurance
          </ContinueButton>
        </GapActivationConfirmationContatiner>
      )}
    </>
  )
}

export default GapActivationConfirmation
