import {
  REQUEST_VEHICLE_SUCCESS,
  REQUEST_VEHICLE_FAILED,
  SET_VEHICLE,
  UPDATE_SEARCHED_VEHICLE,
  GET_MAKES_SUCCESS,
  GET_MODELS_SUCCESS,
  GET_VARIANTS_SUCCESS,
  CREATE_UNKNOWN_VEHICLE_SUCCESS,
  UNKNOWN_VEHICLE_REG_SUCCESS,
  SET_VEHICLE_SEARCHED_REG,
  SET_VEHICLE_ERROR,
  UPDATE_VEHICLE_VALUE,
  UPDATE_SEARCHED_VEHICLE_VALUE,
  SET_VEHICLE_TYPE,
  UPDATE_GROSS_WEIGHT,
  UPDATE_YEAR_OF_MANUFACTURE,
  COMMERCIAL_VEHICLE,
  COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT,
  UPDATE_PREVIOUS_VEHICLE,
  REQUEST_PREVIOUS_VEHICLE_FAILED,
  REQUEST_PREVIOUS_VEHICLE_SUCCESS,
  REQUEST_PREVIOUS_MANUAL_BUILDER,
  MOTORCYCLE,
  CLEAR_SEARCHED_VEHICLE,
  SET_CUSTOMER_ACCOUNT_CAROUSEL_REG,
  SET_NEW_CUSTOMER_ACCOUNT_VEHICLE_FROM_HOME_PAGE,
  UPDATE_VEHICLE_ALREADY_EXISTS,
  UPDATE_SELECTED_VEHICLE_BODY_TYPE,
  UPDATE_IF_MANUALLY_BUILT,
} from './constants'
import { UPDATE_SEARCHED_VEHICLE_BODY_TYPE } from 'containers/VehicleCustomerAccount/constants'
import { REQUEST_QUOTE_SUCCESS, CREATE_QUOTE_SUCCESS } from '../App/constants'
import { REQUEST_CREATE_UNKNOWN_VEHICLE_SUCCESS } from '../UnknownRegPage/constants'
import initialState from '../../initialState'
import {
  REQUEST_POLICY_SUCCESS,
  GET_ANALYSIS_DATA_SUCCESS,
} from '../PolicyConfirmationPage/constants'
import {
  MOTORCYCLE_VEHICLE_VALUES,
  VEHICLE_VALUES,
} from '../../components/VehicleValue/constants'
import moment from 'moment'

const getVehicleValue = (
  vehicleType,
  vehicleValueBandId,
  motorcycleVehicleValueBandId,
) => {
  const isMotorcycle = vehicleType === MOTORCYCLE

  const value = isMotorcycle
    ? MOTORCYCLE_VEHICLE_VALUES.find(
        (vehicle) => vehicle.id === motorcycleVehicleValueBandId,
      )
    : VEHICLE_VALUES.find((vehicle) => vehicle.value === vehicleValueBandId)

  return value
}

function vehicleReducer(state = initialState.vehicle, action) {
  switch (action.type) {
    case UPDATE_SEARCHED_VEHICLE:
      let vehicle = action.vehicle
      return {
        ...state,
        searched: {
          ...state.searched,
          ...vehicle,
          VehicleBuilt: false,
          VehicleFound: false,
        },
        error: vehicle.RegistrationNumber ? null : state.error,
      }

    case UPDATE_SEARCHED_VEHICLE_BODY_TYPE:
      vehicle = action.vehicle
      return {
        ...state,
        searched: {
          ...state.searched,
          ...vehicle,
        },
      }
    case UPDATE_PREVIOUS_VEHICLE:
      let previousRiddenVehicle = action.previousRiddenVehicle
      return {
        ...state,
        previousRiddenVehicle: {
          ...state.previousRiddenVehicle,
          ...previousRiddenVehicle,
          VehicleFound: false,
        },
        error: previousRiddenVehicle.RegistrationNumber ? null : state.error,
      }
    case REQUEST_PREVIOUS_VEHICLE_SUCCESS:
      previousRiddenVehicle = action.previousRiddenVehicle
      return {
        ...state,
        previousRiddenVehicle: {
          VehicleId: previousRiddenVehicle.VehicleId.toString(),
          RegistrationNumber: previousRiddenVehicle.RegistrationNumber,
          Make: previousRiddenVehicle.Make,
          Model: previousRiddenVehicle.Model,
          VehicleType: previousRiddenVehicle.VehicleType,
          Abi: previousRiddenVehicle.Abi,
          MakeAndModel: previousRiddenVehicle.MakeAndModel,
          DateFirstRegistered: previousRiddenVehicle.DateFirstRegistered,
          vehicleValue: getVehicleValue(
            previousRiddenVehicle.VehicleType,
            previousRiddenVehicle.VehicleValueBandId,
          ),
          VehicleFound: true,
          Variant: null,
          error: false,
        },
        error: null,
      }
    case REQUEST_VEHICLE_SUCCESS:
    case REQUEST_CREATE_UNKNOWN_VEHICLE_SUCCESS:
      vehicle = action.vehicle
      const motorcycleValueBandId = vehicle.MotorcycleVehicleValueBand || 2
      return {
        ...state,
        searched: {
          ...state.searched,
          VehicleId: vehicle.VehicleId,
          RegistrationNumber: vehicle.RegistrationNumber,
          Make: vehicle.Make,
          Model: vehicle.Model,
          VehicleType: vehicle.VehicleType,
          Abi: vehicle.Abi,
          MakeAndModel: vehicle.MakeAndModel,
          DateFirstRegistered: vehicle.DateFirstRegistered,
          vehicleValue: getVehicleValue(
            vehicle.VehicleType,
            vehicle.VehicleValueBandId,
            motorcycleValueBandId,
          ),
          VehicleFound: true,
          Variant: null,
        },
        error: null,
      }
    case REQUEST_QUOTE_SUCCESS:
      vehicle = action.quote.Vehicle
      const returnedPreviousVehicle =
        action.quote.PreviouslyRiddenMotorcycleVehicle || {}
      let quoteMotorcycleValueBandId =
        (action.quote.MotorcycleVehicleValueBand &&
          action.quote.MotorcycleVehicleValueBand.Id) ||
        2

      return {
        ...state,
        selected: {
          ...state.selected,
          VehicleId: vehicle.VehicleId,
          RegistrationNumber: vehicle.Registration,
          Make: vehicle.Make,
          Model: vehicle.Model,
          Abi: vehicle.Abi,
          VehicleType: vehicle.VehicleType,
          vehicleValue: getVehicleValue(
            vehicle.VehicleType,
            action.quote.VehicleValueBandId,
            quoteMotorcycleValueBandId,
          ),
          YearOfManufacture:
            vehicle.DateOfManufacture === null
              ? null
              : moment(vehicle.DateOfManufacture).year(),
          GrossWeight: vehicle.GrossWeight,
          Impounded: vehicle.Impounded,
        },
        searched: {
          ...state.searched,
          VehicleId: vehicle.VehicleId,
          RegistrationNumber: vehicle.Registration,
          VehicleType: vehicle.VehicleType,
        },
        ...(returnedPreviousVehicle && returnedPreviousVehicle.Registration
          ? {
              previousRiddenVehicle: {
                ...state.returnedPreviousVehicle,
                VehicleId: returnedPreviousVehicle.VehicleId,
                RegistrationNumber: returnedPreviousVehicle.Registration,
                VehicleType: returnedPreviousVehicle.VehicleType,
                Make: returnedPreviousVehicle.Make,
                Model: returnedPreviousVehicle.Model,
                VehicleFound: true,
              },
            }
          : null),
        error: null,
      }
    case CREATE_UNKNOWN_VEHICLE_SUCCESS:
      vehicle = action.vehicle
      return {
        ...state,
        searched: {
          ...state.searched,
          VehicleId: vehicle.VehicleId,
          VehicleBuilt: true,
          vehicleValue: {
            description: '£1,500 to £5,000',
            value: 5,
          },
        },
      }
    case SET_VEHICLE:
      vehicle = action.vehicle
      if (
        vehicle &&
        vehicle.VehicleType === COMMERCIAL_VEHICLE &&
        vehicle.GrossWeight > COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT
      ) {
        vehicle = {
          ...vehicle,
          Make: null,
          Model: null,
          Variant: null,
        }
      }
      return {
        ...state,
        searched: {
          ...state.searched,
        },
        selected: vehicle,
      }
    case REQUEST_VEHICLE_FAILED:
      return {
        ...state,
        searched: {
          ...state.searched,
          RegistrationNumber: action.registrationNumber,
          VehicleId: null,
          Make: null,
          Model: null,
          VehicleType: '',
          Abi: null,
          MakeAndModel: null,
          DateFirstRegistered: null,
          VehicleFound: false,
          Variant: null,
          VehicleBuilt: null,
        },
        error: action.error,
      }
    case REQUEST_PREVIOUS_VEHICLE_FAILED:
      return {
        ...state,
        previousRiddenVehicle: {
          ...initialState.vehicle.previousRiddenVehicle,
          RegistrationNumber: action.registrationNumber,
          VehicleType: 'Motorcycle',
          VehicleFound: false,
          error: action.error,
        },
      }
    case REQUEST_PREVIOUS_MANUAL_BUILDER:
      return {
        ...state,
        previousRiddenVehicle: {
          ...state.previousRiddenVehicle,
          ManuallyBuilt: true,
        },
      }
    case GET_MAKES_SUCCESS:
      return {
        ...state,
        makes: action.makes,
        models: [],
        variants: [],
      }
    case GET_MODELS_SUCCESS:
      return {
        ...state,
        models: action.models,
        variants: [],
      }
    case GET_VARIANTS_SUCCESS:
      return {
        ...state,
        variants: action.variants,
      }
    case UNKNOWN_VEHICLE_REG_SUCCESS:
      return {
        ...state,
        searched: {
          ...state.searched,
          Make: action.manualVehicle.Make,
          Model: action.manualVehicle.Model,
          VehicleType: action.manualVehicle.VehicleType,
        },
        selected: {
          ...state.selected,
          VehicleId: action.builtVehicle.VehicleId,
          Make: action.manualVehicle.Make,
          Model: action.manualVehicle.Model,
          Abi: action.manualVehicle.Variant,
          VehicleType: action.manualVehicle.VehicleType,
          GrossWeight: action.manualVehicle.GrossWeight,
          YearOfManufacture: action.manualVehicle.YearOfManufacture,
        },
      }
    case REQUEST_POLICY_SUCCESS:
      return {
        ...state,
        selected: {
          ...state.selected,
          Make: action.policy.VehicleSummary.Make,
          Model: action.policy.VehicleSummary.Model,
          RegistrationNumber: action.policy.VehicleSummary.Registration,
          VehicleType: action.policy.VehicleSummary.VehicleType,
        },
      }
    case SET_VEHICLE_SEARCHED_REG:
      return {
        ...state,
        searched: {
          ...state.searched,
          RegistrationNumber: action.vehicleReg,
          VehicleType: action.vehicleType || null,
          VehicleFound: action.vehicleType ? true : false,
        },
      }
    case SET_VEHICLE_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case CREATE_QUOTE_SUCCESS:
      quoteMotorcycleValueBandId = quoteMotorcycleValueBandId || 2
      return {
        ...state,
        selected: {
          ...state.selected,
          vehicleValue: getVehicleValue(
            state.searched.VehicleType,
            action.quote.VehicleValueBandId,
            quoteMotorcycleValueBandId,
          ),
        },
      }
    case UPDATE_VEHICLE_VALUE:
      return {
        ...state,
        selected: {
          ...state.selected,
          vehicleValue: action.value,
        },
      }
    case UPDATE_SEARCHED_VEHICLE_VALUE:
      return {
        ...state,
        searched: {
          ...state.searched,
          vehicleValue: action.value,
        },
      }
    case SET_VEHICLE_TYPE:
      return {
        ...state,
        searched: {
          ...state.searched,
          VehicleType: action.vehicleType,
          Abi: null,
          Model: null,
          Make: '',
          VehicleBuilt: null,
          Variant: null,
          GrossWeight: null,
          YearOfManufacture: null,
        },
      }
    case GET_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        scheme: action.response.Scheme,
      }
    case UPDATE_GROSS_WEIGHT:
      return {
        ...state,
        searched: {
          ...state.searched,
          GrossWeight: action.grossWeight,
        },
      }
    case UPDATE_YEAR_OF_MANUFACTURE:
      return {
        ...state,
        searched: {
          ...state.searched,
          YearOfManufacture: action.yearOfManufacture,
        },
      }
    case SET_NEW_CUSTOMER_ACCOUNT_VEHICLE_FROM_HOME_PAGE:
      return {
        ...state,
        preloadNewCustomerAccountVehicle:
          action.preloadNewCustomerAccountVehicle,
      }
    case CLEAR_SEARCHED_VEHICLE:
      return {
        ...state,
        searched: {
          ...initialState.vehicle.searched,
        },
        selected: {
          ...initialState.vehicle.selected,
        },
      }
    case SET_CUSTOMER_ACCOUNT_CAROUSEL_REG:
      return {
        ...state,
        customerAccountCarouselReg: action.reg,
      }
    case UPDATE_VEHICLE_ALREADY_EXISTS:
      return {
        ...state,
        existsInGarage: action.alreadyExists,
      }
    case UPDATE_SELECTED_VEHICLE_BODY_TYPE:
      return {
        ...state,
        customerAccountCommercialVehicleType: action.commercialVehicleBodyType,
      }
    case UPDATE_IF_MANUALLY_BUILT:
      return {
        ...state,
        customerAccountManuallyBuiltVehicle: action.manuallyBuilt,
      }
    default:
      return state
  }
}

export default vehicleReducer
