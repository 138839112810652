import { useMutation } from 'react-query'
import customerAccountApi, {
  SaveCustomerRegistrationRequest,
} from 'api/customerAccount'

export const useSaveNewDrivingLicenceToCustomer = () => {
  const saveNewDrivingLicenceToCustomerMutation = useMutation(
    ['customerAccountApi.saveCustomerLicenceDetails'],
    async (data: SaveCustomerRegistrationRequest) =>
      customerAccountApi.saveCustomerLicenceDetails(data.drivingLicenceNumber),
    {
      retry: false,
    },
  )
  return saveNewDrivingLicenceToCustomerMutation
}
