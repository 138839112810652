import React from 'react'

export const RAC = ({ width, height }) => {
  const opts = {}

  if (width) {
    opts.width = width
  }

  if (height) {
    opts.height = height
  }

  return (
    <svg
      width='59'
      class='d-none d-lg-block'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 59 20'
    >
      <path
        fill='#F95108'
        d='M14.32 10.014H7.728c-.261 0-.32.122-.32.24 0 .091.057.18.114.211l10.355 6.895L16.245 20l-9.683-6.566c-1.223-.84-2.16-1.618-2.16-3.239 0-1.83 1.285-3.089 3.62-3.089h6.27c.962 0 .962-.808.962-1.11V3.988c0-.3 0-1.11-.963-1.11H3.967c-.962 0-.962.81-.962 1.11V20H0V4.135C0 .958 2.276 0 3.967 0H14.38C16.07 0 18.2.81 18.2 3.988v1.979c-.002 3.18-2.188 4.047-3.88 4.047ZM34.071 20H23.776c-1.693 0-3.908-.899-3.908-4.079v-4.827c0-3.178 2.276-4.138 3.968-4.138h11.112v-2.82c0-.298 0-1.108-.96-1.108H21.355V0h12.748c1.693 0 3.908.899 3.908 4.076V15.89c0 3.18-2.247 4.11-3.94 4.11Zm.875-10.045H23.805c-.963 0-.963.808-.963 1.11v4.827c0 .3 0 1.108.963 1.108h10.18c.96 0 .96-.808.96-1.108V9.955ZM44.47 20c-1.691 0-3.908-.899-3.908-4.079V4.136C40.563.96 42.835 0 44.531 0h13.65v3.028H44.649c-.963 0-.963.81-.963 1.107V15.86c0 .3 0 1.11.963 1.11h13.534V20h-13.71Z'
      ></path>
    </svg>
  )
}
