import React from 'react'
import Modal from '../@common/Modal'
import styled from 'styled-components'

const ModalContent = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  text-align: left;

  .list-declaration {
    margin: 0;
    li {
      margin-bottom: 16px;
    }
  }

  .list-declaration-details li {
    margin-bottom: 4px;
  }
`

export const DeclarationModal = ({
  id,
  isVisible,
  text,
  title,
  hideFullDeclaration,
}) => {
  return (
    <Modal
      id={id}
      isVisible={isVisible}
      isLarge
      onClose={hideFullDeclaration}
      header={title}
      headerAlignment='center'
      fullscreen={window.innerWidth <= 500}
    >
      <ModalContent
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </Modal>
  )
}
