import { takeLatest, select, put } from 'redux-saga/effects'

import { SET_VEHICLE_FROM_CAROUSEL } from 'containers/Vehicle/constants'
import fetchVehicleSaga from 'containers/Vehicle/saga'
import { getVehicleInfo } from 'containers/App/selector'
import { setVehicle } from 'containers/Vehicle/actions'

function* initSetVehicleFromCarousel(selectedVehicle) {
  let vehicle = yield select(getVehicleInfo)

  const commericalVehicleBodyType = vehicle.customerAccountCommercialVehicleType
  if (!vehicle.customerAccountManuallyBuiltVehicle) {
    const requestStatus = yield* fetchVehicleSaga({
      registrationNumber: vehicle.customerAccountCarouselReg,
    })
    vehicle = yield select(getVehicleInfo)
  }

  vehicle.searched.BodyType = commericalVehicleBodyType

  const customerVehicle = {
    ...vehicle.searched,
    vehicleValue: {
      value: selectedVehicle.vehicle.vehicleValueBandId,
    },
  }

  yield put(setVehicle(customerVehicle))
}

export default function* vehicleCarouselSaga() {
  yield takeLatest(SET_VEHICLE_FROM_CAROUSEL, initSetVehicleFromCarousel)
}
