type ActionTypes = 'SET_AUTH_ACCESS_TOKEN'

type PayloadType = {
  accessToken: string
}

export interface AuthAction {
  type: ActionTypes
  payload?: PayloadType
}

export const setAuthAccessToken: (auth: PayloadType) => {
  type: ActionTypes
  payload: PayloadType
} = (auth) => ({
  type: 'SET_AUTH_ACCESS_TOKEN',
  payload: auth,
})
