import React from 'react'
import {
  OptimizelyProvider,
  createInstance,
  setLogger,
} from '@optimizely/react-sdk'
import { v4 as uuidv4 } from 'uuid'
import config from 'config'
import { UserInfo } from '@optimizely/react-sdk/dist/utils'
import { getCookie, COOKIE_MANAGEMENT_NAME } from '../../utils/cookie-helpers'

const OptimizelyProviderWrapper: React.FC = ({ children }) => {
  function isClientValid() {
    const cookieManagementCookie = getCookie(COOKIE_MANAGEMENT_NAME)
    if (cookieManagementCookie) {
      const cookieSessionObject = JSON.parse(cookieManagementCookie)
      if (cookieSessionObject.trackingCookies) {
        return true
      }
      return false
    }
    return false
  }

  const existingUserId = localStorage.getItem('userId')

  const userId = existingUserId || uuidv4()
  localStorage.setItem('userId', userId)

  const optimizelyClient = createInstance({
    sdkKey: config.OPTIMIZELY_API_KEY,
    datafileOptions: {
      autoUpdate: true,
    },
    eventBatchSize: 10,
    eventFlushInterval: 1000,
  })

  if (config.ENVIRONMENT_NAME === 'Production') setLogger(null)

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const hasRef = params.has('ref')

  const userObject: UserInfo = {
    id: userId,
    attributes: {
      legalDeclarationPageClick: false,
      isTempcoverDirectCustomer: !hasRef,
    },
  }

  if (isClientValid()) {
    return (
      <OptimizelyProvider optimizely={optimizelyClient} user={userObject}>
        {children}
      </OptimizelyProvider>
    )
  }
  return <div>{children}</div>
}

export default OptimizelyProviderWrapper
