import { P } from 'components/common/P'
import styled from 'styled-components'
import { Button } from '../common/Button'

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    flex-grow: ${(props) => (props.grow ? 1 : 0)};
  }
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const PriceComparisonWrapper = styled(FlexColumn)`
  border: 1.5px solid
    ${(props) => (props.selected ? props.theme.primaryColour : '#8FA7E1')};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  margin-bottom: 1em;
  padding: 10px;
  position: relative;

  @media (max-width: 374px) {
    padding: 5px;
  }

  @media (min-width: 768px) {
    padding: 25px 40px;
  }

  &.cheapest::before {
    position: absolute;
    top: -1.2em;
    left: 10px;
    content: 'Cheapest';
    font-family: ${(props) => props.theme.button.fontFamily};
    font-size: ${(props) =>
      props.theme.multiPrice.mobile.lowestPriceBannerFontSize};
    letter-spacing: 1px;
    line-height: 1em;
    color: #fff;
    background-color: ${(props) => props.theme.primaryColour};
    border-radius: ${(props) => props.theme.formControlBorderRadius};
    padding: 0.6em 0.5em;

    @media (max-width: 374px) {
      left: 5px;
    }

    @media (min-width: 768px) {
      font-size: 22px;
      padding: 0.6em 1.5em;
      left: 40px;
    }
  }
`

export const QuoteSummaryWrapper = styled.div`
  display: flex;
  place-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }

  .price-comparison-underwriter-logo {
    max-width: 85px;
    width: 100%;

    @media (min-width: 768px) {
      max-width: initial;
    }
  }
`

export const QuoteDetailsWrapper = styled.div`
  padding: 20px;
  margin: 10px;
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  border: 1px solid ${(props) => props.theme.primaryColour};

  & a {
    display: block;
    align-self: center;

    color: ${(props) => props.theme.primaryColour};

    & > span {
      text-decoration-line: underline;
    }
  }
`

export const ContainedQuoteDetailsWrapper = styled(QuoteDetailsWrapper)`
  padding: 0px;
  padding-top: 25px;
  margin: 0;
  margin-top: 12px;
  border-radius: 0;
  border: none;
  border-top: 2px solid #758abf;
  transition: 0.4s ease-in;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  height: ${({ state }) =>
    state === 'entering' || state === 'entered' ? 'auto' : 0};
  display: ${({ state }) =>
    state === 'entering' || state === 'entered' ? 'block' : 'none'};
  pointer-events: ${({ state }) =>
    state === 'entering' || state === 'entered' ? 'initial' : 'none'};
  transform: translateY(
    ${({ state }) => (state === 'entering' || state === 'entered' ? 0 : -10)}px
  );
`

export const EditBlock = styled.div`
  grid-column: 2 / span 2;
  justify-self: start;
  align-self: center;
  font-size: 12px;

  span {
    padding-left: 5px;
    font-family: ${(props) => props.theme.fontFamily};
  }

  @media (min-width: 768px) {
    font-size: 14px;
  }
`

export const NameBlock = styled.div`
  padding-right: 10px;
`

export const DOBBlock = styled.div`
  padding-left: 5px;
  @media (min-width: 768px) {
    padding-right: 10px;
  }
`

export const LicenceHeldBlock = styled.div`
  padding-left: 5px;

  @media (min-width: 768px) {
    padding-right: 10px;
  }
`

export const FullRowBlock = styled.div`
  grid-column: 1 / span 3;

  @media (min-width: 768px) {
    grid-column: ${(props) =>
      props.alwaysFullRow ? '1 / span 3' : '4 / span 1'};
  }
`

export const QuoteDetailsHeader = styled.h3`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 14px;
  line-height: 1em;
  color: ${(props) => props.theme.priceComparison.headerColour};
  margin: 0;
  justify-self: start;

  @media (min-width: 768px) {
    font-size: 22px;
  }
`

export const QuoteDetailsLabel = styled.p`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 12px;
  line-height: 1.2em;
  text-align: start;
  margin: 0;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.multiPrice.fontSize};
  }
`

export const QuoteDetailsValue = styled.p`
  font-size: 12px;
  line-height: 1.2em;
  text-align: start;
  font-weight: bold;
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.primaryTextColour};
  margin: 0;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`

export const TotalPriceWrapper = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.priceComparison.textColour};
  box-sizing: border-box;
  text-align: left;
  font-weight: bold;
  align-items: center;
  column-gap: 20px;

  @media (max-width: 374px) {
    font-size: 0.8em;
  }

  @media (min-width: 768px) {
    font-size: 22px;
  }

  p {
    margin: 0;
    font-size: 12px;
    white-space: nowrap;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
`
export const WasPriceLabel = styled.div`
  color: #ba0000; // Set to red for the "WAS" price
  font-size: 22px;
  font-weight: bold;
  position: relative;
  display: inline-block;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

export const WasPriceValue = styled.div`
  color: #ba0000; // Set to red for the "WAS" price
  font-size: 22px;
  font-weight: bold;
  position: relative;
  display: inline-block;
  margin-right: 15px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #ba0000;
    transform: rotate(5deg);
    width: 100%;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

export const TotalPriceLabel = styled.span`
  font-family: ${(props) => props.theme.fontFamily};
  color: #264b94;
  font-size: 18px; 
`

export const PaymentBreakdownIconWrapper = styled.div`
  @media (max-width: 768px) {
    padding-top: 3px;
  }
`

export const TotalPriceMainBlock = styled.div`
  display: block;

  & > p {
    font-family: ${(props) => props.theme.fontFamily};
  }
`

export const TotalPriceValueWrapper = styled.span`
  font-weight: bold;
  font-size: 22px;
  color: #264b94;
  line-height: 1;
  font-family: ${(props) => props.theme.headerFontFamily};

  @media (max-width: 768px) {
    font-size: 20px;
  }
`
export const CoverTypeText = styled(P)`
  font-size: 16px !important;
  color: #264b94;
`

export const QuoteInfoWrapper = styled.div`
  align-self: center;
  text-align: left;
  margin-right: 50px;

  p {
    margin: 0;
  }

  @media (max-width: 768px) {
    margin-right: 0px;
    margin-top: 10px;
    text-align: center;
    padding-left: 0;
  }
`

export const SelectWrapper = styled.div`
  justify-self: end;
`

export const PriceComparisonSelectButton = styled(Button)`
  padding: 1.75em 5.35em;
  height: auto;
  font-size: 18px;
  background: ${(props) => props.theme.multiPrice.button.backgroundColour};
  color: ${(props) =>
    props.disabled
      ? props.theme.multiPrice.button.disabledFont
      : props.theme.button.textColour};

  //animation
  transition: 0.5s;
  opacity: ${({ state }) =>
    state === 'entering' || state === 'entered' ? 1 : 0};
  // width:  ${({ state }) =>
    state === 'entering' || state === 'entered' ? '200px' : 0};
  pointer-events: ${({ state }) =>
    state === 'entering' || state === 'entered' ? 'initial' : 'none'};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};

  @media (max-width: 767px) {
    margin-top: 15px;
    padding: 0.85em 0.8em;
    letter-spacing: 1px;
    font-size: 18px;
    width: ${({ state }) =>
      state === 'entering' || state === 'entered' ? '100%' : 0};
  }

  @media (max-width: 344px) {
    font-size: 14px;
  }
`

export const PriceComparisonContinueButton = styled(Button)`
  width: 100%;
  padding: 1.3em 2em;
  height: auto;
  font-size: ${(props) => props.theme.multiPrice.continueButtonFontSize}
  margin-top: 20px;
  grid-column: 1 / span 4;
  background: #779e00;
  color: ${(props) =>
    props.disabled
      ? props.theme.multiPrice.button.disabledFont
      : props.theme.button.textColour};

  @media (max-width: 767px) {
    font-size: ${(props) =>
      props.theme.multiPrice.mobile.continueButtonFontSize}
    padding: 1em 0.8em;
    letter-spacing: 1px;
    margin-top: 10px;
    grid-column: 1 / span 3;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const ImageWrapper = styled.div`
  display: flex;
  margin: auto;
  max-wdith: 100px;
`

export const AmendDetailsLink = styled.div`
  text-align: left;
  & > a {
    font-size: 16px;
    display: inline;
  }
`

export const CustomerDetailsContainer = styled.div`
  display: block;
  margin-bottom: 20px;
`

export const TitlesContainer = styled.div`
  justify-content: space-between;
  display: flex;
`

export const PersonalDetailsContainer = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: 15px;
`

export const AddressDetailsContainer = styled.div`
  display: flex;
  margin-top: 15px;
`

export const LogoAndPriceContainer = styled.div`
  display: flex;
`
