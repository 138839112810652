import {
  REQUEST_ANTI_FORGERY,
  REQUEST_ANTI_FORGERY_SUCCESS,
  REQUEST_ANTI_FORGERY_FAILURE,
  REQUEST_LINKS,
  REQUEST_LINKS_SUCCESS,
  REQUEST_LINKS_FAILURE,
  REQUEST_FEATURE_TOGGLES,
  REQUEST_FEATURE_TOGGLES_SUCCESS,
  REQUEST_FEATURE_TOGGLES_FAILURE,
  CREATE_QUOTE,
  CREATE_QUOTE_SUCCESS,
  CREATE_QUOTE_FAILURE,
  REQUEST_PRICE,
  REQUEST_PRICE_SUCCESS,
  REQUEST_PRICE_FAILURE,
  REQUEST_ADDONS,
  REQUEST_ADDONS_SUCCESS,
  REQUEST_ADDONS_FAILURE,
  REQUEST_EXCESS_FAILURE,
  REQUEST_BREAKDOWN_FAILURE,
  REQUEST_LEGALEXPENSES_FAILURE,
  REQUEST_GAPINSURANCE_FAILURE,
  SELECT_PRICE,
  CLEAR_PRICE,
  CLEAR_ADDONS,
  CREATE_CLIENT_SESSION,
  GET_QUOTE_SUMMARY_SUCCESS,
  IS_LOADING,
  REQUEST_AFFILIATE,
  REQUEST_AFFILIATE_FAILURE,
  REQUEST_AFFILIATE_SUCCESS,
  REQUEST_OPEN_SCHEMES,
  REQUEST_OPEN_SCHEMES_FAILURE,
  REQUEST_OPEN_SCHEMES_SUCCESS,
  REQUEST_QUOTE,
  REQUEST_QUOTE_FAILURE,
  REQUEST_QUOTE_SUCCESS,
  SET_CAMPAIGN_ACTIVE,
  SET_CLIENT_SESSION_DATA,
  SET_CLIENT_SESSION_GUID,
  SET_CLIENT_SESSION_LAST_ACTIVITY,
  SET_CLIENT_SESSION_QUOTE,
  SET_CLIENT_SESSION_STATE,
  SET_QUERY_STRING,
  SET_QUOTE_JOURNEY_POSITION,
  SET_SCHEME,
  SET_TEMP_UNDERWRITER,
  SET_UI_ELEMENT_MESSAGE_VISIBILITY,
  SET_UI_ELEMENT_VISIBILITY,
  SET_UNDERWRITER_CHANGED,
  UPDATE_CAMPAIGN_DATA,
  UPDATE_CLIENT_SESSION_QUOTE,
  UPDATE_DURATION,
  UPDATE_STEP,
  UPDATE_CUSOTMER_ACCOUNT_LOGGED_IN,
  UPDATE_CONFUSED_QUICK_QUOTE_OPT_IN,
  REQUEST_CONFUSED_QUICK_QUOTE,
  REQUEST_CONFUSED_QUICK_QUOTE_SUCCESS,
  REQUEST_CONFUSED_QUICK_QUOTE_FAILURE,
  REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE,
  REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_SUCCESS,
  REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_FAILURE,
  UPDATE_IS_CUSTOMER_ACCOUNT_QUOTE,
  UPDATE_CUSTOMER_ACCOUNT_EXISTS,
  UPDATE_GAP_ACTIVATION_SELECTED,
} from './constants'

export function isLoading(isLoading) {
  return {
    type: IS_LOADING,
    isLoading,
  }
}

export function requestAntiForgery() {
  return {
    type: REQUEST_ANTI_FORGERY,
  }
}

export function requestAntiForgerySuccess() {
  return {
    type: REQUEST_ANTI_FORGERY_SUCCESS,
  }
}

export function requestAntiForgeryFailure(message) {
  return {
    type: REQUEST_ANTI_FORGERY_FAILURE,
    message,
  }
}

export function requestLinks() {
  return {
    type: REQUEST_LINKS,
  }
}

export function requestLinksSuccess(links) {
  return {
    type: REQUEST_LINKS_SUCCESS,
    links,
  }
}

export function requestLinksFailure(message) {
  return {
    type: REQUEST_LINKS_FAILURE,
    message,
  }
}

export function requestFeatureToggles() {
  return {
    type: REQUEST_FEATURE_TOGGLES,
  }
}

export function requestFeatureTogglesSuccess(featureToggles) {
  return {
    type: REQUEST_FEATURE_TOGGLES_SUCCESS,
    featureToggles,
  }
}

export function requestFeatureTogglesFailure(message) {
  return {
    type: REQUEST_FEATURE_TOGGLES_FAILURE,
    message,
  }
}

export function createQuote() {
  return {
    type: CREATE_QUOTE,
  }
}

export function createQuoteSuccess(quote, emailAddress) {
  return {
    type: CREATE_QUOTE_SUCCESS,
    quote,
    emailAddress,
  }
}

export function createQuoteFailure(message) {
  return {
    type: CREATE_QUOTE_FAILURE,
    message,
  }
}

export function requestPrice() {
  return {
    type: REQUEST_PRICE,
  }
}

export function requestPriceSuccess(price) {
  return {
    type: REQUEST_PRICE_SUCCESS,
    price,
  }
}

export function requestPriceFailure(message) {
  return {
    type: REQUEST_PRICE_FAILURE,
    message,
  }
}

export function requestAddons(addonOptions, addonType) {
  return {
    type: REQUEST_ADDONS,
    addonOptions,
    addonType,
  }
}

export function requestAddonsSuccess(price) {
  return {
    type: REQUEST_ADDONS_SUCCESS,
    price,
  }
}

export function requestAddonsFailure(message) {
  return {
    type: REQUEST_ADDONS_FAILURE,
    message,
  }
}

export function requestExcessFailure(message) {
  return {
    type: REQUEST_EXCESS_FAILURE,
    message,
  }
}

export function requestBreakdownFailure(message) {
  return {
    type: REQUEST_BREAKDOWN_FAILURE,
    message,
  }
}

export function requestLegalExpensesFailure(message) {
  return {
    type: REQUEST_LEGALEXPENSES_FAILURE,
    message,
  }
}
export function requestGAPInsuranceFailure(message) {
  return {
    type: REQUEST_GAPINSURANCE_FAILURE,
    message,
  }
}

export function selectPrice(price) {
  return {
    type: SELECT_PRICE,
    price,
  }
}

export function clearPrice() {
  return {
    type: CLEAR_PRICE,
  }
}

export function clearAddons() {
  return {
    type: CLEAR_ADDONS,
  }
}

export function setUnderwriterChanged() {
  return {
    type: SET_UNDERWRITER_CHANGED,
  }
}

export function requestQuote(quoteId) {
  return {
    type: REQUEST_QUOTE,
    quoteId,
  }
}

export function requestQuoteSuccess(quote, scheme) {
  return {
    type: REQUEST_QUOTE_SUCCESS,
    quote,
    scheme,
  }
}

export function requestQuoteFailure(message) {
  return {
    type: REQUEST_QUOTE_FAILURE,
    message,
  }
}

export function updateStep(step) {
  return {
    type: UPDATE_STEP,
    step,
  }
}

export function updateCustomerAccountLoggedIn(customerAccountLoggedIn) {
  return {
    type: UPDATE_CUSOTMER_ACCOUNT_LOGGED_IN,
    customerAccountLoggedIn,
  }
}

export function requestAffiliate() {
  return {
    type: REQUEST_AFFILIATE,
  }
}

export function requestAffiliateSuccess(affiliate) {
  return {
    type: REQUEST_AFFILIATE_SUCCESS,
    affiliate,
  }
}

export function requestAffiliateFailure() {
  return {
    type: REQUEST_AFFILIATE_FAILURE,
  }
}

export function setQueryString(queryString) {
  return {
    type: SET_QUERY_STRING,
    queryString,
  }
}
export function requestOpenSchemes() {
  return {
    type: REQUEST_OPEN_SCHEMES,
  }
}

export function requestOpenSchemesSuccess(openSchemes) {
  return {
    type: REQUEST_OPEN_SCHEMES_SUCCESS,
    openSchemes,
  }
}

export function requestOpenSchemesFailure() {
  return {
    type: REQUEST_OPEN_SCHEMES_FAILURE,
  }
}

export function getQuoteSummarySuccess(quoteSummary) {
  return {
    type: GET_QUOTE_SUMMARY_SUCCESS,
    quoteSummary,
  }
}

export function setUiElementVisibility(element, visibility) {
  return {
    type: SET_UI_ELEMENT_VISIBILITY,
    element,
    visibility,
  }
}

export function setUiElementMessageVisibility(element, visibility) {
  return {
    type: SET_UI_ELEMENT_MESSAGE_VISIBILITY,
    element,
    visibility,
  }
}

export function setTempUnderwriter(underwriter) {
  return {
    type: SET_TEMP_UNDERWRITER,
    underwriter,
  }
}

export function setCampaignActive(campaignData) {
  return {
    type: SET_CAMPAIGN_ACTIVE,
    campaignData,
  }
}

export function updateCampaignData(campaignData) {
  let showCampaignDiscountBanner = (campaignData.Active || campaignData.CampaignName) && campaignData.CampaignDiscount != 0 ;
    return {
    type: UPDATE_CAMPAIGN_DATA,
    campaignData,
    showCampaignDiscountBanner,
  }
}

export function createClientSession(sessionData) {
  return {
    type: CREATE_CLIENT_SESSION,
    sessionData,
  }
}

export function updateClientSessionQuote(sessionQuoteId) {
  return {
    type: UPDATE_CLIENT_SESSION_QUOTE,
    sessionQuoteId,
  }
}

export function setClientSessionQuote(sessionQuoteId) {
  return {
    type: SET_CLIENT_SESSION_QUOTE,
    sessionQuoteId,
  }
}

export function setClientSessionGuid(sessionGuid) {
  return {
    type: SET_CLIENT_SESSION_GUID,
    sessionGuid,
  }
}

export function setSessionBaseData(sessionBaseData) {
  return {
    type: SET_CLIENT_SESSION_DATA,
    sessionBaseData,
  }
}

export function setClientSessionState(sessionState) {
  return {
    type: SET_CLIENT_SESSION_STATE,
    sessionState,
  }
}

export function setClientSessionLastActivity(lastActivityTimeStamp) {
  return {
    type: SET_CLIENT_SESSION_LAST_ACTIVITY,
    lastActivityTimeStamp,
  }
}

export function updateDurationValue(duration) {
  return {
    type: UPDATE_DURATION,
    duration,
  }
}

export const setQuoteJourneyPosition = (journeyPosition) => ({
  type: SET_QUOTE_JOURNEY_POSITION,
  journeyPosition,
})

export const setScheme = (newScheme) => ({
  type: SET_SCHEME,
  newScheme,
})

export const updateConfusedQuickQuoteOptIn = (optIn) => ({
  type: UPDATE_CONFUSED_QUICK_QUOTE_OPT_IN,
  optIn,
})

export const requestConfusedQuickQuote = () => ({
  type: REQUEST_CONFUSED_QUICK_QUOTE,
})

export const requestConfusedQuickQuoteSuccess = (status) => ({
  type: REQUEST_CONFUSED_QUICK_QUOTE_SUCCESS,
  status,
})

export const requestConfusedQuickQuoteFailure = () => ({
  type: REQUEST_CONFUSED_QUICK_QUOTE_FAILURE,
})

export const requestConfusedQuickQuoteResponse = () => ({
  type: REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE,
})

export const requestConfusedQuickQuoteResponseSuccess = (quickQuote) => ({
  type: REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_SUCCESS,
  quickQuote,
})

export const requestConfusedQuickQuoteResponseFailure = () => ({
  type: REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_FAILURE,
})

export const updateIsCustomerAccountQuote = (isCustomerAccountQuote) => ({
  type: UPDATE_IS_CUSTOMER_ACCOUNT_QUOTE,
  isCustomerAccountQuote,
})

export const updateCustomerAccountExists = (accountExists) => ({
  type: UPDATE_CUSTOMER_ACCOUNT_EXISTS,
  accountExists,
})

export function updateFreeGapActivationSelected(freeGapSelected) {
  return {
    type: UPDATE_GAP_ACTIVATION_SELECTED,
    freeGapSelected,
  }
}
