import { InitialStateType } from 'initialState'
import { useSelector } from 'react-redux'

const useCustomerAccount = () => {
  const isCustomerAccountQuote = useSelector(
    (state: InitialStateType) => state.quote.isCustomerAccountQuote,
  )

  return {
    isCustomerAccountQuote,
  }
}

export default useCustomerAccount
