import {
  REQUEST_VEHICLE_LINK_REL,
  GET_MAKES_REL,
  GET_MODELS_REL,
  GET_VARIANTS_REL,
  CREATE_UNKNOWN_VEHICLE_REL,
  CREATE_UNKNOWN_COMMERCIAL_VEHICLE_REL,
  CREATE_UNKNOWN_MOTORCYCLE_VEHICLE_REL,
  PRIVATE_CAR,
  COMMERCIAL_VEHICLE,
  MOTORCYCLE,
} from './constants'

export const vehicleLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === REQUEST_VEHICLE_LINK_REL)[0]
export const getMakesLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === GET_MAKES_REL)[0]
export const getModelsLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === GET_MODELS_REL)[0]
export const getVariantsLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === GET_VARIANTS_REL)[0]
export const createUnkownVehicleLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === CREATE_UNKNOWN_VEHICLE_REL)[0]
export const createUnkownCommercialVehicleLinkSelector = (state) =>
  state.links.filter(
    (link) => link.Rel === CREATE_UNKNOWN_COMMERCIAL_VEHICLE_REL,
  )[0]
export const createUnkownMotorcycleVehicleLinkSelector = (state) =>
  state.links.filter(
    (link) => link.Rel === CREATE_UNKNOWN_MOTORCYCLE_VEHICLE_REL,
  )[0]

export const getVariant = (state) =>
  state.vehicle.variants.filter(
    (variant) => variant.value === state.vehicle.selected.Abi,
  )[0]
export const getSelectedVehicle = (state) => state.vehicle.selected

export const transformVehicleType = (types) => {
  let convertedVehicleTypes = []
  let id = 0
  for (let type in types) {
    convertedVehicleTypes.push({
      id: id++,
      value: types[type],
      description: types[type],
    })
  }
  return convertedVehicleTypes
}

export const transformVariants = (variants, vehicleType) => {
  let convertedVehicleTypes = []
  let id = 0

  switch (vehicleType) {
    case PRIVATE_CAR:
      for (let variant in variants) {
        let vehicle = variants[variant]
        convertedVehicleTypes.push({
          id: id++,
          value: vehicle.Abi,
          description: `${vehicle.Make} ${vehicle.Model} (${
            vehicle.ManufacturedFrom
          } -${
            vehicle.ManufacturedTo === 0 ? '' : ` ${vehicle.ManufacturedTo}`
          }) ${vehicle.EngineCc}cc, ${vehicle.Doors} door`,
        })
      }
      break
    case COMMERCIAL_VEHICLE:
      for (let variant in variants) {
        let vehicle = variants[variant]
        convertedVehicleTypes.push({
          id: id++,
          value: vehicle.Abi,
          description: `${vehicle.Make} ${vehicle.Model} (${
            vehicle.ManufacturedFrom
          } -${
            vehicle.ManufacturedTo === 0 ? '' : ` ${vehicle.ManufacturedTo}`
          }) ${vehicle.BodyType}, ${vehicle.EngineCc}cc, ${vehicle.EngineType}`,
        })
      }
      break
    case MOTORCYCLE:
      for (let variant in variants) {
        let vehicle = variants[variant]
        convertedVehicleTypes.push({
          id: id++,
          value: vehicle.Abi,
          description: `${vehicle.Make} ${vehicle.Model} (${
            vehicle.ManufacturedFrom
          } -${
            vehicle.ManufacturedTo === 0 ? '' : ` ${vehicle.ManufacturedTo}`
          }) - ${vehicle.EngineCc}cc`,
        })
      }
      break
    default:
      break
  }

  return convertedVehicleTypes
}
