export const motorcycleEntitlementOptions = [
  {
    id: 2,
    value: 'Provisional',
    description: 'Provisional',
  },
  {
    id: 3,
    value: 'FullPassedWithinThirtyDays',
    description: 'Full - passed within 30 days',
  },
  {
    id: 4,
    value: 'FullPassedThirtyOneDaysToLessThanOneYear',
    description: 'Full - passed 31 days to less than 1 year',
  },
  {
    id: 5,
    value: 'FullPassedOneToTwoYears',
    description: 'Full - passed 1-2 years',
  },
  {
    id: 6,
    value: 'FullPassedThreeToFourYears',
    description: 'Full - passed 3-4 years',
  },
  {
    id: 7,
    value: 'FullPassedFiveYearsPlus',
    description: 'Full - passed 5+ years',
  },
]
