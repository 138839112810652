import React from 'react'
import { EditWrapper } from './EditLink.style'

const EditLink: React.FC = ({ children }) => (
  <>
    <EditWrapper>
      <i className='fa fa-pencil-alt' />
      <span className='amend-space'>{children}</span>
    </EditWrapper>
  </>
)
export default EditLink
