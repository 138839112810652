import styled from 'styled-components'

export const StyledVehicalCarouselAddNew = styled.div`
  width: 300px;
  margin: auto;
  border-radius: 16px;
  border: 1px solid #070707;
  background-color: white;
  padding: 20px;
  margin-top: 10px;

  & select {
    margin-top: 10px;
  }

  & button {
    width: 100%;
  }

  & h4 {
    color: ${(props) => props.theme.primaryColour};
    margin-top: 0px;
  }

  & h5 {
    color: ${(props) => props.theme.primaryColour};
    margin-top: 10px;
    margin-bottom: 0px;
  }

  & .found-new-vehicle {
    margin: 5px auto;
  }

  & .add-new-vehicle-button {
    background-color: #779e00;
  }

  & .add-new-vehicle-button:disabled {
    background-color: #d2d2d2;
    color: #fff;
  }

  & .vehicle-value-label {
    text-align: left;
  }
`
