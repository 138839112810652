import styled from 'styled-components'
import { TextFieldStyled } from '../TextField/TextField.styles'

export const DateInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.inputBorderColour};
  border-radius: ${({ theme }) => theme.formControlBorderRadius};
  height: ${({ theme }) => theme.formControlHeight};
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  width: 100%;
  margin-bottom: 10px;
`

export const DateInputFieldWrapper = styled.div`
  width: 100%;
  ::after {
    position: absolute;
    content: '/';
    font-size: 32px;
    font-weight: ${({ theme }) => theme.fontWeight};
    color: #ccc;
    line-height: 1;
    top: 50%;
    margin-top: -0.5em;
  }
`

export const YearInputWrapper = styled.div`
  width: 100%;
`

export const DateInputField = styled(TextFieldStyled)`
  border: none;
  height: 100%;
  margin: 0;
  text-align: center;
  outline: none;
`
