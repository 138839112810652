import Trustpilot from 'components/@Logos/Trustpilot'
import TrustPilotStars from 'components/@Logos/Trustpilot/TrustPilotStars'
import { P } from 'components/common/P'
import React from 'react'
import { hardcodedTrustPilotReviews } from '../../constants'
import {
  TrustPilotLogoWrapper,
  TrustPilotStarsWrapper,
  TrustPilotSummaryBold,
  TrustPilotSummaryWrapper,
} from './TrustPilotSummary.styles'

const TrustPilotSummary = () => (
  <div>
    <TrustPilotSummaryWrapper>
      <TrustPilotSummaryBold>Excellent</TrustPilotSummaryBold>
      <TrustPilotStarsWrapper>
        <TrustPilotStars width={50} />
      </TrustPilotStarsWrapper>
    </TrustPilotSummaryWrapper>

    <TrustPilotSummaryWrapper>
      <P>{hardcodedTrustPilotReviews} reviews on </P>
      <TrustPilotLogoWrapper>
        <a
          href='https://uk.trustpilot.com/review/tempcover.com'
          target='_blank'
          rel='noreferrer'
        >
          <Trustpilot width={60} />
        </a>
      </TrustPilotLogoWrapper>
    </TrustPilotSummaryWrapper>
  </div>
)

export default TrustPilotSummary
