import initialState from '../../../initialState'
import { UPDATE_CUSOTMER_ACCOUNT_LOGGED_IN } from '../constants'

function updateCustomerAccountReducer(
  state = initialState.customerAccountLoggedIn,
  action,
) {
  switch (action.type) {
    case UPDATE_CUSOTMER_ACCOUNT_LOGGED_IN:
      return (state = action.customerAccountLoggedIn)
    default:
      return state
  }
}

export default updateCustomerAccountReducer
