import styled from 'styled-components'

export const BackgroundGradient = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
  background-image: linear-gradient(
    ${(props) => (props.isLoading ? '#FFF' : props.theme.gradient.background)},
    ${(props) => (props.isLoading ? '#FFF' : props.theme.gradient.startColour)}
  );
  @media (max-width: 767px) {
    background-color: #fff;
    background-image: linear-gradient(
      ${(props) =>
        props.isLoading ? '#FFF' : props.theme.gradient.background},
      ${(props) =>
        props.isLoading ? '#FFF' : props.theme.gradient.startColour}
    );
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    background-color: #fff;
    background-image: linear-gradient(
      ${(props) =>
        props.isLoading ? '#FFF' : props.theme.gradient.background},
      #fff
    );
  }
`
