import React, { useState, useMemo } from 'react'
import pluralize from 'pluralize'
import { useDuration } from 'hooks'
import { SummarySection } from 'containers/QuoteSummary/SummarySectionStyles'
import PolicyDurationModal from 'components/PolicyDurationModal'
import { DurationType } from 'types/global'

interface PolicyDurationSummaryProps {
  id: string
  handleUpdate: (duration: number | null, durationType: DurationType) => void
}

const PolicyDurationSummary: React.FC<PolicyDurationSummaryProps> = ({
  id,
  handleUpdate,
}) => {
  const [isModalVisible, setModalVisible] = useState(false)

  const { duration, durationType, isEditable } = useDuration()

  const durationString = useMemo(() => {
    const formattedDurationType =
      parseInt(duration.toString(), 10) === 1
        ? pluralize.singular(durationType)
        : durationType

    return `${duration} ${formattedDurationType}`
  }, [duration, durationType])

  const togglePolicyDurationModal = () => {
    setModalVisible(!isModalVisible)
  }

  return (
    <>
      <SummarySection
        id={id}
        cursor={!isEditable ? 'auto' : undefined}
        onClick={() => (isEditable ? togglePolicyDurationModal() : null)}
      >
        <p className='quote-summary-title'>Duration</p>
        <p className='quote-summary-text'>{durationString}</p>
        {isEditable ? <i className='fa fa-pencil-alt'></i> : null}
      </SummarySection>
      <PolicyDurationModal
        isVisible={isModalVisible}
        hideModal={togglePolicyDurationModal}
        handleUpdate={handleUpdate}
      />
    </>
  )
}

export default PolicyDurationSummary
