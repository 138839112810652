import React, { useEffect, useState } from 'react'
import { H2 } from '../../../../common/H2'
import DurationSelector from '../../../PolicyDuration/components/DurationSelector'
import PolicyDateTimePicker from 'components/PolicyDateTimePicker'

const QuoteParametersForm = () => {
  return (
    <div>
      <H2>How long do you need cover for?</H2>
      <div style={{ padding: '1em 0' }}>
        <DurationSelector />
      </div>
      <H2 style={{ margin: 0 }}>...and when do you want the cover to start?</H2>
      <div>
        <PolicyDateTimePicker />
      </div>
    </div>
  )
}

export default QuoteParametersForm
