import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export const Free30DayGAPInsuranceIncluded = ({
  width = 0,
  height = 0,
  scale = 1,
}) => {
  const opts = {}
  const { svg } = useContext(ThemeContext)

  if (width) {
    opts.width = width
  }

  if (height) {
    opts.height = height
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='181.299'
      height='148.522'
      viewBox='0 0 181.299 148.522'
    >
      <g id='car-insurance-svgrepo-com' transform='translate(0 -55.325)'>
        <path
          id='Path_5834'
          data-name='Path 5834'
          d='M178.146,165.1l-30.332,25.83V200.2a3.461,3.461,0,0,1-3.673,3.347H123.909a3.5,3.5,0,0,1-3.673-3.347V179.41a3.476,3.476,0,0,1,1.422-2.814l37.057-23.016,3.673-48.876a7.586,7.586,0,0,1,15.167-.3l3.673,52.815C181.523,161.489,180.989,162.851,178.146,165.1Zm-145.5-18.217V110.362a20.828,20.828,0,0,1,2.251-9.834l17.98-37.353c1.985-4.236,7.583-7.85,12.352-7.85h50.86c5.036,0,10.693,3.347,12.619,7.85l17.98,37.353a21.721,21.721,0,0,1,2.251,9.834v36.553a3.026,3.026,0,0,1-2.814,2.814H131.818A2.829,2.829,0,0,1,129,146.915V137.88H52.58v9.005a3.026,3.026,0,0,1-2.814,2.814H35.459A3.051,3.051,0,0,1,32.645,146.885Zm97.484-37.945a9,9,0,0,0,0,18.01,9,9,0,1,0,0-18.01ZM48.937,99.4h83.71l-12.352-25.83c-2.518-5.036-4.769-6.191-9.834-6.191H70.886c-5.036,0-7.287,1.1-9.538,6.191Zm-6.457,18.513a9.077,9.077,0,0,0,9,9,9,9,0,0,0,0-18.01A9.077,9.077,0,0,0,42.479,117.915ZM59.9,176.892,22.84,153.876,19.167,105a7.43,7.43,0,0,0-7.583-7.85,7.747,7.747,0,0,0-7.85,7.553L.061,157.549c-.267,4.236.267,5.6,3.081,7.85l30.332,25.83V200.5a3.461,3.461,0,0,0,3.673,3.347H57.675a3.5,3.5,0,0,0,3.673-3.347V179.706A3.873,3.873,0,0,0,59.9,176.892Z'
          transform='translate(0 0)'
          fill='#264694'
        />
      </g>
    </svg>
  )
}
