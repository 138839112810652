import queryString, { parse } from 'query-string'
import React from 'react'
import {
  COMMERCIAL_VEHICLE,
  MOTORCYCLE,
  PRIVATE_CAR,
} from '../../containers/Vehicle/constants'
import {
  HyperlinkButton,
  VehicleButtonWrapper,
  VehicleTypeParagraph,
} from '../../containers/Vehicle/styles'

export class VehicleTypeMessage extends React.Component {
  vehicleTypeFriendlyWording(vehicleType) {
    switch (vehicleType) {
      case PRIVATE_CAR:
        return 'car'
      case COMMERCIAL_VEHICLE:
        return 'van'
      case MOTORCYCLE:
        return 'motorcycle'
      default:
        return null
    }
  }

  render() {
    const { message, vehicleType, registration, duration } = this.props
    const parsedQueryString = queryString.parse(window.location.search)
    const hasRef = !!parsedQueryString.ref

    return (
      <VehicleButtonWrapper>
        <VehicleTypeParagraph>{message}</VehicleTypeParagraph>
        <VehicleTypeParagraph>
          Looking for temporary {this.vehicleTypeFriendlyWording(vehicleType)}{' '}
          insurance?&nbsp;
          <HyperlinkButton
            href={`/?registration=${registration}&duration=${duration}${
              hasRef ? `&ref=${parsedQueryString.ref}` : ''
            }`}
          >
            Click here to get a quote.
          </HyperlinkButton>
        </VehicleTypeParagraph>
      </VehicleButtonWrapper>
    )
  }
}
