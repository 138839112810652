import { put, select, call } from 'redux-saga/effects'
import { history } from '../..'
import { enableAllButtons } from '../../containers/RiskDataCollectionPage/actions'
import { requestLegalDeclarationLink } from './selector'
import { requestLegalDeclarationSuccess } from './actions'
import { getQueryString } from '../RiskDataCollectionPage/selector'
import underwriterApi from '../../api/underwriterApi'

export default function* legalDeclarationSaga() {
  const legalDeclarationLink = yield select(requestLegalDeclarationLink)
  const legalDeclarationText = yield call(
    underwriterApi.getLegalDeclaration,
    legalDeclarationLink.Href,
  )

  yield put(requestLegalDeclarationSuccess(legalDeclarationText.data))
  const queryString = yield select(getQueryString)
  history.push(`/quote/legal-declaration${queryString}`) //TODO Need to fix up declartion routing, too many things going on prior to getting here
  yield put(enableAllButtons())
}
