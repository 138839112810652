import styled from 'styled-components'

interface NotActiveFeatureWrapperProps {
  isVisible?: boolean
  fullscreen?: boolean
}

interface NotActiveFeatureContentProps {
  isLarge?: boolean
  fullscreen?: boolean
}

export const NotActiveFeatureWrapperStyled = styled.div<NotActiveFeatureWrapperProps>`
  position: fixed;
  top: ${({ isVisible, fullscreen }) => {
    if (fullscreen) {
      return isVisible ? 0 : '100%'
    }

    return 0
  }};
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ isVisible, fullscreen }) =>
    !fullscreen && !isVisible ? 0 : 1};
  transition: all ${({ fullscreen }) => (fullscreen ? 0.5 : 0.3)}s ease-in-out;
  pointer-events: ${({ isVisible }) => (isVisible ? 'visible' : 'none')};
  z-index: 1000000;
`

export const NotActiveFeatureContentStyled = styled.div<NotActiveFeatureContentProps>`
    background-color: #EBEFE7;
    max-width: ${({ fullscreen, isLarge }) => {
      if (fullscreen) return '100%;'

      return `${isLarge ? 800 : 500}px;`
    }}
    width: 100%;
    height: '100%';
    box-sizing: border-box;
    border-radius: ${({ fullscreen }) => (fullscreen ? 0 : 20)}px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border: 1px solid #7F9D2F;
    padding:40px 40px 15px;
    
    & h3{
        color:#779e00;
        margin: 5px 0;
        font-size:22px;
    }

    & p{
        font-size:15px;
    }

    & .coming-soon-image{
        margin:auto;
        margin-top:-120px;
    }
`
