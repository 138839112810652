import React from 'react'
import Proptypes from 'prop-types'

export const ShortDeclaration = ({ html }) => {
  return (
    <div
      id='ShortDeclaration'
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  )
}

ShortDeclaration.propTypes = {
  html: Proptypes.string.isRequired,
}
