import styled from 'styled-components'
import Button from 'components/@common/Button'
import { H2 } from 'components/common/H2'

export const StyledCustomerAccountQuote = styled.div`
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;

  & ul {
    text-align: left;
  }

  & ul {
    margin: 8px 0 16px;
    padding-left: 0;
  }

  & li {
    list-style: none;
    padding: 5px 0;
  }

  & ul li div {
    text-align: left;
  }

  & ul li div i {
    color: #264b94;
    font-size: 20px;
    margin-right: 10px;
  }

  & .no-header {
    padding-top: 20px;
  }
`

export const CustomerAccountQuoteHeading = styled(H2)`
  font-weight: 400;
  margin: 8px 0 4px;
  text-align: left;
`

export const CustomerAccountQuoteButton = styled(Button)`
  background-color: #a2bc1e;
  font-size: 18px;
  line-height: 25px;
  padding: 10px;
  height: auto;
  width: 100%;
  a {
    text-decoration: none;
    color: white !important;

    font-size: 18px;
    &:hover {
      text-decoration: none;
    }
  }
`
