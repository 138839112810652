import { SEND_EMAIL_QUOTE, UPDATE_EMAIL_RISK_DATA } from './constants'
import { call, put, takeLatest, select } from 'redux-saga/effects'

import { getRiskData, sendEmailSelector, getPriceData } from './selector'
import EmailQuoteApi from '../../api/emailQuoteApi'
import {
  sendEmailQuoteFailure,
  sendEmailQuoteSuccess,
  updateEmailRiskDataSuccess,
} from './actions'
import { updateRiskDataSaga } from '../RiskDataCollectionPage/sagas/riskData'

export function* sendEmailQuote() {
  try {
    const riskData = yield select(getRiskData)
    const priceData = yield select(getPriceData)
    const emailLink = yield select(sendEmailSelector)

    let quoteReminders = []

    if (priceData.Prices && priceData.Prices.length > 0) {
      quoteReminders = priceData.Prices.map((p) => {
        return {
          Premium: p.TotalPrice.toFixed(2),
          Underwriter: p.UnderwriterId || 0,
        }
      })
    } else {
      quoteReminders = [
        {
          Premium: priceData.TotalPrice.toFixed(2),
          Underwriter: priceData.UnderwriterId || 0,
        },
      ]
    }
    const data = {
      Email: riskData.EmailAddress,
      QuoteId: {
        value: riskData.QuoteId,
      },
      QuoteReminders: quoteReminders,
    }
    const response = yield call(
      EmailQuoteApi.sendQuoteEmail,
      emailLink.Href,
      data,
    )
    yield put(sendEmailQuoteSuccess(response.data))
  } catch (e) {
    yield put(sendEmailQuoteFailure(e))
  }
}

function* updateEmailRiskData() {
  try {
    yield* updateRiskDataSaga({
      payload: { partialValidation: false, getPrice: true },
    })
    yield put(updateEmailRiskDataSuccess())
  } catch (e) {
    yield put(sendEmailQuoteFailure(e))
  }
}

export default function* emailQuoteSaga() {
  yield takeLatest(SEND_EMAIL_QUOTE, sendEmailQuote)
  yield takeLatest(UPDATE_EMAIL_RISK_DATA, updateEmailRiskData)
}
