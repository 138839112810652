/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */

import { RAC } from 'components/SVG/add-on-icons/RAC'
import { requestAddons } from 'containers/App/actions'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useAddons } from '../../hooks'
import { ExcessCover } from '../SVG/add-on-icons/ExcessCover'
import { LegalExpenses } from '../SVG/add-on-icons/LegalExpenses'
import { Free30DayGAPInsuranceIncluded } from '../SVG/add-on-icons/Free30DayGAPInsuranceIncluded'

import { AddonMoreInfo } from './AddonMoreInfo/AddonMoreInfo'
import {
  AddonButton,
  AddonContentBody,
  AddonContentContainer,
  AddonContentHeader,
  AddonError,
  AddonLogoContainer,
  AddonPriceContainer,
  AddonSection,
  LinkContainer,
  Price,
  TextForGapPrice,
} from './AddonPriceComparisonContainer.styles'
// eslint-disable-next-line import/no-cycle
import { Excess } from './Addons'

interface AddonProps {
  disabled: boolean
  error: boolean
  excess: Excess
  price: string
  selected: boolean
  type: string
}

const AddonPriceComparisonContainer: React.FC<AddonProps> = ({
  disabled,
  error,
  excess,
  price,
  selected,
  type,
}) => {
  const { selectedAddons } = useAddons()
  const dispatch = useDispatch()

  const buildNewAddonSelections = (newSelection: boolean) => {
    const selections = selectedAddons.map((addon) => ({
      [addon.type]: addon.selected,
    }))
    const includedAddons = Object.assign({}, ...selections)

    switch (type) {
      case 'excess':
        includedAddons.ExcessReductionIncluded = newSelection
        break
      case 'breakdown':
        includedAddons.BreakdownIncluded = newSelection
        break
      case 'legalexpenses':
        includedAddons.LegalExpensesIncluded = newSelection
        break
      case 'gapinsurance':
        includedAddons.Free30DayGAPInsuranceIncluded = newSelection
        break
      default:
        break
    }

    return includedAddons
  }

  const handleClick = (newSelected: boolean) => {
    if (!disabled) {
      const addonOptions = buildNewAddonSelections(newSelected)
      dispatch(requestAddons(addonOptions, type))
    }
  }

  const AddonButtonComponent = () => {
    if (selected) {
      if (type === 'gapinsurance') {
        return (
          <>
            <AddonButton id={`addon-addbtn-${type}`} active disabled>
              ADDED
            </AddonButton>
            <TextForGapPrice>
              You can then choose to extend or let it expire.
            </TextForGapPrice>
            <LinkContainer
              id={`addon-remove-${type}`}
              onClick={() => handleClick(false)}
              disabled={disabled}
            >
              Remove
            </LinkContainer>
          </>
        )
      }
      return (
        <>
          <AddonButton id={`addon-addbtn-${type}`} active disabled>
            ADDED
          </AddonButton>
          <LinkContainer
            id={`addon-remove-${type}`}
            onClick={() => handleClick(false)}
            disabled={disabled}
          >
            Remove
          </LinkContainer>
        </>
      )
    }
    if (type === 'gapinsurance') {
      return (
        <>
          <AddonButton
            id={`addon-addbtn-${type}`}
            onClick={() => handleClick(true)}
            disabled={disabled}
          >
            ADD
          </AddonButton>
          <TextForGapPrice>
            You can then choose to extend or let it expire.
          </TextForGapPrice>
        </>
      )
    }
    return (
      <>
        <AddonButton
          id={`addon-addbtn-${type}`}
          onClick={() => handleClick(true)}
          disabled={disabled}
        >
          ADD
        </AddonButton>
      </>
    )
  }

  const AddonContent = () => {
    switch (type) {
      case 'excess':
        return (
          <AddonContentBody>
            Your policy excess is £{excess.compulsory}. Add excess reduction to
            reduce your excess to £{excess.reduced}.
          </AddonContentBody>
        )
      case 'breakdown':
        return (
          <AddonContentBody>
            Includes roadside assistance, cover at home and national recovery.
          </AddonContentBody>
        )
      case 'legalexpenses':
        return (
          <AddonContentBody>
            Provides cover up to £100,000 for legal costs including uninsured
            loss recovery for incidents that are non-fault.
          </AddonContentBody>
        )
      case 'gapinsurance':
        return (
          <AddonContentBody>
            Tops up your insurer payout to the original purchase value if your
            vehicle is written off or stolen.
          </AddonContentBody>
        )
      default:
        return <></>
    }
  }

  const AddonHeader = () => {
    switch (type) {
      case 'excess':
        return (
          <AddonContentHeader id='addon-header-excess'>
            Excess Reduction Cover
          </AddonContentHeader>
        )
      case 'breakdown':
        return (
          <AddonContentHeader id='addon-header-breakdown'>
            RAC UK Breakdown Cover
          </AddonContentHeader>
        )
      case 'legalexpenses':
        return (
          <>
            <AddonContentHeader id='addon-header-legalexpenses'>
              Legal Expenses Cover
            </AddonContentHeader>
          </>
        )
      case 'gapinsurance':
        return (
          <AddonContentHeader id='addon-header-freegapinsurance'>
            Free 30 Days GAP Insurance
          </AddonContentHeader>
        )
      default:
        return <></>
    }
  }

  const AddonLogo = () => {
    switch (type) {
      case 'excess':
        return (
          <AddonLogoContainer>
            <ExcessCover scale={1.5} />
          </AddonLogoContainer>
        )
      case 'breakdown':
        return (
          <AddonLogoContainer>
            <RAC height={1} width={1} />
          </AddonLogoContainer>
        )
      case 'legalexpenses':
        return (
          <AddonLogoContainer>
            <LegalExpenses height={1} width={1} />
          </AddonLogoContainer>
        )
      case 'gapinsurance':
        return (
          <AddonLogoContainer>
            <Free30DayGAPInsuranceIncluded height={1} width={1} />
          </AddonLogoContainer>
        )
      default:
        return <></>
    }
  }

  const PriceComponent = () => {
    const parsed = parseFloat(price)
    let priceText =
      parsed > 1 ? `+£${parsed.toFixed(2)}` : `+${(parsed * 100).toFixed(0)}p`
    if (!price) {
      priceText = 'Free'
    }

    return (
      <Price id={`addon-price-${type}`} active={selected}>
        {priceText}
      </Price>
    )
  }

  return (
    <>
      {error && (
        <AddonError>Unable to update your selection, try again.</AddonError>
      )}
      <AddonSection
        id={`addon-container-${type}`}
        active={selected}
        error={error}
      >
        <AddonLogo />
        <AddonContentContainer>
          <div>
            <AddonHeader />
            <AddonContent />
          </div>
          <AddonMoreInfo type={type} isPaymentPage />
        </AddonContentContainer>
        <AddonPriceContainer>
          <PriceComponent />
          <AddonButtonComponent />
        </AddonPriceContainer>
      </AddonSection>
    </>
  )
}

export default AddonPriceComparisonContainer
