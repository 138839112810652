import defaultTheme from './default'
import motorEasyHeader from '../images/HeaderBackground/motorEasyHeader.jpg'

let motorEasyTheme = { ...defaultTheme }
const primaryColour = '#00a68f'
motorEasyTheme.primaryColour = primaryColour
motorEasyTheme.primaryTextColour = '#333'
motorEasyTheme.headerFontColour = primaryColour
motorEasyTheme.breadcrumb = {
  ...defaultTheme.breadcrumb,
  activeColor: '#ffffff',
  activeBorderColor: primaryColour,
  defaultColor: '#ffffff',
  defaultBorderColor: primaryColour,
  defaultBarColor: '#CCCCCC',
  completeColor: primaryColour,
  completeBorderColor: primaryColour,
  completeBarColor: primaryColour,
}
motorEasyTheme.spinner = {
  ...defaultTheme.spinner,
  primaryColour: primaryColour,
  textColour: primaryColour,
}
motorEasyTheme.durationOptions = {
  ...defaultTheme.durationOptions,
  primaryColour: primaryColour,
  secondaryColour: primaryColour,
}

motorEasyTheme.button = {
  ...defaultTheme.button,
  backgroundColour: primaryColour,
  addon: {
    backgroundColour: primaryColour,
    disabledBackground: '#808080',
    disabledFont: '#fff',
  },
}
motorEasyTheme.errorBorderProperties = '2px solid #e47008'
motorEasyTheme.headerLinkStyled.padding = '6px 7px;'

motorEasyTheme.headerLinkStyled = {
  ...defaultTheme.headerLinkStyled,
  hover: {
    ...defaultTheme.headerLinkStyled.hover,
    textDecoration: 'underline',
    color: '#fff',
    backgroundColour: 'transparent',
    padding: '0px',
  },
}

motorEasyTheme.header = {
  ...defaultTheme.header,
  background: primaryColour,
  backgroundImage: `url(${motorEasyHeader})`,
}
motorEasyTheme.gradient = {
  ...defaultTheme.gradient,
  background: '#ffffff',
}
motorEasyTheme.GbIcon = '#ffffff'
motorEasyTheme.formControlValidIcon = {
  ...defaultTheme.formControlValidIcon,
  backgroundColour: primaryColour,
  colour: '#fff',
}
motorEasyTheme.icon = {
  ...defaultTheme.icon,
  color: '#ffffff',
  backgroundColor: primaryColour,
}
motorEasyTheme.inputBorderColour = primaryColour
motorEasyTheme.successColour = primaryColour
motorEasyTheme.marketingPreferencesColour = primaryColour
motorEasyTheme.svg = {
  ...defaultTheme.svg,
  primaryColour: '#545656',
  accentColour: primaryColour,
}

export default motorEasyTheme
