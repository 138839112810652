import React from 'react'
import Modal from '../@common/Modal'
import { useSelector } from 'react-redux'
import { InitialStateType } from 'initialState'
import { PolicyStartDateFormData } from 'components/@forms/DriverDetails/DriverDetails.types'
import Form from 'components/@templates/Form'
import PolicyDateTimePicker from '../PolicyDateTimePicker'
import { UpdateButton } from './PolicyStartTimeModal.styles'
import { QuoteParamsSchema } from 'components/@forms/DriverDetails/SubForms/QuoteParametersForm/QuoteParametersForm.validation'
import { FormikHelpers } from 'formik'

interface Props {
  isVisible: boolean
  hideModal: () => void
  handleDateTimeSet: (dateTime: string, manuallyChanged: boolean) => void
}

const PolicyStartTimeModal: React.FC<Props> = ({
  isVisible,
  hideModal,
  handleDateTimeSet,
}) => {
  const policyDate = useSelector(
    (state: InitialStateType) => state.riskData.PolicyStartDate,
  )

  const initialValues: PolicyStartDateFormData = {
    policyStartTime: policyDate.manuallyEdited ? policyDate.value || '' : '',
    manuallyChanged: policyDate.manuallyEdited,
  }

  const onFormSubmit = (
    data: PolicyStartDateFormData,
    bag?: FormikHelpers<PolicyStartDateFormData>,
  ) => {
    handleDateTimeSet(data.policyStartTime, data.manuallyChanged)
    if (!data.manuallyChanged && bag) {
      bag.setFieldValue('policyStartTime', '', false)
    }

    hideModal()
  }

  return (
    <Modal
      id='policyDateTimePicker'
      isVisible={isVisible}
      onClose={hideModal}
      header='When would you like the policy to start?'
      headerAlignment='center'
      fullscreen={window.innerWidth <= 500}
    >
      <Form
        onSubmit={(data, bag) => {
          onFormSubmit(data, bag)
        }}
        initialValues={initialValues}
        validationSchema={QuoteParamsSchema}
      >
        <PolicyDateTimePicker />
        <UpdateButton id='policyStartDoneButton' type='submit'>
          Done
        </UpdateButton>
      </Form>
    </Modal>
  )
}

export default PolicyStartTimeModal
