import styled from 'styled-components'
import { H2 } from 'components/common/H2'
import Button from 'components/@common/Button'
import policyDocsImage1 from '../../../images/policy-confirmation-docs-1.png'
import policyDocsImage2 from '../../../images/policy-confirmation-docs-2.png'
import policyConfirmationQRCode from '../../../images/policy-confirmation-qr-code.png'

export const PolicyDatesCoverLabel = styled.p`
  font-family: ${(props) => props.theme.labelFontFamily};
  font-size: 16px;
  margin: 0 0 4px;
  @media (max-width: 768px) {
    font-weight: 700;
    margin: 0px;
    color: ${(props) => props.theme.primaryTextColour};
  }
`

export const PolicyDatesCoverDate = styled.p`
  white-space: pre-line;
  margin: 0;
  color: ${(props) => props.theme.primaryTextColour};
  font-family: ${(props) => props.theme.labelFontFamily};
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  line-height: 22px;
`

export const PolicyDatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
export const PolicyDatesCol = styled.div`
  margin: 0 16px;
  align-items: center;
  justify-content: center;
`

export const PolicyDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 10px;
`

export const PolicyDetailsHeading = styled(H2)`
  font-weight: 400;
  margin-bottom: 4px;
`

export const PolicyDetailsP = styled.p`
  margin: 4px 0;
  font-family: ${(props) => props.theme.labelFontFamily};
  line-height: 20px;
  b {
    font-family: ${(props) => props.theme.headerFontFamily};
    font-weight: 400;
  }
`

export const PolicyDocumentsList = styled.ul`
  padding: 0 10px;
  list-style: none;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const PolicyDocumentListItem = styled.li`
  display: flex;
  flex: 1;
  width: 100%;
`

export const PolicyDocumentLink = styled.a`
  align-items: center;
  background-color: white;
  border: 1px solid ${(props) => props.theme.primaryColour};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 8px;
  color: ${(props) => props.theme.primaryTextColour};
  font-family: ${(props) => props.theme.button.fontFamily};
  font-size: 16px;
  line-height: 20px;
  width: 100%;
`

export const PolicyDocumentName = styled.span`
  display: inline-block;
  margin-left: 8px;
`

export const MobileAppBannerMobile = styled.div`
  display: none;
  margin-top: 32px;
  padding: 0 10px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`
export const MobileAppBannerMobileContent = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #274594;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

export const MobileAppBannerMobileText = styled.p`
  color: white;
  font-family: ${(props) => props.theme.headerFontFamily};
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  padding: 16px 18px;
  line-height: 22px;
  flex: 2;
`

export const MobileAppBannerMobileImage = styled.div`
  flex: 1.5;
  max-width: 160px;
  background-image: url(${policyDocsImage1});
  margin-top: -16px;
  margin-right: 4px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  display: none;
  @media (min-width: 320px) {
    display: flex;
  }
`

export const MobileAppBannerMobileCTA = styled.div`
  flex: 1;
  display: flex;
  padding: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #274594;
`

export const MobileAppBannerMobileCTALink = styled.a`
  background-color: #a2bc1e;
  font-size: 18px;
  line-height: 25px;
  padding: 10px;
  height: auto;
  color: white !important;
  text-decoration: none;
  font-size: 18px;
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  font-family: ${(props) => props.theme.button.fontFamily};
  text-transform: ${(props) => props.theme.button.textTransform};
  text-align: ${(props) => props.theme.button.textAlign};
  box-shadow: none;
  border: none;
  outline: none;
  width: 100%;
  &:hover {
    filter: brightness(80%);
    text-decoration: none;
  }
`

export const MobileAppBannerDesktop = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  background-color: #274594;
  padding: 0 10px;
  border-radius: 8px;
  @media (max-width: 768px) {
    display: none;
  }
`

export const MobileAppBannerDesktopText = styled.p`
  color: white;
  font-family: ${(props) => props.theme.headerFontFamily};
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  padding: 16px 18px;
  line-height: 30px;
  flex: 2;
  align-self: center;
`

export const MobileAppBannerDesktopQRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  padding: 16px 0;
`

export const MobileAppBannerDesktopQRCode = styled.div`
  background-image: url(${policyConfirmationQRCode});
  background-repeat: no-repeat;
  background-size: contain;
  height: 75px;
  width: 75px;
`

export const MobileAppBannerDesktopQRCodeText = styled.p`
  color: white;
  size: 14px;
  margin: 8px 0 0;
`

export const MobileAppBannerDesktopImage = styled.div`
  flex: 1.5;
  max-width: 160px;
  background-image: url(${policyDocsImage2});
  margin-top: -16px;
  margin-left: 18px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
`

export const VehicleRegistrationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;
`

export const VehicleRegistrationContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  text-transform: uppercase;
  letter-spacing: 1px;
`

export const GBIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 37px;
  border-radius: 8px 0px 0px 8px;
  background-color: ${(props) => props.theme.buttonAccentColour};
  color: ${(props) => props.theme.GbIcon};
  font-family: 'BrandonText-Bold', sans-serif;
  font-size: 14px;
  line-height: 21px;
`
export const RegistrationNumber = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 32px;
  border: 1px solid ${(props) => props.theme.primaryColour};
  border-radius: 0px 8px 8px 0px;
  font-family: UKNumberPlate;
  font-size: 25px;
  line-height: 38px;
  box-sizing: border-box;
`
