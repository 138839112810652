import styled from 'styled-components'
import { H1 } from '../../components/common/H1'

export const MainHeading = styled(H1)`
  color: ${(props) => props.theme.primaryColour};
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 32px;
`

export const I = styled.i`
  color: ${(props) => props.theme.successColour};
  font-size: 55px;
`

export const IconWrapper = styled.div`
  text-align: center;
`

export const SubHeading = styled.p`
  font-family: ${(props) => props.theme.labelFontFamily};
  font-size: 16px;
  text-align: center;
  margin: 0;
  font-weight: 700;
`
