import React from 'react'
import validator from 'validator'
import { ErrorMessage } from '../common/ErrorMessage'
import { RegistrationInput, ChangeVehicleGBIcon } from './styles'

export class VehicleRegistration extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleValidation = this.handleValidation.bind(this)
    // https://gist.github.com/danielrbradley/7567269
    this.regexEpression =
      /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/
  }

  handleChange = (event) => {
    let message = this.handleValidation(event)
    this.props.onChange(event, message)
  }

  handleValidation = (event) => {
    let message = validator.matches(
      event.target.value.replace(/\s/g, '').toUpperCase(),
      this.regexEpression,
    )
      ? ''
      : 'Please enter a valid Registration'

    return message
  }

  render() {
    return (
      <>
        <ChangeVehicleGBIcon>GB</ChangeVehicleGBIcon>
        <RegistrationInput
          id='RegistrationInput'
          placeholder='My reg number is...'
          onChange={this.handleChange}
          onBlur={this.handleChange}
          name='RegistrationInput'
          value={this.props.value}
          error={this.props.error}
          disabled={this.props.disabled}
          autoComplete='off'
          autoCorrect='off'
        />
        <ErrorMessage>{this.props.error}</ErrorMessage>
      </>
    )
  }
}

export default VehicleRegistration
