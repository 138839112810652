import { Field } from 'formik'
import Select from 'components/@common/Select'
import { H2 } from 'components/common/H2'
import { BlueH3 } from 'components/common/H3'
import { H5 } from 'components/common/H5'
import React from 'react'
import FormFieldContent from 'components/@common/FormField/FormFieldContent'
import { SelectType } from '../types'
import { ConfusedQuickQuoteOptInValues } from './ConfusedQuickQuoteOptIn.constants'

interface Props extends SelectType {}

const ConfusedQuickQuoteOptIn: React.FC<Props> = ({ onSelect }) => (
  <>
    <H2>Annual car insurance quote</H2>
    <BlueH3>
      Would you like an annual car insurance quote from Confused.com for this
      vehicle?
    </BlueH3>
    <H5>
      We&apos;ve partnered with Confused.com so that if you have a Confused.com
      account and have received a car insurance quote in the last 36 months, we
      can show you a personalised quote for this vehicle after you have
      purchased your temporary insurance policy.
    </H5>
    <Field name='confusedQuickQuoteOptIn'>
      {/* @ts-ignore */}
      {({ field, form, meta }) => (
        <FormFieldContent
          field={field}
          meta={meta}
          form={form}
          isValidIcon={false}
        >
          <Select
            id='ConfusedQuickQuoteOptIn'
            name='confusedQuickQuoteOptIn'
            onChange={onSelect}
            options={ConfusedQuickQuoteOptInValues}
            placeholder='Please select if you would like an annual car insurance quote'
          />
        </FormFieldContent>
      )}
    </Field>
  </>
)

export default ConfusedQuickQuoteOptIn
