import { SchemeEnum } from '@enums'
import moment from 'moment'
import queryString from 'query-string'
import { POLICY_DURATION_DAYS } from '../components/PolicyDurationModal/constants'
import {
  COMMERCIAL_VEHICLE,
  COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_PRIVATE_CAR_SCHEME,
  MOTORCYCLE,
  MOTORCYCLE_VEHICLE_SCHEME,
  PRIVATE_CAR,
  PRIVATE_CAR_SCHEME,
  VEHICLE_LEARNER_SCHEME,
} from '../containers/Vehicle/constants'
import { LICENCE_HELD_DURATIONS } from './constants'

export const handleQueryString = () => {
  const parsedQueryString = queryString.parse(window.location.search)
  let policyDuration, policyDurationType
  // set default
  policyDuration = 1
  policyDurationType = POLICY_DURATION_DAYS

  if (parsedQueryString.duration) {
    var durationArray = parsedQueryString.duration.split(' ')

    if (durationArray.length > 1 && !isNaN(Number(durationArray[0]))) {
      policyDuration = Number(durationArray[0])
      policyDurationType = durationArray[1].toLowerCase()
      policyDurationType =
        parseInt(policyDuration) === 1
          ? policyDurationType + 's'
          : policyDurationType
    }
  }
  let vehicleType = null
  switch (parsedQueryString.type) {
    case PRIVATE_CAR:
    case PRIVATE_CAR.toLowerCase():
      vehicleType = PRIVATE_CAR
      break
    case COMMERCIAL_VEHICLE:
    case COMMERCIAL_VEHICLE.toLowerCase():
      vehicleType = COMMERCIAL_VEHICLE
      break
    case MOTORCYCLE:
    case MOTORCYCLE.toLowerCase():
      vehicleType = MOTORCYCLE
      break
    default:
      break
  }

  let preQuoteInformation = {
    RegistrationNumber: parsedQueryString.registration,
    Duration: policyDuration, //2+week,
    DurationType: policyDurationType,
    UnknownVehicle: {
      Make: parsedQueryString.make,
      Model: parsedQueryString.model,
      Variant: parsedQueryString.variant,
      Abi: parsedQueryString.variant,
      GrossWeight: parsedQueryString.weight,
      YearOfManufacture: parsedQueryString.year,
      VehicleType: vehicleType,
      RegistrationNumber: parsedQueryString.registration,
    },
    QuoteId: parsedQueryString.q,
    Email: parsedQueryString.EmailAddress,
    Ref: parsedQueryString.ref,
    SelectedUnderwriter: parsedQueryString.u,
    CampaignRef: parsedQueryString.utm_campaignref,
    Impounded:
      parsedQueryString.impounded && parsedQueryString.impounded === 'true',
    Expat: parsedQueryString.expat && parsedQueryString.expat === 'true',
    Learner: parsedQueryString.learner && parsedQueryString.learner === 'true',
    Motorbike:
      parsedQueryString.motorbike && parsedQueryString.motorbike === 'true',
    Courier: parsedQueryString.courier && parsedQueryString.courier === 'true',
  }
  return preQuoteInformation
}

export const handleLicenceDurations = (dateOfBirth) => {
  const assumedPassedDate = moment().subtract(17, 'years')
  const fullOptions = LICENCE_HELD_DURATIONS
  let filteredOptions = []
  let yearDiff = moment(assumedPassedDate).diff(dateOfBirth, 'years', true)

  fullOptions.forEach((option) => {
    if (yearDiff >= option.yearDiff) {
      filteredOptions.push(option)
    }
  })

  return filteredOptions
}

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const humanize = (value) => {
  return value.split(/(?=[A-Z])/).join(' ')
}

export const loadScript = (url, callback) => {
  let script = document.createElement('script')
  script.type = 'text/javascript'

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        if (callback) callback()
      }
    }
  } else {
    script.onload = function () {
      if (callback) callback()
    }
  }

  script.src = url
  document.getElementsByTagName('body')[0].appendChild(script)
}

export const appendToHead = function (html) {
  document
    .getElementsByTagName('head')[0]
    .appendChild(document.createRange().createContextualFragment(html))
}

export const appendToBody = function (html) {
  document
    .getElementsByTagName('body')[0]
    .appendChild(document.createRange().createContextualFragment(html))
}

export const smoothScroll = (el) => {
  const pageYOffset = window.pageYOffset
  const halfViewportHeight =
    ((window.visualViewport && window.visualViewport.height) ||
      window.innerHeight) / 2
  const elementYOffset = el.getBoundingClientRect().top
  const relativeScrollDistance =
    pageYOffset + elementYOffset - halfViewportHeight - pageYOffset
  if (Math.abs(relativeScrollDistance) > 50) {
    const duration = 200
    const steps = 50
    const step = relativeScrollDistance / steps
    let yScrollPos = pageYOffset
    let currentStep = 0
    const loop = setInterval(() => {
      if (currentStep < steps) {
        yScrollPos += step
        currentStep++
        window.scrollTo(0, yScrollPos)
      } else {
        clearInterval(loop)
      }
    }, duration / steps)
  }
}

export const isValidScheme = (selectedScheme) => {
  // Needs to be built up as we introduce new schemes
  const schemeList = [
    PRIVATE_CAR_SCHEME,
    IMPOUNDED_PRIVATE_CAR_SCHEME,
    COMMERCIAL_VEHICLE_SCHEME,
    IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
    VEHICLE_LEARNER_SCHEME,
    MOTORCYCLE_VEHICLE_SCHEME,
    SchemeEnum.COURIER,
  ]
  return schemeList.indexOf(selectedScheme) >= 0
}

export const convertValueToEnum = (
  options,
  value,
  valueDefault = '',
  descriptionDefault = null,
) => {
  const filteredResult = options.filter((o) => o.id === value)

  if (filteredResult.length) {
    return {
      value: filteredResult[0].value,
      description: filteredResult[0].description,
    }
  }

  return {
    value: valueDefault,
    description: descriptionDefault,
  }
}
