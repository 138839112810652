import styled from 'styled-components'

export const FormFieldWrapper = styled.div`
  position: relative;

  input:before {
    content: '\f00c';
  }
`

interface Props {
  showIcon?: boolean
}

export const FormControlValidIcon = styled.i<Props>`
  position: absolute;
  top: 11px;
  right: 9px;
  display: ${({ showIcon }) => (showIcon ? 'block' : 'none')}!important;
  color: ${(props) => props.theme.formControlValidIcon.colour};
  font-size: 16px;
  border: 4px solid
    ${(props) => props.theme.formControlValidIcon.backgroundColour};
  background-color: ${(props) =>
    props.theme.formControlValidIcon.backgroundColour};
  border-radius: 50%;
`
