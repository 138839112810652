import React from 'react'
import Moment from 'moment'

import DatePicker from '../../components/@common/DatePicker'
import { ErrorMessage } from '../common/ErrorMessage'
import { FormControlWrapper } from '../FormControlWrapper'
import { FormControlValidIcon } from '../FormControlValidIcon'

class PassportExpiryDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      validMinimumDate: null,
      validMaximumDate: null,
    }

    this.handleValidDates = this.handleValidDates.bind(this)
    this.updateExpiryDate = this.updateExpiryDate.bind(this)
    this.updateError = this.updateError.bind(this)
  }

  handleValidDates = () => {
    const now = Moment()
    this.setState({
      validMinimumDate: now,
      validMaximumDate: now.clone().add(15, 'years').format('YYYY-MM-DD'),
    })
  }

  updateExpiryDate = (value) => {
    this.props.handleDateSet('PassportExpiryDate', value)
  }

  updateError = (value) => {
    this.props.setDateError('PassportExpiryDate', value)
  }

  render() {
    return (
      <>
        <FormControlWrapper>
          <FormControlValidIcon
            error={this.props.error}
            className='fas fa-check'
          ></FormControlValidIcon>
          <DatePicker
            name='PassportExpiryDate'
            setValue={this.updateExpiryDate}
            value={this.props.value}
            setError={this.updateError}
          />
        </FormControlWrapper>
        <ErrorMessage>{this.props.error}</ErrorMessage>
      </>
    )
  }
}

export default PassportExpiryDate
