import {
  ANNUAL_CALLBACK_PROVIDER,
  CALLBACK_SOURCE,
  REQUEST_CALLBACK_STATUS,
} from '../../constants'
import {
  RTPBanner,
  BannerButton,
  BannerH2Secondary,
  BannerTextTertiary,
  ButtonTextPrimary,
  ConfusedLogo,
  PricingContainer,
  PriceContainer,
  ImageContainer,
  Image,
  PriceResult,
  PriceText,
  InsurerText,
  AnnualPremium,
  InsurerName,
  BannerTextPrimary,
  BannerTitle,
  MobileWrap,
  InsurerWrapper,
  PriceWrapper,
} from '../../styles'
import React, { useMemo } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
const generateWording = (difference, string) => {
  if (difference > 1) {
    return (string += 's')
  }
  return string
}
const ConfusedRTPBanner = ({
  callbackRequest,
  requestCallback,
  pricing,
  registrationNumber,
}) => {
  return (
    <RTPBanner id='ConfusedUpsellRTPBanner'>
      <BannerTitle>Annual car insurance</BannerTitle>
      <BannerH2Secondary>
        Cheapest 3 annual car insurance prices for {registrationNumber} from{' '}
        <ConfusedLogo alt='Confused.com' />
      </BannerH2Secondary>
      <PricingContainer>
        {pricing.Results.map((p) => (
          <PriceContainer key={p.BrandId}>
            <ImageContainer>
              <div>
                <Image
                  src={`https://partnerimages.confused.com/${p.BrandId}`}
                  alt={p.BrandName}
                />
              </div>
            </ImageContainer>
            <PriceResult>
              <InsurerWrapper>
                <InsurerText>Insurer: </InsurerText>
                <InsurerName> {p.BrandName}</InsurerName>
              </InsurerWrapper>
              <PriceWrapper>
                <AnnualPremium>£{p.AnnualPremium}</AnnualPremium>
                <PriceText>Per annum</PriceText>
              </PriceWrapper>
            </PriceResult>
          </PriceContainer>
        ))}
      </PricingContainer>
      <BannerTextTertiary>
        We've created a personalised quote for you for the car you're insuring
        to make it easier for you to stay insured. It'll be saved to your
        Confused.com account so you can come back to it later.
      </BannerTextTertiary>
      <BannerButton
        id='ConfusedUpsellRTPBannerButton'
        onClick={() =>
          requestCallback({
            linkType: CALLBACK_SOURCE.Website,
            provider: ANNUAL_CALLBACK_PROVIDER.ConfusedRTP,
            urlReplacement: [{ key: 'ActivityId', value: pricing.ActivityID }],
          })
        }
        disabled={callbackRequest.status === REQUEST_CALLBACK_STATUS.REQUESTING}
      >
        <div>
          <ButtonTextPrimary>See all prices</ButtonTextPrimary>
        </div>
      </BannerButton>
    </RTPBanner>
  )
}
export default ConfusedRTPBanner
