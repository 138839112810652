/* eslint-disable max-len */
import useCustomerAccountFeatureFlag from 'hooks/useCustomerAccountFeatureFlag'
import {
  BreakdownCover,
  ExcessReduction,
  InitialStateType,
  LegalExpensesCover,
  FreeGAPInsuranceCover,
} from 'initialState'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

type AddonSelection = {
  // name of property to be sent in requests
  type:
    | 'BreakdownIncluded'
    | 'ExcessReductionIncluded'
    | 'LegalExpensesIncluded'
    | 'GAPInsuranceIncluded'
  selected: boolean
  wording: string
}

export const getAddonSelection = (
  state: InitialStateType,
): AddonSelection[] => [
  {
    type: 'BreakdownIncluded',
    selected: state.price.BreakdownCover.BreakdownIncluded,
    wording: 'RAC UK Breakdown Cover',
  },
  {
    type: 'ExcessReductionIncluded',
    selected: state.price.ExcessReduction.ExcessReductionIncluded,
    wording: 'Excess Reduction',
  },
  {
    type: 'LegalExpensesIncluded',
    selected: state.price.LegalExpensesCover.LegalExpensesIncluded,
    wording: 'Legal Expenses',
  },
  {
    type: 'GAPInsuranceIncluded',
    selected: state.price.FreeGAPInsuranceCover.Free30DayGAPInsuranceIncluded,
    wording: 'Free 30 Day GAP Insurance',
  },
]

const getExcessReduction = (
  reducedExcessStoreData: ExcessReduction | undefined,
  compulsoryExcess: number,
) => {
  const handleReducedExcess = (
    compExcess: number,
    reductionExcess: number,
  ): string => {
    const reducedExcess = compExcess - reductionExcess
    const hasDecimalPlaces = reducedExcess - Math.floor(reducedExcess) !== 0

    if (hasDecimalPlaces) {
      return reducedExcess.toFixed(2)
    }

    return reducedExcess.toString()
  }

  const hasExcessAddonPremium =
    !!reducedExcessStoreData?.ExcessReductionTotalPremium
  const coverLevel = reducedExcessStoreData?.ExcessReductionCoverLevel ?? 0
  const isExcessEnabled = !!reducedExcessStoreData?.ExcessReductionIncluded
  const excessAmount = hasExcessAddonPremium
    ? handleReducedExcess(compulsoryExcess, coverLevel)
    : compulsoryExcess.toFixed(2)

  return {
    type: 'excess',
    heading: 'Add Excess Reduction Cover?',
    hasTotalPremium: hasExcessAddonPremium,
    selected: isExcessEnabled,
    ipt: reducedExcessStoreData?.ExcessReductionIPT?.toFixed(2) ?? 0,
    premium:
      reducedExcessStoreData?.ExcessReductionPremiumGross?.toFixed(2) ?? 0,
    totalPrice:
      reducedExcessStoreData?.ExcessReductionTotalPremium?.toFixed(2) ?? 0,
    excessAmount,
    coverLevel,
    staticDocuments: {
      policyWording: 'CoplusMotorExcessReductionPolicyWording',
      ipid: 'CoplusMotorExcessReductionInsuranceProductInformationDocument',
    },
    hasError: reducedExcessStoreData && reducedExcessStoreData.HasError,
    price: `£${
      reducedExcessStoreData?.ExcessReductionTotalPremium?.toFixed(2) ?? 0
    }`,
  }
}

const getBreakdownCover = (breakdownStoreData: BreakdownCover | undefined) => {
  const hasBreakdownPremium = !!breakdownStoreData?.BreakdownTotalPremium
  const isBreakdownEnabled = !!breakdownStoreData?.BreakdownIncluded

  return {
    type: 'breakdown',
    heading: 'Add RAC UK Breakdown Cover?',
    hasTotalPremium: hasBreakdownPremium,
    selected: isBreakdownEnabled,
    ipt: breakdownStoreData?.BreakdownIPT?.toFixed(2) ?? 0,
    premium: breakdownStoreData?.BreakdownPremiumGross?.toFixed(2) ?? 0,
    totalPrice: breakdownStoreData?.BreakdownTotalPremium?.toFixed(2) ?? 0,
    staticDocuments: {
      policyWording: 'BreakdownPolicyWording',
      ipid: 'BreakdownInsuranceProductInformationDocument',
    },
    hasError: breakdownStoreData && breakdownStoreData.HasError,
    price: `£${breakdownStoreData?.BreakdownTotalPremium?.toFixed(2) ?? 0}`,
  }
}

const getLegalExpensesCover = (
  legalexpensesStoreData: LegalExpensesCover | undefined,
) => {
  const hasLegalExpensesPremium = !!legalexpensesStoreData?.LegalExpensesPremium
  const isLegalExpensesEnabled = !!legalexpensesStoreData?.LegalExpensesIncluded

  return {
    type: 'legalexpenses',
    heading: 'Add Legal Expenses Cover?',
    hasTotalPremium: hasLegalExpensesPremium,
    selected: isLegalExpensesEnabled,
    ipt: legalexpensesStoreData?.LegalExpensesIPT?.toFixed(2) ?? 0,
    premium: legalexpensesStoreData?.LegalExpensesPremiumGross?.toFixed(2) ?? 0,
    totalPrice: legalexpensesStoreData?.LegalExpensesPremium?.toFixed(2) ?? 0,
    staticDocuments: {
      policyWording: 'LegalExpensesInsurancePolicyWording',
      ipid: 'LegalExpensesInsuranceProductInformationDocument',
    },
    hasError: legalexpensesStoreData && legalexpensesStoreData.HasError,
    price: `£${
      legalexpensesStoreData?.LegalExpensesPremium?.toFixed(2) ?? 0.0
    }`,
  }
}
const getThirtyDayGAPInsuranceCover = (
  thirtyDayGAPInsuranceStoreData: FreeGAPInsuranceCover | undefined,
  enabledOveride: boolean,
) => {
  let isGAPAvailable =
    !!thirtyDayGAPInsuranceStoreData?.FreeGAPInsuranceAvailable
  let isGAPEnabled =
    !!thirtyDayGAPInsuranceStoreData?.Free30DayGAPInsuranceIncluded

  if (!enabledOveride) {
    isGAPAvailable = false
    isGAPEnabled = false
  }

  return {
    type: 'gapinsurance',
    heading: 'Add Free 30 day GAP Insurance Cover?',
    selected: isGAPEnabled,
    totalPrice: '',
    hasTotalPremium: isGAPAvailable,
    staticDocuments: {
      policyWording: 'Free30DayGAPPolicyWording',
      ipid: 'Free30DayGAPIPID',
    },
    hasError:
      thirtyDayGAPInsuranceStoreData && thirtyDayGAPInsuranceStoreData.HasError,
    price: '',
  }
}

const useAddons = () => {
  const [availableAddons, setAvailableAddons] = useState<any[]>([])
  const [freeGapEnabledOverride, setFreeGapEnabledOverride] = useState(false)

  const { data: featureFlagEnabled, isLoading: featureFlagLoading } =
    useCustomerAccountFeatureFlag('FreeGAPActivationJourney')

  useEffect(() => {
    if (!featureFlagLoading && featureFlagEnabled && !featureFlagEnabled.data) {
      setFreeGapEnabledOverride(true)
    }
  }, [featureFlagEnabled, featureFlagLoading, freeGapEnabledOverride])

  const breakdownStoreData = useSelector(
    (state: InitialStateType) => state.price.BreakdownCover,
  )
  const breakdown = useMemo(
    () => getBreakdownCover(breakdownStoreData),
    [breakdownStoreData],
  )

  const compulsoryExcess = useSelector(
    (state: InitialStateType) => state.price.CompulsoryExcess,
  )
  const reducedExcessStoreData = useSelector(
    (state: InitialStateType) => state.price.ExcessReduction,
  )
  const excess = useMemo(
    () => getExcessReduction(reducedExcessStoreData, compulsoryExcess ?? 0),
    [compulsoryExcess, reducedExcessStoreData],
  )

  const legalexpensesStoreData = useSelector(
    (state: InitialStateType) => state.price.LegalExpensesCover,
  )
  const legalexpenses = useMemo(
    () => getLegalExpensesCover(legalexpensesStoreData),
    [legalexpensesStoreData],
  )

  const thirtyDayGAPInsuranceStoreData = useSelector(
    (state: InitialStateType) => state.price.FreeGAPInsuranceCover,
  )

  const gapinsurance = useMemo(
    () =>
      getThirtyDayGAPInsuranceCover(
        thirtyDayGAPInsuranceStoreData,
        freeGapEnabledOverride,
      ),
    [freeGapEnabledOverride, thirtyDayGAPInsuranceStoreData],
  )

  const selectedAddons = useSelector(getAddonSelection)

  useEffect(() => {
    setAvailableAddons(
      [gapinsurance, breakdown, legalexpenses, excess].filter(
        (i) => i.hasTotalPremium,
      ),
    )
  }, [gapinsurance, breakdown, excess, legalexpenses])

  return {
    availableAddons,
    selectedAddons,
    excess,
    breakdown,
    legalexpenses,
    gapinsurance,
  }
}

export default useAddons
