import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  PaymentMethodCreateParams,
  StripePaymentElementOptions,
} from '@stripe/stripe-js'
import {
  PaymentIntentFinalResponse,
  PaymentIntentPriceCheckResponse,
  Price,
  confirmPaymentIntent,
  createPaymentIntent,
} from 'api/paymentservice/paymentservice.api'
import { getPolicySummary } from 'api/policy/policy.api'
import Button from 'components/@common/Button'
import {
  requestPriceSuccess,
  setUnderwriterChanged,
} from 'containers/App/actions'
import {
  setPaymentInProgress,
  setPaymentNotInProgress,
} from 'containers/PaymentPage/actions'
import { usePrice, useQueryString, useRiskData } from 'hooks'
import { history } from 'index'
import { InitialStateType } from 'initialState'
import React, { FormEvent, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QuoteJourneyPosition } from 'types/global'

interface PaymentFormProps {
  amount: number
  onError?: (errorMessage: string) => void
}

const PaymentForm = ({ amount, onError = () => {} }: PaymentFormProps) => {
  const {
    encryptedString,
    price: {
      BreakdownCover,
      ExcessReduction,
      LegalExpensesCover,
      FreeGAPInsuranceCover,
      UnderwriterId,
      IsAddonUpdating,
    },
  } = usePrice()

  const [loading, setLoading] = useState(false)
  const [isStripeReady, setIsStripeReady] = useState(false)
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false)

  const { queryString } = useQueryString()

  const dispatch = useDispatch()

  const { quoteId } = useRiskData()
  const { data, isSuccess } = useQuery(
    ['getPolicy', quoteId],
    () => getPolicySummary(quoteId),
    {
      enabled: isPaymentSuccess,
      refetchInterval: 500,
      retry: true,
    },
  )
  const { Postcode: postcode } = useSelector(
    (state: InitialStateType) => state.address,
  )

  const stripe = useStripe()
  const elements = useElements()

  const amountWithDecimal = useMemo(() => (amount / 100).toFixed(2), [amount])

  const stripePaymentOptions: StripePaymentElementOptions = {
    defaultValues: {
      billingDetails: {
        address: {
          postal_code: postcode,
          country: 'GB',
        },
      },
    },
    fields: {
      billingDetails: {
        address: {
          postalCode: 'never',
          country: 'never',
        },
      },
    },
  }

  const isButtonDisabled = useMemo(
    () => loading || !stripe || IsAddonUpdating,
    [loading, stripe, IsAddonUpdating],
  )

  const handleError = () => {
    setLoading(false)
    dispatch(setPaymentNotInProgress())
  }

  const handlePriceChange = (price: Price) => {
    dispatch(setPaymentNotInProgress())
    dispatch(requestPriceSuccess(price))
    dispatch(setUnderwriterChanged())
    history.push({
      pathname: '/quote/driving-licence',
      search: `${queryString}`,
    })
  }

  const handlePayment = async (event: FormEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    if (!elements) {
      // elements not loading so return
      return
    }
    onError('')
    setLoading(true)
    dispatch(setPaymentInProgress())

    // Price check? Or something at least or we can do this in the backend later on

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      handleError()
      return
    }

    const params: PaymentMethodCreateParams = {
      billing_details: {
        address: {
          country: 'GB',
          postal_code: postcode,
        },
      },
    }

    // Create the PaymentMethod using the details collected by the Payment Element
    const { error: createPaymentMethodError, paymentMethod } =
      await stripe.createPaymentMethod({
        elements,

        // Use params only if postcode is not shown on the form
        params,
      })

    if (!paymentMethod) {
      handleError()
      return
    }

    if (createPaymentMethodError) {
      handleError()
      return
    }

    if (!encryptedString) {
      handleError()
      return
    }

    // Create payment intent and call our backend
    // Use react query to do a mutation?
    const paymentIntentRequest = {
      amount,
      breakdownIncluded: BreakdownCover.BreakdownIncluded,
      excessReductionIncluded: ExcessReduction.ExcessReductionIncluded,
      free30DayGAPInsuranceIncluded:
        FreeGAPInsuranceCover.Free30DayGAPInsuranceIncluded,
      legalExpensesIncluded: LegalExpensesCover.LegalExpensesIncluded,
      paymentMethodId: paymentMethod.id,
      quoteId,
      validationData: encryptedString,
      allowPriceComparison: true,
      quoteJourneyPosition: QuoteJourneyPosition.SystemPriceRefresh,
      underwriterId: UnderwriterId!,
    }

    try {
      const createdPaymentIntentResponse = await createPaymentIntent(
        paymentIntentRequest,
      )

      const createdPaymentCheckResponse =
        createdPaymentIntentResponse as PaymentIntentPriceCheckResponse

      if (createdPaymentCheckResponse.StatusMessage === 'PRICE_CHANGE') {
        handlePriceChange(createdPaymentCheckResponse.Price)
        return
      }

      const finalPaymentIntentResponse =
        createdPaymentIntentResponse as PaymentIntentFinalResponse
      // 3DS check required
      if (finalPaymentIntentResponse.status === 'requires_action') {
        // TODO: Double check timer on this. Might need to replace with handleNextAction
        const { error: handleCardActionError, paymentIntent } =
          await stripe.handleCardAction(finalPaymentIntentResponse.clientSecret)

        if (handleCardActionError) {
          handleError()
          return
        }
        const paymentIntentId = paymentIntent?.id

        const confirmPaymentIntentResponse = await confirmPaymentIntent(
          paymentIntentId!,
          paymentIntentRequest,
        )

        const confirmPaymentPriceResponse =
          confirmPaymentIntentResponse as PaymentIntentPriceCheckResponse

        if (confirmPaymentPriceResponse.StatusMessage === 'PRICE_CHANGE') {
          handlePriceChange(confirmPaymentPriceResponse.Price)
          return
        }
        const confirmPaymentFinalResponse =
          confirmPaymentIntentResponse as PaymentIntentFinalResponse
        if (confirmPaymentFinalResponse.statusMessage) {
          onError(confirmPaymentFinalResponse.statusMessage)
          handleError()
          return
        }
        setIsPaymentSuccess(true)
      }
      // 3DS not needed
      else {
        if (finalPaymentIntentResponse.statusMessage) {
          onError(finalPaymentIntentResponse.statusMessage)
          handleError()
          return
        }
        setIsPaymentSuccess(true)
      }
    } catch (e) {
      onError('There was an error')
      handleError()
    }
  }

  if (data && isSuccess) {
    history.push(`/policy/confirmation${queryString}&t=${Date.now()}`)
  }

  return (
    <form style={{ width: '100%' }} onSubmit={handlePayment}>
      <PaymentElement
        options={stripePaymentOptions}
        onReady={() => setIsStripeReady(true)}
      />
      {isStripeReady && (
        <Button
          id='StripePaymentButton'
          type='submit'
          style={{ width: '100%', marginTop: 8 }}
          disabled={isButtonDisabled}
          loading={isButtonDisabled}
        >
          Pay &pound;{amountWithDecimal}
        </Button>
      )}
    </form>
  )
}

export default PaymentForm
