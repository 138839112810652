import React from 'react'

import { StyledLinkButton } from './LinkButton.styles'

export interface Props {
  url?: string
  linkName?: string
  id: string
  className?: string
}

const LinkButton: React.FC<Props> = ({ url, linkName, id, className }) => (
  <StyledLinkButton id={id} href={url} className={className}>
    {linkName}
  </StyledLinkButton>
)

export default LinkButton
