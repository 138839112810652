import { P } from 'components/common/P'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  border: 1px solid #000;
`

export const TimeWrapper = styled(Wrapper)`
  background-color: #fff;
`

export const DescriptionWrapper = styled(Wrapper)`
  background-color: #f1f2f3;
`

export const TimerText = styled(P)`
  font-family: ${(props) => props.theme.fontFamily};
  color: ${({ theme }) => theme.primaryColour};
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  & > div {
    font-size: 1em;
    margin: 0 5px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const FixedWidthTime = styled.span`
  display: inline-block;
  width: 3em;
  text-align: left;
`
