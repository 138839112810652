import styled from 'styled-components'
import CtmBackground from '../../../images/CtmUpsell/CtmBackground.png'
import CtmBackgroundMobile from '../../../images/CtmUpsell/CTMMobileBackground.png'
import CtmLogo from '../../../images/CtmUpsell/CtmLogo.png'
import CtmMascot from '../../../images/CtmUpsell/CtmMascot.png'
import { Button } from '../../../components/common/Button'

export const TextDiv = styled.div`
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;

  @media only screen and (max-width: 740px) {
    font-size: 18px;
  }
`

export const InlineDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;

  @media only screen and (max-width: 740px) {
    flex-direction: column;
  }
`

export const AllTextDiv = styled.div`
  flex-direction: column;
  display: flex;
  padding: 10px 0 0 10px;
  justify-content: space-between;
`

export const Span1 = styled.span`
  font-size: 16px;

  @media only screen and (max-width: 740px) {
    font-size: 16px;
  }
`

export const Bold = styled.span`
  font-weight: 700;
`

export const Span3 = styled.span``

export const CtmButton = styled.button`
  width: 126px;
  height: 35px;
  background-color: #0ec228;
  border-radius: 8px;
  border-color: #0ec228;
  font-weight: 300;
  color: #ffffff;
  font-size: 14px;
  font-style: 'bold';
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  :hover  {
    cursor: pointer;
    background-color: #35a930;
  }
`

export const OuterDiv = styled.div`
  font-family: 'Verdana';
  display: grid;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  background-image: url(${CtmBackground});
  width: 100%;
  grid-template-columns: auto 122px;
  box-sizing: border-box;

  @media only screen and (max-width: 740px) {
    background-image: url(${CtmBackgroundMobile});
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    grid-template-rows: auto auto;
    grid-template-columns: 60% 40%;
  }
`
export const UpsellLogo = styled.div`
  width: 114px;
  height: 30px;
  position: relative;
  background-image: url(${CtmLogo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 10px;

  @media only screen and (max-width: 740px) {
    margin-top: 10px;
  }
`
export const TermsAndConditionsText = styled.p`
  font-weight: 400;
  color: #ffffff;
  font-size: 10px;
  grid-row-start: 2;
  padding-left: 10px;

  a: visited {
    text-decoration: none;
    color: #ffffff;
  }

  @media only screen and (max-width: 740px) {
    font-size: 8px;
    position: relative;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    padding-right: 10px;
  }
`

export const SergeiDiv = styled.div`
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 2;
  background-image: url(${CtmMascot});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;

  @media only screen and (max-width: 740px) {
    grid-row-start: 1;
    grid-row-end: 2;
  }
`
export const TsAndCs = styled.a`
  color: #ffffff;
  text-decoration: none;
  :hover  {
    text-decoration: underline;
    color: #ffffff;
  }
  :visited  {
    text-decoration: none;
    color: #ffffff;
  }
  :active  {
    text-decoration: none;
    color: #ffffff;
  }
`

export const EmailConfirmationWrapper = styled.div`
  border: 1px solid #264694;
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  text-align: center;
  padding: 0 10px;
  margin: 10% 10px 0 10px;
  @media screen and (max-width: 768px) {
    margin: 5% 10px 0 10px;
  }
`
export const AnnualInsuranceText = styled.p`
  font-size: 22px;
`
export const RequestCallbackButton = styled(Button)`
  background-color: #219e3e;
  display: block;
  margin: 10px auto 0 auto;
  padding: 10px 40px;
  height: auto;
`
export const AnnualInsuranceConfirmationHeaderWrapper = styled.div`
  margin: 0 -10px;
`

export const AnnualInsuranceConfirmationHeader = styled.h2`
  color: #ffffff;
  font-size: 35px @media screen and (max-width: 768px) {
    color: #ffffff;
    margin: 0;
    padding: 15px 0;
  }
`
export const ConfirmationWrapper = styled.div`
  background-image: url(${CtmBackgroundMobile});
  border: 1px solid #264b94;
  border-radius: #264b94;
  text-align: center;
  padding: 0 10px;
  margin: 5% 10px 0 5%;

  @media screen and (max-width: 768px) {
    margin: 5% 10px 0 5%;
  }
`
export const AnnuaLInsuranceLogo = styled.img`
  width: 50%;
  max-width: 200px;
  display: block;
  margin: 0 auto;
  padding-bottom: 16px;
`

export const AnnuaLInsuranceSpinner = styled.i`
  color: #ffffff;
  font-size: 40px;
`
