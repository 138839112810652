import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import VanDetails from '.'
import QuoteSummary from '../QuoteSummary'
import { Message } from '../../components/Message'
import { SubmitVanInfo } from './styles'
import {
  updateRiskData,
  updateValidation,
  updateQuote,
  isButtonDisabled,
  enableAllButtons,
} from '../RiskDataCollectionPage/actions'
import { Grid } from 'unsemantic'
import GridContainerWrapper from 'components/@common/GridContainerWrapper'

class MoreInfoVan extends Component {
  handleContinueClick = () => {
    this.props.actions.isButtonDisabled({
      buttonName: 'vanInfo',
      disabled: true,
    })
    this.props.actions.updateQuote({
      partialValidation: true,
      getPrice: true,
      callback: () => {
        this.props.actions.enableAllButtons()
        this.props.history.push(
          `/quote/driving-licence${this.props.queryString}`,
        )
      },
    })
  }

  render() {
    return (
      <GridContainerWrapper>
        <QuoteSummary />
        <Message
          heading='We need more information about your van'
          content='Need wording from Graham'
          type='info'
        />
        <Grid desktop='100'>
          <VanDetails />
          {this.props.validation.ValidForm && (
            <SubmitVanInfo
              id='TeslaSubmitVanInformationNextButton'
              onClick={this.handleContinueClick}
              disabled={this.props.vanInfoButtonDisabled}
            >
              Continue
            </SubmitVanInfo>
          )}
        </Grid>
      </GridContainerWrapper>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    validation: state.validation.riskDataCollectionPage,
    quoteId: state.riskData.QuoteId,
    vanInfoButtonDisabled: state.formButtons.vanInfo.disabled,
    queryString: state.queryString,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateValidation,
        updateRiskData,
        updateQuote,
        isButtonDisabled,
        enableAllButtons,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreInfoVan)
