import { InitialStateType } from 'initialState'
import { useSelector } from 'react-redux'

const useAccountExists = () => {
  const accountExists = useSelector(
    (state: InitialStateType) => state.quote.accountExists,
  )

  return {
    accountExists,
  }
}

export default useAccountExists
