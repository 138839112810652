import styled from 'styled-components'

export const StyledLinkButton = styled.a`
  background-color: white;
  padding: 8px 13px;
  border: 1px solid #707070;
  border-radius: 4px;
  text-decoration: none;
  color: ${(props) => props.theme.primaryColour};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;

  &.get-a-quote {
    background-color: #779e00;
    color: white;
  }

  @media (max-width: 375px) {
    font-size: 12px;
  }
`
