import styled from 'styled-components'

export const A = styled.a`
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.primaryTextColour};
  cursor: pointer;
  text-decoration: none;
  font-size: ${(props) => props.theme.linkFontSize};
  border-bottom: 1px solid ${(props) => props.theme.primaryTextColour};
`

export const URL = styled.a`
  color: #264b94 !important;
  text-decoration: none;
  font-size: ${(props) => props.theme.linkFontSize};

  &:hover {
    text-decoration: underline;
  }
`
export const WhiteLabelledLink = styled(A)`
  color: ${(props) => props.theme.linkColour};
  &:link {
    color: ${(props) => props.theme.linkColour};
  }
  border-bottom: 1px solid ${(props) => props.theme.linkUnderline};
`
