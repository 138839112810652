type ActionTypes = 'SET_EXTERNAL_DATA'

type PayloadType = {
  [key: string]: string
}

export interface ExternalAction {
  type: ActionTypes
  payload?: PayloadType
}

export const setExternalData: (externalData: PayloadType) => {
  type: ActionTypes
  payload: PayloadType
} = (externalData) => ({
  type: 'SET_EXTERNAL_DATA',
  payload: externalData,
})
