import React from 'react'

export const Expat = ({ width, height }) => {
  const opts = {}

  if (width) {
    opts.width = width
  }

  if (height) {
    opts.height = height
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 134.998 134.998'
      {...opts}
    >
      <g
        id='Group_341'
        data-name='Group 341'
        transform='translate(2253.206 8784.797)'
      >
        <path
          id='Path_297'
          data-name='Path 297'
          d='M1774.033,1052.561a40.533,40.533,0,0,1,2.584,4.79c.519,1.453,4.565,4.98,4.565,4.98l.83-4.442c.415-2.989,4.15-.538,2.7-2.757s-5.81-2.139-5.81-2.139,3.736-3.943,5.81-4.773,0,2.075,2.7,3.052,3.735-6.995,4.98-8.153,11.1-8.18,11.1-8.18l-.931-7.381a29.636,29.636,0,0,1-4.358,5.513c-1.971,1.66-10.272,3.424-11,6.848s-.208,5.026-.208,5.026-3.424-1.3-5.6,0S1777.571,1051.4,1774.033,1052.561Z'
          transform='translate(-4003.116 -9777.944)'
          fill='#ccdcff'
        ></path>
        <path
          id='Path_298'
          data-name='Path 298'
          d='M1846.3,1192.755c1.66-10.64,5.188-9.074,8.508-17.959s-3.113-11.866-9.753-13.526c0,0,5.911,4.047,5.911,9.961s-8.393,7.248-7.982,19.81Z'
          transform='translate(-4050.594 -9870.035)'
          fill='#ccdcff'
        ></path>
        <path
          id='Path_299'
          data-name='Path 299'
          d='M1928.061,1152.071c-.508-.415-5.3-.623-4.472-7.263s-1.245-6.641-3.735-10.168,1.868-13.073-1.868-12.715-14.318,1.717-14.318-9.565a9.03,9.03,0,0,0,10.272,4.953c7.574-1.452,11.932-.492,12.243,5.875s-1.981,4.768.681,12.408C1928.88,1141.384,1928.061,1152.071,1928.061,1152.071Z'
          transform='translate(-4092.399 -9836.349)'
          fill='#ccdcff'
        ></path>
        <path
          id='Path_300'
          data-name='Path 300'
          d='M2018.013,1090.359c-2.582,5.541-6.549,2.344-8,1.439s-7.678-9.885-7.678-9.262a17.091,17.091,0,0,1,6.433,1.453C2012.6,1085.544,2013.149,1089.674,2018.013,1090.359Z'
          transform='translate(-4160.354 -9815.787)'
          fill='#ccdcff'
        ></path>
        <path
          id='Path_355'
          data-name='Path 355'
          d='M1764.036,1052.008a67.5,67.5,0,1,1,47.729-19.77A67.058,67.058,0,0,1,1764.036,1052.008Zm0-132.352a64.853,64.853,0,1,0,64.853,64.853A64.926,64.926,0,0,0,1764.036,919.656Z'
          transform='translate(-3949.743 -9701.807)'
          fill='#264694'
        ></path>
        <path
          id='Path_358'
          data-name='Path 358'
          d='M1812.843,1078.756a45.44,45.44,0,1,1,45.44-45.44A45.492,45.492,0,0,1,1812.843,1078.756Zm0-88.234a42.794,42.794,0,1,0,42.794,42.794A42.843,42.843,0,0,0,1812.843,990.522Z'
          transform='translate(-3998.551 -9750.614)'
          fill='#264694'
        ></path>
        <path
          id='Path_359'
          data-name='Path 359'
          d='M1778.255,1052.189a1.322,1.322,0,0,1-.869-.326c-1.011-.881-4.353-3.884-4.942-5.533a37.725,37.725,0,0,0-2.463-4.539,1.323,1.323,0,1,1,2.25-1.392,39.521,39.521,0,0,1,2.7,5.042,13.569,13.569,0,0,0,2.458,2.833l.386-2.064a2.905,2.905,0,0,1,2.03-2.556,8.922,8.922,0,0,0-3.812-.8h-.024a1.323,1.323,0,0,1-.96-2.233c.4-.426,4-4.18,6.279-5.092a2.259,2.259,0,0,1,2,.007,2.18,2.18,0,0,1,.909,1.835c.074.729.126.973.714,1.189.667-.34,1.588-3.109,1.985-4.3.623-1.875,1.008-2.966,1.656-3.57,1.125-1.047,8.256-6.162,10.589-7.829l-.832-6.6a1.323,1.323,0,0,1,1.89-1.356l5.062,2.452a27.193,27.193,0,0,1,4.963-2.42,46.94,46.94,0,0,1-5.38-1.193c-2.456-.737-5.9.878-8.664,2.176a26.849,26.849,0,0,1-3.447,1.443,17.53,17.53,0,0,1-7.736.019,1.323,1.323,0,0,1,.6-2.577h0a15.04,15.04,0,0,0,6.388.02,25.432,25.432,0,0,0,3.073-1.3c3.174-1.489,7.125-3.343,10.548-2.315a70.029,70.029,0,0,0,10.336,1.844,1.323,1.323,0,0,1,.264,2.578c-3.17,1-8.645,3.021-9.837,4.213a1.322,1.322,0,0,1-1.512.255l-3.616-1.752.626,4.961a1.323,1.323,0,0,1-.545,1.243c-3.909,2.786-10.022,7.21-10.949,8.056a14.2,14.2,0,0,0-.965,2.483c-.824,2.479-1.676,5.041-3.37,5.862a2.537,2.537,0,0,1-2.013.1,3.455,3.455,0,0,1-2.367-2.747,20.912,20.912,0,0,0-2.778,2.223,6.369,6.369,0,0,1,4,2.417,2.2,2.2,0,0,1,.375,2.206,2.017,2.017,0,0,1-1.92.924c-.88.035-.886.073-.95.534,0,.021-.006.041-.01.061l-.83,4.442a1.323,1.323,0,0,1-1.3,1.08Z'
          transform='translate(-4000.19 -9766.479)'
          fill='#264694'
        ></path>
        <path
          id='Path_360'
          data-name='Path 360'
          d='M1809.337,1179.5a1.345,1.345,0,0,1-.205-.016,1.322,1.322,0,0,1-1.1-1.511c1.01-6.476,2.753-8.762,4.6-11.183a24.133,24.133,0,0,0,3.977-7.035c1.034-2.767,1.092-4.957.178-6.7-1.52-2.889-5.614-4.234-9.012-5.084-3.872-.968-5.226-2.2-6.315-3.192a6.163,6.163,0,0,0-3.41-1.786c-3.286-.743-3.836-.067-5.63,2.917-1.324,2.2.158,6.052,4.407,11.442l.276.352a11.478,11.478,0,0,0,1.822,1.583c1.917,1.474,4.542,3.494,4.906,6.76a43.311,43.311,0,0,1,0,8.317,1.323,1.323,0,0,1-2.63-.283,41.309,41.309,0,0,0,0-7.74c-.239-2.148-2.179-3.64-3.89-4.957a12.854,12.854,0,0,1-2.3-2.056l-.266-.338c-2.061-2.614-7.534-9.558-4.6-14.443,1.993-3.314,3.558-5.247,8.481-4.134a8.7,8.7,0,0,1,4.607,2.41c.959.873,1.95,1.775,5.175,2.581,3.933.983,8.705,2.6,10.713,6.42,1.268,2.41,1.254,5.388-.041,8.853a26.7,26.7,0,0,1-4.35,7.713c-1.778,2.332-3.183,4.174-4.09,9.987A1.323,1.323,0,0,1,1809.337,1179.5Z'
          transform='translate(-4013.631 -9855.452)'
          fill='#264694'
        ></path>
        <path
          id='Path_361'
          data-name='Path 361'
          d='M1925.184,1079.874a1.4,1.4,0,0,1-.74-.208c-.067-.025-.151-.049-.248-.08-1.4-.448-5.68-1.812-4.847-8.476.559-4.48-.213-5.351-1.752-7.085a23.62,23.62,0,0,1-1.751-2.156c-1.459-2.068-1.087-5.332-.759-8.213a13.784,13.784,0,0,0,.131-3.95l-.033,0-.316.031c-2.792.275-8.6.847-12.3-2.5-2.093-1.9-3.155-4.728-3.155-8.412,0-7.264,4-9.919,6.655-11.676,2.078-1.378,2.576-1.846,2.307-2.938-.414-1.676-.8-2.857-1.1-3.806-.617-1.911-1.026-3.174.125-4.555s3.33-.765,5.02.04a12.942,12.942,0,0,1,1.288-2.972c1.078-1.849,3.087-2.376,4.7-2.8.748-.2,1.773-.465,1.959-.763a13.216,13.216,0,0,1,4.11-3.591,1.323,1.323,0,0,1,1.309,2.3,10.743,10.743,0,0,0-3.176,2.694c-.742,1.189-2.161,1.561-3.532,1.92-1.26.331-2.562.672-3.088,1.573a8.54,8.54,0,0,0-1.272,3.7,1.329,1.329,0,0,1-2.03,1.106,9.171,9.171,0,0,0-3.325-1.395h-.016c-.08.177-.016.5.445,1.925.3.937.717,2.22,1.153,3.986.745,3.019-1.464,4.484-3.413,5.776-2.565,1.7-5.471,3.628-5.471,9.471,0,2.906.77,5.077,2.286,6.451,2.826,2.562,7.848,2.067,10.261,1.829l.322-.032a2.53,2.53,0,0,1,2.143.711c1.2,1.225.955,3.408.64,6.171-.261,2.291-.587,5.143.292,6.386a21.1,21.1,0,0,0,1.569,1.927c1.926,2.17,3.073,3.775,2.4,9.169-.56,4.483,1.685,5.2,3.026,5.627h0c1.589-1.541,6.289-7.759,7.254-11.135a20.684,20.684,0,0,0,.386-5.913c-.075-2.854-.151-5.806,1.2-8.1,1.552-2.632,3.1-5.283,3.786-6.451-7.5-14.986-7.5-15.538-7.5-15.9a1.35,1.35,0,0,1,.914-1.284c.972-.328,1.539.386,2.325,1.373.486.611,1.135,1.463,1.821,2.366a56.735,56.735,0,0,0,4.647,5.688c.112.069.244.157.39.252.886.582,2.368,1.554,3.583,1.211a3.9,3.9,0,0,0,2.124-2.342,1.323,1.323,0,0,1,2.4,1.117c-.959,2.057-2.237,3.325-3.8,3.77-2.379.676-4.578-.769-5.759-1.545-.127-.083-.241-.159-.343-.222a5.779,5.779,0,0,1-1.071-.967c1.033,2.095,2.08,4.2,2.954,5.944a1.323,1.323,0,0,1-.041,1.26s-2.077,3.555-4.153,7.078c-.966,1.638-.9,4.09-.834,6.686a22.563,22.563,0,0,1-.486,6.708c-1.143,4-7.393,12.592-9.17,13.148A1.5,1.5,0,0,1,1925.184,1079.874Zm-.887-.312h0Zm-9-29.854h0Z'
          transform='translate(-4089.473 -9762.815)'
          fill='#264694'
        ></path>
        <path
          id='Path_362'
          data-name='Path 362'
          d='M1992.117,1053.272c-2.738,0-6.551-1.655-7-1.855a1.325,1.325,0,0,1-.611-.55c-.126-.219-1.2-2.19-.324-3.82a3.392,3.392,0,0,1,3.054-1.621,78.5,78.5,0,0,0,9.489-1.594,1.323,1.323,0,0,1,.592,2.579,79.414,79.414,0,0,1-9.926,1.657c-.646.038-.86.2-.882.238a1.5,1.5,0,0,0,.126.883c1.392.571,4.191,1.558,5.755,1.428,1.545-.129,4.628-2,6.208-3.15a1.323,1.323,0,0,1,1.558,2.139c-.48.349-4.767,3.416-7.545,3.647C1992.451,1053.266,1992.286,1053.272,1992.117,1053.272Z'
          transform='translate(-4147.616 -9789.129)'
          fill='#264694'
        ></path>
      </g>
    </svg>
  )
}
