import { SchemeEnum } from '@enums'
import initialState from '../../../initialState'
import {
  COMMERCIAL_VEHICLE,
  IMPOUNDED_PRIVATE_CAR_SCHEME,
  MOTORCYCLE,
  MOTORCYCLE_VEHICLE_SCHEME,
  PRIVATE_CAR,
  PRIVATE_CAR_SCHEME,
  SET_VEHICLE,
  VEHICLE_LEARNER_SCHEME,
} from '../../Vehicle/constants'
import {
  GET_QUOTE_SUMMARY_SUCCESS,
  REQUEST_OPEN_SCHEMES_SUCCESS,
  SET_SCHEME,
} from '../constants'

function schemeReducer(state = initialState.scheme, action) {
  switch (action.type) {
    case GET_QUOTE_SUMMARY_SUCCESS:
      return {
        ...state,
        selected: action.quoteSummary.Scheme,
      }
    case REQUEST_OPEN_SCHEMES_SUCCESS:
      return {
        ...state,
        open: action.openSchemes.Schemes,
      }
    case SET_VEHICLE:
      let scheme = ''
      switch (action.vehicle.VehicleType) {
        case PRIVATE_CAR:
          scheme = action.vehicle.Impounded
            ? IMPOUNDED_PRIVATE_CAR_SCHEME
            : PRIVATE_CAR_SCHEME
          break
        case COMMERCIAL_VEHICLE:
          if (action.vehicle.Impounded) {
            scheme = SchemeEnum.COMMERCIAL_VEHICLE_IMPOUNDED
            break
          }
          if (action.vehicle.Courier) {
            scheme = SchemeEnum.COURIER
            break
          } else {
            scheme = SchemeEnum.COMMERCIAL_VEHICLE
          }
          break
        case MOTORCYCLE:
          scheme = MOTORCYCLE_VEHICLE_SCHEME
          break
        default:
          scheme = PRIVATE_CAR_SCHEME
      }

      scheme = action.vehicle.IsLearnerVehicle ? VEHICLE_LEARNER_SCHEME : scheme

      return {
        ...state,
        selected: scheme,
      }
    case SET_SCHEME:
      return {
        ...state,
        selected: action.newScheme,
      }
    //Add Case for Licence Type for those learners. else change based on vehicle type/keep curent scheme
    default:
      return state
  }
}

export default schemeReducer
