import React, { useEffect, useState } from 'react'
import config from 'config'
import {
  MobileAppBannerMobile,
  MobileAppBannerMobileText,
  MobileAppBannerDesktop,
  MobileAppBannerMobileImage,
  MobileAppBannerMobileCTA,
  MobileAppBannerMobileContent,
  MobileAppBannerDesktopText,
  MobileAppBannerDesktopQRCode,
  MobileAppBannerDesktopQRCodeContainer,
  MobileAppBannerDesktopImage,
  MobileAppBannerDesktopQRCodeText,
  MobileAppBannerMobileCTALink,
} from './styles'

const MOBILE_APP_BANNER_VARIANT_A = '30426830290'
const MOBILE_APP_BANNER_VARIANT_B = '30426630185'

const MOBILE_APP_BANNER_EXPERIMENT = {
  id: 30444240444,
  variations: {
    [MOBILE_APP_BANNER_VARIANT_A]: {
      name: 'Variation A',
      text: 'Your policy documents are ready in the app',
      button: 'Get your documents',
      link: 'https://tempcover.onelink.me/4oOc/yg043438',
    },
    [MOBILE_APP_BANNER_VARIANT_B]: {
      name: 'Variation B',
      text: 'Instant access to your Insurance Certificate with the Tempcover app',
      button: 'Go to the app',
      link: 'https://tempcover.onelink.me/4oOc/1klg9kph',
    },
  },
}

const getExperimentBucket = (experimentId: number) => {
  const variationMap = window.optimizely?.get('state')?.getVariationMap()

  return variationMap?.[experimentId]
}

interface Variant {
  name: string
  text: string
  button: string
  link: string
}

export const MobileAppBanner = () => {
  const [variant, setVariant] = useState<Variant>()

  useEffect(() => {
    const setupExperiment = (fallback: boolean) => {
      // Default to the variation A if window.optimizely not available
      //
      const bucket = getExperimentBucket(MOBILE_APP_BANNER_EXPERIMENT.id) || {
        id: MOBILE_APP_BANNER_VARIANT_A, 
      }

      const variation = MOBILE_APP_BANNER_EXPERIMENT.variations[bucket.id]
      
      setVariant(variation)
      
      window.dataLayer.push({
        event: 'experiment.loaded',
        experimentId: MOBILE_APP_BANNER_EXPERIMENT.id,
        variantionId: bucket.id,
        variation,
        fallback,
      })
    }
    
    if (config.ENVIRONMENT_NAME !== 'Production') return setupExperiment(true)

    const timeout = setTimeout(() => setupExperiment(true), 2000)

    document.addEventListener('optimizely-tag-loaded', () =>
      setupExperiment(false),
    )

    return () => {
      clearTimeout(timeout)

      document.removeEventListener('optimizely-tag-loaded', () =>
        setupExperiment(false),
      )
    }
  }, [])

  if (!variant) return null

  return (
    <>
      <MobileAppBannerMobile>
        <MobileAppBannerMobileContent>
          <MobileAppBannerMobileText>{variant.text}</MobileAppBannerMobileText>
          <MobileAppBannerMobileImage />
        </MobileAppBannerMobileContent>
        <MobileAppBannerMobileCTA>
          <MobileAppBannerMobileCTALink
            href={variant.link}
            id='mobile-app-banner-mobile-cta'
          >
            {variant.button}
          </MobileAppBannerMobileCTALink>
        </MobileAppBannerMobileCTA>
      </MobileAppBannerMobile>

      <MobileAppBannerDesktop>
        <MobileAppBannerDesktopText>
          To access your policy documents go to the Tempcover app
        </MobileAppBannerDesktopText>
        <MobileAppBannerDesktopQRCodeContainer>
          <MobileAppBannerDesktopQRCode />
          <MobileAppBannerDesktopQRCodeText>
            Scan to go to the app
          </MobileAppBannerDesktopQRCodeText>
        </MobileAppBannerDesktopQRCodeContainer>
        <MobileAppBannerDesktopImage />
      </MobileAppBannerDesktop>
    </>
  )
}
