import { useSelector } from 'react-redux'
import { InitialStateType } from 'initialState'

const useLegalDeclaration = () => {
  const legalDeclaration = useSelector(
    (state: InitialStateType) => state.legalDeclaration,
  )
  return { legalDeclaration }
}

export default useLegalDeclaration
