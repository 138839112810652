import React from 'react'
import {
  VehicleRegistrationContainer,
  VehicleRegistrationContent,
  RegistrationNumber,
  GBIcon,
} from './styles.js'

interface Props {
  reg: string
}

export const VehicleRegistration = ({ reg }: Props) => (
  <VehicleRegistrationContainer>
    <VehicleRegistrationContent>
      <GBIcon>GB</GBIcon>
      <RegistrationNumber>{reg}</RegistrationNumber>
    </VehicleRegistrationContent>
  </VehicleRegistrationContainer>
)
