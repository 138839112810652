import { REQUEST_QUOTE } from '../constants'
import queryString from 'query-string'

import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  createQuoteLink,
  getRiskData,
  getVehicleInfo,
  getQuoteLink,
  getSelectedScheme,
  getCampaignData,
  getDurationData,
  getExternalData,
  getOpenSchemes,
} from '../selector'
import quoteApi from '../../../api/quoteApi'
import {
  createQuoteSuccess,
  createQuoteFailure,
  createQuote,
  requestQuoteSuccess,
  requestQuoteFailure,
  getQuoteSummarySuccess,
} from '../actions'

import { getAffiliate } from '../../PolicyConfirmationPage/selector'
import { updateRiskData } from '../../RiskDataCollectionPage/actions'
import { getQuoteSummaryLink } from '../selector'
import { isValidScheme } from '../../../services/handlers'
import config from '../../../config'
import LoggedIn from 'pages/DriverDetails/LoggedIn'
import { parseTwoDigitYear } from 'moment'

export function* createQuoteSaga() {
  try {
    yield put(createQuote())
    const quoteLink = yield select(createQuoteLink)
    const vehicleInfo = yield select(getVehicleInfo)
    const riskData = yield select(getRiskData)
    const durationData = yield select(getDurationData)
    const affiliate = yield select(getAffiliate)
    const parsedQueryString = queryString.parse(window.location.search)
    const marketingAffiliateId = parsedQueryString.affiliateId
    let partner = null
    const campaignData = yield select(getCampaignData)
    const { QuidcoClickId } = yield select(getExternalData)

    const utmPartners = ['carshop', 'caphpi']

    if (
      parsedQueryString.utm_campaign === 'driveaway' &&
      utmPartners.includes(parsedQueryString.utm_source)
    ) {
      partner = parsedQueryString.utm_source
    }

    const data = {
      VehicleAbi: vehicleInfo.selected.Abi,
      VehicleId: vehicleInfo.selected.VehicleId
        ? vehicleInfo.selected.VehicleId
        : 0,
      Duration: parseInt(durationData.value, 10),
      DurationType: durationData.type,
      CampaignRef: campaignData.Ref,
      Impounded: riskData.Impounded,
      Expat: riskData.Expat,
      Learner: riskData.Learner,
      Motorbike: riskData.Motorbike,
      ResidencyType: riskData.ResidencyType,
      UkResidencyDuration: riskData.UkResidencyDuration,
      QuidcoClickId: QuidcoClickId,
      NoRegCustomerAccount: riskData.NoRegCustomerAccount,
      CustomerId: riskData.customerId,
      EncryptedCustomerAccountId: riskData.CustomerId,
      MarketingAffiliateId: marketingAffiliateId,
      Partner: partner,
    }

    let quoteEndpoint = quoteLink.Href.replace(
      /{[affiliateRef}]*}/,
      affiliate.Ref || 'tempcover',
    )

    // check customer account logged in
    if (LoggedIn) {
    } else {
    }

    const response = yield call(quoteApi.createQuote, quoteEndpoint, data)
    const referenceNumber = response.data.ReferenceNumber
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'quoteData',
      quoteId: referenceNumber,
    })
    yield put(createQuoteSuccess(response.data))
  } catch (e) {
    yield put(createQuoteFailure(e))
  }
}

export function* fetchQuote() {
  try {
    const quoteLink = yield select(getQuoteLink)
    const response = yield call(quoteApi.getMotorQuote, quoteLink.Href)
    const scheme = yield select(getSelectedScheme)
    yield put(requestQuoteSuccess(response.data, scheme))
  } catch (e) {
    yield put(requestQuoteFailure(e))
  }
}

export function* fetchQuoteSummary(quoteId) {
  yield put(
    updateRiskData({
      QuoteId: quoteId,
    }),
  )

  let quoteSummaryLink = yield select(getQuoteSummaryLink)
  const validSchemes = yield select(getOpenSchemes)
  const quoteSummary = yield call(
    quoteApi.getQuoteSummary,
    quoteSummaryLink.Href.replace('{encryptedQuoteId}', quoteId),
  )
  const { data } = quoteSummary
  if (validSchemes.filter((s) => s.Scheme === data.Scheme).length > 0) {
    yield put(getQuoteSummarySuccess(data))
    // Push data layer
    window.dataLayer = window.dataLayer || []
    const referenceNumber = data.ReferenceNumber
    window.dataLayer.push({
      event: 'quoteData',
      quoteId: referenceNumber,
    })
  } else {
    window.location.href = config.SITE_URL
  }
}

export default function* fetchQuoteSaga() {
  yield takeLatest(REQUEST_QUOTE, fetchQuote)
}
