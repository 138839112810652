import styled from 'styled-components'

export const LabelContent = styled.span`
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  font-family: ${(props) => props.theme.fontFamily};
  padding: 5px;
  float: left;
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: 10px;
`
