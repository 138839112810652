import GridContainerWrapper from 'components/@common/GridContainerWrapper'
import Addons from 'components/Addons'
import { ButtonSpinner } from 'components/common/ButtonSpinner'
import { InitialStateType } from 'initialState'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { QUOTE_URL } from 'services/constants'
import { updateStep } from 'containers/App/actions'
import { AddonsHeader } from 'pages/Addons/AddonsPage.styles'
import ContinueButton from 'components/ContinueButton'

const AddonsPage = () => {
  // Ideally use react query for this at some point
  const history = useHistory()

  const queryString: string = useSelector(
    (state: InitialStateType) => state.queryString,
  )

  const isAddonLoading = useSelector(
    (state: InitialStateType) => state.price.IsAddonUpdating,
  )

  const handleContinueClick = () => {
    history.push(`${QUOTE_URL}/payment${queryString}`)
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(updateStep(3))
  })

  return (
    <>
      <AddonsHeader>Add-ons</AddonsHeader>
      <GridContainerWrapper>
        {/* Need to look at pulling this via react query eventually */}
        <Addons />

        <ContinueButton
          type='button'
          style={{ marginTop: 16 }}
          disabled={isAddonLoading}
          id='AddonsNextButton'
          fullWidth
          onClick={handleContinueClick}
        >
          {isAddonLoading ? 'Please wait' : 'Continue'}
          <ButtonSpinner disabled={isAddonLoading} />
        </ContinueButton>
      </GridContainerWrapper>
    </>
  )
}

export default AddonsPage
