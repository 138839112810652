export const VEHICLE_HAS_ANNUAL_INSURANCE = [
  {
    id: 2,
    description: 'Yes',
    value: 'Yes',
  },
  {
    id: 3,
    description: 'No',
    value: 'No',
  },
]
