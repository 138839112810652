import styled from 'styled-components'

interface AddonStyleProps {
  active?: boolean
  error?: boolean
  disabled?: boolean
}

export const GapActivationContatiner = styled.div<AddonStyleProps>`
display: flex;
padding: 15px 30px;
column-gap: 30px;
width: 100%;
max-width: 628px;
border: 1px solid ${(props) => props.theme.inputBorderColour};
border-radius: ${(props) => props.theme.formControlBorderRadius}
box-sizing: border-box;
background: #FFFFFF;

${({ active, theme }) =>
  active &&
  `
  background: theme.button.disabledBackground;
`}

@media (max-width: 768px) {
  padding: 10px 15px;
  column-gap: 15px;
}
`
export const GapTitle = styled.h3`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 14px;
  line-height: 1em;
  color: ${(props) => props.theme.priceComparison.headerColour};
  margin: 0;
  justify-self: start;
  padding: 0 0 15px;

  @media (min-width: 768px) {
    font-size: 22px;
  }
`

export const GapText = styled.p`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 14px;
  line-height: 1.2em;
  text-align: start;
  margin: 0;
  padding-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`

export const GapModalStyles = styled.div`
  text-align: left;
`
