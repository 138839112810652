import { useMutation } from 'react-query'
import customerAccountApi, {
  SaveNewOccupationRequest,
} from 'api/customerAccount'

export const useSaveNewOccupationToCustomer = () => {
  const saveNewOccupationToCustomerMutation = useMutation(
    ['customerAccountApi.saveNewOccupationToCustomer'],
    async (data: SaveNewOccupationRequest) =>
      customerAccountApi.saveNewOccupationToCustomer(data),
    {
      retry: false,
    },
  )
  return saveNewOccupationToCustomerMutation
}
