import config from '../../config'

export const REQUEST_CALLBACK = 'src/AnnualUpsell/REQUEST_CALLBACK'
export const REQUEST_CALLBACK_SUCCESS =
  'src/AnnualUpsell/REQUEST_CALLBACK_SUCCESS'
export const REQUEST_CALLBACK_FAILURE =
  'src/AnnualUpsell/REQUEST_CALLBACK_FAILURE'
export const REQUEST_CALLBACK_REL = 'RequestAnnualCallback'

export const ANNUAL_UPSELL_VEHICLE_TYPE = {
  CAR: 'https://partnerships-motor-gateway.comparethemarket.com/product/car/newquote?affclie=FE12&SRC=FE12&cmpid=PS-_-TCV-_-PC-_-ALL-_-y36Vu7fke8gaGfv',
  CAR_EMAIL:
    'https://partnerships-motor-gateway.comparethemarket.com/product/car/newquote?affclie=FE13&SRC=FE13&cmpid=PS-_-TCV-_-PC-_-ALL-_-hzUbgyjXwsayMTL',
  CAR_EMAIL_CANCELLATION:
    'https://partnerships-motor-gateway.comparethemarket.com/product/car/newquote?affclie=FE14&SRC=FE14&cmpid=PS-_-TCV-_-PC-_-ALL-_-UVDsJxMwN1wnbWj',
  VAN: 'https://partnerships-motor-gateway.comparethemarket.com/product/van/newquote?affclie=FE15&SRC=FE15&cmpid=PS-_-TCV-_-LC-_-ALL-_-WTMLs4tY3RhC0tL',
  VAN_EMAIL:
    'https://partnerships-motor-gateway.comparethemarket.com/product/van/newquote?affclie=FE16&SRC=FE16&cmpid=PS-_-TCV-_-LC-_-ALL-_-DixfCnq1TUteJdA',
  VAN_EMAIL_CANCELLATION:
    'https://partnerships-motor-gateway.comparethemarket.com/product/van/newquote?affclie=FE17&SRC=FE17&cmpid=PS-_-TCV-_-LC-_-ALL-_-wfKaNyvxIr1C4Jl',
}

export const CALLBACK_SOURCE = {
  Website: 'Website',
  Email: 'Email',
}

export const REQUEST_CALLBACK_STATUS = {
  WAITING: 0,
  REQUESTING: 1,
  SUCCESS: 2,
  ERROR: 3,
}

export const ANNUAL_UPSELL_URL = 'api/motorQuotePolicy/requestAnnualCallback'

export const ANNUAL_CALLBACK_PROVIDER = {
  Confused: {
    Id: 'Confused',
    RedirectUrl:
      'https://www.confused.com/car-insurance?utm_medium=referrer&utm_source=tempcover.com&utm_campaign=synergy2_ph1',
  },
  ConfusedRTP: {
    Id: 7,
    RedirectUrl: config.CONFUSED_QUICK_QUOTE_REDIRECT_URL,
  },
}
