import { updateRequiredFieldsSaga } from 'containers/RiskDataCollectionPage/sagas/validation'
import {
  REQUEST_VEHICLE,
  REQUEST_VEHICLE_FAILED,
  REQUEST_VEHICLE_SUCCESS,
  SET_VEHICLE,
  UPDATE_SEARCHED_VEHICLE,
  GET_MAKES,
  GET_MAKES_SUCCESS,
  GET_MODELS,
  GET_MODELS_SUCCESS,
  GET_VARIANTS_SUCCESS,
  GET_VARIANTS,
  CREATE_UNKNOWN_VEHICLE,
  CREATE_UNKNOWN_VEHICLE_SUCCESS,
  GET_MAKES_FAILURE,
  GET_MODELS_FAILURE,
  GET_VARIANTS_FAILURE,
  CREATE_UNKNOWN_VEHICLE_FAILURE,
  UNKNOWN_VEHICLE_REG,
  UNKNOWN_VEHICLE_REG_SUCCESS,
  UNKNOWN_VEHICLE_REG_FAILURE,
  SET_VEHICLE_SEARCHED_REG,
  SET_VEHICLE_ERROR,
  UPDATE_VEHICLE_VALUE,
  UPDATE_SEARCHED_VEHICLE_VALUE,
  SET_VEHICLE_TYPE,
  UPDATE_GROSS_WEIGHT,
  UPDATE_YEAR_OF_MANUFACTURE,
  SET_VEHICLE_LISTENER,
  VEHICLE_TYPE_CHANGE,
  UPDATE_PREVIOUS_VEHICLE,
  REQUEST_PREVIOUS_VEHICLE,
  REQUEST_PREVIOUS_VEHICLE_SUCCESS,
  REQUEST_PREVIOUS_VEHICLE_FAILED,
  REQUEST_PREVIOUS_MANUAL_BUILDER,
  CLEAR_SEARCHED_VEHICLE,
  SET_VEHICLE_FROM_CAROUSEL,
  SET_CUSTOMER_ACCOUNT_CAROUSEL_REG,
  SET_NEW_CUSTOMER_ACCOUNT_VEHICLE_FROM_HOME_PAGE,
  UPDATE_VEHICLE_ALREADY_EXISTS,
  UPDATE_SELECTED_VEHICLE_BODY_TYPE,
  UPDATE_IF_MANUALLY_BUILT,
} from './constants'

export function requestVehicle(registrationNumber) {
  return {
    type: REQUEST_VEHICLE,
    registrationNumber,
  }
}

export function requestPreviousVehicle(registrationNumber) {
  return {
    type: REQUEST_PREVIOUS_VEHICLE,
    registrationNumber,
  }
}

export function updatePreviousVehicle(previousRiddenVehicle) {
  return {
    type: UPDATE_PREVIOUS_VEHICLE,
    previousRiddenVehicle,
  }
}

export function updateSearchedVehicle(vehicle) {
  return {
    type: UPDATE_SEARCHED_VEHICLE,
    vehicle,
  }
}
export function requestVehicleSuccess(vehicle) {
  return {
    type: REQUEST_VEHICLE_SUCCESS,
    vehicle,
  }
}

export function requestVehicleFailed(registrationNumber, error) {
  return {
    type: REQUEST_VEHICLE_FAILED,
    registrationNumber,
    error,
  }
}

export function requestPreviousVehicleSuccess(previousRiddenVehicle) {
  return {
    type: REQUEST_PREVIOUS_VEHICLE_SUCCESS,
    previousRiddenVehicle,
  }
}

export function requestPreviousVehicleFailed(registrationNumber, error) {
  return {
    type: REQUEST_PREVIOUS_VEHICLE_FAILED,
    registrationNumber,
    error,
  }
}

export function requestPreviousManualBuilder() {
  return {
    type: REQUEST_PREVIOUS_MANUAL_BUILDER,
  }
}

export function setVehicle(vehicle) {
  return {
    type: SET_VEHICLE,
    vehicle,
  }
}

export function setVehicleListener(vehicle) {
  return {
    type: SET_VEHICLE_LISTENER,
    vehicle,
  }
}

export function setVehicleSearchedReg(vehicleReg, vehicleType) {
  return {
    type: SET_VEHICLE_SEARCHED_REG,
    vehicleReg,
    vehicleType,
  }
}

export function setVehicleError(error) {
  return {
    type: SET_VEHICLE_ERROR,
    error,
  }
}

export function getMakes() {
  return {
    type: GET_MAKES,
  }
}

export function getMakesSuccess(makes, links) {
  return {
    type: GET_MAKES_SUCCESS,
    makes,
    links,
  }
}

export function getMakesFailure(error) {
  return {
    type: GET_MAKES_FAILURE,
    error,
  }
}

export function getModels() {
  return {
    type: GET_MODELS,
  }
}

export function getModelsSuccess(models, links) {
  return {
    type: GET_MODELS_SUCCESS,
    models,
    links,
  }
}

export function getModelsFailure(error) {
  return {
    type: GET_MODELS_FAILURE,
    error,
  }
}

export function getVariants() {
  return {
    type: GET_VARIANTS,
  }
}

export function getVariantsSuccess(variants, links) {
  return {
    type: GET_VARIANTS_SUCCESS,
    variants,
    links,
  }
}

export function getVariantsFailure(error) {
  return {
    type: GET_VARIANTS_FAILURE,
    error,
  }
}

export function createUnkownVehicle() {
  return {
    type: CREATE_UNKNOWN_VEHICLE,
  }
}

export function createUnkownVehicleSuccess(vehicle) {
  return {
    type: CREATE_UNKNOWN_VEHICLE_SUCCESS,
    vehicle,
  }
}

export function createUnkownVehicleFailure(error) {
  return {
    type: CREATE_UNKNOWN_VEHICLE_FAILURE,
    error,
  }
}

export function unknownReg() {
  return {
    type: UNKNOWN_VEHICLE_REG,
  }
}

export function unknownRegSuccess(builtVehicle, manualVehicle) {
  return {
    type: UNKNOWN_VEHICLE_REG_SUCCESS,
    builtVehicle,
    manualVehicle,
  }
}

export function unknownRegFailure() {
  return {
    type: UNKNOWN_VEHICLE_REG_FAILURE,
  }
}

export function updateVehicleValue(value) {
  return {
    type: UPDATE_VEHICLE_VALUE,
    value,
  }
}

export function updateSearchedVehicleValue(value) {
  return {
    type: UPDATE_SEARCHED_VEHICLE_VALUE,
    value,
  }
}

export function setVehicleType(vehicleType) {
  return {
    type: SET_VEHICLE_TYPE,
    vehicleType,
  }
}

export function updateGrossWeight(grossWeight) {
  return {
    type: UPDATE_GROSS_WEIGHT,
    grossWeight,
  }
}

export function updateYearOfManufacture(yearOfManufacture) {
  return {
    type: UPDATE_YEAR_OF_MANUFACTURE,
    yearOfManufacture,
  }
}

export function vehicleTypeChange() {
  return {
    type: VEHICLE_TYPE_CHANGE,
  }
}

export function setNewCustomerAccountVehicleFromHomePage(
  preloadNewCustomerAccountVehicle,
) {
  return {
    type: SET_NEW_CUSTOMER_ACCOUNT_VEHICLE_FROM_HOME_PAGE,
    preloadNewCustomerAccountVehicle,
  }
}

export function clearSearchedVehicle() {
  return {
    type: CLEAR_SEARCHED_VEHICLE,
  }
}

export function setVehicleFromCarousel(vehicle) {
  return {
    type: SET_VEHICLE_FROM_CAROUSEL,
    vehicle,
  }
}

export function setCustomerAccountCarouselReg(reg) {
  return {
    type: SET_CUSTOMER_ACCOUNT_CAROUSEL_REG,
    reg,
  }
}
export function updateVehicleAlreadyExists(alreadyExists) {
  return {
    type: UPDATE_VEHICLE_ALREADY_EXISTS,
    alreadyExists,
  }
}

export function updateSelectedVehicleBodyType(commercialVehicleBodyType) {
  return {
    type: UPDATE_SELECTED_VEHICLE_BODY_TYPE,
    commercialVehicleBodyType,
  }
}

export function updateIfManuallyBuilt(manuallyBuilt) {
  return {
    type: UPDATE_IF_MANUALLY_BUILT,
    manuallyBuilt,
  }
}
