import { call, put, select } from 'redux-saga/effects'
import {
  requestAffiliate,
  requestAffiliateSuccess,
  requestAffiliateFailure,
} from '../actions'
import affiliateApi from '../../../api/affiliateApi'
import { requestAffiliateLink } from '../selector'

export function* getAffiliate(affiliateRef) {
  try {
    yield put(requestAffiliate())
    const affiliateLink = yield select(requestAffiliateLink)
    const response = yield call(
      affiliateApi.fetchAffiliate,
      affiliateLink.Href.replace(/{[affiliateRef}]*}/, affiliateRef),
    )
    yield put(requestAffiliateSuccess(response.data))
  } catch (err) {
    yield put(requestAffiliateFailure(err))
  }
}

export default getAffiliate
