import { P } from 'components/common/P'
import styled from 'styled-components'

export const TrustPilotSummaryWrapper = styled.div`
  p {
    display: inline;
  }
`

export const TrustPilotLogoWrapper = styled.span`
  vertical-align: top;
`

export const TrustPilotSummaryBold = styled(P)`
  font-weight: bold;
`

export const TrustPilotStarsWrapper = styled.div`
  display: inline;
  margin-left: 4px;
  margin-right: 4px;
`
