import React from 'react'
import { ReactComponent as ComingSoonImage } from 'images/clockwithcar.svg'
import LinkButton from '../LinkButton'
import {
  NotActiveFeatureContentStyled,
  NotActiveFeatureWrapperStyled,
} from './NotActiveFeature.styles'

interface Props {
  isVisible: boolean
  feature: string
  product: string
  buttonText: string
  buttonId: string
  buttonLink: string
  onClose: () => void
}

const NotActiveFeature: React.FC<Props> = ({
  isVisible,
  feature,
  product,
  buttonText,
  buttonId,
  buttonLink,
  onClose,
}) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <NotActiveFeatureWrapperStyled
      isVisible={isVisible}
      fullscreen
      onClick={handleClose}
    >
      <NotActiveFeatureContentStyled>
        <ComingSoonImage className='coming-soon-image' />
        <h3>Coming soon</h3>
        <p>
          {feature} aren't currently available for account holders. Please use
          the guest journey below for now. Any policies bought for {product}{' '}
          won't appear in your account area. They'll still be available via your
          policy confirmation email.
        </p>
        <LinkButton
          url={buttonLink}
          id={buttonId}
          linkName={buttonText}
          className='get-a-quote'
        />
      </NotActiveFeatureContentStyled>
    </NotActiveFeatureWrapperStyled>
  )
}

export default NotActiveFeature
