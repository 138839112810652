/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import GridContainerWrapper from 'components/@common/GridContainerWrapper'
import PriceComparisonDriverDetails from 'components/PriceComparison/PriceComparisonDriverDetails'
import { QuoteDetailsWrapper } from 'components/PriceComparison/styles'
import SecurityLogos from 'components/SecurityLogos'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { scroller } from 'react-scroll'
import { showExperiment } from 'store/experiment/experiment.actions'
import { Grid } from 'unsemantic'
import useCustomerAccountFeatureFlag from 'hooks/useCustomerAccountFeatureFlag'
import GapActivation from 'components/GapActivation'
import queryString from 'query-string'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { ClearFix } from '../../components/common/ClearFix'
import ContinueButton from '../../components/ContinueButton'
import { ErrorContainer } from '../../components/ErrorContainer'
import { Message } from '../../components/Message'
import { ErrorIcon } from '../../components/Message/ErrorIcon'
import { MessageContent } from '../../components/Message/MessageContent'
import { MessageHeader } from '../../components/Message/MessageHeader'
import PriceComparison from '../../components/PriceComparison'
import config from '../../config'
import {
  selectPrice,
  updateCampaignData,
  updateFreeGapActivationSelected,
  updateStep,
} from '../../containers/App/actions'
import { showBreakdownIcon } from '../../containers/PaymentBreakdownIcon/actions'
import QuoteSummary from '../../containers/QuoteSummary'
import {
  isButtonDisabled,
  updateQuote,
  updateRiskData,
} from '../../containers/RiskDataCollectionPage/actions'
import {
  useABExperiment,
  useAffiliate,
  useDuration,
  usePrice,
  useRiskData,
  useSystemFeatures,
  useVehicle,
} from '../../hooks'
import { InitialStateType, PriceState } from '../../initialState'
import logFullStoryCustomEvent from '../../utils/fullstory-custom-event'
import CashbackBanner from './components/CashbackBanner'
import DrivingLicenceAB from './components/DrivingLicenceAB'
import './driving-licence.css'
import {
  PriceComparisonWrapper,
  QuotesHeader,
  SecurityLogosWrapper,
} from './DrivingLicence.styles'

const DrivingLicence: React.FC = () => {
  const dispatch = useDispatch()

  const { variant: multiPriceVariant } = useABExperiment(
    config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID,
    'Price comparison experiment',
  )

  const [underwriterChanged, setUnderwriterChanged] = useState<boolean>(false)
  // const [showLoader, setShowLoader] = useState<boolean>(true);

  const [comparisonSelectedPriceIndex, setComparisonSelectedPriceIndex] =
    useState<number | null>(null)

  const priceComparisonContainer = useRef(null)

  const myLicence = useSelector((store: InitialStateType) => store.myLicence)
  const location = useLocation<{ fraudFailure: boolean }>()
  const address = useSelector((store: InitialStateType) => store.address)
  const isDrivingLicenceFormButtonDisabled = useSelector(
    (store: InitialStateType) => store.formButtons.driverLicence.disabled,
  )
  const quoteUnderwriter = useSelector(
    (store: InitialStateType) => store.quote.Underwriter,
  )

  const { registrationNumber } = useVehicle()
  const { duration } = useDuration()
  const { affiliate } = useAffiliate()
  const { price } = usePrice()
  const { riskData } = useRiskData()
  const { IsCashbackEnabled } = useSystemFeatures()
  const history = useHistory()

  const { data: featureFlagEnabled, isLoading: featureFlagLoading } =
    useCustomerAccountFeatureFlag('FreeGAPActivationJourney')

  const isGapActivationEnabled =
    price.FreeGAPInsuranceCover.FreeGAPInsuranceAvailable &&
    !featureFlagLoading &&
    featureFlagEnabled &&
    featureFlagEnabled?.data

  const [freeGapSelected, setFreeGapSelected] = useState(
    price.FreeGAPInsuranceCover.FreeGapAdded,
  )

  useEffect(() => {
    if (multiPriceVariant && multiPriceVariant.length === 0) {
      dispatch(
        showExperiment({
          [config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID]:
            Number(multiPriceVariant),
        }),
      )
    }
  }, [multiPriceVariant, dispatch])

  useEffect(() => {
    const parsedQueryString = queryString.parse(window.location.search)
    const queryStringAffiliateRef = parsedQueryString.ref

    if (
      queryStringAffiliateRef !== undefined &&
      queryStringAffiliateRef !== '' &&
      queryStringAffiliateRef !== null &&
      affiliate.Ref !== null &&
      queryStringAffiliateRef !== affiliate.Ref
    ) {
      history.push(
        history.location.search.replace(
          queryStringAffiliateRef.toString(),
          affiliate.Ref,
        ),
      )
    }
  }, [affiliate, history])

  const fraudFailure = useMemo(() => {
    if (location.state) {
      return location.state.fraudFailure
    }

    return undefined
  }, [location])

  const AddressInfo = useMemo(() => {
    if (address.SelectedAddress) {
      return address.SelectedAddress
    }

    return `${address.AddressLineOne} ${address.AddressLineTwo} ${address.AddressLineThree} ${address.AddressLineFour}`
      .trim()
      .concat(` ${address.Postcode}`)
  }, [address])

  const isContinueButtonDisabled = useMemo(
    () => isDrivingLicenceFormButtonDisabled || price.isUpdating,
    [price.isUpdating, isDrivingLicenceFormButtonDisabled],
  )

  const isPriceLoading = useMemo(
    () => price.IsAddonUpdating,
    [price.IsAddonUpdating],
  )

  const hasQuotes = useMemo(
    () =>
      price.TotalPrice > 0 ||
      (price.Prices && price.Prices.filter((p) => p.TotalPrice > 0).length > 0),
    [price],
  )

  const handlePriceSelected = (priceIndex: number | null) => {
    setTimeout(() => {
      scroller.scrollTo(`PriceBlock_${priceIndex}`, {
        duration: 500,
        delay: 0,
        offset: -20,
        smooth: 'easeInOutQuart',
      })
    }, 70)

    setComparisonSelectedPriceIndex(priceIndex)
  }

  const handleContinueClick = (priceValue: PriceState | null = null) => {
    // setShowLoader(false);
    dispatch(
      isButtonDisabled({
        buttonName: 'driverLicence',
        disabled: true,
      }),
    )

    if (!priceValue) {
      dispatch(
        updateQuote({
          partialValidation: true,
          confirmRiskData: true,
          getPrice: false,
          requestConfusedQuickQuote: true,
          gapActivationIncluded: freeGapSelected,
        }),
      )
      dispatch(updateFreeGapActivationSelected(freeGapSelected))
    } else {
      dispatch(selectPrice(priceValue))

      // update discount if there
      dispatch(updateCampaignData(priceValue))
    }
  }

  useEffect(() => {
    dispatch(updateStep(1))
    dispatch(showBreakdownIcon(null))

    if (
      (quoteUnderwriter &&
        quoteUnderwriter !== 'Uninitialised' &&
        price.Underwriter &&
        quoteUnderwriter !== price.Underwriter) ||
      price.UnderwriterChanged
    ) {
      setUnderwriterChanged(true)
    }
  }, [dispatch, price.Underwriter, price.UnderwriterChanged, quoteUnderwriter])

  useEffect(() => {
    if (underwriterChanged) {
      logFullStoryCustomEvent('Price page - Price Change')
    }
  }, [underwriterChanged])

  useEffect(() => {
    setComparisonSelectedPriceIndex(null)
  }, [registrationNumber, duration])

  useEffect(() => {
    if (!hasQuotes) {
      logFullStoryCustomEvent('Price page - No Quote')
    }
  }, [hasQuotes])

  useEffect(() => {
    if (price.Prices && comparisonSelectedPriceIndex === null) {
      const existingQuoteUnderwriterPriceIndex = price.Prices.findIndex(
        (p) => p.Underwriter === price.Underwriter,
      )
      if (existingQuoteUnderwriterPriceIndex >= 0) {
        setComparisonSelectedPriceIndex(existingQuoteUnderwriterPriceIndex)
      }
    }
  }, [price, comparisonSelectedPriceIndex])

  const selectFreeGapSelected = (selected: boolean) => {
    setFreeGapSelected(selected)
  }

  return (
    <>
      <GridContainerWrapper pageBottomPadding overflowX='hidden'>
        {/* @ts-ignore TOOD: Revisit as updateQuote isnt a boolean in TS */}
        <QuoteSummary updateQuote />
        {myLicence.CheckFailed &&
          myLicence.FailReasons.indexOf('LicenceHeld') >= 0 && (
            <Message
              heading='Your licence held duration has been updated'
              content='Based on the information from your driving licence, we have updated the duration you have held your licence to match the details held by the DVLA. By proceeding with this quote you acknowledge and agree that this information is correct.'
              type='info'
            />
          )}

        {underwriterChanged && (
          <Message
            heading='The quote has been updated'
            content='Please continue and ensure you meet the criteria outlined on the next page'
            type='info'
          />
        )}
        {price.UnderwriterChangedByCue && hasQuotes && (
          <Message
            heading='The quote has been updated'
            content='Based on the information you have provided and/or information we have found, a new insurer has been selected, and your price has been updated. Please ensure you have checked the price and are happy to proceed before continuing.'
            type='info'
          />
        )}
        {fraudFailure && (
          <ErrorContainer>
            <Grid desktop='20' tablet='20' mobile='20'>
              <ErrorIcon className='fa fa-exclamation-triangle' />
            </Grid>
            <Grid desktop='80' tablet='80' mobile='80'>
              <MessageHeader>
                There has been an issue with your quote.
              </MessageHeader>
              <MessageContent>
                Please ensure all data is valid and try again.
              </MessageContent>
            </Grid>
            <ClearFix />
          </ErrorContainer>
        )}
        {/* <KlarnaPriceInfoWrapper>
          <KlarnaPriceInfo />
        </KlarnaPriceInfoWrapper> */}
        {/* {(!affiliate.AffiliateId && priceUpsellVariant === '1')
          && (<GridContainer>
            <PriceUpsellWrapper>
              <PriceUpsell animation />
            </PriceUpsellWrapper>
          </GridContainer>
          )
        } */}
        {!affiliate.PriceComparison && <DrivingLicenceAB />}
        {!registrationNumber && (
          <ErrorContainer>
            <Grid desktop='20' tablet='20' mobile='20'>
              <ErrorIcon className='fa fa-exclamation-triangle' />
            </Grid>
            <Grid desktop='80' tablet='80' mobile='80'>
              <MessageContent>
                We are unable to proceed any further without a registration.
                Please enter a valid reg to continue.
              </MessageContent>
            </Grid>
            <ClearFix />
          </ErrorContainer>
        )}
        {!hasQuotes && (
          <>
            {affiliate.PriceComparison ||
              (multiPriceVariant === '1' && (
                // @ts-ignore
                <QuoteDetailsWrapper standalone>
                  <PriceComparisonDriverDetails
                    addressInfo={AddressInfo}
                    showAmendLink
                    standalone
                  />
                </QuoteDetailsWrapper>
              ))}
            <ErrorContainer>
              <Grid desktop='20' tablet='20' mobile='20'>
                <ErrorIcon className='fa fa-exclamation-triangle' />
              </Grid>
              <Grid desktop='80' tablet='80' mobile='80'>
                <MessageContent>
                  We have been unable to offer you a quote at this time.
                </MessageContent>
                {affiliate.PriceComparison ||
                  (multiPriceVariant === '1' && (
                    <MessageContent>
                      Please check the above details are correct.
                    </MessageContent>
                  ))}
                <MessageContent>
                  We are regularly reviewing our products so we may be able to
                  cover you in the future.
                </MessageContent>
              </Grid>
              <ClearFix />
            </ErrorContainer>
          </>
        )}
        {affiliate.PriceComparison &&
          registrationNumber &&
          hasQuotes &&
          affiliate.IsCashbackEnabled &&
          IsCashbackEnabled && <CashbackBanner />}
      </GridContainerWrapper>
      <PriceComparisonWrapper>
        {(affiliate.PriceComparison || multiPriceVariant === '1') &&
          registrationNumber &&
          hasQuotes && (
            <>
              {!affiliate.IsCashbackEnabled && (
                <QuotesHeader>Your quotes:</QuotesHeader>
              )}
              <div
                className='price-comparison-container'
                ref={priceComparisonContainer}
              >
                {price.Prices && (
                  <>
                    {price.Prices.map((priceData, index) => (
                      <PriceComparison
                        key={`price_${index}`}
                        priceData={priceData}
                        selectedUnderwriter={price.UnderwriterId}
                        onContinue={() => handleContinueClick(priceData)}
                        continueButtonDisabled={isContinueButtonDisabled}
                        onSelect={handlePriceSelected}
                        isSelected={index === comparisonSelectedPriceIndex}
                        index={index}
                        addressInfo={AddressInfo}
                        cheapest={
                          price.Prices.filter(
                            (p) => p.TotalPriceBeforeAddons > 0,
                          ).length > 1 && index === 0
                        }
                      />
                    ))}
                  </>
                )}
              </div>
            </>
          )}
      </PriceComparisonWrapper>
      <GridContainerWrapper>
        {!affiliate.PriceComparison &&
          multiPriceVariant !== '1' &&
          price.Underwriter &&
          registrationNumber &&
          hasQuotes && (
            <Grid desktop='100'>
              {/* Re enabled below for A/B testing */}
              {/* {isGapActivationEnabled && (
                <GapActivation
                  selectGap={selectFreeGapSelected}
                  gapSelected={freeGapSelected}
                />
              )} */}

              <ContinueButton
                fullWidth
                type='submit'
                id='TeslaSubmitPricePageNextButton'
                onClick={() => handleContinueClick()}
                disabled={isContinueButtonDisabled || isPriceLoading}
                data-email={riskData.EmailAddress}
                className={
                  myLicence.DisabledBySetting
                    ? `uw_${price.Underwriter}`
                    : myLicence.Enabled
                    ? `ab_mylicence_on uw_${price.Underwriter}`
                    : `ab_mylicence_off uw_${price.Underwriter}`
                }
              >
                {isContinueButtonDisabled ? 'Please wait' : 'Continue'}
                <ButtonSpinner
                  disabled={isContinueButtonDisabled || isPriceLoading}
                />
              </ContinueButton>
              <SecurityLogosWrapper>
                <SecurityLogos />
              </SecurityLogosWrapper>
            </Grid>
          )}
      </GridContainerWrapper>
    </>
  )
}

export default DrivingLicence
