import React, { useRef, useState } from 'react'
import config from 'config'

import ContinueButton from 'components/ContinueButton'
import {
  AddonMoreInfoBody,
  AddonMoreInfoText,
  AddonMoreInfoTitle,
  AddonMoreInfoWrapper,
  PaymentPageLinkContainer,
} from 'components/Addons/AddonMoreInfo/AddonMoreInfo.styles'
import AddonMoreInfomItem from 'components/Addons/AddonMoreInfo/AddonMoreInfoItem'
import { useAddons, useScheme } from 'hooks'
import { Free30DayGAPInsuranceIncluded } from 'components/SVG/add-on-icons/Free30DayGAPInsuranceIncluded'
import {
  AddonContentContainer,
  AddonContentHeader,
  AddonLogoContainer,
  AddonContentBody,
  AddonPriceContainer,
  Price,
  AddonButton,
  TextForGapPrice,
  LinkContainer,
} from 'components/Addons/AddonPriceComparisonContainer.styles'
import {
  GapActivationContatiner,
  GapTitle,
  GapText,
  GapModalStyles,
} from './styles'

const GapDetailsModal = () => {
  const { scheme } = useScheme()
  const {
    gapinsurance: { staticDocuments: gapinsuranceDocuments },
  } = useAddons()
  const documentStartDate = new Date().toISOString().slice(0, 10)
  return (
    <>
      <AddonMoreInfoTitle>More info about GAP Insurance</AddonMoreInfoTitle>
      <AddonMoreInfoBody>
        <div>
          <AddonMoreInfoText>
            GAP Insurance protects the lifetime value of your vehicle by topping
            up your main insurer&apos;s payout in the unfortunate event your
            vehicle is written off or stolen (and not recovered).
          </AddonMoreInfoText>
          <AddonMoreInfoText>
            <b>Why would you need GAP Insurance?</b>
            <br />
            If your vehicle is declared a total loss, your motor insurer will
            payout the value of your vehicle at the time of your claim. Given
            most vehicles lose up to 60% of their original value during their
            first 3 years, that payout can be less than expected and usually, a
            lot less than you originally paid for your vehicle.
          </AddonMoreInfoText>
          <AddonMoreInfoText>
            <b>How does it work?</b>
            <br />
            The Free 30 Days GAP Insurance is administered by our partner,
            Motoreasy and works by topping up a motor insurer&apos;s payout,
            covering the shortfall, up to the vehicle&apos;s original value when
            your GAP policy was issued. This will allow you to get the
            additional funds needed to purchase a replacement vehicle or payoff
            any outstanding finance you might owe. You&apos;ll need to activate
            your policy for it to start. As part of the activation process,
            you&apos;ll create a Motoreasy account where you&apos;ll be able to
            access your policy documents.
          </AddonMoreInfoText>
          <AddonMoreInfoText>
            <b>
              After the 30 days free period, this policy will expire. Motoreasy
              have longer durations available and details can be found on their
              website.
            </b>
          </AddonMoreInfoText>
          <AddonMoreInfoText>
            To be valid for a claim, the following must apply:
            <ol style={{ listStyle: 'disc' }}>
              <li>
                You must be the registered owner and, or keeper of the vehicle
              </li>
              <li>The vehicle must be under 8 years old</li>
              <li>Less than 70,000 recorded miles</li>
              <li>Insured value of less than £75,000.</li>
              <li>Be UK specification and not modified</li>
            </ol>
          </AddonMoreInfoText>
          <AddonMoreInfoText>See links below for details:</AddonMoreInfoText>
        </div>
        <ul>
          <AddonMoreInfomItem
            content='GAP Insurance IPID'
            doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${gapinsuranceDocuments.ipid}/versions/${documentStartDate}`}
          />
          <AddonMoreInfomItem
            content='GAP Insurance Policy Wording'
            doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${gapinsuranceDocuments.policyWording}/versions/${documentStartDate}`}
          />
        </ul>
      </AddonMoreInfoBody>
    </>
  )
}

export interface Props {
  selectGap: (result: boolean) => void
  gapSelected: boolean
}

const GapActivationAddon: React.FC<Props> = ({ selectGap, gapSelected }) => {
  const [componentVisible, setComponentVisible] = useState(false)

  const showModal = () => {
    setComponentVisible(true)
  }

  const UpdateSelectedGap = () => {
    selectGap(true)
  }

  const removeSelectedGap = () => {
    selectGap(false)
  }

  return (
    <>
      <GapActivationContatiner>
        <AddonLogoContainer>
          <Free30DayGAPInsuranceIncluded height={1} width={1} />
        </AddonLogoContainer>
        <AddonContentContainer>
          <>
            <div>
              <AddonContentHeader id='addon-header-freegapinsurance'>
                Free 30 Days GAP Insurance
              </AddonContentHeader>
              <AddonContentBody>
                Tops up your insurer payout to the original purchase value if
                your vehicle is written off or stolen.
              </AddonContentBody>
            </div>
            {/* {gapSelected && ( */}
            <>
              <br />
              <TextForGapPrice>
                You will be sent an email after purchase to activate your free
                Gap policy.
              </TextForGapPrice>
            </>
            {/* )} */}
            <PaymentPageLinkContainer
              id='addon-detailslink-gapinsurance'
              onClick={showModal}
            >
              Full details <i className='fa fa-external-link-alt fa-xs' />
            </PaymentPageLinkContainer>
          </>
        </AddonContentContainer>
        <AddonPriceContainer>
          <Price id='addon-price-gapinsurance' active={false}>
            Free
          </Price>
          {!gapSelected && (
            <>
              <AddonButton id='AddGapInsurance' onClick={UpdateSelectedGap}>
                Add
              </AddonButton>
              <TextForGapPrice>
                You can then choose to extend or let it expire.
              </TextForGapPrice>
            </>
          )}
          {gapSelected && (
            <>
              <AddonButton id='addon-addbtn-gapinsurance' active disabled>
                ADDED
              </AddonButton>
              <TextForGapPrice>
                You can then choose to extend or let it expire.
              </TextForGapPrice>
              <LinkContainer
                id='addon-remove-gapinsurance'
                onClick={removeSelectedGap}
              >
                Remove
              </LinkContainer>
            </>
          )}
        </AddonPriceContainer>
      </GapActivationContatiner>
      {componentVisible && (
        <GapModalStyles>
          <AddonMoreInfoWrapper id='addon-detailsmodal-gapinsurance'>
            <GapDetailsModal />
            <hr />
            <div>
              <PaymentPageLinkContainer
                id='addon-closedetails-gapinsurance'
                onClick={() => setComponentVisible(false)}
              >
                Close
              </PaymentPageLinkContainer>
            </div>
          </AddonMoreInfoWrapper>
        </GapModalStyles>
      )}
    </>
  )
}
export default GapActivationAddon
