import React from 'react'
import { H1 } from '../common/H1'
import GridContainerWrapper from 'components/@common/GridContainerWrapper'

export const PageNotFound = () => {
  return (
    <GridContainerWrapper>
      <H1>404 - Page not found</H1>
    </GridContainerWrapper>
  )
}
