import * as yup from 'yup'
import moment from 'moment'

export const QuoteParamsSchema = yup.object().shape({
  manuallyChanged: yup.boolean().notRequired(),
  policyStartTime: yup.string().when('manuallyChanged', {
    is: true,
    then: yup
      .string()
      .test('policyStartTime', 'Please enter a valid start date', (value) => {
        const startDateTime = moment(value)
        const now = moment()
        return (
          !!value &&
          /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/.test(value) &&
          startDateTime.isValid() &&
          startDateTime.isAfter(now)
        )
      }),
    otherwise: yup.string().notRequired(),
  }),
})
