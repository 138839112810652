import queryString from 'query-string'
import { createBrowserHistory } from 'history'

const baseHistory = createBrowserHistory()

export const createHistory = () => {
  return {
    ...baseHistory,
    push: (location, state) => {
      let parsed
      if (typeof location === 'string') {
        parsed = queryString.parseUrl(location)
        parsed.query = addMissingUtmTags(parsed.query)
        const url = queryString.stringifyUrl(parsed)
        baseHistory.push(url, state)
      } else {
        const { search } = location
        parsed = queryString.parse(search)
        parsed = addMissingUtmTags(parsed)
        const updatedSearch = queryString.stringify(parsed)
        baseHistory.push({ ...location, search: updatedSearch })
      }
    },
    replace: (location, state) => {
      let parsed
      if (typeof location === 'string') {
        parsed = queryString.parseUrl(location)
        parsed.query = addMissingUtmTags(parsed.query)
        const url = queryString.stringifyUrl(parsed)
        baseHistory.replace(url, state)
      } else {
        const { search } = location
        parsed = queryString.parse(search)
        parsed = addMissingUtmTags(parsed)
        const updatedSearch = queryString.stringify(parsed)
        baseHistory.replace({ ...location, search: updatedSearch })
      }
    },
  }
}

export const addMissingUtmTags = (tags) => {
  let currentTags
  const { search } = window.location
  currentTags = queryString.parse(search)

  Object.keys(currentTags).forEach((key) => {
    key = key.toLowerCase()
    if (
      (key.startsWith('utm_') ||
        key.startsWith('ref') ||
        key.startsWith('sskey')) &&
      !Object.prototype.hasOwnProperty.call(tags, key)
    ) {
      tags[key] = currentTags[key]
    }
  })

  return tags
}
