import { InitialStateType } from 'initialState'
import { useSelector } from 'react-redux'

declare global {
  interface Window {
    dataLayer: any
  }
}
const useDataLayer = () => {
  const referenceNumber = useSelector(
    (state: InitialStateType) => state.quote.ReferenceNumber,
  )
  window.dataLayer = window.dataLayer || []

  const pushQuoteReferenceNumber = () => {
    window.dataLayer.push({
      event: 'quoteData',
      quoteId: referenceNumber,
    })
  }

  return {
    pushQuoteReferenceNumber,
  }
}

export default useDataLayer
