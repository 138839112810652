import { InitialiseQuoteRequest } from 'types/webAPI.definition'
// Not sure why we're getting a depdency cycle reported
// eslint-disable-next-line import/no-cycle
import http from './http'

export default class QuoteApi {
  static createQuote(endpoint, riskData) {
    return http.post(endpoint, riskData)
  }

  static updateQuote(endpoint, quoteData, partialValidation, scheme) {
    const contentType = `application/tempcover.${
      partialValidation ? '' : 'final'
    }updatequote${scheme}representation+json`

    return http.put(endpoint, quoteData, {
      headers: {
        Accept: 'application/tempcover.UpdateQuoteResultRepresentation+json',
        'Content-Type': contentType,
      },
    })
  }

  static updateUnderwriter(endpoint, data) {
    return http.put(endpoint, data, {
      headers: {
        Accept:
          'application/tempcover.UpdateQuotingUnderwriterAndPremiumUnitsRepresentation+json',
      },
    })
  }

  static async getPrice(endpoint, quoteData, headers) {
    return http
      .post(endpoint, quoteData, {
        headers,
      })
      .catch(async () => {
        await http.post(endpoint, quoteData, {
          headers,
        })
      })
      .catch(async () => {
        await http.post(endpoint, quoteData, {
          headers,
        })
      })
  }

  static getQuoteSummary(endpoint) {
    return http.get(endpoint)
  }

  static getMotorQuote(endpoint) {
    const isMotorcycleQuote = endpoint.includes(
      'VehicleMotorcycleLearnerAndFullLicenceScheme',
    )
    return http.get(endpoint, {
      headers: {
        Accept: `application/tempcover.${
          isMotorcycleQuote
            ? 'MotorcycleQuoteDetailsRepresentation+json'
            : 'PrivateCarMotorQuoteDetailsRepresentation+json'
        }`,
      },
    })
  }

  static unlockQuote(endpoint, data) {
    return http.put(endpoint, data, {
      headers: {
        Accept: 'application/tempcover.DecryptedQuoteRepresentation+json',
      },
    })
  }

  static initialiseQuote(data: InitialiseQuoteRequest) {
    return http.post('/api/quote/initialise', data)
  }
}
