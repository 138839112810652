import http from '../api/http'

export default class sessionApi {
  static createClientSession(endpoint, data) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.ClientSessionRepresentation+json',
      },
    })
  }

  static updateClientSessionQuote(endpoint, data) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.ClientSessionRepresentation+json',
      },
    })
  }
}
