import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import { useField } from 'formik'
import { DateTimePicker } from '../@common/DateTimePicker'

const PolicyDateTimePicker: React.FC = () => {
  const [validMinimumDate, setMinDate] = useState<string>(moment().format())
  const [validMaximumDate, setMaxDate] = useState<string>(moment().format())
  const [field, meta, helpers] = useField('policyStartTime')
  const [isManualField, isManualMeta, isManualHelpers] =
    useField('manuallyChanged')

  const signPosting = useMemo(() => {
    if (isManualField.value) {
      return 'Please make sure you buy your policy before the start time you select. If you buy your policy after that time, your policy will begin a few minutes later. You will see the exact start and end date/time on screen and in your documents after you buy your policy.'
    }

    return 'Policies starting immediately will begin a few minutes after you buy your policy. You will see the exact start and end date/time on screen and in your documents after you buy your policy.'
  }, [isManualField.value])

  useEffect(() => {
    const now = moment()
    let timeWithDelayAndInterval

    if (now.minute() % 5 === 0) {
      timeWithDelayAndInterval = moment(now).add(10, 'minutes')
    } else {
      const intervalDifference = 5 - (now.minute() % 5)
      timeWithDelayAndInterval = moment(now).add(
        5 + intervalDifference,
        'minutes',
      )
    }

    const minDate = timeWithDelayAndInterval.format('YYYY-MM-DDTHH:mm')
    const maxDate = timeWithDelayAndInterval
      .add(30, 'day')
      .hour(23)
      .minute(55)
      .format('YYYY-MM-DDTHH:mm')

    setMinDate(minDate)
    setMaxDate(maxDate)
  }, [])

  const onDateTimeChange = (dateTimeString: string) => {
    helpers.setValue(dateTimeString, false)
  }

  return (
    <div>
      <DateTimePicker
        name='policyStartTime'
        value={field.value}
        startDate={validMinimumDate}
        endDate={validMaximumDate}
        onDateTimeChange={onDateTimeChange}
        isManuallyChanged={isManualField.value}
        onManualChange={(isManual: boolean) =>
          isManualHelpers.setValue(isManual)
        }
        showTime={isManualField.value}
        hasError={!!meta.error}
        errorMessage={meta.error || ''}
        setError={helpers.setError}
        setTouched={helpers.setTouched}
      />
      <p>{signPosting}</p>
    </div>
  )
}

export default PolicyDateTimePicker
