import { useSelector } from 'react-redux'
import { InitialStateType } from 'initialState'

const useMyLicence = () => {
  const myLicence = useSelector((state: InitialStateType) => state.myLicence)
  return {
    myLicence,
  }
}

export default useMyLicence
